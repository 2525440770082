import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ErrorPageLayout } from 'components/Layout/ErrorPageLayout';
import { ErrorMessage } from 'modules/errors/components/ErrorMessage';
import { BackButton } from '../components/BackButton';
import { HomeButton } from '../components/HomeButton';

import { ReactComponent as NotFoundImage } from '../img/not-found.svg';

const m = defineMessages({
  header: {
    id: 'Errors.ErrorPage.NotFound.Header',
    defaultMessage: "Oups...on dirait que tu t'es perdu",
  },
  subHeader: {
    id: 'Errors.ErrorPage.NotFound.SubHeader',
    defaultMessage: "La page que tu recherche n'existe pas.",
  },
});

export const NotFoundError: React.FC = () => {
  const { formatMessage } = useIntl();

  const buttons = (
    <>
      <BackButton basic primary />
      <HomeButton primary />
    </>
  );

  return (
    <ErrorPageLayout>
      <ErrorMessage
        headerMessage={formatMessage(m.header)}
        subHeaderMessage={formatMessage(m.subHeader)}
        errorImage={NotFoundImage}
        buttons={buttons}
      />
    </ErrorPageLayout>
  );
};
