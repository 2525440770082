import { defaultErrorHandler } from 'api';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { PageHeader } from 'components/Layout/PageHeader';
import { PageLayout } from 'components/Layout/PageLayout';
import React, { Fragment, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { ProblematicsApi } from '../api';
import {
  ProblematicForm,
  ProblematicFormValues,
} from '../components/ProblematicForm';
import { useFetchProblematic } from '../hooks/useFetchProblematic';

const m = defineMessages({
  pageTitle: {
    id: 'Problematic.EditPage.PageTittle',
    defaultMessage: 'Modifier une préoccupation ou problématique',
  },
  savedSuccessfully: {
    id: 'Problematic.EditPage.SavedSuccessfully',
    defaultMessage:
      'La préoccupation ou problématique a été sauvegardé avec succès',
  },
});

interface Params {
  problematicId: string;
}

export const EditProblematicPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const params = useParams<Params>();
  const id = parseInt(params.problematicId, 10);
  const { problematic } = useFetchProblematic(id);
  const [saving, setSaving] = useState(false);

  const returnToProblematicsPage = () => {
    history.push(`/admin/problematics`);
  };

  const handleSubmit = (editedProblematic: ProblematicFormValues) => {
    setSaving(true);

    ProblematicsApi.update(id, editedProblematic)
      .then(() => {
        toast.success(formatMessage(m.savedSuccessfully));
        returnToProblematicsPage();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };

  if (problematic === null) {
    return null;
  }

  const showLoader = !problematic || saving;

  return (
    <Fragment>
      <PageLayout pageTitle={formatMessage(m.pageTitle)}>
        <PageHeader
          header={formatMessage(m.pageTitle)}
          iconName="warning sign"
        />
        {problematic && (
          <ProblematicForm problematic={problematic} onSubmit={handleSubmit} />
        )}
        {showLoader && <ApplicationLoader />}
      </PageLayout>
    </Fragment>
  );
};
