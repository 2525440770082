import React, { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button, List, Message } from 'semantic-ui-react';
import { resizeImage } from './imageResizer';

const m = defineMessages({
  upload: { id: 'ImageInput.Upload', defaultMessage: 'Téléverser' },
  delete: { id: 'ImageInput.Delete', defaultMessage: 'Supprimer' },
  invalidImageFile: {
    id: 'ImageInput.InvalidImageFile',
    defaultMessage: 'Veuillez choisir une image de moin de 10 Mo.',
  },
  resizeError: {
    id: 'ImageInput.ResizeError',
    defaultMessage:
      "Une erreur s'est produite lors du traitement de l'image. Veuillez réessayer avec une autre image.",
  },
  noImage: { id: 'ImageInput.NoImage', defaultMessage: 'Aucune image' },
});

type DisplayMode =
  | 'ShowExistingImage'
  | 'ShowUploadedImage'
  | 'NoImage';

const acceptedTypes = ['image/png', 'image/jpeg', 'image/gif'];
const maxFileSize = 10_000_000;

interface Props {
  image: string | undefined;
  onChange: (file: File | null | undefined) => void;
  maxImageSizePx?: number;
}

export const ImageInput: React.FC<Props> = ({
  image,
  onChange,
  maxImageSizePx,
}) => {
  const { formatMessage } = useIntl();
  const fileInput = useRef<HTMLInputElement>(null);
  const [mode, setMode] = useState<DisplayMode>('NoImage');
  const [hasExistingImage, setHasExistingImage] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string>();
  const [warningMessage, setWarningMessage] = useState<string | null>(null);

  useEffect(() => {
    setMode(image ? "ShowExistingImage" : "NoImage");
  }, [image]);

  const handleFileInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const isFilePresent = e.target.files && e.target.files.length > 0;
    if (!isFilePresent) return;

    const file = e.target.files![0];

    if (!validateFile(file)) {
      setWarningMessage(formatMessage(m.invalidImageFile));
      return;
    }

    try {
      const resizedImage = await resizeImage({
        file: file,
        maxSize: maxImageSizePx ?? 300,
      });
      const resizedFile = new File([resizedImage], file.name, {
        type: file.type,
      });
      const url = URL.createObjectURL(resizedFile);
      setWarningMessage(null);
      setUploadedImageUrl(url);
      onChange(resizedFile);
      setMode('ShowUploadedImage');
    } catch {
      setWarningMessage(formatMessage(m.resizeError));
    }
  };

  const validateFile = (file: File): boolean => {
    if (!acceptedTypes.includes(file.type)) return false;
    if (file.size > maxFileSize) return false;
    return true;
  };

  const handleDelete = () => {
    onChange(null);
    setMode('NoImage');
  };

  const handleExistingImageSuccess = () => {
    setHasExistingImage(true);
  };

  const handleExistingImageError = () => {
    setMode('NoImage');
  };

  const handleUpload = () => {
    fileInput.current && fileInput.current.click();
  };

  const showDeleteButton =
    mode !== 'NoImage' && (hasExistingImage || uploadedImageUrl);

  return (
    <Fragment>
      {warningMessage && <Message warning visible header={warningMessage} />}
      <div className="tw-flex">
        {mode === 'ShowExistingImage' && (
          <img
            src={image}
            className="tw-object-contain tw-object-left-top tw-w-64 tw-h-64"
            onError={handleExistingImageError}
            onLoad={handleExistingImageSuccess}
            alt="logo"
          />
        )}

        {mode === 'ShowUploadedImage' && (
          <img
            src={uploadedImageUrl}
            className="tw-object-contain tw-object-left-top tw-w-64 tw-h-64"
            alt="logo"
          />
        )}

        {mode === 'NoImage' && (
          <div
            className="tw-w-64 tw-h-64 tw-flex tw-justify-center tw-items-center tw-border"
            onClick={handleUpload}
          >
            <div>{formatMessage(m.noImage)}</div>
          </div>
        )}

        <div className="tw-pl-6">
          <List>
            <List.Item>
              <input
                ref={fileInput}
                type="file"
                onChange={handleFileInput}
                className="tw-hidden"
              />
              <Button fluid type="button" color="green" onClick={handleUpload}>
                {formatMessage(m.upload)}
              </Button>
            </List.Item>
            <List.Item>
              {showDeleteButton && (
                <Button
                  fluid
                  type="button"
                  basic
                  negative
                  onClick={handleDelete}
                >
                  {formatMessage(m.delete)}
                </Button>
              )}
            </List.Item>
          </List>
        </div>
      </div>
    </Fragment>
  );
};
