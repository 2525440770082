import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';

const m = defineMessages({
  matchAtLeastOne: {
    id: 'LogicalOperatorDropdown.MatchAtLeastOne',
    defaultMessage: 'correspondant à au moins une valeur',
  },
  matchAll: {
    id: 'LogicalOperatorDropdown.MatchAll',
    defaultMessage: 'correspondant à toutes ces valeurs',
  },
});

const defaultValue = 'or';

interface Props {
  value: string;
  onChange: (newValue: string) => void;
}

export const LogicalOperatorDropdown: React.FC<Props> = ({
  value,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  const options = [
    {
      key: 'or',
      value: 'or',
      text: formatMessage(m.matchAtLeastOne),
    },
    {
      key: 'and',
      value: 'and',
      text: formatMessage(m.matchAll),
    },
  ];

  return (
    <Dropdown
      className="tw-text-black tw-text-opacity-70"
      inline
      options={options}      
      value={value || defaultValue}
      onChange={(e, { value }) => onChange(value as string)}
    />
  );
};
