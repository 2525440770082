import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Table } from 'semantic-ui-react';
import { SortingDirection } from 'hooks/useTableSorting';
import { EntriesTableRow } from './EntriesTable';

const m = defineMessages({
  id: {
    id: 'Entries.Table.Id',
    defaultMessage: 'Id',
  },
  date: {
    id: 'Entries.Table.Date',
    defaultMessage: 'Date',
  },
  createdBy: {
    id: 'Entries.Table.CreatedBy',
    defaultMessage: 'Créé par',
  },
  team: {
    id: 'Entries.Table.Team',
    defaultMessage: 'Équipe',
  },
  meetingType: {
    id: 'Entries.Table.MeetingType',
    defaultMessage: 'Type de rencontre',
  },
  population: {
    id: 'Entries.Table.Population',
    defaultMessage: 'Population rejointe',
  },
  location: {
    id: 'Entries.Table.Location',
    defaultMessage: 'Lieu',
  },
  district: {
    id: 'Entries.Table.District',
    defaultMessage: 'Arrondissement',
  },
});

interface Props {
  sortingColumn: string;
  sortingDirection: SortingDirection;
  visibleColumns?: (keyof EntriesTableRow)[];
  onColumnSorting: (columnName: string) => void;
}

export const Headers: React.FC<Props> = ({
  sortingColumn,
  sortingDirection,
  visibleColumns,
  onColumnSorting,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Table.Header>
      <Table.Row>
        {(!visibleColumns || visibleColumns.includes('id')) && (
          <Table.HeaderCell
            sorted={sortingColumn === 'id' ? sortingDirection : undefined}
            onClick={() => onColumnSorting('id')}
          >
            {formatMessage(m.id)}
          </Table.HeaderCell>
        )}

        {(!visibleColumns || visibleColumns.includes('date')) && (
          <Table.HeaderCell
            sorted={sortingColumn === 'date' ? sortingDirection : undefined}
            onClick={() => onColumnSorting('date')}
          >
            {formatMessage(m.date)}
          </Table.HeaderCell>
        )}

        {(!visibleColumns ||
          visibleColumns.includes('createdByUserFullName')) && (
          <Table.HeaderCell
            sorted={
              sortingColumn === 'createdByUserFullName'
                ? sortingDirection
                : undefined
            }
            onClick={() => onColumnSorting('createdByUserFullName')}
          >
            {formatMessage(m.createdBy)}
          </Table.HeaderCell>
        )}

        {(!visibleColumns || visibleColumns.includes('teamName')) && (
          <Table.HeaderCell
            sorted={sortingColumn === 'teamName' ? sortingDirection : undefined}
            onClick={() => onColumnSorting('teamName')}
          >
            {formatMessage(m.team)}
          </Table.HeaderCell>
        )}

        {(!visibleColumns || visibleColumns.includes('meetingTypeName')) && (
          <Table.HeaderCell
            sorted={
              sortingColumn === 'meetingTypeName' ? sortingDirection : undefined
            }
            onClick={() => onColumnSorting('meetingTypeName')}
          >
            {formatMessage(m.meetingType)}
          </Table.HeaderCell>
        )}

        {(!visibleColumns || visibleColumns.includes('populationName')) && (
          <Table.HeaderCell
            sorted={
              sortingColumn === 'populationName' ? sortingDirection : undefined
            }
            onClick={() => onColumnSorting('populationName')}
          >
            {formatMessage(m.population)}
          </Table.HeaderCell>
        )}

        {(!visibleColumns || visibleColumns.includes('locationName')) && (
          <Table.HeaderCell
            sorted={
              sortingColumn === 'locationName' ? sortingDirection : undefined
            }
            onClick={() => onColumnSorting('locationName')}
          >
            {formatMessage(m.location)}
          </Table.HeaderCell>
        )}

        {(!visibleColumns || visibleColumns.includes('districtsName')) && (
          <Table.HeaderCell
            sorted={
              sortingColumn === 'districtsName' ? sortingDirection : undefined
            }
            onClick={() => onColumnSorting('districtsName')}
          >
            {formatMessage(m.district)}
          </Table.HeaderCell>
        )}
      </Table.Row>
    </Table.Header>
  );
};
