import { Language } from 'modules/interventionLanguage';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { Header } from 'semantic-ui-react';
import { DemographiesListingMobile } from './DemographiesListingMobile';
import { DemographyFormMobile } from './DemographyFormMobile';
import { EntryDemographyFormValues, EntryFormValues } from './EntryForm';

const m = defineMessages({
  title: {
    id: 'Entry.Form.Demographies.Title',
    defaultMessage: 'Démographies',
  },
});

interface Props {
  onCreate: (formValues: EntryDemographyFormValues) => void;
  onUpdate: (index: number, formValues: EntryDemographyFormValues) => void;
  onDelete: (index: number) => void;
}

export const DemographiesSectionMobile: React.FC<Props> = ({
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const { formatMessage } = useIntl();

  const [editedDemography, setEditedDemography] =
    useState<EntryDemographyFormValues>();
  const [editedDemographyIndex, setEditedDemographyIndex] = useState<number>();

  const { control, setValue } = useFormContext<EntryFormValues>();

  const showForm = () => setValue('demographySectionMobileMode', 'form');
  const showList = () => setValue('demographySectionMobileMode', 'list');

  const demographySectionMobileMode = useWatch({
    control,
    name: 'demographySectionMobileMode',
  });

  const formDemographies = useWatch({
    control,
    name: 'demographies',
    defaultValue: [],
  });

  const handleNew = () => {
    const emptyDemography: EntryDemographyFormValues = {
      count: 1,
      genderId: null,
      ageGroupId: null,
      occupationId: null,
      originIds: [],
      interventionLanguageId: Language.French,
    };

    setEditedDemography(emptyDemography);
    setEditedDemographyIndex(undefined);

    showForm();
  };

  const handleEdit = (index: number) => {
    setEditedDemography(formDemographies[index]);
    setEditedDemographyIndex(index);
    showForm();
  };

  const handleCancelEdit = () => {
    showList();
  };

  const isNew = editedDemographyIndex === undefined;

  const handleSave = (formValues: EntryDemographyFormValues) => {
    if (isNew) {
      handleCreate(formValues);
    } else {
      handleUpdate(formValues);
    }
  };

  const handleCreate = (formValues: EntryDemographyFormValues) => {
    showList();
    onCreate(formValues);
  };

  const handleUpdate = (formValues: EntryDemographyFormValues) => {
    showList();
    onUpdate(editedDemographyIndex as number, formValues);
  };

  const handleDelete = () => {
    showList();
    onDelete(editedDemographyIndex as number);
  };

  return (
    <>
      <Header size="small" className="tw-mb-8">
        {formatMessage(m.title)}
      </Header>

      {(!demographySectionMobileMode ||
        demographySectionMobileMode === 'list') && (
        <DemographiesListingMobile
          demographies={formDemographies}
          onNew={handleNew}
          onEdit={handleEdit}
        />
      )}
      {demographySectionMobileMode === 'form' && (
        <DemographyFormMobile
          demography={editedDemography as EntryDemographyFormValues}
          onSave={handleSave}
          onCancel={handleCancelEdit}
          onDelete={handleDelete}
          isNew={isNew}
          canDelete={formDemographies.length > 1 && !isNew}
        />
      )}
    </>
  );
};
