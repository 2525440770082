import { useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';
import { ContactState } from '../types';
import { ContactStatesApi } from '../api';
import { ApiError, defaultErrorHandler } from 'api';
import { useQuery } from 'react-query';

export interface ContactStateLists {
  contactStates: ContactState[];
  dropdownItems: DropdownItemProps[];
  indexedContactStates: Record<number, ContactState>;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchContactStates = (options?: {
  staleTime?: number;
}): ContactStateLists => {
  const {
    data: contactStates,
    isFetching,
    isFetched,
  } = useQuery('contactStates', ContactStatesApi.getAll, {
    staleTime: 15 * 60 * 1000,
    onSettled: (_, error) =>
      error && error instanceof ApiError && defaultErrorHandler(error),
    ...options,
  });

  const indexedContactStates = useMemo(
    () =>
      (contactStates || []).reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<number, ContactState>),
    [contactStates]
  );

  const dropdownItems = useMemo(() => {
    const activeContactStates = (contactStates || []).filter(
      (contactState) => contactState.isActive
    );

    const items = activeContactStates.map((contactState) => ({
      key: contactState.id,
      value: contactState.id,
      text: contactState.name,
    }));

    const sortedItems = orderBy(items, (item) =>
      deburr(item.text).toLowerCase()
    );

    return sortedItems;
  }, [contactStates]);

  return {
    contactStates: contactStates || [],
    dropdownItems,
    indexedContactStates,
    isFetched,
    isFetching,
  };
};
