import { useMemo } from 'react';
import { Entry } from 'modules/entry/types';
import { EntriesApi } from '../api';
import { useQuery } from 'react-query';
import { EntryQuery } from '../utils/entryQuery';
import { getEntryQueryResult } from '../utils/entryQueryResult';
import { ApiError, defaultErrorHandler } from 'api';

interface Props {
  entryQuery?: EntryQuery;
}

export const useEntryQuery = ({ entryQuery }: Props) => {
  const isDateRangeValid = !!entryQuery?.fromDate && !!entryQuery?.toDate;

  const { data: entriesInDateRange, isFetching } = useQuery(
    [
      'entries',
      'search',
      {
        fromDate: entryQuery?.fromDate,
        toDate: entryQuery?.toDate,
      },
    ],
    async () =>
      EntriesApi.search({
        fromDate: entryQuery?.fromDate || '',
        toDate: entryQuery?.toDate || '',
      }),
      {
          enabled: isDateRangeValid,
          staleTime: 5 * 60 * 1000,
          placeholderData: [],
          onSettled: (_, error) => error && error instanceof ApiError && defaultErrorHandler(error),
      }
  );

  const entryQueryResult = useMemo(() => {
    const entriesAndQueryAreReady = entriesInDateRange?.length && entryQuery;

    return entriesAndQueryAreReady 
       ? getEntryQueryResult(entriesInDateRange as Entry[], entryQuery!)
       : undefined;
  }, [entriesInDateRange, entryQuery])

  return {
    entryQueryResult,
    isFetching,
  };
};
