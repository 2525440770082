import React, { useContext, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { DropdownItemProps, Form, Grid, Header } from 'semantic-ui-react';
import { EntryQuery } from 'modules/entry/utils/entryQuery';
import { MobileFriendlyFormDropdown } from 'components/MobileFriendlyDropdown';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { EntryQueryContext } from 'modules/entry/components/EntryQueryContext';
import { LogicalOperatorDropdown } from './LogicalOperatorDropdown';

const m = defineMessages({
  homelessnessSection: {
    id: 'EntryQueryForm.HomelessnessSection',
    defaultMessage: 'Itinérance',
  },
  homelessnessFilter: {
    id: 'EntryQueryForm.HomelessnessFilter',
    defaultMessage: 'Filtre',
  },
  withHomelessness: {
    id: 'EntryQueryForm.HomelessnessFilter.WithHomelessness',
    defaultMessage: 'Avec itinérance',
  },
  withoutHomelessness: {
    id: 'EntryQueryForm.HomelessnessFilter.WithoutHomelessness',
    defaultMessage: 'Sans itinérance',
  },
  withOrWithoutHomelessness: {
    id: 'EntryQueryForm.HomelessnessFilter.WithOrWithoutHomelessness',
    defaultMessage: 'Avec et sans itinérance',
  },
  homelessnessSituations: {
    id: 'EntryQueryForm.HomelessnessSituations',
    defaultMessage: "Situations d'itinérance",
  },
  allHomelessnessSituations: {
    id: 'EntryQueryForm.AllHomelessnessSituations',
    defaultMessage: "Toutes les situations d'itinérance",
  },
  homelessnessInterventions: {
    id: 'EntryQueryForm.HomelessnessInterventions',
    defaultMessage: 'Interventions',
  },
  allHomelessnessInterventions: {
    id: 'EntryQueryForm.AllHomelessnessInterventions',
    defaultMessage: 'Toutes les interventions',
  },
});

interface Props {
  isMobile: boolean;
  onChange: () => void;
}

export const HomelessnessSection: React.FC<Props> = ({
  isMobile,
  onChange,
}) => {
  const { formatMessage } = useIntl();
  const { lists } = useContext(EntryQueryContext);

  const { control } = useFormContext<EntryQuery>();

  const hasHomelessness = useWatch({
    control,
    name: 'homelessnesses.hasHomelessness',
    defaultValue: null,
  });

  const homelessnessInclusionFilterDropdownItems = useMemo<DropdownItemProps[]>(
    () => [
      {
        key: 1,
        value: 'any',
        text: formatMessage(m.withOrWithoutHomelessness),
      },
      { key: 2, value: true, text: formatMessage(m.withHomelessness) },
      {
        key: 3,
        value: false,
        text: formatMessage(m.withoutHomelessness),
      },
    ],
    [formatMessage]
  );

  const handleChange = () => onChange();

  const hasHomelessnessField = (
    <Form.Field className="tw-flex tw-items-baseline">
      <label className="tw-text-purple">
        {formatMessage(m.homelessnessFilter)}
      </label>
      <Controller
        control={control}
        name="homelessnesses.hasHomelessness"
        defaultValue="any"
        render={({ field: { onBlur, value, onChange} }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value ?? ''}
            options={homelessnessInclusionFilterDropdownItems}
            fluid
            selection
            isMobile={isMobile}
            placeholder={formatMessage(m.withOrWithoutHomelessness)}
            className="tw-w-72 tw-ml-4"
          />
        )}
      />
    </Form.Field>
  );

  const homelessnessSituationsField = (
    <Form.Field>
      <label className="tw-text-purple">
        {formatMessage(m.homelessnessSituations)}
      </label>
      <Controller
        control={control}
        name="homelessnesses.homelessnessSituation"
        render={({ field: { onBlur, value, onChange} }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.homelessnessSituations.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allHomelessnessSituations)}
            isMobile={isMobile}
          />
        )}
      />
    </Form.Field>
  );

  const homelessnessesField = (
    <Form.Field>
      <label className="tw-text-purple">
        {formatMessage(m.homelessnessInterventions)}
      </label>
      <Controller
        control={control}
        name="homelessnesses.homelessnessInterventions"
        render={({ field: { onBlur, value, onChange} }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.homelessnesses.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allHomelessnessInterventions)}
            isMobile={isMobile}
          />
        )}
      />
      <Controller
        control={control}
        name="homelessnesses.homelessnessInterventionsLogicalOperator"
        render={({ field: { value, onChange} }) => (
          <LogicalOperatorDropdown
            value={value ?? ''}
            onChange={(newValue) => {
              onChange(newValue);
              handleChange();
            }}
          />
        )}
      />
    </Form.Field>
  );

  const homelessnessesFieldsVisibilityClass =
    hasHomelessness === false ? 'tw-hidden' : '';

  return (
    <ApplicationSegment>
      <div className="tw-flex tw-items-baseline tw-pr-4">
        <Header className="tw-flex-1" color="purple">
          {formatMessage(m.homelessnessSection)}
        </Header>
        {hasHomelessnessField}
      </div>

      <div className={`tw-p-4 ${homelessnessesFieldsVisibilityClass}`}>
        <Grid columns="2" doubling>
          <Grid.Column>{homelessnessSituationsField}</Grid.Column>
          <Grid.Column>{homelessnessesField}</Grid.Column>
        </Grid>
      </div>
    </ApplicationSegment>
  );
};
