import React, { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { EntryFormContext, EntryFormContextType, EntryFormValues } from './EntryForm';
import { Form, Grid } from 'semantic-ui-react';
import { MobileFriendlyFormDropdown } from 'components/MobileFriendlyDropdown';

const m = defineMessages({
  problematics: {
    id: 'Entry.Form.Problematics',
    defaultMessage: 'Principales préoccupations ou problématiques',
  },
});

interface Props {
  isMobile: boolean;
}

export const ProblematicsForm: React.FC<Props> = ({ isMobile }) => {
  const { formatMessage } = useIntl();  

  const { lists } = useContext(EntryFormContext) as EntryFormContextType;  

  const { control } = useFormContext<EntryFormValues>();

  return (
    <Grid columns={2} doubling>
      <Grid.Column>
        <Form.Field>
          <label>{formatMessage(m.problematics)}</label>
          <Controller
            control={control}
            name="problematicIds"
            render={({ field: { value, onChange} }) => (
              <MobileFriendlyFormDropdown
                fluid
                multiple
                search
                selection
                options={lists?.problematics.dropdownItems}
                value={value}
                onChange={(e, data) => onChange(data.value)}
                isMobile={isMobile}
              />
            )}
          />
        </Form.Field>
      </Grid.Column>
    </Grid>
  );
};
