import { useCallback } from 'react';
import { groupBy, has, orderBy, range, sum, sumBy, take, uniq } from 'lodash';
import moment from 'moment';
import { Entry } from 'modules/entry/types';
import { MeetingType } from 'modules/entry/components/EntryForm';
import { useFetchInterventions } from 'modules/intervention/hooks/useFetchInterventions';
import { useFetchProblematics } from 'modules/problematic/hooks/useFetchProblematics';
import { useSecurity } from 'modules/account/hooks/useSecurity';

export interface DashboardStats {
  myEntriesStats: SubsetDetailedStats;
  myTeamEntriesStats: SubsetDetailedStats;
  allEntries?: SubsetDetailedStats;
}

export interface SubsetDetailedStats {
  allEntriesStats: EntriesStats;
  singlePersonEntries: {
    entriesWithIntervention: EntriesStats;
    entriesWithoutIntervention: EntriesStats;
  };
  groupEntries: {
    entriesWithIntervention: EntriesStats;
    entriesWithoutIntervention: EntriesStats;
  };
  topInterventions: InterventionStat[];
  topProblematics: ProblematicStat[];
  periodsStats: PeriodStats[];
}

export interface EntriesStats {
  entryCount: number;
  personCount: number;
}

export interface PeriodStats {
  label: string;
  entryCount: number;
}

export interface InterventionStat {
  name: string;
  entriesStat: EntriesStats;
}

export interface ProblematicStat {
  name: string;
  entriesStat: EntriesStats;
}

const getEntrieStats = (entries: Entry[]): EntriesStats => {
  return {
    entryCount: entries.length,
    personCount: getEntriesPersonCount(entries),
  };
};

const getEntriesPersonCount = (entries: Entry[]): number =>
  sumBy(
    entries.flatMap((entry) => entry.demographies),
    (demography) => demography.count
  );

const getPeriodStats = (entries: Entry[]): PeriodStats[] => {
  if (!entries.length) {
    return [];
  }

  const entriesDate = uniq(entries.map((entry) => entry.date));
  const year = moment.utc(entriesDate[0]).year();
  const entriesMonths = uniq(
    entriesDate.map((date) => moment.utc(date).month())
  );
  const singleMonth = entriesMonths.length <= 1;

  const getDailyStats = () => {
    const month = moment(entriesDate[0]).month();
    const entriesDays = uniq(
      entriesDate.map((date) => moment.utc(date).date())
    );
    const firstDay = 1;
    const lastDay = Math.max(...entriesDays);
    const entriesByDay = groupBy(entries, (entry) =>
      moment.utc(entry.date).date()
    );

    const dailyStats = range(firstDay, lastDay + 1).map((day) => ({
      label: moment.utc({ day: day, month, year }).format('DD/MM'),
      entryCount: entriesByDay[day]?.length ?? 0,
    }));

    return dailyStats;
  };

  const getMonthlyStats = () => {
    const entriesMonths = uniq(
      entriesDate.map((date) => moment.utc(date).month())
    );
    const firstMonth = 0;
    const lastMonth = Math.max(...entriesMonths);
    const entriesByMonth = groupBy(entries, (entry) =>
      moment.utc(entry.date).month()
    );

    const monthlyStats = range(firstMonth, lastMonth + 1).map((month) => ({
      label: moment.utc({ day: 1, month, year }).format('MMM'),
      entryCount: entriesByMonth[month]?.length ?? 0,
    }));

    return monthlyStats;
  };

  if (singleMonth) {
    return getDailyStats();
  } else {
    return getMonthlyStats();
  }
};

export const useDashboardStats = () => {
  const { indexedInterventions, isFetched: interventionsIsFetched } =
    useFetchInterventions();
  const { indexedProblematics, isFetched: problematicsIsFetched } =
    useFetchProblematics();
  const { currentUser } = useSecurity();

  const isReady = interventionsIsFetched && problematicsIsFetched;

  const getEntriesTopInterventions = useCallback(
    (entries: Entry[]): InterventionStat[] => {
      const personCountsByInterventionName = entries.reduce((result, entry) => {
        const entryInterventionNames = uniq(
          entry.interventions.map(
            (i) => indexedInterventions[i.interventionId].name
          )
        );

        const entryPersonCount = sumBy(
          entry.demographies,
          (demography) => demography.count
        );

        entryInterventionNames.forEach((name) => {
          if (!has(result, name)) {
            result[name] = [];
          }

          result[name].push(entryPersonCount);
        });

        return result;
      }, {} as Record<string, number[]>);

      const stats: InterventionStat[] = Object.entries(
        personCountsByInterventionName
      ).map(([name, personCounts]) => {
        const personCount = sum(personCounts);

        return {
          name: name,
          entriesStat: {
            entryCount: personCounts.length,
            personCount,
          },
        };
      });

      const sortedStats = orderBy(
        stats,
        [
          (stat) => stat.entriesStat.personCount,
          (stat) => stat.entriesStat.entryCount,
          (stat) => stat.name,
        ],
        ['desc', 'desc', 'asc']
      );

      return take(sortedStats, 3);
    },
    [indexedInterventions]
  );

  const getEntriesTopProblematics = useCallback(
    (entries: Entry[]): ProblematicStat[] => {
      const personCountsByProblematicName = entries.reduce((result, entry) => {
        const entryProblematicNames = uniq(
          entry.problematicIds.map((i) => indexedProblematics[i].name)
        );

        const entryPersonCount = sumBy(
          entry.demographies,
          (demography) => demography.count
        );

        entryProblematicNames.forEach((name) => {
          if (!has(result, name)) {
            result[name] = [];
          }

          result[name].push(entryPersonCount);
        });

        return result;
      }, {} as Record<string, number[]>);

      const stats: ProblematicStat[] = Object.entries(
        personCountsByProblematicName
      ).map(([name, personCounts]) => {
        const personCount = sum(personCounts);

        return {
          name: name,
          entriesStat: {
            entryCount: personCounts.length,
            personCount,
          },
        };
      });

      const sortedStats = orderBy(
        stats,
        [
          (stat) => stat.entriesStat.personCount,
          (stat) => stat.entriesStat.entryCount,
          (stat) => stat.name,
        ],
        ['desc', 'desc', 'asc']
      );

      return take(sortedStats, 3);
    },
    [indexedProblematics]
  );

  const getSubsetDetailedStats = useCallback(
    (entries: Entry[]): SubsetDetailedStats => {
      const singlePersonEntries = entries.filter(
        (entry) => entry.meetingTypeId === MeetingType.Single
      );

      const groupEntries = entries.filter(
        (entry) => entry.meetingTypeId === MeetingType.Group
      );

      return {
        allEntriesStats: getEntrieStats(entries),
        singlePersonEntries: {
          entriesWithIntervention: getEntrieStats(
            singlePersonEntries.filter((entry) => entry.hasIntervention)
          ),
          entriesWithoutIntervention: getEntrieStats(
            singlePersonEntries.filter((entry) => !entry.hasIntervention)
          ),
        },
        groupEntries: {
          entriesWithIntervention: getEntrieStats(
            groupEntries.filter((entry) => entry.hasIntervention)
          ),
          entriesWithoutIntervention: getEntrieStats(
            groupEntries.filter((entry) => !entry.hasIntervention)
          ),
        },
        topInterventions: getEntriesTopInterventions(entries),
        topProblematics: getEntriesTopProblematics(entries),
        periodsStats: getPeriodStats(entries),
      };
    },
    [getEntriesTopInterventions, getEntriesTopProblematics]
  );

  const getDashboardStats = useCallback(
    (entries: Entry[]): DashboardStats => {
      const myEntries = entries.filter(
        (entry) => entry.createdByUserId === currentUser?.id
      );

      const myTeamEntries = entries.filter(
        (entry) => entry.teamId === currentUser?.team?.id
      );

      return {
        myEntriesStats: getSubsetDetailedStats(myEntries),
        myTeamEntriesStats: getSubsetDetailedStats(myTeamEntries),
        allEntries: getSubsetDetailedStats(entries),
      };
    },
    [currentUser?.id, currentUser?.team?.id, getSubsetDetailedStats]
  );

  return { getDashboardStats, isReady };
};
