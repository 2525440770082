import { useEffect, useState } from 'react';
import { defaultErrorHandler } from 'api';
import { UsersApi } from '../api';
import { User } from '../types';

interface Output {
  user : User | null | undefined;
  isFetched: boolean;
  isFetching: boolean; 
}

export const useFetchUser = (id: string | null) : Output => {
  const [user, setEntry] = useState<User | null | undefined>(undefined);
  const [isFetched, setIsFetched] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);

    if (id === null) {
      setEntry(null);
    } else {
      UsersApi.getById(id)
        .then((fetchedUser) => {
          setEntry(fetchedUser);
          setIsFetched(true);
        })
        .catch((error) => {
          setEntry(null);
          setIsFetched(false);
          defaultErrorHandler(error);
        })
        .finally(() => setIsFetching(false));
    }
  }, [id]);

  return {user, isFetched, isFetching};
};
