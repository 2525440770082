import React from 'react';

interface Props {
  active: boolean;
}
export const ActiveIndicator: React.FC<Props> = ({ active }) => {
  const backgroundColor = active ? '#166B8C' : '#F3F3F3';

  return (
    <i
      className='tw-block tw-rounded-full tw-h-5 tw-w-5 tw-border tw-border-gray-500 tw-border-solid'
      style={{ background: backgroundColor }}></i>
  );
};
