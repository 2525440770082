import { requests } from 'api';
import { CurrentUser } from '.';

const fetchUserUrl = '/api/currentuser';
const signOutUrl = '/api/auth/signout';

export const AccountApi = {
  fetchCurrentUser: () : Promise<CurrentUser> => requests.get(fetchUserUrl),
  signOut: () : unknown => requests.post(signOutUrl, {}),
}
