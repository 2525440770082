import deburr from 'lodash/deburr';
import sumBy from 'lodash/sumBy';
import uniq from 'lodash/uniq';
import { Entry, EntryDemography } from '../types';
import { EntryQuery } from './entryQuery';

export interface EntryQueryResult {
  filteredEntries: Entry[];
  filteredDemographies: EntryDemography[];
  entryQuery: EntryQuery;
  entryCount: number;
  personCount: number;
}

type EntryQueryFilter = (entry: Entry, query: EntryQuery) => boolean;

const createIncludesQueryFilter = (
  valuesSelector: (entry: Entry) => (number | string)[],
  querySelector: (query: EntryQuery) => (number | string)[],
  logicalOperator: string | null = 'or'
): EntryQueryFilter => {
  return (entry: Entry, query: EntryQuery) => {
    const entryValues = valuesSelector(entry);
    const queryValues = querySelector(query) || [];

    if (queryValues.length === 0) return true;

    return areListsMatching(entryValues, queryValues, logicalOperator);
  };
};

const createMinimumQueryFilter = (
  numberSelector: (entry: Entry) => number | string,
  minimumSelector: (query: EntryQuery) => string | null
): EntryQueryFilter => {
  return (entry: Entry, query: EntryQuery) => {
    const parsedMinimum = parseInt(minimumSelector(query) ?? '') || 0;

    return parsedMinimum > 0 ? numberSelector(entry) >= parsedMinimum : true;
  };
};

const createMaximumQueryFilter = (
  numberSelector: (entry: Entry) => number | string,
  maximumSelector: (query: EntryQuery) => string | null
): EntryQueryFilter => {
  return (entry: Entry, query: EntryQuery) => {
    const parsedMaximum = parseInt(maximumSelector(query) ?? '') || 0;

    return parsedMaximum > 0 ? numberSelector(entry) <= parsedMaximum : true;
  };
};

const areListsMatching = (
  sourceList: any[],
  testList: any[],
  logicalOperator: string | null = 'or'
) => {
  return logicalOperator === 'and'
    ? testList.every((item) => sourceList.includes(item))
    : testList.some((item) => sourceList.includes(item));
};

const isTextContains = (text: string, search: string) => {
  return (
    deburr(text.toLocaleLowerCase()).indexOf(
      deburr(search.toLocaleLowerCase())
    ) > -1
  );
};

export const isEntryMatchQueryOnReferredBy = (
  entry: Entry,
  query: EntryQuery
): boolean =>
  query.referredBy.length
    ? query.referredBy.some((reference) =>
        isTextContains(entry.referredBy || '', reference)
      )
    : true;

export const isEntryMatchQueryOnOrigins = (
  entry: Entry,
  query: EntryQuery
): boolean =>
  query.demographies.origins.length
    ? entry.demographies.some((demograhy) =>
        areListsMatching(
          demograhy.originIds,
          query.demographies.origins,
          query.demographies.originsLogicalOperator
        )
      )
    : true;

export const isEntryMatchQueryOnHasIntervention = (
  entry: Entry,
  query: EntryQuery
) =>
  (query.interventions.hasIntervention ?? 'any') === 'any'
    ? true
    : entry.hasIntervention === query.interventions.hasIntervention;

const shouldSkipInterventionFilters = (entry: Entry, query: EntryQuery) =>
  query.interventions.hasIntervention !== true &&
  entry.hasIntervention === false;

export const isEntryMatchQueryOnInterventionMinDuration = (
  entry: Entry,
  query: EntryQuery
) => {
  const minDurationInMinutes =
    parseInt(query.interventions.minDurationMinutes ?? '') || 0;

  return minDurationInMinutes > 0
    ? shouldSkipInterventionFilters(entry, query) ||
        entry.interventionDurationInMinute >= minDurationInMinutes
    : true;
};

export const isEntryMatchQueryOnInterventionMaxDuration = (
  entry: Entry,
  query: EntryQuery
) => {
  const maxDurationInMinutes =
    parseInt(query.interventions.maxDurationMinutes ?? '') || 0;

  return maxDurationInMinutes > 0
    ? shouldSkipInterventionFilters(entry, query) ||
        entry.interventionDurationInMinute <= maxDurationInMinutes
    : true;
};

export const isEntryMatchQueryOnInterventions = (
  entry: Entry,
  query: EntryQuery
) =>
  query.interventions.interventions.length
    ? shouldSkipInterventionFilters(entry, query) ||
      areListsMatching(
        entry.interventions.map((i) => i.interventionId),
        query.interventions.interventions,
        query.interventions.interventionsLogicalOperator
      )
    : true;

export const isEntryMatchQueryOnInterventionsTextValue = (
  entry: Entry,
  query: EntryQuery
) => {
  const entryInterventionTextValues = uniq(
    entry.interventions.map((i) => i.textValue)
  ).filter((i) => i) as string[];

  return query.interventions.interventionsTextValues.length
    ? shouldSkipInterventionFilters(entry, query) ||
        query.interventions.interventionsTextValues.some(
          (queryInterventionText) =>
            entryInterventionTextValues.some((interventionText) =>
              isTextContains(interventionText, queryInterventionText)
            )
        )
    : true;
};

export const isEntryMatchQueryOnInterventionsMinNumericValue = (
  entry: Entry,
  query: EntryQuery
) => {
  const interventionsWithNumericValue = entry.interventions.filter(
    (i) => i.numberValue
  );

  const interventionsMinNumericValue =
    parseInt(query.interventions.interventionsMinNumericValue ?? '') || 0;

  return interventionsMinNumericValue
    ? shouldSkipInterventionFilters(entry, query) ||
        interventionsWithNumericValue.some(
          (intervention) =>
            (intervention.numberValue as number) >= interventionsMinNumericValue
        )
    : true;
};

export const isEntryMatchQueryOnInterventionsMaxNumericValue = (
  entry: Entry,
  query: EntryQuery
) => {
  const interventionsWithNumericValue = entry.interventions.filter(
    (i) => i.numberValue
  );

  const interventionsMaxNumericValue =
    parseInt(query.interventions.interventionsMaxNumericValue ?? '') || 0;

  return interventionsMaxNumericValue
    ? shouldSkipInterventionFilters(entry, query) ||
        interventionsWithNumericValue.some(
          (intervention) =>
            (intervention.numberValue as number) <= interventionsMaxNumericValue
        )
    : true;
};

export const isEntryMatchQueryOnProblematics = (
  entry: Entry,
  query: EntryQuery
) =>
  query.interventions.problematics.length
    ? shouldSkipInterventionFilters(entry, query) ||
      areListsMatching(
        entry.problematicIds,
        query.interventions.problematics,
        query.interventions.problematicsLogicalOperator
      )
    : true;

export const isEntryMatchQueryOnHasHomelessness = (
  entry: Entry,
  query: EntryQuery
) =>
  (query.homelessnesses.hasHomelessness ?? 'any') === 'any'
    ? true
    : entry.hasHomelessness === query.homelessnesses.hasHomelessness;

const shouldSkipHomelessnessFilters = (entry: Entry, query: EntryQuery) =>
  query.homelessnesses.hasHomelessness !== true &&
  entry.hasHomelessness === false;

export const isEntryMatchQueryOnHomelessnessSituations = (
  entry: Entry,
  query: EntryQuery
) =>
  query.homelessnesses.homelessnessSituation.length
    ? shouldSkipHomelessnessFilters(entry, query) ||
      query.homelessnesses.homelessnessSituation.includes(
        entry.homelessnessSituationId ?? 0
      )
    : true;

export const isEntryMatchQueryOnHomelessnessInterventions = (
  entry: Entry,
  query: EntryQuery
) =>
  query.homelessnesses.homelessnessInterventions.length
    ? shouldSkipHomelessnessFilters(entry, query) ||
      areListsMatching(
        entry.homelessnessIds,
        query.homelessnesses.homelessnessInterventions,
        query.homelessnesses.homelessnessInterventionsLogicalOperator
      )
    : true;

export const isEntryMatchQueryOnHasTapajProgress = (
  entry: Entry,
  query: EntryQuery
) =>
  (query.tapajProgress.hasTapajProgress ?? 'any') === 'any'
    ? true
    : entry.hasTapajProgress === query.tapajProgress.hasTapajProgress;

const shouldSkipTapajProgressFilters = (entry: Entry, query: EntryQuery) =>
  query.tapajProgress.hasTapajProgress !== true &&
  entry.hasTapajProgress === false;

export const isEntryMatchQueryOnTapajProgresses = (
  entry: Entry,
  query: EntryQuery
) =>
  query.tapajProgress.tapajProgresses.length
    ? shouldSkipTapajProgressFilters(entry, query) ||
      query.tapajProgress.tapajProgresses.some((tapajProgressId) =>
        entry.tapajProgressIds.includes(tapajProgressId)
      )
    : true;

const entryFilters = [
  createIncludesQueryFilter(
    (entry) => [entry.createdByUserId],
    (query) => query.createdByCaseworkers
  ),
  createIncludesQueryFilter(
    (entry) => [entry.teamId],
    (query) => query.teams
  ),
  createIncludesQueryFilter(
    (entry) => [entry.meetingTypeId],
    (query) => query.meetingTypes
  ),
  createIncludesQueryFilter(
    (entry) => [entry.populationId],
    (query) => query.populations
  ),
  createIncludesQueryFilter(
    (entry) => [entry.locationId],
    (query) => query.locations
  ),
  createIncludesQueryFilter(
    (entry) => [entry.districtId],
    (query) => query.districts
  ),
  createIncludesQueryFilter(
    (entry) => [entry.momentId],
    (query) => query.moments
  ),
  createIncludesQueryFilter(
    (entry) => [entry.contactStateId],
    (query) => query.contactStates
  ),
  createIncludesQueryFilter(
    (entry) => [entry.initiatorId],
    (query) => query.initiators
  ),
  isEntryMatchQueryOnReferredBy,
  createMinimumQueryFilter(
    (entry) => sumBy(entry.demographies, (demography) => demography.count),
    (query) => query.demographies.minPersonCount
  ),
  createMaximumQueryFilter(
    (entry) => sumBy(entry.demographies, (demography) => demography.count),
    (query) => query.demographies.maxPersonCount
  ),
  createIncludesQueryFilter(
    (entry) =>
      uniq(entry.demographies.map((demography) => demography.genderId)),
    (query) => query.demographies.genders
  ),
  createIncludesQueryFilter(
    (entry) =>
      uniq(entry.demographies.map((demography) => demography.ageGroupId)),
    (query) => query.demographies.ageGroups
  ),
  createIncludesQueryFilter(
    (entry) =>
      uniq(entry.demographies.map((demography) => demography.occupationId)),
    (query) => query.demographies.occupations
  ),
  createIncludesQueryFilter(
    (entry) =>
      uniq(entry.demographies.map((demography) => demography.occupationId)),
    (query) => query.demographies.occupations
  ),
  createIncludesQueryFilter(
    (entry) =>
      uniq(entry.demographies.map((demography) => demography.occupationId)),
    (query) => query.demographies.occupations
  ),
  isEntryMatchQueryOnOrigins,
  isEntryMatchQueryOnHasIntervention,
  isEntryMatchQueryOnInterventionMinDuration,
  isEntryMatchQueryOnInterventionMaxDuration,
  isEntryMatchQueryOnInterventions,
  isEntryMatchQueryOnInterventionsTextValue,
  isEntryMatchQueryOnInterventionsMinNumericValue,
  isEntryMatchQueryOnInterventionsMaxNumericValue,
  isEntryMatchQueryOnProblematics,
  isEntryMatchQueryOnHasHomelessness,
  isEntryMatchQueryOnHomelessnessSituations,
  isEntryMatchQueryOnHomelessnessInterventions,
  isEntryMatchQueryOnHasTapajProgress,
  isEntryMatchQueryOnTapajProgresses,
];

export const isEntryMatchQuery = (entry: Entry, query: EntryQuery) =>
  entryFilters.every((filter) => filter(entry, query));

export const isDemographyMatchQueryOnGenders = (
  demography: EntryDemography,
  query: EntryQuery
) =>
  query.demographies.genders.length
    ? query.demographies.genders.includes(demography.genderId)
    : true;

export const isDemographyMatchQueryOnAgeGroups = (
  demography: EntryDemography,
  query: EntryQuery
) =>
  query.demographies.ageGroups.length
    ? query.demographies.ageGroups.includes(demography.ageGroupId)
    : true;

export const isDemographyMatchQueryOnOccupations = (
  demography: EntryDemography,
  query: EntryQuery
) =>
  query.demographies.occupations.length
    ? query.demographies.occupations.includes(demography.occupationId)
    : true;

export const isDemographyMatchQueryOnOrigins = (
  demography: EntryDemography,
  query: EntryQuery
) =>
  query.demographies.origins.length
    ? query.demographies.origins.some((originId) =>
        demography.originIds.includes(originId)
      )
    : true;

const demographyFilters = [
  isDemographyMatchQueryOnGenders,
  isDemographyMatchQueryOnAgeGroups,
  isDemographyMatchQueryOnOccupations,
  isDemographyMatchQueryOnOrigins,
];

export const isDemographyMatchQuery = (
  demography: EntryDemography,
  query: EntryQuery
) => demographyFilters.every((filter) => filter(demography, query));

export const getEntryQueryResult = (
  sourceEntries: Entry[],
  query: EntryQuery
): EntryQueryResult => {
  const filteredEntries = sourceEntries.filter((entry) =>
    isEntryMatchQuery(entry, query)
  );

  const filteredDemographies = filteredEntries
    .flatMap((entry) => entry.demographies)
    .filter((demography) => isDemographyMatchQuery(demography, query));

  const personCount = sumBy(
    filteredDemographies,
    (demography) => demography.count
  );

  return {
    filteredEntries,
    filteredDemographies,
    entryQuery: query,
    entryCount: filteredEntries.length,
    personCount,
  };
};
