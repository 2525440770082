import './MSButton.scss';

import { ReactComponent as MSLogo } from './icons/microsoft.svg';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

const m = defineMessages({
  text: {
    id: 'msbutton.text',
    defaultMessage: 'Se connecter avec Microsoft',
  },
});

export const MSButton: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <button className="ms-button">
      <MSLogo /> {formatMessage(m.text)}
    </button>
  );
};
