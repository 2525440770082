import { ApplicationSegment } from 'components/ApplicationSegment';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Checkbox, Form, Grid } from 'semantic-ui-react';
import { useTeamValidations } from 'modules/team/hooks/useTeamValidation';
import { InterventionType } from 'modules/intervention/types';
import { useInterventionTypes } from 'modules/intervention/hooks/useInterventionTypes';
import { MobileFriendlyFormDropdown } from 'components/MobileFriendlyDropdown';
import { useResponsive } from 'hooks/useResponsive';
import { useFetchProblematics } from 'modules/problematic/hooks/useFetchProblematics';
import { InterventionListValues } from './InterventionListValues';

const m = defineMessages({
  name: { id: 'Intervention.Form.Name', defaultMessage: 'Nom' },
  type: { id: 'Intervention.Form.Name', defaultMessage: 'Type' },
  problematics: {
    id: 'Intervention.Form.Name',
    defaultMessage: 'Problématiques et préoccupations reliées',
  },
  save: { id: 'Intervention.Form.Save', defaultMessage: 'Sauvegarder' },
  active: { id: 'ActivityToggleSwitch.Active', defaultMessage: 'Actif' },
  inactive: { id: 'ActivityToggleSwitch.Inactive', defaultMessage: 'Inactif' },
});

export interface InterventionListValueFormValues {
  valueCategory: string;
  valueDescription: string;
}

export interface InterventionFormValues {
  name: string;
  interventionType: InterventionType;
  isActive: boolean;
  problematicIds: number[];
  interventionListValues: InterventionListValueFormValues[];
}

interface Props {
  intervention: InterventionFormValues;
  onSubmit: (data: InterventionFormValues) => void;
}

export const InterventionForm: React.FC<Props> = ({
  intervention,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();

  const validations = useTeamValidations();
  const { dropdownItems: interventionTypeDropdownItems } =
    useInterventionTypes();
  const { dropdownItems: problematicDropdownItems } = useFetchProblematics();

  const formMethods = useForm<InterventionFormValues>({
    defaultValues: intervention,
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    reset(intervention);
  }, [intervention, reset]);

  const formInterventionType = useWatch({
    control,
    name: 'interventionType',
  });

  const handleOnSubmit = (data: InterventionFormValues) => {
    onSubmit(data);
  };

  const ErrorMessage: React.FC = ({ children }) => {
    return <div className="tw-text-red tw-mb-4">{children}</div>;
  };

  const nameField = (
    <Form.Field error={Boolean(errors.name)} className="tw-max-w-lg">
      <label className="tw-text-purple">{formatMessage(m.name)}</label>
      <Controller
        control={control}
        name="name"
        rules={{ validate: validations.name }}
        render={({ field: { onBlur, value, onChange } }) => (
          <input
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            type="text"
          />
        )}
      />
      {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
    </Form.Field>
  );

  const problematicsField = (
    <Form.Field className="tw-max-w-lg">
      <label className="tw-text-purple">{formatMessage(m.problematics)}</label>
      <Controller
        control={control}
        name="problematicIds"
        render={({ field: { value, onChange } }) => (
          <MobileFriendlyFormDropdown
            fluid
            search
            selection
            multiple
            options={problematicDropdownItems ?? []}
            value={value}
            onChange={(e, data) => onChange(data.value)}
            isMobile={isMobile}
          />
        )}
      />
    </Form.Field>
  );

  const interventionTypeField = (
    <Form.Field className="tw-max-w-lg">
      <label className="tw-text-purple">{formatMessage(m.type)}</label>
      <Controller
        control={control}
        name="interventionType"
        render={({ field: { onBlur, value, onChange } }) => (
          <Form.Dropdown
            onChange={(e, data) => onChange(data.value)}
            onBlur={onBlur}
            value={value}
            selection
            options={interventionTypeDropdownItems}
          />
        )}
      />
    </Form.Field>
  );

  return (
    <ApplicationSegment>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
          <Grid columns={2} doubling>
            <Grid.Column>
              {nameField}
              {problematicsField}
              {interventionTypeField}
            </Grid.Column>
          </Grid>
          {formInterventionType === InterventionType.List && (
            <InterventionListValues />
          )}

          <div className="tw-flex tw-justify-between tw-pt-8 tw-mt-8 tw-border-t tw-border-gray-300">
            <Controller
              control={control}
              name="isActive"
              render={({ field: { onBlur, value, onChange } }) => (
                <div className="tw-flex tw-items-center">
                  <Checkbox
                    onChange={(e, data) => onChange(data.checked)}
                    onBlur={onBlur}
                    checked={value}
                    toggle
                  />
                  <div className="tw-ml-6">
                    {value
                      ? formatMessage(m.active)
                      : formatMessage(m.inactive)}
                  </div>
                </div>
              )}
            />

            <Button primary type="submit">
              {formatMessage(m.save)}
            </Button>
          </div>
        </Form>
      </FormProvider>      
    </ApplicationSegment>
  );
};
