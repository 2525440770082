import React from 'react';
import { PageLayout } from 'components/Layout/PageLayout';
import { useFetchEntries } from '../hooks/useFetchEntries';
import { PageHeader } from 'components/Layout/PageHeader';
import { defineMessages, useIntl } from 'react-intl';
import { DefaultEntriesTableLayout, EntriesTableOptions } from '../components/DefaultEntriesTableLayout';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';

const m = defineMessages({
  pageTitle: { id: 'Entries.Page.Title', defaultMessage: 'Entrées' },
});

export const EntriesPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const history = useHistory();
  const { entries, isLoading: isLoadingEntries } = useFetchEntries();
  
  const options = queryString.parse(location.search);

  const handleOptionsChange = (options: EntriesTableOptions) => {
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify(options),
    });
  };

  const isLoaded = !isLoadingEntries;

  return (
    <PageLayout pageTitle={formatMessage(m.pageTitle)}>
      <PageHeader
        header={formatMessage(m.pageTitle)}
        iconName="file alternate"
      />
      {isLoaded ? (
        <DefaultEntriesTableLayout
          entries={entries}
          options={options}
          onOptionsChange={handleOptionsChange}
        />
      ) : (
        <ApplicationLoader />
      )}
    </PageLayout>
  );
};
