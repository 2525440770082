import React from 'react'
import { LoaderImage } from 'components/LoaderImage'
import './ApplicationLoader.scss';

export const ApplicationLoader : React.FC = () => {
  return (
    <div className="app-loader">
      <div className="app-loader-content">
        <LoaderImage />
      </div>
    </div>
  )
}
