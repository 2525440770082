import { ApplicationSegment } from 'components/ApplicationSegment';
import React from 'react'
import { defineMessages, useIntl } from 'react-intl';
import { SingleStatCard } from './SingleStatCard';

interface Props {
  personCount: number;
}

const m = defineMessages({
  person: {
    id: 'Dashboard.View.Person',
    defaultMessage: 'Personne',
  },
  persons: {
    id: 'Dashboard.View.Persons',
    defaultMessage: 'Personnes',
  },  
});

export const PersonsStats : React.FC<Props> = ({personCount}) => {
  const { formatMessage } = useIntl();

  return (
    <ApplicationSegment>
      <SingleStatCard
        value={personCount}
        label={
          personCount > 1
            ? formatMessage(m.persons)
            : formatMessage(m.person)
        }
        icon="user"
      />
    </ApplicationSegment>
  )
}
