import { EntryLists } from 'modules/entry/hooks/useEntryLists';
import { UserList } from 'modules/user/hooks/useFetchUsers';
import React, { createContext } from 'react';

export interface EntryQueryContextType {
  lists: EntryLists | null;
  users: UserList | null;
}

export const EntryQueryContext = createContext<EntryQueryContextType>({
  lists: null,
  users: null,
});

interface Props {
  value: EntryQueryContextType;
  children?: React.ReactNode;
}

export const EntryQueryContextProvider: React.FC<Props> = ({ value, children }) => {
  return (
    <EntryQueryContext.Provider value={value}>
      {children}
    </EntryQueryContext.Provider>
  );
};
