import { useMemo } from 'react';
import { UserSummary } from '../types';
import { UsersApi } from '../api';
import { ApiError, defaultErrorHandler } from 'api';
import { DropdownItemProps } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';
import { useQuery } from 'react-query';

export interface UserList {
  users: UserSummary[];
  dropdownItems: DropdownItemProps[];
  indexedUsers: Record<string, UserSummary>;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchUsers = (options?: { staleTime?: number }): UserList => {
  const {
    data: users,
    isFetching,
    isFetched,
  } = useQuery('users', UsersApi.getAll, {
    staleTime: 15 * 60 * 1000,
    onSettled: (_, error) =>
      error && error instanceof ApiError && defaultErrorHandler(error),
    ...options,
  });

  const indexedUsers = useMemo(
    () =>
      (users || []).reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<string, UserSummary>),
    [users]
  );

  const dropdownItems = useMemo(() => {
    const activeUsers = (users || []).filter((user) => user.isActive);

    const items = activeUsers.map((user) => ({
      key: user.id,
      value: user.id,
      text: user.fullName,
    }));

    const sortedItems = orderBy(items, (item) =>
      deburr(item.text).toLowerCase()
    );

    return sortedItems;
  }, [users]);

  return {
    users: users || [],
    dropdownItems,
    indexedUsers,
    isFetched,
    isFetching,
  };
};
