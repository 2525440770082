import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import { PageHeader } from 'components/Layout/PageHeader';
import { PageLayout } from 'components/Layout/PageLayout';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { ReactComponent as AboutImage } from 'modules/about/img/about.svg';
import { useResponsive } from 'hooks/useResponsive';

const m = defineMessages({
  pageTitle: { id: 'About.Page.Title', defaultMessage: 'À Propos' },
  developedBy: { id: 'About.Page.DevelopedBy', defaultMessage: 'Développé par' },
});

const arivWebsiteUrl = "https://www.ariv.ca";
const arivTwitterUrl = "https://twitter.com/ariv_inc";
const arivLinkedInUrl = "https://ca.linkedin.com/company/ariv-inc";

export const AboutPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();

  const infos = (
    <>
      <div className="tw-font-bold">Macstats</div>
      <div>{process.env.REACT_APP_VERSION}</div>

      <div className="tw-font-bold tw-mt-6">{formatMessage(m.developedBy)}</div>
      <div>
        <a href={arivWebsiteUrl}>
          <img
            src="/ariv-logo-color-small.png"
            alt="logo ariv"
            className="tw-w-40"
          />
        </a>
      </div>

      <div className="tw-text-primary">
        <a href={arivWebsiteUrl} target="_blank" rel="noreferrer">
          <span className="tw-underline">{arivWebsiteUrl}</span>
          <Icon className="tw-ml-1" name="external" />
        </a>
      </div>

      <div className="tw-mt-4">
        <a href={arivTwitterUrl} target="_blank" rel="noreferrer">          
          <img
            src="/twitter-logo.png"
            alt="logo ariv"
            className="tw-inline tw-w-8"
          />
        </a>
        <a href={arivLinkedInUrl} target="_blank" rel="noreferrer" className="tw-ml-2">          
          <img
            src="/linkedin-logo.png"
            alt="logo ariv"
            className="tw-inline tw-w-8"
          />
        </a>
      </div>
    </>
  );

  const desktopView = (
    <div className="tw-flex tw-flex-wrap" style={{ margin: '-1.25rem' }}>
      <div className="tw-p-5" style={{ flexBasis: '30rem', flexGrow: 1 }}>
        <ApplicationSegment>{infos}</ApplicationSegment>
      </div>
      <div className="tw-p-5" style={{ flexBasis: '30rem', flexGrow: 1 }}>
        <AboutImage />
      </div>
    </div>
  );

  const mobileView = (
    <ApplicationSegment>
      {infos}
      <AboutImage />
    </ApplicationSegment>
  );

  return (
    <PageLayout pageTitle={formatMessage(m.pageTitle)}>
      <PageHeader header={formatMessage(m.pageTitle)} iconName="info circle" />
      {isMobile ? mobileView : desktopView}
    </PageLayout>
  );
};
