import { defaultErrorHandler } from 'api';
import { useEffect, useState } from 'react';
import { EntriesApi } from '../api';
import { Entry } from '../types';

export const useFetchEntry = (id: number | null) => {
  const [entry, setEntry] = useState<Entry | null | undefined>(undefined);

  useEffect(() => {
    if (id === null || isNaN(id)) {
      setEntry(null);
    } else {
      EntriesApi.getById(id)
        .then((fetchedEntry) => {
          setEntry(fetchedEntry);
        })
        .catch((error) => {
          setEntry(null);
          defaultErrorHandler(error);
        });
    }
  }, [id]);

  return entry;
};
