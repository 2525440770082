import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { InterventionStat } from 'modules/dashboard/hooks/useDashboardStats';
import { SegmentHeader } from './SegmentHeader';

const m = defineMessages({
  title: {
    id: 'Dashboard.View.TopInterventions.Title',
    defaultMessage: 'Top 3 interventions',
  },
  entries: {
    id: 'Dashboard.View.TopInterventions.Entries',
    defaultMessage: 'Entrée(s)',
  },
  persons: {
    id: 'Dashboard.View.TopInterventions.Persons',
    defaultMessage: 'Personne(s)',
  },
  emptyMessage: {
    id: 'Dashboard.View.TopInterventions.EmptyMessage',
    defaultMessage: 'Aucune intervention',
  },
});

interface Props {
  topInterventions: InterventionStat[];
}

export const TopInterventions: React.FC<Props> = ({ topInterventions }) => {
  const { formatMessage } = useIntl();

  const table = (
    <table className="tw-text-xs tw-w-full tw-max-w-lg">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th className="tw-text-right tw-p-1">{formatMessage(m.entries)}</th>
          <th className="tw-text-right tw-p-1">{formatMessage(m.persons)}</th>
        </tr>
      </thead>
      <tbody>
        {topInterventions.map((intervention, index) => (
          <tr className="tw-py-2" key={index}>
            <td className="tw-text-green tw-text-2xl tw-py-1 tw-pr-2">
              #{index + 1}
            </td>
            <td>{intervention.name}</td>
            <td className="tw-text-right tw-p-1">
              {intervention.entriesStat.entryCount}
            </td>
            <td className="tw-text-right tw-p-1">
              {intervention.entriesStat.personCount}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const emptyMessage = <p>{formatMessage(m.emptyMessage)}</p>;

  return (
    <ApplicationSegment>
      <SegmentHeader header={formatMessage(m.title)} iconName="trophy" />
      {topInterventions.length ? table : emptyMessage}
    </ApplicationSegment>
  );
};
