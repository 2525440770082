import React, { useContext, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { DropdownItemProps, Form, Grid, Header } from 'semantic-ui-react';
import { MobileFriendlyFormDropdown } from 'components/MobileFriendlyDropdown';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { EntryQueryContext } from 'modules/entry/components/EntryQueryContext';
import { InterventionTextDropdown } from '../InterventionTextDropdown';
import { LogicalOperatorDropdown } from './LogicalOperatorDropdown';
import { EntryQuery } from 'modules/entry/utils/entryQuery';

const m = defineMessages({
  interventionsSection: {
    id: 'EntryQueryForm.InterventionsSection',
    defaultMessage: 'Interventions',
  },
  interventionFilter: {
    id: 'EntryQueryForm.InterventionFilter',
    defaultMessage: 'Filtre',
  },
  withIntervention: {
    id: 'EntryQueryForm.InterventionFilter.WithIntervention',
    defaultMessage: 'Avec intervention',
  },
  withoutIntervention: {
    id: 'EntryQueryForm.InterventionFilter.WithoutIntervention',
    defaultMessage: 'Sans intervention',
  },
  withOrWithoutIntervention: {
    id: 'EntryQueryForm.InterventionFilter.WithOrWithoutIntervention',
    defaultMessage: 'Avec et sans intervention',
  },
  interventionDuration: {
    id: 'EntryQueryForm.InterventionDuration',
    defaultMessage: 'Durée en minutes',
  },
  interventionDurationTo: {
    id: 'EntryQueryForm.InterventionDurationTo',
    defaultMessage: 'à',
  },
  interventions: {
    id: 'EntryQueryForm.Interventions',
    defaultMessage: "Types d'intervention",
  },
  allInterventions: {
    id: 'EntryQueryForm.AllInterventions',
    defaultMessage: "Tous les types d'intervention",
  },
  textValues: {
    id: 'EntryQueryForm.InterventionsTextValue',
    defaultMessage: "Valeur texte d'une intervention",
  },
  numericValue: {
    id: 'EntryQueryForm.InterventionsNumericValue',
    defaultMessage: "Valeur numérique d'une intervention",
  },
  numericValueTo: {
    id: 'EntryQueryForm.InterventionsNumericValueTo',
    defaultMessage: 'à',
  },
  problematics: {
    id: 'EntryQueryForm.Problematics',
    defaultMessage: 'Principales préoccupations et problématiques',
  },
  allProblematics: {
    id: 'EntryQueryForm.AllProblematics',
    defaultMessage: 'Toutes les préoccupations et problématiques',
  },
});

interface Props {
  isMobile: boolean;
  onChange: () => void;
}

export const InterventionsSection: React.FC<Props> = ({
  isMobile,
  onChange,
}) => {
  const { formatMessage } = useIntl();
  const { lists } = useContext(EntryQueryContext);

  const { control } = useFormContext<EntryQuery>();

  const hasIntervention = useWatch({
    control,
    name: 'interventions.hasIntervention',
    defaultValue: null,
  });

  const interventionInclusionFilterDropdownItems = useMemo<DropdownItemProps[]>(
    () => [
      {
        key: 1,
        value: 'any',
        text: formatMessage(m.withOrWithoutIntervention),
      },
      { key: 2, value: true, text: formatMessage(m.withIntervention) },
      {
        key: 3,
        value: false,
        text: formatMessage(m.withoutIntervention),
      },
    ],
    [formatMessage]
  );

  const handleChange = () => onChange();

  const hasInterventionField = (
    <Form.Field className="tw-flex tw-items-baseline">
      <label className="tw-text-purple">
        {formatMessage(m.interventionFilter)}
      </label>
      <Controller
        control={control}
        name="interventions.hasIntervention"
        defaultValue="any"
        render={({ field: { onBlur, value, onChange } }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value ?? ''}
            options={interventionInclusionFilterDropdownItems}
            fluid
            selection
            isMobile={isMobile}
            placeholder={formatMessage(m.withOrWithoutIntervention)}
            className="tw-w-72 tw-ml-4"
          />
        )}
      />
    </Form.Field>
  );

  const interventionDurationField = (
    <Form.Field>
      <p className="tw-text-purple tw-font-bold">
        <label>{formatMessage(m.interventionDuration)}</label>

        <Controller
          control={control}
          name="interventions.minDurationMinutes"
          render={({ field: { onBlur, value, onChange } }) => (
            <input
              className="tw-inline-block tw-w-24 tw-align-baseline tw-mx-4"
              type="number"
              placeholder="Min"
              min="0"
              value={value ?? ''}
              onChange={(event) => {
                onChange(event.target.value);
                handleChange();
              }}
              onBlur={onBlur}
            />
          )}
        />
        {formatMessage(m.interventionDurationTo)}
        <Controller
          control={control}
          name="interventions.maxDurationMinutes"
          render={({ field: { onBlur, value, onChange } }) => (
            <input
              className="tw-inline-block tw-w-24 tw-align-baseline tw-mx-4"
              type="number"
              placeholder="Max"
              min="0"
              value={value ?? ''}
              onChange={(event) => {
                onChange(event.target.value);
                handleChange();
              }}
              onBlur={onBlur}
            />
          )}
        />
      </p>
    </Form.Field>
  );

  const interventionsField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.interventions)}</label>
      <Controller
        control={control}
        name="interventions.interventions"
        render={({ field: { onBlur, value, onChange } }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.interventions.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allInterventions)}
            isMobile={isMobile}
          />
        )}
      />
      <Controller
        control={control}
        name="interventions.interventionsLogicalOperator"
        render={({ field: { value, onChange } }) => (
          <LogicalOperatorDropdown
            value={value ?? ''}
            onChange={(newValue) => {
              onChange(newValue);
              handleChange();
            }}
          />
        )}
      />
    </Form.Field>
  );

  const problematicsField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.problematics)}</label>
      <Controller
        control={control}
        name="interventions.problematics"
        render={({ field: { onBlur, value, onChange } }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.problematics.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allProblematics)}
            isMobile={isMobile}
          />
        )}
      />
      <Controller
        control={control}
        name="interventions.problematicsLogicalOperator"
        render={({ field: { value, onChange } }) => (
          <LogicalOperatorDropdown
            value={value ?? ''}
            onChange={(newValue) => {
              onChange(newValue);
              handleChange();
            }}
          />
        )}
      />
    </Form.Field>
  );

  const interventionTextValueField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.textValues)}</label>
      <Controller
        control={control}
        name="interventions.interventionsTextValues"
        render={({ field: { value, onChange } }) => (
          <InterventionTextDropdown
            onChange={(value) => {
              onChange(value);
              handleChange();
            }}
            value={value}
          />
        )}
      />
    </Form.Field>
  );

  const interventionNumericValueField = (
    <Form.Field>
      <p className="tw-text-purple tw-font-bold">
        <label>{formatMessage(m.numericValue)}</label>
        <Controller
          control={control}
          name="interventions.interventionsMinNumericValue"
          render={({ field: { onBlur, value, onChange } }) => (
            <input
              className="tw-inline-block tw-w-24 tw-align-baseline tw-mx-4"
              type="number"
              placeholder="Min"
              value={value ?? ''}
              min="0"
              onChange={(event) => {
                onChange(event.target.value);
                handleChange();
              }}
              onBlur={onBlur}
            />
          )}
        />
        {formatMessage(m.numericValueTo)}
        <Controller
          control={control}
          name="interventions.interventionsMaxNumericValue"
          render={({ field: { onBlur, value, onChange } }) => (
            <input
              className="tw-inline-block tw-w-24 tw-align-baseline tw-mx-4"
              type="number"
              placeholder="Max"
              min="0"
              value={value ?? ''}
              onChange={(event) => {
                onChange(event.target.value);
                handleChange();
              }}
              onBlur={onBlur}
            />
          )}
        />
      </p>
    </Form.Field>
  );

  const interventionsFieldsVisibilityClass =
    hasIntervention === false ? 'tw-hidden' : '';

  return (
    <ApplicationSegment>
      <div className="tw-flex tw-items-baseline tw-pr-4">
        <Header className="tw-flex-1" color="purple">
          {formatMessage(m.interventionsSection)}
        </Header>
        {hasInterventionField}
      </div>

      <div className={`tw-p-4 ${interventionsFieldsVisibilityClass}`}>
        {interventionDurationField}
        <Grid columns="2" doubling>
          <Grid.Column>
            {interventionsField}
            {interventionTextValueField}
            {interventionNumericValueField}
          </Grid.Column>
          <Grid.Column>{problematicsField}</Grid.Column>
        </Grid>
      </div>
    </ApplicationSegment>
  );
};
