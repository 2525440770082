import { ApplicationSegment } from 'components/ApplicationSegment';
import { useUserValidations } from 'modules/user/hooks/useUserValidations';
import { useFetchTeams } from 'modules/team/hooks/useFetchTeams';
import { useRoleSets } from 'modules/user/hooks/useRoleSets';
import { User } from 'modules/user/types';
import React, { Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Checkbox, Form, Grid, List } from 'semantic-ui-react';
import { MobileFriendlyFormDropdown } from 'components/MobileFriendlyDropdown';
import { useResponsive } from 'hooks/useResponsive';
import { ApplicationLoader } from 'components/ApplicationLoader';

const m = defineMessages({
  role: { id: 'User.Form.Role', defaultMessage: 'Rôle' },
  rolePlaceholder: {
    id: 'User.Form.Role.Placeholder',
    defaultMessage: 'Veuillez choisir un rôle',
  },
  team: { id: 'User.Form.Team', defaultMessage: 'Équipe' },
  teamPlaceholder: {
    id: 'User.Form.Team.Placeholder',
    defaultMessage: 'Veuillez choisir une équipe',
  },
  userWithRoleCan: {
    id: 'User.Form.UserWithRoleCan',
    defaultMessage: 'Un utilisateur avec ce role peut:',
  },
  save: { id: 'User.Form.Save', defaultMessage: 'Sauvegarder' },
  active: { id: 'ActivityToggleSwitch.Active', defaultMessage: 'Actif' },
  inactive: { id: 'ActivityToggleSwitch.Inactive', defaultMessage: 'Inactif' },
});

export interface UserFormValues {
  roleSetName: string;
  teamId: number | null;
  isActive: boolean;
}

interface Props {
  user: User;
  onSubmit: (data: UserFormValues) => void;
}

export const UserForm: React.FC<Props> = ({ user, onSubmit }) => {
  const { formatMessage } = useIntl();

  const { isMobile } = useResponsive();

  const {
    getMatchingRoleSet,
    getRoleSetByName,
    dropdownItems: roleSetDropdownItems,
  } = useRoleSets();

  const {
    dropdownItems: teamsDropdownItems,
    isFetched: AreTeamsFetched,
  } = useFetchTeams();

  const validations = useUserValidations();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<UserFormValues>({
    defaultValues: {
      roleSetName: getMatchingRoleSet(user.roleNames)?.name,
      teamId: user.teamId,
      isActive: user.isActive,
    },
  });

  const selectedRoleSetName = watch('roleSetName');
  const selectedRoleSet = getRoleSetByName(selectedRoleSetName);

  useEffect(() => {
    reset({
      roleSetName: getMatchingRoleSet(user.roleNames)?.name,
      teamId: user.teamId,
      isActive: user.isActive,
    });
  }, [user, reset, getMatchingRoleSet]);

  const handleOnSubmit = (data: UserFormValues) => {
    onSubmit(data);
  };

  const isLoaded = AreTeamsFetched;

  if (!isLoaded) {
    return <ApplicationLoader />;
  }

  return (
    <ApplicationSegment>
      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid columns={2} doubling>
          <Grid.Column>
            <div className="tw-max-w-lg">
              <Form.Field error={Boolean(errors.roleSetName)}>
                <label className="tw-text-purple">
                  {formatMessage(m.role)}
                </label>
                <Controller
                  control={control}
                  name="roleSetName"
                  rules={{ validate: validations.roleSetName }}
                  render={({ field: { onBlur, value, onChange} }) => (
                    <MobileFriendlyFormDropdown
                      onChange={(e, data) => onChange(data.value)}
                      onBlur={onBlur}
                      value={value}
                      selection
                      fluid
                      search
                      options={roleSetDropdownItems}
                      placeholder={'Veuillez choisir un rôle'}
                      isMobile={isMobile}
                    />
                  )}
                />
              </Form.Field>

              <div>
                {selectedRoleSet && (
                  <Fragment>
                    <div className="tw-text-purple">
                      {formatMessage(m.userWithRoleCan)}
                    </div>
                    <List bulleted>
                      {selectedRoleSet.permissions.map((permission, key) => (
                        <List.Item key={key}>{permission}</List.Item>
                      ))}
                    </List>
                  </Fragment>
                )}
              </div>
            </div>
          </Grid.Column>

          <Grid.Column>
            <div className="tw-max-w-lg">
              <Form.Field error={Boolean(errors.teamId)}>
                <label className="tw-text-purple">
                  {formatMessage(m.team)}
                </label>
                <Controller
                  control={control}
                  name="teamId"
                  rules={{ validate: validations.teamId }}
                  render={({ field: { onBlur, value, onChange} }) => (
                    <MobileFriendlyFormDropdown
                      onChange={(e, data) => onChange(data.value)}
                      onBlur={onBlur}
                      value={value ?? ''}
                      selection
                      fluid
                      search
                      options={teamsDropdownItems}
                      placeholder={formatMessage(m.teamPlaceholder)}
                      isMobile={isMobile}
                    />
                  )}
                />
              </Form.Field>
            </div>
          </Grid.Column>
        </Grid>

        <div className="tw-flex tw-justify-between tw-pt-8 tw-mt-8 tw-border-t tw-border-gray-300">
          <Controller
            control={control}
            name="isActive"
            render={({ field: { onBlur, value, onChange} }) => (
              <div className="tw-flex tw-items-center">
                <Checkbox
                  onChange={(e, data) => onChange(data.checked)}
                  onBlur={onBlur}
                  checked={value}
                  toggle
                />
                <div className="tw-ml-6">
                  {value ? formatMessage(m.active) : formatMessage(m.inactive)}
                </div>
              </div>
            )}
          />

          <Button primary type="submit">
            {formatMessage(m.save)}
          </Button>
        </div>
      </Form>
    </ApplicationSegment>
  );
};
