import { defaultErrorHandler } from 'api';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { PageHeader } from 'components/Layout/PageHeader';
import { PageLayout } from 'components/Layout/PageLayout';
import React, { Fragment, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { InterventionsApi } from '../api';
import {
  InterventionForm,
  InterventionFormValues,
} from '../components/InterventionForm';
import { useFetchIntervention } from '../hooks/useFetchIntervention';

const m = defineMessages({
  pageTitle: {
    id: 'Interventions.EditPage.Title',
    defaultMessage: 'Modifier une intervention',
  },
  savedSuccessfully: {
    id: 'Intervention.EditPage.SavedSuccessfully',
    defaultMessage: "L'intervention a été sauvegardé avec succès.",
  },
});

interface Params {
  interventionId: string;
}

export const EditInterventionPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const params = useParams<Params>();
  const id = parseInt(params.interventionId, 10);
  const { intervention } = useFetchIntervention(id);
  const [saving, setSaving] = useState(false);

  const returnToInterventionsPage = () => {
    history.push(`/admin/interventions`);
  };

  const handleSubmit = (editedIntervention: InterventionFormValues) => {
    setSaving(true);

    InterventionsApi.update(id, editedIntervention)
      .then(() => {
        toast.success(formatMessage(m.savedSuccessfully));
        returnToInterventionsPage();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };

  if (intervention === null) {
    returnToInterventionsPage();
  }

  const showLoader = !intervention || saving;

  return (
    <Fragment>
      <PageLayout pageTitle={formatMessage(m.pageTitle)}>
        <PageHeader
          header={formatMessage(m.pageTitle)}
          iconName="hand-holding-heart"
        />
        {intervention && (
          <InterventionForm
            intervention={intervention}
            onSubmit={handleSubmit}
          />
        )}
        {showLoader && <ApplicationLoader />}
      </PageLayout>
    </Fragment>
  );
};
