import React, { Fragment, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { defaultErrorHandler } from 'api';
import { Segment } from 'semantic-ui-react';
import {
  HomelessnessForm,
  HomelessnessFormValues,
} from '../components/HomelessnessForm';
import { HomelessnessesApi } from '../api';
import { PageLayout } from 'components/Layout/PageLayout';
import { PageHeader } from 'components/Layout/PageHeader';
import { ApplicationLoader } from 'components/ApplicationLoader';

const m = defineMessages({
  pageTitle: {
    id: 'Homelessness.NewPage.Title',
    defaultMessage: 'Nouvelle intervention en itinérance',
  },
  savedSuccessfully: {
    id: 'Homelessness.NewPage.SavedSuccessfully',
    defaultMessage: "L'intervention en itinérance a été sauvegardé avec succès",
  },
});

export const NewHomelessnessPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [saving, setSaving] = useState(false);

  const emtpyHomelessness: HomelessnessFormValues = {
    name: '',
    isActive: true,
  };

  const returnToHomelessnessesPage = () => {
    history.push(`/admin/homelessnesses`);
  };

  const handleSubmit = (data: HomelessnessFormValues) => {
    setSaving(true);

    HomelessnessesApi.create(data)
      .then(() => {
        toast.success(formatMessage(m.savedSuccessfully));
        returnToHomelessnessesPage();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };

  return (
    <Fragment>
      <PageLayout pageTitle={formatMessage(m.pageTitle)}>
        <PageHeader
          header={formatMessage(m.pageTitle)}
          iconName="shoe-prints"
        />
        <Segment>
          <HomelessnessForm
            homelessness={emtpyHomelessness}
            onSubmit={handleSubmit}
          />
        </Segment>
        {saving && <ApplicationLoader />}
      </PageLayout>
    </Fragment>
  );
};
