export enum InterventionType {
  Boolean = 'Boolean',
  Text = 'Text',
  Number = 'Number',
  List = 'List',
}

export interface InterventionSummary {
  id: number;
  name: string;
  interventionType: InterventionType;
  isActive: boolean;
  problematicIds: number[];
}

export interface Intervention {
  id: number;
  name: string;
  interventionType: InterventionType;
  isActive: boolean;
  problematicIds: number[];
  interventionListValues: InterventionListValue[];
}

export interface InterventionListValue {
  interventionId: number;
  valueCategory: string;
  valueDescription: string;
}
