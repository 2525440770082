import { useCallback, useState } from 'react';
import { EntryLists } from 'modules/entry/hooks/useEntryLists';
import { UserList } from 'modules/user/hooks/useFetchUsers';
import { useEntryQuery } from 'modules/entry/hooks/useEntryQuery';
import { useReportStat } from './useReportStat';
import { useReportPdf } from './useReportPdf';
import { defaultEntryQuery, EntryQuery } from 'modules/entry/utils/entryQuery';

interface Props {
  initialEntryQuery?: EntryQuery;
  lists: EntryLists;
  users: UserList;
}

export const useReport = ({ initialEntryQuery, lists, users }: Props) => {
  const [entryQuery, setEntryQuery] = useState<EntryQuery>(
    initialEntryQuery || defaultEntryQuery
  );

  const { entryQueryResult, isFetching } = useEntryQuery({
    entryQuery,
  });

  const { getReportStatsAsync } = useReportStat({
    lists,
    users,
    entryQueryResult,
  });

  const { getPdfDocumentDefinition } = useReportPdf();

  const updateEntryQuery = useCallback(
    (newEntryQuery: EntryQuery) => {
      setEntryQuery(newEntryQuery);
    },
    [setEntryQuery]
  );

  return {
    updateEntryQuery,
    entryQueryResult,
    getReportStatsAsync,
    getPdfDocumentDefinition,
    isFetching,
  };
};
