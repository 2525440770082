import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import moment from 'moment';
import { useResponsive } from 'hooks/useResponsive';
import { useSecurity } from 'modules/account/hooks/useSecurity';
import { Dropdown, Menu } from 'semantic-ui-react';

const m = defineMessages({
  myEntries: {
    id: 'Dashboard.Tabs.MyEntries',
    defaultMessage: 'Personnel',
  },
  myTeamEntries: {
    id: 'Dashboard.Tabs.MyTeamEntries',
    defaultMessage: 'Mon équipe',
  },
  allEntries: {
    id: 'Dashboard.Tabs.AllEntries',
    defaultMessage: 'Toutes les entrées',
  },
  showDataForPeriod: {
    id: 'Dashboard.Period.ShowDataForPeriod',
    defaultMessage: 'afficher les données',
  },
  currentMonth: {
    id: 'Dashboard.Period.CurrentMonth',
    defaultMessage: 'du mois courant',
  },
  previousMonth: {
    id: 'Dashboard.Period.PreviousMonth',
    defaultMessage: 'du mois précédent',
  },
  currentYear: {
    id: 'Dashboard.Period.CurrentYear',
    defaultMessage: "de l'année en cours",
  },
  previousYear: {
    id: 'Dashboard.Period.PreviousYear',
    defaultMessage: "de l'année précédente",
  },
});

type DashboardPeriodName =
  | 'current month'
  | 'previous month'
  | 'current year'
  | 'previous year';

const startOfMonth = moment.utc().startOf('month');
const endOfMonth = moment.utc().endOf('month');
const startOfPreviousMonth = startOfMonth.clone().subtract(1, 'month');
const endOfPreviousMonth = startOfPreviousMonth.clone().endOf('month');

const fiscalYearStartMonth = 3;
const isStarOfFiscalYearThisYear = moment.utc().month() >= fiscalYearStartMonth;

const startOfFiscalYear = isStarOfFiscalYearThisYear
  ? moment.utc().month(fiscalYearStartMonth).startOf('month')
  : moment
      .utc()
      .subtract(1, 'year')
      .month(fiscalYearStartMonth)
      .startOf('month');

const endOfFiscalYear = isStarOfFiscalYearThisYear
  ? moment
      .utc()
      .add(1, 'year')
      .month(fiscalYearStartMonth - 1)
      .endOf('month')
  : moment.utc().month(fiscalYearStartMonth).endOf('month');

const startOfPreviousFiscalYear = startOfFiscalYear.clone().subtract(1, 'year');
const endOfPreviousFiscalYear = endOfFiscalYear.clone().subtract(1, 'year');

const periodDatesByName: Record<DashboardPeriodName, moment.Moment[]> = {
  'current month': [startOfMonth, endOfMonth],
  'previous month': [startOfPreviousMonth, endOfPreviousMonth],
  'current year': [startOfFiscalYear, endOfFiscalYear],
  'previous year': [startOfPreviousFiscalYear, endOfPreviousFiscalYear],
};

export enum DashboardTab {
  MyEntries,
  MyTeamEntries,
  AllEntries,
}

interface Props {
  currentTab: DashboardTab;
  onTabChange: (tab: DashboardTab) => void;
  onPeriodRangeChange: (range: moment.Moment[]) => void;
}

export const Tabs: React.FC<Props> = ({
  currentTab,
  onTabChange,
  onPeriodRangeChange,
}) => {
  const { formatMessage } = useIntl();
  const { isAdmin } = useSecurity();
  const { isMobile } = useResponsive();
  const [periodName, setPeriodName] =
    useState<DashboardPeriodName>('current month');

  useEffect(() => {
    onPeriodRangeChange(periodDatesByName[periodName]);
  }, [periodName, onPeriodRangeChange]);

  const handlePeriodChange = (periodName: DashboardPeriodName) => {
    setPeriodName(periodName);
  };

  const periodOptions = [
    {
      key: 'current month',
      text: formatMessage(m.currentMonth),
      value: 'current month',
    },
    {
      key: 'previous month',
      text: formatMessage(m.previousMonth),
      value: 'previous month',
    },
    {
      key: 'current year',
      text: formatMessage(m.currentYear),
      value: 'current year',
    },
    {
      key: 'previous year',
      text: formatMessage(m.previousYear),
      value: 'previous year',
    },
  ];

  const tabCount = isAdmin ? 3 : 2;

  return (
    <div className={`${isMobile ? 'tw-mx-12' : undefined} tw-mb-8`}>
      <Menu
        color={isMobile ? undefined : 'green'}
        inverted={isMobile ? true : undefined}
        widths={isMobile ? tabCount : undefined}
        pointing
        secondary
        className="tw-border-t-0 tw-border-l-0 tw-border-r-0"
      >
        <Menu.Item
          active={currentTab === DashboardTab.MyEntries}
          onClick={() => onTabChange(DashboardTab.MyEntries)}
        >
          {formatMessage(m.myEntries)}
        </Menu.Item>
        <Menu.Item
          active={currentTab === DashboardTab.MyTeamEntries}
          onClick={() => onTabChange(DashboardTab.MyTeamEntries)}
        >
          {formatMessage(m.myTeamEntries)}
        </Menu.Item>

        {isAdmin && (
          <Menu.Item
            active={currentTab === DashboardTab.AllEntries}
            onClick={() => onTabChange(DashboardTab.AllEntries)}
          >
            {formatMessage(m.allEntries)}
          </Menu.Item>
        )}

        {!isMobile && (
          <Menu.Menu position="right">
            <Menu.Item>
              {formatMessage(m.showDataForPeriod)}
              <Dropdown
                className="tw-ml-2"
                inline
                options={periodOptions}
                value={periodName}
                onChange={(event, { value }) =>
                  handlePeriodChange(value as DashboardPeriodName)
                }
              />
            </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>

      {isMobile && (
        <div className="tw-flex tw-justify-end tw-text-white">
          {formatMessage(m.showDataForPeriod)}
          <Dropdown
            className="tw-ml-2"
            inline
            options={periodOptions}
            value={periodName}
            onChange={(event, { value }) =>
              handlePeriodChange(value as DashboardPeriodName)
            }
          />
        </div>
      )}
    </div>
  );
};
