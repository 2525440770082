import { deburr, sortBy } from 'lodash';
import { defineMessages, useIntl } from 'react-intl';
import { InterventionWithTextStat, ReportStats } from './reportTypes';

const maxTableLineToUseUnbreakableBlock = 35;

const canBeUnbreakable = ( lines : any[]) => {
  return lines.length <= maxTableLineToUseUnbreakableBlock;
}

const m = defineMessages({
  generatedOn: {
    id: 'Report.PDF.Footer.GeneratedOn',
    defaultMessage: 'Généré le',
  },
  page: {
    id: 'Report.PDF.Footer.Page',
    defaultMessage: 'Page',
  },
  pageTo: {
    id: 'Report.PDF.Footer.PageTo',
    defaultMessage: 'de',
  },
  documentTitle: {
    id: 'Report.PDF.DocumentTitle',
    defaultMessage: 'Statistiques',
  },
  periodSectionTitle: {
    id: 'Report.PDF.PeriodSection.Title',
    defaultMessage: 'Période',
  },
  fromDate: {
    id: 'Report.PDF.PeriodSection.FromDate',
    defaultMessage: 'Date de début',
  },
  toDate: {
    id: 'Report.PDF.PeriodSection.ToDate',
    defaultMessage: 'Date de fin',
  },
  total: {
    id: 'Report.PDF.Total',
    defaultMessage: 'Total',
  },
  entries: {
    id: 'Report.PDF.EntriesSection.Title',
    defaultMessage: 'Entrées',
  },
  reportCountHeader: {
    id: 'Report.PDF.ReportCountHeader',
    defaultMessage: 'Entrée(s)',
  },
  personCountHeader: {
    id: 'Report.PDF.PersonCountHeader',
    defaultMessage: 'Personne(s)',
  },
  personPercentHeader: {
    id: 'Report.PDF.PersonPercentHeader',
    defaultMessage: '% Personne(s)',
  },
  allEntriesStat: {
    id: 'Report.PDF.AllEntries',
    defaultMessage: 'Toutes les entrées',
  },
  entriesWithInterventionAndSinglePersonStat: {
    id: 'Report.PDF.EntriesWithInterventionAndSinglePersonStat',
    defaultMessage: 'Rencontres individuelles avec intervention',
  },
  entriesWithInterventionAndGroupStat: {
    id: 'Report.PDF.EntriesWithInterventionAndGroupStat',
    defaultMessage: 'Groupe avec intervention',
  },
  entriesWithoutInterventionAndSinglePersonStat: {
    id: 'Report.PDF.EntriesWithoutInterventionAndSinglePersonStat',
    defaultMessage: 'Rencontres individuelles sans intervention',
  },
  entriesWithoutInterventionAndGroupStat: {
    id: 'Report.PDF.EntriesWithoutInterventionAndGroupStat',
    defaultMessage: 'Groupe sans intervention',
  },
  caseworkers: {
    id: 'Report.PDF.Caseworkers.Title',
    defaultMessage: 'Intervenants',
  },
  teams: {
    id: 'Report.PDF.Teams.Title',
    defaultMessage: 'Équipes',
  },
  meetingTypes: {
    id: 'Report.PDF.MeetingTypes.Title',
    defaultMessage: 'Types de rencontre',
  },
  populations: {
    id: 'Report.PDF.Populations.Title',
    defaultMessage: 'Populations rejointes',
  },
  locations: {
    id: 'Report.PDF.Locations.Title',
    defaultMessage: "Lieux d'activité",
  },
  districts: {
    id: 'Report.PDF.Districts.Title',
    defaultMessage: 'Arrondissements',
  },
  moments: {
    id: 'Report.PDF.Moments.Title',
    defaultMessage: 'Moments',
  },
  contactStates: {
    id: 'Report.PDF.ContactStates.Title',
    defaultMessage: 'États du contact',
  },
  initiators: {
    id: 'Report.PDF.Initiators.Title',
    defaultMessage: 'Initiateurs du contact',
  },
  referredBy: {
    id: 'Report.PDF.ReferredBy.Title',
    defaultMessage: 'Référé Par',
  },
  demographiesPersonCount: {
    id: 'Report.PDF.DemographiesPersonCount.Title',
    defaultMessage: 'Nombre de personne',
  },
  person: {
    id: 'Report.PDF.Person',
    defaultMessage: 'personne',
  },
  personPlural: {
    id: 'Report.PDF.PersonPlural',
    defaultMessage: 'personnes',
  },
  genders: {
    id: 'Report.PDF.Genders.Title',
    defaultMessage: 'Sexes',
  },
  ageGroups: {
    id: 'Report.PDF.AgeGroups.Title',
    defaultMessage: "Groupes d'âge",
  },
  occupations: {
    id: 'Report.PDF.Occupations.Title',
    defaultMessage: 'Occupations/Revenus',
  },
  origins: {
    id: 'Report.PDF.Origins.Title',
    defaultMessage: 'Origines',
  },
  languages: {
    id: 'Report.PDF.Languages.Title',
    defaultMessage: 'Langues',
  },
  interventions: {
    id: 'Report.PDF.Interventions.Title',
    defaultMessage: 'Interventions',
  },
  count: {
    id: 'Report.PDF.Count',
    defaultMessage: 'Quantité',
  },
  interventionDuration: {
    id: 'Report.PDF.InterventionDuration.Title',
    defaultMessage: "Durées d'intervention",
  },
  interventionWithNumber: {
    id: 'Report.PDF.InterventionWithNumber.Title',
    defaultMessage: 'Distributions prévention',
  },
  countPercent: {
    id: 'Report.PDF.CountPercent',
    defaultMessage: '% Quantité',
  },
  problematics: {
    id: 'Report.PDF.Problematics.Title',
    defaultMessage: 'Préoccupations et problématiques',
  },
  homelessnessSituations: {
    id: 'Report.PDF.HomelessnessSituations.Title',
    defaultMessage: "Situations d'itinérance",
  },
  homelessness: {
    id: 'Report.PDF.Homelessness.Title',
    defaultMessage: 'Interventions en itinérance',
  },
  minute: {
    id: 'Report.PDF.Minute',
    defaultMessage: 'minute',
  },
  minutePlural: {
    id: 'Report.PDF.MinutePlural',
    defaultMessage: 'minutes',
  },
  averageInterventionDuration: {
    id: 'Report.PDF.AverageInterventionDuration',
    defaultMessage: 'Durée moyenne des interventions',
  },
  entriesWithInterventions: {
    id: 'Report.PDF.EntriesWithInterventions.Title',
    defaultMessage: 'Entrées avec intervention',
  },
  noEntryWithIntervention: {
    id: 'Report.PDF.EntriesWithInterventions.None',
    defaultMessage: 'Aucune entrée avec intervention',
  },
  entriesWithProblematics: {
    id: 'Report.PDF.EntriesWithProblematics.Title',
    defaultMessage: 'Entrées avec préoccupations ou problématiques',
  },
  noEntryWithProblematic: {
    id: 'Report.PDF.EntriesWithProblematics.None',
    defaultMessage: 'Aucune entrées avec préoccupations ou problématiques',
  },
  entriesWithHomelessness: {
    id: 'Report.PDF.EntriesWithHomelessness.Title',
    defaultMessage: "Entrées avec situation d'itinérance",
  },
  noEntryWithHomelessness: {
    id: 'Report.PDF.EntriesWithHomelessness.None',
    defaultMessage: "Aucune entrées avec situation d'itinérance",
  },
  entriesWithTapajProgress: {
    id: 'Report.PDF.EntriesWithTapajProgress.Title',
    defaultMessage: 'Entrées avec progrès TAPAJ ou CAPAB',
  },
  noEntryWithTapajProgress: {
    id: 'Report.PDF.EntriesWithTapajProgress.None',
    defaultMessage: 'Aucune entrées avec progrès TAPAJ ou CAPAB',
  },
  tapajProgress: {
    id: 'Report.PDF.TapajProgress.Title',
    defaultMessage: 'Progrès TAPAJ/CAPAB',
  },
  selectionCriterias: {
    id: 'Report.PDF.SelectionCriterias.Title',
    defaultMessage: 'Critères de sélection',
  },
  createdByCaseworkersCriteria: {
    id: 'Report.PDF.Criteria.CreatedByCaseworkers',
    defaultMessage: 'Intervenants',
  },
  teamsCriteria: {
    id: 'Report.PDF.Criteria.Teams',
    defaultMessage: 'Équipes',
  },
  meetingTypesCriteria: {
    id: 'Report.PDF.Criteria.MeetingTypes',
    defaultMessage: 'Types de rencontre',
  },
  populationsCriteria: {
    id: 'Report.PDF.Criteria.Populations',
    defaultMessage: 'Populations rejointes',
  },
  locationsCriteria: {
    id: 'Report.PDF.Criteria.Locations',
    defaultMessage: "Lieux d'activité",
  },
  districtsCriteria: {
    id: 'Report.PDF.Criteria.Districts',
    defaultMessage: 'Arrondissements',
  },
  momentsCriteria: {
    id: 'Report.PDF.Criteria.Moments',
    defaultMessage: 'Moments',
  },
  contactStatesCriteria: {
    id: 'Report.PDF.Criteria.ContactStates',
    defaultMessage: 'États du contact',
  },
  initiatorsCriteria: {
    id: 'Report.PDF.Criteria.Initiators',
    defaultMessage: 'Initiateurs du contact',
  },
  referredByCriteria: {
    id: 'Report.PDF.Criteria.ReferredBy',
    defaultMessage: 'Référé par',
  },
  minPersonCountCriteria: {
    id: 'Report.PDF.Criteria.MinPersonCount',
    defaultMessage: 'Nombre de personne minimum',
  },
  maxPersonCountCriteria: {
    id: 'Report.PDF.Criteria.MaxPersonCount',
    defaultMessage: 'Nombre de personne maximum',
  },
  gendersCriteria: {
    id: 'Report.PDF.Criteria.Genders',
    defaultMessage: 'Sexes',
  },
  ageGroupsCriteria: {
    id: 'Report.PDF.Criteria.AgeGroups',
    defaultMessage: "Groupes d'âge",
  },
  occupationsCriteria: {
    id: 'Report.PDF.Criteria.Occupations',
    defaultMessage: 'Occupations/Revenus',
  },
  originsCriteria: {
    id: 'Report.PDF.Criteria.Origins',
    defaultMessage: 'Origines',
  },
  hasInterventionCriteria: {
    id: 'Report.PDF.Criteria.HasIntervention',
    defaultMessage: 'Avec intervention',
  },
  minDurationMinutesCriteria: {
    id: 'Report.PDF.Criteria.MinDurationMinutes',
    defaultMessage: "Durée de l'intervention minimum",
  },
  maxDurationMinutesCriteria: {
    id: 'Report.PDF.Criteria.MaxDurationMinutes',
    defaultMessage: "Durée de l'intervention maximum",
  },
  interventionsCriteria: {
    id: 'Report.PDF.Criteria.Interventions',
    defaultMessage: 'Interventions',
  },
  problematicsCriteria: {
    id: 'Report.PDF.Criteria.Problematics',
    defaultMessage: 'Préoccupations et problématiques',
  },
  hasHomelessnessCriteria: {
    id: 'Report.PDF.Criteria.HasHomelessness',
    defaultMessage: 'Avec itinérance',
  },
  homelessnessSituationsCriteria: {
    id: 'Report.PDF.Criteria.HomelessnessSituations',
    defaultMessage: "Situations d'itinérance",
  },
  homelessnessInterventionsCriteria: {
    id: 'Report.PDF.Criteria.HomelessnessInterventions',
    defaultMessage: 'Interventions en itinérance',
  },
  hasTapajProgressCriteria: {
    id: 'Report.PDF.Criteria.HasTapajProgress',
    defaultMessage: 'Avec progrès TAPAJ/CAPAB',
  },
  tapajProgressesCriteria: {
    id: 'Report.PDF.Criteria.TapajProgresses',
    defaultMessage: 'Progrès TAPAJ/CAPAB',
  },
  with: {
    id: 'Report.PDF.With',
    defaultMessage: 'Oui',
  },
  without: {
    id: 'Report.PDF.Without',
    defaultMessage: 'Non',
  },
});

const styles = {
  pageHeader: {
    fontSize: 18,
    bold: true,
    alignment: 'center',
    margin: [0, 0, 0, 10],
  },
  subheader: {
    fontSize: 14,
    bold: true,
    margin: [0, 5, 0, 5],
  },
  table: {
    margin: [0, 0, 0, 15],
  },
  tableHeader: {
    bold: false,
    color: 'black',
  },
  totalCell: {
    bold: true,
    alignment: 'right',
  },
  numberCell: {
    alignment: 'right',
  },
  numberHeaderCell: {
    alignment: 'right',
  },
  criteriaValueCell: {
    alignment: 'left',
  },
};

const defaultStyle = {
  fontSize: 9.5,
};

export const useReportPdf = () => {
  const { formatMessage, formatDate, formatNumber } = useIntl();

  const getFormattedDate = (date: Date) =>
    formatDate(date, { day: 'numeric', month: 'long', year: 'numeric' });

  const getFormattedDateUtc = (date: Date) =>
    formatDate(date, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: 'utc',
    });

  const getCountCell = (count: number) => ({
    text: count,
    style: 'numberCell',
  });

  const getPercentCell = (percent: number) => ({
    text: formatNumber(percent / 100, {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    style: 'numberCell',
  });

  const footer = (currentPage: any, pageCount: any) => ({
    stack: [
      {
        columns: [
          {
            width: '*',
            text: `${formatMessage(m.generatedOn)} ${getFormattedDate(
              new Date()
            )}`,
            alignment: 'left',
          },
          {
            width: '*',
            text: `${formatMessage(m.page)} ${currentPage} ${formatMessage(
              m.pageTo
            )} ${pageCount.toString()}`,
            alignment: 'right',
          },
        ],
      },
    ],
    margin: [40, 10, 40, 0],
  });

  const getPeriodSection = (reportStats: ReportStats) => {
    const {
      reportQuery: { fromDate, toDate },
    } = reportStats;

    return [
      { text: formatMessage(m.periodSectionTitle), style: 'subheader' },
      {
        style: 'table',
        table: {
          widths: ['*', 'auto'],
          body: [
            [
              formatMessage(m.fromDate),
              fromDate ? getFormattedDateUtc(new Date(fromDate)) : '',
            ],
            [
              formatMessage(m.toDate),
              toDate ? getFormattedDateUtc(new Date(toDate)) : '',
            ],
          ],
        },
        layout: 'lightHorizontalLines',
      },
    ];
  };

  const getEntriesSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: true,
        stack: [
          { text: formatMessage(m.entries), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                [
                  formatMessage(m.allEntriesStat),
                  getCountCell(reportStats.allEntriesStat.entryCount),
                  getCountCell(reportStats.allEntriesStat.personCount),
                  getPercentCell(reportStats.allEntriesStat.personPercent),
                ],
                [
                  formatMessage(m.entriesWithInterventionAndSinglePersonStat),
                  getCountCell(
                    reportStats.entriesWithInterventionAndSinglePersonStat
                      .entryCount
                  ),
                  getCountCell(
                    reportStats.entriesWithInterventionAndSinglePersonStat
                      .personCount
                  ),
                  getPercentCell(
                    reportStats.entriesWithInterventionAndSinglePersonStat
                      .personPercent
                  ),
                ],
                [
                  formatMessage(
                    m.entriesWithoutInterventionAndSinglePersonStat
                  ),
                  getCountCell(
                    reportStats.entriesWithoutInterventionAndSinglePersonStat
                      .entryCount
                  ),
                  getCountCell(
                    reportStats.entriesWithoutInterventionAndSinglePersonStat
                      .personCount
                  ),
                  getPercentCell(
                    reportStats.entriesWithoutInterventionAndSinglePersonStat
                      .personPercent
                  ),
                ],
                [
                  formatMessage(m.entriesWithInterventionAndGroupStat),
                  getCountCell(
                    reportStats.entriesWithInterventionAndGroupStat.entryCount
                  ),
                  getCountCell(
                    reportStats.entriesWithInterventionAndGroupStat.personCount
                  ),
                  getPercentCell(
                    reportStats.entriesWithInterventionAndGroupStat
                      .personPercent
                  ),
                ],
                [
                  formatMessage(m.entriesWithoutInterventionAndGroupStat),
                  getCountCell(
                    reportStats.entriesWithoutInterventionAndGroupStat
                      .entryCount
                  ),
                  getCountCell(
                    reportStats.entriesWithoutInterventionAndGroupStat
                      .personCount
                  ),
                  getPercentCell(
                    reportStats.entriesWithoutInterventionAndGroupStat
                      .personPercent
                  ),
                ],
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getCaseworkersSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.caseworkersStat.itemsStat),
        stack: [
          { text: formatMessage(m.caseworkers), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.caseworkersStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getTeamsSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.teamsStat.itemsStat),
        stack: [
          { text: formatMessage(m.teams), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.teamsStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getMeetingTypesSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.meetingTypesStat.itemsStat),
        stack: [
          { text: formatMessage(m.meetingTypes), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.meetingTypesStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getPopulationsSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.populationsStat.itemsStat),
        stack: [
          { text: formatMessage(m.populations), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.populationsStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getLocationsSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.locationsStat.itemsStat),
        stack: [
          { text: formatMessage(m.locations), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.locationsStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getDistrictsSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.districtsStat.itemsStat),
        stack: [
          { text: formatMessage(m.districts), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.districtsStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getMomentsSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.momentsStat.itemsStat),
        stack: [
          { text: formatMessage(m.moments), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.momentsStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getContactStatesSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.contactStatesStat.itemsStat),
        stack: [
          { text: formatMessage(m.contactStates), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.contactStatesStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getInitiatorsSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.initiatorsStat.itemsStat),
        stack: [
          { text: formatMessage(m.initiators), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.initiatorsStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getReferredBySection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.referredByStat.itemsStat),
        stack: [
          { text: formatMessage(m.referredBy), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.referredByStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
                [
                  { text: formatMessage(m.total), style: 'totalCell' },
                  {
                    text: reportStats.referredByStat.entryCount,
                    style: 'totalCell',
                  },
                  {
                    text: reportStats.referredByStat.personCount,
                    style: 'totalCell',
                  },
                  '',
                ],
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getDemographiesCountSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.demographiesCountStat.itemsStat),
        stack: [
          {
            text: formatMessage(m.demographiesPersonCount),
            style: 'subheader',
          },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.demographiesCountStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  `${item.name} ${
                    parseInt(item.name) > 1
                      ? formatMessage(m.personPlural)
                      : formatMessage(m.person)
                  }`,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getGendersSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.gendersStat.itemsStat),
        stack: [
          { text: formatMessage(m.genders), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.gendersStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getAgeGroupsSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.ageGroupsStat.itemsStat),
        stack: [
          { text: formatMessage(m.ageGroups), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.ageGroupsStat.itemsStat, (item) =>
                  deburr(item.orderKey)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getOccupationsSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.occupationsStat.itemsStat),
        stack: [
          { text: formatMessage(m.occupations), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.occupationsStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getOriginsSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.originsStat.itemsStat),
        stack: [
          { text: formatMessage(m.origins), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.originsStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getLanguagesSection = (reportStats: ReportStats) => {
    return [
      {
        unbreakable: canBeUnbreakable(reportStats.languagesStat.itemsStat),
        stack: [
          { text: formatMessage(m.languages), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.languagesStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getEntriesWithInterventionsSection = (reportStats: ReportStats) => {
    const header = {
      text: formatMessage(m.entriesWithInterventions),
      style: 'subheader',
    };

    if (reportStats.entriesWithInterventionStat.entryCount === 0) {
      return [
        header,
        {
          text: formatMessage(m.noEntryWithIntervention),
          margin: [0, 0, 0, 20],
        },
      ];
    }

    return [
      {
        unbreakable: true,
        stack: [
          header,
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                [
                  '',
                  getCountCell(
                    reportStats.entriesWithInterventionStat.entryCount
                  ),
                  getCountCell(
                    reportStats.entriesWithInterventionStat.personCount
                  ),
                  getPercentCell(
                    reportStats.entriesWithInterventionStat.personPercent
                  ),
                ],
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getInterventionsSection = (reportStats: ReportStats) => {
    if (reportStats.interventionsStat.length === 0) {
      return [];
    }

    return [
      {
        unbreakable: canBeUnbreakable(reportStats.interventionsStat),
        stack: [
          { text: formatMessage(m.interventions), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.count),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.interventionsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.count),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getInterventionDurationSection = (reportStats: ReportStats) => {
    if (reportStats.interventionDurationStat.itemsStat.length === 0) {
      return [];
    }

    const {
      interventionDurationStat: { averageDurationInMinutes },
    } = reportStats;

    const formattedInterventionDurationAverageInMinutes = formatNumber(
      averageDurationInMinutes,
      { maximumFractionDigits: 1 }
    );

    return [
      {
        unbreakable: canBeUnbreakable(reportStats.interventionDurationStat.itemsStat),
        stack: [
          { text: formatMessage(m.interventionDuration), style: 'subheader' },
          {
            text: `${formatMessage(
              m.averageInterventionDuration
            )} : ${formattedInterventionDurationAverageInMinutes} ${
              averageDurationInMinutes > 1
                ? formatMessage(m.minutePlural)
                : formatMessage(m.minute)
            }`,
          },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.count),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(
                  reportStats.interventionDurationStat.itemsStat,
                  (item) => item.orderKey
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
                [
                  '',
                  { text: formatMessage(m.total), style: 'totalCell' },
                  {
                    text: reportStats.interventionDurationStat.personCount,
                    style: 'totalCell',
                  },
                  '',
                ],
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getInterventionWithNumberSection = (reportStats: ReportStats) => {
    if (reportStats.interventionsWithNumberValueStat.itemsStat.length === 0) {
      return [];
    }

    return [
      {
        unbreakable: canBeUnbreakable(reportStats.interventionsWithNumberValueStat.itemsStat),
        stack: [
          { text: formatMessage(m.interventionWithNumber), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.count),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.countPercent),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(
                  reportStats.interventionsWithNumberValueStat.itemsStat,
                  (item) => item.name
                ).map((item) => [
                  item.name,
                  getCountCell(item.count),
                  getPercentCell(item.countPercent),
                ]),
                [
                  { text: formatMessage(m.total), style: 'totalCell' },
                  {
                    text: reportStats.interventionsWithNumberValueStat.total,
                    style: 'totalCell',
                  },
                  '',
                ],
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getEntriesWithProblematicsSection = (reportStats: ReportStats) => {
    const header = {
      text: formatMessage(m.entriesWithProblematics),
      style: 'subheader',
    };

    if (reportStats.entriesWithProblematicsStat.entryCount === 0) {
      return [
        header,
        {
          text: formatMessage(m.noEntryWithProblematic),
          margin: [0, 0, 0, 20],
        },
      ];
    }

    return [
      {
        unbreakable: true,
        stack: [
          header,
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                [
                  '',
                  getCountCell(
                    reportStats.entriesWithProblematicsStat.entryCount
                  ),
                  getCountCell(
                    reportStats.entriesWithProblematicsStat.personCount
                  ),
                  getPercentCell(
                    reportStats.entriesWithProblematicsStat.personPercent
                  ),
                ],
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getProblematicsSection = (reportStats: ReportStats) => {
    if (reportStats.problematicsStat.itemsStat.length === 0) {
      return [];
    }

    return [
      {
        unbreakable: canBeUnbreakable(reportStats.problematicsStat.itemsStat),
        stack: [
          { text: formatMessage(m.problematics), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.count),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.problematicsStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.count),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getInterventionWithTextSection = (
    interventionWithTextStat: InterventionWithTextStat
  ) => {
    return [
      {
        unbreakable: canBeUnbreakable(interventionWithTextStat.itemsStat),
        stack: [
          { text: interventionWithTextStat.name, style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.count),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(interventionWithTextStat.itemsStat, (item) =>
                  deburr(item.textValue)
                ).map((item) => [item.textValue, getCountCell(item.count)]),
                [
                  { text: formatMessage(m.total), style: 'totalCell' },
                  {
                    text: interventionWithTextStat.total,
                    style: 'totalCell',
                  },
                ]
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getInterventionWithTextSections = (reportStats: ReportStats) => {
    if (reportStats.interventionsWithTextValueStat.length === 0) {
      return [];
    }
    
    const def = reportStats.interventionsWithTextValueStat
      .map((interventionWithTextStat) =>
        getInterventionWithTextSection(interventionWithTextStat)
      )
      .flat();
      
      return def;
  };

  const getEntriesWithHomelessnessSection = (reportStats: ReportStats) => {
    const header = {
      text: formatMessage(m.entriesWithHomelessness),
      style: 'subheader',
    };

    if (reportStats.entriesWithHomelessnessStat.entryCount === 0) {
      return [
        header,
        {
          text: formatMessage(m.noEntryWithHomelessness),
          margin: [0, 0, 0, 20],
        },
      ];
    }

    return [
      {
        unbreakable: true,
        stack: [
          header,
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                [
                  '',
                  getCountCell(
                    reportStats.entriesWithHomelessnessStat.entryCount
                  ),
                  getCountCell(
                    reportStats.entriesWithHomelessnessStat.personCount
                  ),
                  getPercentCell(
                    reportStats.entriesWithHomelessnessStat.personPercent
                  ),
                ],
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getHomelessnessSituationsSection = (reportStats: ReportStats) => {
    if (reportStats.homelessnessSituationsStat.itemsStat.length === 0) {
      return [];
    }

    return [
      {
        unbreakable: canBeUnbreakable(reportStats.homelessnessSituationsStat.itemsStat),
        stack: [
          { text: formatMessage(m.homelessnessSituations), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(
                  reportStats.homelessnessSituationsStat.itemsStat,
                  (item) => deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.entryCount),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
                [
                  { text: formatMessage(m.total), style: 'totalCell' },
                  {
                    text: reportStats.homelessnessSituationsStat.entryCount,
                    style: 'totalCell',
                  },
                  {
                    text: reportStats.homelessnessSituationsStat.personCount,
                    style: 'totalCell',
                  },
                  '',
                ],
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getHomelessnessSection = (reportStats: ReportStats) => {
    if (reportStats.homelessnessesStat.itemsStat.length === 0) {
      return [];
    }

    return [
      {
        unbreakable: canBeUnbreakable(reportStats.homelessnessesStat.itemsStat),
        stack: [
          { text: formatMessage(m.homelessness), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.count),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.homelessnessesStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.count),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getEntriesWithTapajProgressSection = (reportStats: ReportStats) => {
    const header = {
      text: formatMessage(m.entriesWithTapajProgress),
      style: 'subheader',
    };

    if (reportStats.entriesWithTapajProgressStat.entryCount === 0) {
      return [
        header,
        {
          text: formatMessage(m.noEntryWithTapajProgress),
          margin: [0, 0, 0, 20],
        },
      ];
    }

    return [
      {
        unbreakable: true,
        stack: [
          header,
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.reportCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                [
                  '',
                  getCountCell(
                    reportStats.entriesWithTapajProgressStat.entryCount
                  ),
                  getCountCell(
                    reportStats.entriesWithTapajProgressStat.personCount
                  ),
                  getPercentCell(
                    reportStats.entriesWithTapajProgressStat.personPercent
                  ),
                ],
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getTapajProgressSection = (reportStats: ReportStats) => {
    if (reportStats.tapajProgressesStat.itemsStat.length === 0) {
      return [];
    }

    return [
      {
        unbreakable: canBeUnbreakable(reportStats.tapajProgressesStat.itemsStat),
        stack: [
          { text: formatMessage(m.tapajProgress), style: 'subheader' },
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                [
                  '',
                  {
                    text: formatMessage(m.count),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personCountHeader),
                    style: 'numberHeaderCell',
                  },
                  {
                    text: formatMessage(m.personPercentHeader),
                    style: 'numberHeaderCell',
                  },
                ],
                ...sortBy(reportStats.tapajProgressesStat.itemsStat, (item) =>
                  deburr(item.name)
                ).map((item) => [
                  item.name,
                  getCountCell(item.count),
                  getCountCell(item.personCount),
                  getPercentCell(item.personPercent),
                ]),
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      },
    ];
  };

  const getSelectionCriteriasSection = (reportStats: ReportStats) => {
    const { reportQuery, lists, users } = reportStats;

    const getValueCell = (value: any) => ({
      text: value,
      style: 'criteriaValueCell',
    });

    const getFromDateCriteria = () =>
      reportQuery.fromDate
        ? [
            [
              formatMessage(m.fromDate),
              getValueCell(getFormattedDateUtc(new Date(reportQuery.fromDate))),
            ],
          ]
        : [];

    const getToDateCriteria = () =>
      reportQuery.toDate
        ? [
            [
              formatMessage(m.toDate),
              getValueCell(getFormattedDateUtc(new Date(reportQuery.toDate))),
            ],
          ]
        : [];

    const getCreatedByCaseworkersCriteria = () =>
      reportQuery.createdByCaseworkers.length
        ? [
            [
              formatMessage(m.createdByCaseworkersCriteria),
              getValueCell(
                reportQuery.createdByCaseworkers
                  .map((userId) => users.indexedUsers[userId].fullName)
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getTeamsCriteria = () =>
      reportQuery.teams.length
        ? [
            [
              formatMessage(m.teamsCriteria),
              getValueCell(
                reportQuery.teams
                  .map((teamId) => lists.teams.indexedTeams[teamId].name)
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getMeetingTypesCriteria = () =>
      reportQuery.meetingTypes.length
        ? [
            [
              formatMessage(m.meetingTypesCriteria),
              getValueCell(
                reportQuery.meetingTypes
                  .map(
                    (meetingTypeId) =>
                      lists.meetingTypes.indexedMeetingTypes[meetingTypeId].name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getPopulationsCriteria = () =>
      reportQuery.populations.length
        ? [
            [
              formatMessage(m.populationsCriteria),
              getValueCell(
                reportQuery.populations
                  .map(
                    (populationId) =>
                      lists.populations.indexedPopulations[populationId].name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getLocationsCriteria = () =>
      reportQuery.locations.length
        ? [
            [
              formatMessage(m.locationsCriteria),
              getValueCell(
                reportQuery.locations
                  .map(
                    (locationId) =>
                      lists.locations.indexedLocations[locationId].name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getDistrictsCriteria = () =>
      reportQuery.districts.length
        ? [
            [
              formatMessage(m.districtsCriteria),
              getValueCell(
                reportQuery.districts
                  .map(
                    (districtId) =>
                      lists.districts.indexedDistricts[districtId].name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getMomentsCriteria = () =>
      reportQuery.moments.length
        ? [
            [
              formatMessage(m.momentsCriteria),
              getValueCell(
                reportQuery.moments
                  .map(
                    (momentId) => lists.moments.indexedMoments[momentId].name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getContactStatesCriteria = () =>
      reportQuery.contactStates.length
        ? [
            [
              formatMessage(m.contactStatesCriteria),
              getValueCell(
                reportQuery.contactStates
                  .map(
                    (contactStateId) =>
                      lists.contactStates.indexedContactStates[contactStateId]
                        .name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getInitiatorsCriteria = () =>
      reportQuery.initiators.length
        ? [
            [
              formatMessage(m.initiatorsCriteria),
              getValueCell(
                reportQuery.initiators
                  .map(
                    (initiatorId) =>
                      lists.initiators.indexedInitiators[initiatorId].name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getReferredByCriteria = () =>
      reportQuery.referredBy.length
        ? [
            [
              formatMessage(m.referredByCriteria),
              getValueCell(reportQuery.referredBy.join(', ')),
            ],
          ]
        : [];

    const getMinPersonCountCriteria = () => {
      const minPersonCount =
        parseInt(reportQuery.demographies.minPersonCount ?? '') || 0;

      return minPersonCount > 0
        ? [
            [
              formatMessage(m.minPersonCountCriteria),
              getValueCell(minPersonCount),
            ],
          ]
        : [];
    };

    const getMaxPersonCountCriteria = () => {
      const maxPersonCount =
        parseInt(reportQuery.demographies.maxPersonCount ?? '') || 0;

      return maxPersonCount > 0
        ? [
            [
              formatMessage(m.maxPersonCountCriteria),
              getValueCell(maxPersonCount),
            ],
          ]
        : [];
    };

    const getGendersCriteria = () =>
      reportQuery.demographies.genders.length
        ? [
            [
              formatMessage(m.gendersCriteria),
              getValueCell(
                reportQuery.demographies.genders
                  .map(
                    (genderId) => lists.genders.indexedGenders[genderId].name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getAgeGroupsCriteria = () =>
      reportQuery.demographies.ageGroups.length
        ? [
            [
              formatMessage(m.ageGroupsCriteria),
              getValueCell(
                reportQuery.demographies.ageGroups
                  .map(
                    (ageGroupId) =>
                      lists.ageGroups.indexedAgeGroups[ageGroupId].name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getOccupationsCriteria = () =>
      reportQuery.demographies.occupations.length
        ? [
            [
              formatMessage(m.occupationsCriteria),
              getValueCell(
                reportQuery.demographies.occupations
                  .map(
                    (occupationId) =>
                      lists.occupations.indexedOccupations[occupationId].name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getOriginsCriteria = () =>
      reportQuery.demographies.origins.length
        ? [
            [
              formatMessage(m.originsCriteria),
              getValueCell(
                reportQuery.demographies.origins
                  .map(
                    (originId) => lists.origins.indexedOrigins[originId].name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    const isBoolean = (val: any) => {
      return val === false || val === true;
    };

    const getHasInterventionCriteria = () => {
      const hasInterventionWasSpecified = isBoolean(
        reportQuery.interventions.hasIntervention
      );

      return hasInterventionWasSpecified
        ? [
            [
              formatMessage(m.hasInterventionCriteria),
              getValueCell(
                reportQuery.interventions.hasIntervention
                  ? formatMessage(m.with)
                  : formatMessage(m.without)
              ),
            ],
          ]
        : [];
    };

    const getMinDurationMinutesCriteria = () => {
      const minDurationMinutes =
        parseInt(reportQuery.interventions.minDurationMinutes ?? '') || 0;

      return minDurationMinutes > 0
        ? [
            [
              formatMessage(m.minDurationMinutesCriteria),
              getValueCell(minDurationMinutes),
            ],
          ]
        : [];
    };

    const getMaxDurationMinutesCriteria = () => {
      const maxDurationMinutes =
        parseInt(reportQuery.interventions.maxDurationMinutes ?? '') || 0;

      return maxDurationMinutes > 0
        ? [
            [
              formatMessage(m.maxDurationMinutesCriteria),
              getValueCell(maxDurationMinutes),
            ],
          ]
        : [];
    };

    const getInterventionsCriteria = () =>
      reportQuery.interventions.interventions.length
        ? [
            [
              formatMessage(m.interventionsCriteria),
              getValueCell(
                reportQuery.interventions.interventions
                  .map(
                    (interventionId) =>
                      lists.interventions.indexedInterventions[interventionId]
                        .name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getProblematicsCriteria = () =>
      reportQuery.interventions.problematics.length
        ? [
            [
              formatMessage(m.problematicsCriteria),
              getValueCell(
                reportQuery.interventions.problematics
                  .map(
                    (problematicId) =>
                      lists.problematics.indexedProblematics[problematicId].name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getHasHomelessnessCriteria = () => {
      const hasHomelessnessWasSpecified = isBoolean(
        reportQuery.homelessnesses.hasHomelessness
      );

      return hasHomelessnessWasSpecified
        ? [
            [
              formatMessage(m.hasHomelessnessCriteria),
              getValueCell(
                reportQuery.homelessnesses.hasHomelessness
                  ? formatMessage(m.with)
                  : formatMessage(m.without)
              ),
            ],
          ]
        : [];
    };

    const getHomelessnessSituationsCriteria = () =>
      reportQuery.homelessnesses.homelessnessSituation.length
        ? [
            [
              formatMessage(m.homelessnessSituationsCriteria),
              getValueCell(
                reportQuery.homelessnesses.homelessnessSituation
                  .map(
                    (homelessnessSituationId) =>
                      lists.homelessnessSituations
                        .indexedHomelessnessSituations[homelessnessSituationId]
                        .name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getHomelessnessInterventionsCriteria = () =>
      reportQuery.homelessnesses.homelessnessInterventions.length
        ? [
            [
              formatMessage(m.homelessnessInterventionsCriteria),
              getValueCell(
                reportQuery.homelessnesses.homelessnessInterventions
                  .map(
                    (homelessnessId) =>
                      lists.homelessnesses.indexedHomelessnesses[homelessnessId]
                        .name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    const getHasTapajProgressCriteria = () => {
      const hasTapajProgressWasSpecified = isBoolean(
        reportQuery.tapajProgress.hasTapajProgress
      );

      return hasTapajProgressWasSpecified
        ? [
            [
              formatMessage(m.hasTapajProgressCriteria),
              getValueCell(
                reportQuery.tapajProgress.hasTapajProgress
                  ? formatMessage(m.with)
                  : formatMessage(m.without)
              ),
            ],
          ]
        : [];
    };

    const getTapajProgressesCriteria = () =>
      reportQuery.tapajProgress.tapajProgresses.length
        ? [
            [
              formatMessage(m.tapajProgressesCriteria),
              getValueCell(
                reportQuery.tapajProgress.tapajProgresses
                  .map(
                    (tapajProgressId) =>
                      lists.tapajProgresses.indexedTapajProgresses[
                        tapajProgressId
                      ].name
                  )
                  .join(', ')
              ),
            ],
          ]
        : [];

    return [
      {
        unbreakable: true,
        stack: [
          { text: formatMessage(m.selectionCriterias), style: 'subheader' },
          {
            style: 'table',
            layout: 'lightHorizontalLines',
            table: {
              widths: [150, '*'],
              body: [
                ...getFromDateCriteria(),
                ...getToDateCriteria(),
                ...getCreatedByCaseworkersCriteria(),
                ...getTeamsCriteria(),
                ...getMeetingTypesCriteria(),
                ...getPopulationsCriteria(),
                ...getLocationsCriteria(),
                ...getDistrictsCriteria(),
                ...getMomentsCriteria(),
                ...getContactStatesCriteria(),
                ...getInitiatorsCriteria(),
                ...getReferredByCriteria(),
                ...getMinPersonCountCriteria(),
                ...getMaxPersonCountCriteria(),
                ...getGendersCriteria(),
                ...getAgeGroupsCriteria(),
                ...getOccupationsCriteria(),
                ...getOriginsCriteria(),
                ...getHasInterventionCriteria(),
                ...getMinDurationMinutesCriteria(),
                ...getMaxDurationMinutesCriteria(),
                ...getInterventionsCriteria(),
                ...getProblematicsCriteria(),
                ...getHasHomelessnessCriteria(),
                ...getHomelessnessSituationsCriteria(),
                ...getHomelessnessInterventionsCriteria(),
                ...getHasTapajProgressCriteria(),
                ...getTapajProgressesCriteria(),
              ],
            },
          },
        ],
      },
    ];
  };

  const getPdfDocumentDefinition = (reportStats: ReportStats) => {
    const documentDefinition = {
      pageSize: 'LETTER',
      pageOrientation: 'portrait',
      pageMargins: 45,
      footer: footer,
      content: [
        { text: formatMessage(m.documentTitle), style: 'pageHeader' },
        ...getPeriodSection(reportStats),
        ...getEntriesSection(reportStats),
        ...getCaseworkersSection(reportStats),
        ...getTeamsSection(reportStats),
        ...getMeetingTypesSection(reportStats),
        ...getPopulationsSection(reportStats),
        ...getLocationsSection(reportStats),
        ...getDistrictsSection(reportStats),
        ...getMomentsSection(reportStats),
        ...getContactStatesSection(reportStats),
        ...getInitiatorsSection(reportStats),
        ...getReferredBySection(reportStats),
        ...getDemographiesCountSection(reportStats),
        ...getGendersSection(reportStats),
        ...getAgeGroupsSection(reportStats),
        ...getOccupationsSection(reportStats),
        ...getOriginsSection(reportStats),
        ...getLanguagesSection(reportStats),
        ...getEntriesWithInterventionsSection(reportStats),
        ...getInterventionsSection(reportStats),
        ...getInterventionDurationSection(reportStats),
        ...getInterventionWithNumberSection(reportStats),
        ...getInterventionWithTextSections(reportStats),
        ...getEntriesWithProblematicsSection(reportStats),
        ...getProblematicsSection(reportStats),
        ...getEntriesWithHomelessnessSection(reportStats),
        ...getHomelessnessSituationsSection(reportStats),
        ...getHomelessnessSection(reportStats),
        ...getEntriesWithTapajProgressSection(reportStats),
        ...getTapajProgressSection(reportStats),
        ...getSelectionCriteriasSection(reportStats),
      ],
      styles: styles,
      defaultStyle: defaultStyle,
    };
    
    return documentDefinition;
  };

  return { getPdfDocumentDefinition };
};
