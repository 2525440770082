import { MobileFriendlyFormDropdown } from 'components/MobileFriendlyDropdown';
import { useEntryValidations } from 'modules/entry/hooks/useEntryValidations';
import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Form } from 'semantic-ui-react';
import { EntryDemographyFormValues, EntryFormContext } from './EntryForm';

const m = defineMessages({
  count: {
    id: 'Entry.Form.Demographies.Count',
    defaultMessage: 'Nombre de personne',
  },
  gender: { id: 'Entry.Form.Demographies.Gender', defaultMessage: 'Sexe' },
  ageGroup: {
    id: 'Entry.Form.Demographies.AgeGroup',
    defaultMessage: "Groupe d'âge",
  },
  occupation: {
    id: 'Entry.Form.Demographies.Occupation',
    defaultMessage: 'Occupation/Revenu',
  },
  origin: { id: 'Entry.Form.Demographies.Origin', defaultMessage: 'Origine' },
  language: {
    id: 'Entry.Form.Demographies.InterventionLanguage',
    defaultMessage: 'Langue',
  },
  add: {
    id: 'Entry.Form.Demographies.Add',
    defaultMessage: 'Ajouter une démographie',
  },
  save: {
    id: 'Entry.Form.Demographies.Save',
    defaultMessage: 'Sauvegarder',
  },
  delete: {
    id: 'Entry.Form.Demographies.Delete',
    defaultMessage: 'Supprimer',
  },
  cancel: {
    id: 'Entry.Form.Demographies.Cancel',
    defaultMessage: 'Annuler',
  },
});

interface Props {
  demography: EntryDemographyFormValues;
  onCancel: () => void;
  onSave: (formValues: EntryDemographyFormValues) => void;
  onDelete: () => void;
  isNew: boolean;
  canDelete: boolean;
}

export const DemographyFormMobile: React.FC<Props> = ({
  demography,
  onCancel,
  onSave,
  onDelete,
  canDelete,
}) => {
  const { formatMessage } = useIntl();

  const validations = useEntryValidations();

  const { lists } = useContext(EntryFormContext);

  const { handleSubmit, control, reset } = useForm<EntryDemographyFormValues>({
    defaultValues: demography,
  });

  useEffect(() => {
    reset(demography);
  }, [demography, reset]);

  const handleCancel = () => onCancel();

  const handleOnSubmit = (formValues: EntryDemographyFormValues) => {
    onSave(formValues);
  };

  const handleDelete = () => onDelete();

  return (
    <>
      <Controller
        control={control}
        name="count"
        defaultValue={demography.count}
        rules={{ validate: validations.demography.count }}
        render={({
          field: { onBlur, value, onChange },
          fieldState: { error },
        }) => (
          <Form.Field error={Boolean(error)}>
            <label>{formatMessage(m.count)}</label>
            <input
              value={value ?? ''}
              onChange={onChange}
              onBlur={onBlur}
              type="number"
              min="1"
            />
          </Form.Field>
        )}
      />

      <Controller
        control={control}
        name="genderId"
        defaultValue={demography.genderId}
        rules={{ validate: validations.demography.genderId }}
        render={({
          field: { onBlur, value, onChange },
          fieldState: { error },
        }) => (
          <Form.Field error={Boolean(error)}>
            <label>{formatMessage(m.gender)}</label>
            <MobileFriendlyFormDropdown
              fluid
              search
              selection
              options={lists?.genders.dropdownItems}
              value={value ?? ''}
              onChange={(event, data) => onChange(data.value)}
              onBlur={onBlur}
              isMobile={true}
            />
          </Form.Field>
        )}
      />

      <Controller
        control={control}
        name="ageGroupId"
        defaultValue={demography.ageGroupId}
        rules={{ validate: validations.demography.ageGroupId }}
        render={({
          field: { onBlur, value, onChange },
          fieldState: { error },
        }) => (
          <Form.Field error={Boolean(error)}>
            <label>{formatMessage(m.ageGroup)}</label>
            <MobileFriendlyFormDropdown
              fluid
              search
              selection
              options={lists?.ageGroups.dropdownItems}
              value={value ?? ''}
              onChange={(event, data) => onChange(data.value)}
              onBlur={onBlur}
              isMobile={true}
            />
          </Form.Field>
        )}
      />

      <Controller
        control={control}
        name="occupationId"
        defaultValue={demography.occupationId}
        rules={{ validate: validations.demography.occupationId }}
        render={({
          field: { onBlur, value, onChange },
          fieldState: { error },
        }) => (
          <Form.Field error={Boolean(error)}>
            <label>{formatMessage(m.occupation)}</label>
            <MobileFriendlyFormDropdown
              fluid
              search
              selection
              options={lists?.occupations.dropdownItems}
              value={value ?? ''}
              onChange={(event, data) => onChange(data.value)}
              onBlur={onBlur}
              isMobile={true}
            />
          </Form.Field>
        )}
      />

      <Controller
        control={control}
        name="originIds"
        defaultValue={demography.originIds}
        rules={{ validate: validations.demography.originIds }}
        render={({
          field: { onBlur, value, onChange },
          fieldState: { error },
        }) => (
          <Form.Field error={Boolean(error)}>
            <label>{formatMessage(m.origin)}</label>
            <MobileFriendlyFormDropdown
              fluid
              search
              selection
              multiple
              options={lists?.origins.dropdownItems}
              value={value ?? ''}
              onChange={(event, data) => onChange(data.value)}
              onBlur={onBlur}
              isMobile={true}
            />
          </Form.Field>
        )}
      />

      <Controller
        control={control}
        name="interventionLanguageId"
        defaultValue={demography.interventionLanguageId}
        rules={{ validate: validations.demography.interventionLanguageId }}
        render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => (
          <Form.Field error={Boolean(error)}>
            <label>{formatMessage(m.language)}</label>
            <MobileFriendlyFormDropdown
              fluid
              search
              selection
              options={lists?.interventionLanguages.dropdownItems}
              value={value ?? ''}
              onChange={(event, data) => onChange(data.value)}
              onBlur={onBlur}
              isMobile={true}
            />
          </Form.Field>
        )}
      />

      <div className="tw-flex tw-justify-between tw-items-center tw-mt-12">
        <div>
          <Button basic color="green" onClick={handleCancel}>
            {formatMessage(m.cancel)}
          </Button>
        </div>
        <div>
          {canDelete && (
            <Button type="button" negative onClick={handleDelete}>
              {formatMessage(m.delete)}
            </Button>
          )}

          <Button type="button" primary onClick={handleSubmit(handleOnSubmit)}>
            {formatMessage(m.save)}
          </Button>
        </div>
      </div>
    </>
  );
};
