import React, { useContext } from 'react';
import { Button, List, Modal } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { EntryFormContext } from '.';

const m = defineMessages({
  title: {
    id: 'Entry.Form.Interventions.AutoProblematicRemoval.Title',
    defaultMessage: 'Problématiques',
  },
  removedInterventions: {
    id: 'Entry.Form.Interventions.AutoProblematicRemoval.RemovedInterventions',
    defaultMessage: 'Vous avez enlevé les interventions suivantes :',
  },
  shouldWeRemoveRelatedProblematics: {
    id:
      'Entry.Form.Interventions.AutoProblematicRemoval.ShouldWeRemoveRelatedProblematics',
    defaultMessage:
      'Voulez-vous enlever les préoccupations et problématiques suivantes qui sont associées ?',
  },
  keep: {
    id: 'Entry.Form.Interventions.AutoProblematicRemoval.Keep',
    defaultMessage: 'Garder',
  },
  remove: {
    id: 'Entry.Form.Interventions.AutoProblematicRemoval.Remove',
    defaultMessage: 'Enlever',
  },
});

interface Props {
  problematicIdsToRemove: number[];
  removedInterventionIds: number[];
  onKeep: () => void;
  onRemove: () => void;
}

export const AutoProblematicRemovalConfirmationModal: React.FC<Props> = ({
  problematicIdsToRemove,
  removedInterventionIds,
  onKeep,
  onRemove,
}) => {
  const { formatMessage } = useIntl();

  const { lists } = useContext(EntryFormContext);
  
  const problematicToRemoveNames = (lists?.problematics.problematics ?? [])
    .filter((p) => problematicIdsToRemove.includes(p.id))
    .map((p) => p.name);

  const removedInterventionNames = (lists?.interventions.interventions ?? [])
    .filter((i) => removedInterventionIds.includes(i.id))
    .map((i) => i.name);

  return (
    <Modal size="mini" open={true} onClose={onKeep}>
      <Modal.Header>{formatMessage(m.title)}</Modal.Header>
      <Modal.Content>
        <p>{formatMessage(m.removedInterventions)}</p>
        <List bulleted>
          {removedInterventionNames.map((i, index) => (
            <List.Item key={index}>{i}</List.Item>
          ))}
        </List>
        <p>{formatMessage(m.shouldWeRemoveRelatedProblematics)}</p>
        <List bulleted>
          {problematicToRemoveNames.map((i, index) => (
            <List.Item key={index}>{i}</List.Item>
          ))}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={onKeep}>
          {formatMessage(m.keep)}
        </Button>
        <Button negative onClick={onRemove}>
          {formatMessage(m.remove)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
