import { defaultErrorHandler } from 'api';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { PageHeader } from 'components/Layout/PageHeader';
import { PageLayout } from 'components/Layout/PageLayout';
import React, { Fragment, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { UsersApi, UserUpdate } from '../api';
import { UserForm, UserFormValues } from '../components/UserForm';
import { useFetchUser } from '../hooks/useFetchUser';
import { useRoleSets } from '../hooks/useRoleSets';

const m = defineMessages({
  savedSuccessfully: {
    id: 'User.EditPage.SavedSuccessfully',
    defaultMessage: "L'utilisateur a été sauvegardé avec succès.",
  },
});

interface Params {
  userId: string;
}

export const EditUserPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const params = useParams<Params>();
  const id = params.userId;
  const { user } = useFetchUser(id);
  const { getRoleSetByName } = useRoleSets();
  const [saving, setSaving] = useState(false);

  const returnToUsersPage = () => {
    history.push(`/admin/users`);
  };

  const handleSubmit = (editedUser: UserFormValues) => {
    setSaving(true);

    const updateValues: UserUpdate = {
      roleNames: getRoleSetByName(editedUser.roleSetName)?.roleNames ?? [],
      teamId: editedUser.teamId as number,
      isActive: editedUser.isActive,
    };

    UsersApi.update(id, updateValues)
      .then(() => {
        toast.success(formatMessage(m.savedSuccessfully));
        returnToUsersPage();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };

  if (user === null) {
    returnToUsersPage();
  }

  const showLoader = !user || saving;

  return (
    <Fragment>
      <PageLayout pageTitle={user?.fullName ?? ''}>
        {user && (
          <>
            <PageHeader header={user.fullName} iconName="user" />
            <UserForm user={user} onSubmit={handleSubmit} />
          </>
        )}
        {showLoader && <ApplicationLoader />}
      </PageLayout>
    </Fragment>
  );
};
