import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { DropdownProps, PaginationProps } from 'semantic-ui-react';
import drop from 'lodash/drop';
import take from 'lodash/take';

const validPageSizes = [10, 25, 50, 100];

export const pageSizeOptions = validPageSizes.map((pageSize) => ({
  key: pageSize,
  value: pageSize,
  text: pageSize,
}));

const defaultPage = 1;
const defaultPageSize = 10;

const getValidPage = (page: any): number =>
  Math.floor(Number(page) || defaultPage);

const getValidPageSize = (pageSize: any): number => {
  const inputPageSize = Math.floor(Number(pageSize) || 0);

  return validPageSizes.includes(inputPageSize)
    ? inputPageSize
    : defaultPageSize;
};

interface Props {
  currentPage?: number | string;
  pageSize?: number | string;
}

export const useTablePaginating = <Type>(options: Props | null) => {
  const [rowsToPaginate, setRowsToPaginateState] = useState<Type[]>([]);
  const [currentPageRows, setCurrentPageRows] = useState<Type[]>([]);

  const [isInitialized, setIsInitialized] = useState(false);
  const [isPaginated, setIsPaginated] = useState(false);

  const [currentPage, setCurrentPage] = useState(
    getValidPage(options?.currentPage)
  );

  const [pageSize, setPageSize] = useState(getValidPageSize(options?.pageSize));

  const setRowsToPaginate = useCallback((rowsToPaginate: Type[]) => {
    setRowsToPaginateState(rowsToPaginate);
    setIsInitialized(true);
  }, []);

  const getPageCount = useCallback(
    (itemCount: number) => Math.ceil(itemCount / pageSize),
    [pageSize]
  );

  const getCurrentPageRows = useCallback(
    (rows: Type[]): Type[] => {
      const pageCount = getPageCount(rowsToPaginate.length);
      const validCurrentPage = currentPage > pageCount ? pageCount : currentPage;
      return take(drop(rows, (validCurrentPage - 1) * pageSize), pageSize);
    },
    [currentPage, getPageCount, pageSize, rowsToPaginate.length]
  );

  useEffect(() => {
    if (isInitialized) {
      setCurrentPageRows(getCurrentPageRows(rowsToPaginate));
      setIsPaginated(true);
    }
  }, [getCurrentPageRows, isInitialized, rowsToPaginate]);

  const changePage = useCallback(
    (newPage: any) => {
      const parsedNewPage = getValidPage(newPage);

      if (parsedNewPage !== currentPage) {
        setCurrentPage(parsedNewPage);
      }
    },
    [currentPage]
  );

  const handlePageChange = useCallback(
    (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      data: PaginationProps
    ) => {
      const { activePage } = data;
      changePage(activePage);
    },
    [changePage]
  );

  const changePageSize = useCallback((newPageSize: any) => {
    const parsedNewPageSize = getValidPageSize(newPageSize);
    setPageSize(parsedNewPageSize);
  }, []);

  const handlePageSizeChange = useCallback(
    (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      setCurrentPage(1);
      setPageSize(data.value as number);
    },
    []
  );

  const pageCount = getPageCount(rowsToPaginate.length);
  const hasMultiplePages = pageCount > 1;

  return {
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    pageSizeOptions,
    handlePageChange,
    handlePageSizeChange,
    setRowsToPaginate,
    currentPageRows,
    pageCount,
    hasMultiplePages,
    isPaginated,
    changePage,
    changePageSize,
  };
};
