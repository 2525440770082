import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ErrorPageLayout } from 'components/Layout/ErrorPageLayout';
import { ErrorMessage } from '../components/ErrorMessage';
import { BackButton } from '../components/BackButton';
import { HomeButton } from '../components/HomeButton';

import { ReactComponent as ServerErrorImage } from '../img/server-error.svg';

const m = defineMessages({
  header: {
    id: 'Errors.ErrorPage.InternalServerError.Header',
    defaultMessage: "Oups...il s'est produit une erreur",
  },
  subHeader: {
    id: 'Errors.ErrorPage.InternalServerError.SubHeader',
    defaultMessage:
      "Une erreur s'est produite lors du traitement d'une opération sur le serveur. Veuillez réessayer plus tard ou contacter l'administrateur.",
  },
});

export const InternalServerError: React.FC = () => {
  const { formatMessage } = useIntl();

  const buttons = (
    <>
      <BackButton basic primary />
      <HomeButton primary />
    </>
  );

  return (
    <ErrorPageLayout>
      <ErrorMessage
        headerMessage={formatMessage(m.header)}
        subHeaderMessage={formatMessage(m.subHeader)}
        errorImage={ServerErrorImage}
        buttons={buttons}
      />
    </ErrorPageLayout>
  );
};
