import axios, { AxiosResponse } from 'axios';
import { ApiError } from "./ApiError";

export const defaultApi = axios.create({
  responseType: 'json',
});

defaultApi.interceptors.response.use(
  (response) => response,
  (error) => {    
    const { status, data } = error.response;
    return Promise.reject(
      new ApiError(
        status,
        data ? data.userMessage : error.message,
        data?.debugMessage,
        data?.debugDetails,
        error.code,
      )
    );
  }
);

const responseBody = (response: AxiosResponse) => response.data;

export const requests = {
  get: (url: string) => defaultApi.get(url).then(responseBody),
  getWithParams: (url: string, params : any) => defaultApi.get(url, {params : params}).then(responseBody),
  post: (url: string, body: unknown) =>
    defaultApi.post(url, body).then(responseBody),
  put: (url: string, body: unknown) => defaultApi.put(url, body).then(responseBody),
  patch: (url: string, body: unknown) => defaultApi.patch(url, body).then(responseBody),
  del: (url: string) => defaultApi.delete(url).then(responseBody),
};