import React, { useCallback, useState } from 'react';
import { PageLayout } from 'components/Layout/PageLayout';
import { PageHeader } from 'components/Layout/PageHeader';
import { defineMessages, useIntl } from 'react-intl';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { useEntryLists } from 'modules/entry/hooks/useEntryLists';
import { Button, Grid } from 'semantic-ui-react';
import { EntryQueryForm } from '../../entry/components/EntryQueryForm';
import { StatPreview } from '../components/StatPreview';
import { useReport } from '../hooks/useReport';
import { defaultEntryQuery, EntryQuery } from 'modules/entry/utils/entryQuery';
import { useFetchUsers } from 'modules/user/hooks/useFetchUsers';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ReportStats } from '../hooks/reportTypes';
import { EntryQueryContextProvider } from 'modules/entry/components/EntryQueryContext';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const m = defineMessages({
  pageTitle: {
    id: 'Report.Page.Title',
    defaultMessage: 'Générer les statistiques',
  },
  reportFileName: {
    id: 'Report.Page.Title',
    defaultMessage: 'Rapport statistique',
  },
  generateReport: {
    id: 'Report.Page.Generate',
    defaultMessage: 'Générer',
  },
});

export const ReportPage: React.FC = () => {
  const { formatMessage, formatDate } = useIntl();
  const lists = useEntryLists();
  const users = useFetchUsers();
  const [isBuildingReport, setIsBuildingReport] = useState(false);

  const {
    updateEntryQuery,
    entryQueryResult,
    getReportStatsAsync,
    getPdfDocumentDefinition,
    isFetching: isFetchingEntries,
  } = useReport({lists, users});

  const handleFormChange = useCallback(
    (formValues: EntryQuery) => {
      updateEntryQuery(formValues);
    },
    [updateEntryQuery]
  );

  const handleGenerateReport = () => {
    setIsBuildingReport(true);

    getReportStatsAsync().then((reportStats: ReportStats | undefined) => {
      setIsBuildingReport(false);

      if (reportStats) {
        const reportGeneratedDate = formatDate(new Date(), {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        });

        const filename = `${formatMessage(
          m.reportFileName
        )} (${reportGeneratedDate}).pdf`;

        const dd = getPdfDocumentDefinition(reportStats);
        pdfMake.createPdf(dd as any).download(filename);
      }
    });
  };

  const isReadyToGenerate =
    Boolean(entryQueryResult?.entryCount) && !isFetchingEntries;

  const isLoaded = lists.isReady && users.isFetched;

  return (
    <EntryQueryContextProvider value={{ lists, users }}>
      <PageLayout pageTitle={formatMessage(m.pageTitle)}>
        <PageHeader header={formatMessage(m.pageTitle)} iconName="chart pie" />
        {isLoaded && (
          <>
            <Grid columns={2} doubling>
              <Grid.Column width="13">
                <EntryQueryForm
                  formId="entry-query-form"
                  entryQuery={defaultEntryQuery}
                  onChange={handleFormChange}
                  onSubmit={handleGenerateReport}
                  isFetchingEntries={isFetchingEntries}
                />
                <div className="tw-flex tw-justify-end tw-my-4">
                  <Button
                    primary
                    type="submit"
                    form="entry-query-form"
                    disabled={!isReadyToGenerate}
                  >
                    {formatMessage(m.generateReport)}
                  </Button>
                </div>
              </Grid.Column>
              <Grid.Column width="3">
                <div className="tw-sticky" style={{ top: '2rem' }}>
                  <StatPreview
                    entryCount={entryQueryResult?.entryCount ?? 0}
                    personCount={entryQueryResult?.personCount ?? 0}
                    isFetchingEntries={isFetchingEntries}
                  />
                </div>
              </Grid.Column>
            </Grid>
            {isBuildingReport && <ApplicationLoader />}
          </>
        )}

        {!isLoaded && <ApplicationLoader />}
      </PageLayout>
    </EntryQueryContextProvider>
  );
};
