import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { EntriesStats } from 'modules/dashboard/hooks/useDashboardStats';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { MultipleStatCard } from './MultipleStatCard';

const m = defineMessages({
  singlePersonMeetings: {
    id: 'Dashboard.View.SinglePersonMeetings',
    defaultMessage: 'Rencontres individuelles',
  },
  groupMeetings: {
    id: 'Dashboard.View.GroupMeetings',
    defaultMessage: 'Rencontres avec un groupe',
  },
  withIntervention: {
    id: 'Dashboard.View.WithIntervention',
    defaultMessage: 'Avec intervention',
  },
  withoutIntervention: {
    id: 'Dashboard.View.WithoutIntervention',
    defaultMessage: 'Sans intervention',
  },
});

interface Props {
  stats: {
    entriesWithIntervention: EntriesStats;
    entriesWithoutIntervention: EntriesStats;
  };
}
export const SinglePersonMeetingsStats: React.FC<Props> = ({ stats }) => {
  const { formatMessage } = useIntl();
  const { entriesWithIntervention, entriesWithoutIntervention } = stats;

  return (
    <ApplicationSegment>
      <MultipleStatCard
        header={formatMessage(m.singlePersonMeetings)}
        iconName="user"
        firstColumnStat={{
          groupName: formatMessage(m.withIntervention),
          entryCount: entriesWithIntervention.entryCount ?? 0,
          personCount: entriesWithIntervention.personCount ?? 0,
        }}
        secondColumnStat={{
          groupName: formatMessage(m.withoutIntervention),
          entryCount: entriesWithoutIntervention.entryCount ?? 0,
          personCount: entriesWithoutIntervention.personCount ?? 0,
        }}
      />
    </ApplicationSegment>
  );
};
