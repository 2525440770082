import * as actionTypes from './actionTypes';
import { ApplicationActionTypes, GlobalToast } from '.';
import { SideMenuState } from './types';

export function startApplication(): ApplicationActionTypes {
  return {
    type: actionTypes.START_APPLICATION_REQUEST,
  };
}

export function startApplicationCompleted(): ApplicationActionTypes {
  return {
    type: actionTypes.START_APPLICATION_COMPLETED,
  };
}

export function showGlobalToastNotification(
  toast: GlobalToast
): ApplicationActionTypes {
  return {
    type: actionTypes.SHOW_GLOBAL_TOAST_NOTIFICATION,
    payload: toast,
  };
}

export function setSideMenuState(
  newSideMenuState: SideMenuState
): ApplicationActionTypes {
  return {
    type: actionTypes.SET_SIDE_MENU_STATE,
    payload: newSideMenuState,
  };
}
