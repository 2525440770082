import { connectRouter, routerMiddleware, } from 'connected-react-router';
import { createStore, applyMiddleware, combineReducers, compose, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { History } from 'history';
import rootSaga from 'sagas/sagas';
import { RootState } from './types';
import { accountReducer } from 'modules/account/reducer';
import { applicationReducer } from 'modules/application';

export const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    account: accountReducer,
    application: applicationReducer,
  });  

export const configureStore= (history: History): Store<RootState> => {
  const composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer(history),
    {},
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware,
      )
    )
  );

  sagaMiddleware.run(rootSaga);

  return store as Store<RootState>;
}
