import React from 'react';
import { Header, SemanticICONS } from 'semantic-ui-react';
import { useResponsive } from 'hooks/useResponsive';
import { AppIcon } from './AppIcon';

interface Props {
  header: string;
  iconName: SemanticICONS | string;
}

export const PageHeader: React.FC<Props> = ({ header, iconName }) => {
  const { isMobile } = useResponsive();

  if (isMobile) return null;

  return (
    <Header color="purple" className="tw-m-0 tw-mb-5">
      <AppIcon name={iconName as SemanticICONS} size="big" />
      <Header.Content className="tw-align-middle tw-pl-3">
        {header}
      </Header.Content>
    </Header>
  );
};
