import React from 'react';
import { Header } from 'semantic-ui-react';
import { ReactComponent as NoContent } from './empty-state.svg';

interface Props {
  message: string;
}

export const EmptyStateMessage: React.FC<Props> = ({ message }) => {
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-p-12">
      <div>
        <Header>{message}</Header>
      </div>
      <NoContent className="tw-max-h-80 tw-mt-12" />
    </div>
  );
};
