import React, { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { Form, Grid } from 'semantic-ui-react';
import {
  EntryFormContext,
  EntryFormContextType,
  EntryFormValues,
} from './EntryForm';
import { useEntryValidations } from 'modules/entry/hooks/useEntryValidations';
import { MobileFriendlyFormDropdown } from 'components/MobileFriendlyDropdown';
import { ReferredByInput } from './ReferredByInput';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const m = defineMessages({
  team: { id: 'Entry.Form.Team', defaultMessage: 'Équipe' },
  teamPlaceholder: {
    id: 'Entry.Form.Team.Placeholder',
    defaultMessage: 'Veuillez choisir une équipe',
  },
  date: { id: 'Entry.Form.Date', defaultMessage: 'Date' },
  meetingType: {
    id: 'Entry.Form.MeetingType',
    defaultMessage: 'Type de rencontre',
  },
  meetingTypePlaceholder: {
    id: 'Entry.Form.MeetingType.PlaceHolder',
    defaultMessage: 'Veuillez choisir un type de rencontre',
  },
  population: {
    id: 'Entry.Form.Population',
    defaultMessage: 'Population rejointe',
  },
  populationPlaceholder: {
    id: 'Entry.Form.Population.PlaceHolder',
    defaultMessage: 'Veuillez choisir la population rejointe',
  },
  location: { id: 'Entry.Form.Location', defaultMessage: "Lieu d'activité" },
  locationPlaceholder: {
    id: 'Entry.Form.Location.PlaceHolder',
    defaultMessage: "Veuillez choisir le lieu de l'activité",
  },
  district: { id: 'Entry.Form.District', defaultMessage: 'Arrondissement' },
  districtPlaceholder: {
    id: 'Entry.Form.District.PlaceHolder',
    defaultMessage: "Veuillez choisir l'arrondissement",
  },
  moment: { id: 'Entry.Form.Moment', defaultMessage: 'Moment' },
  momentPlaceholder: {
    id: 'Entry.Form.Moment.PlaceHolder',
    defaultMessage: 'Veuillez choisir le moment',
  },
  contactState: {
    id: 'Entry.Form.ContactState',
    defaultMessage: 'État du contact',
  },
  contactStatePlaceholder: {
    id: 'Entry.Form.ContactState.PlaceHolder',
    defaultMessage: "Veuillez choisir l'état du contact",
  },
  initiator: {
    id: 'Entry.Form.Initiator',
    defaultMessage: 'Initiateur du contact',
  },
  initiatorPlaceholder: {
    id: 'Entry.Form.Initiator.PlaceHolder',
    defaultMessage: "Veuillez choisir l'initiateur du contact",
  },
});

interface Props {
  isMobile: boolean;
}

export const BaseForm: React.FC<Props> = ({ isMobile }) => {
  const { formatMessage } = useIntl();

  const validations = useEntryValidations();

  const { lists } = useContext(EntryFormContext) as EntryFormContextType;

  const { control } = useFormContext<EntryFormValues>();

  return (
    <Grid columns={2} doubling>
      <Grid.Column>
        <Controller
          control={control}
          name="teamId"
          rules={{ validate: validations.teamId }}
          render={({
            field: { onBlur, value, onChange },
            fieldState: { error },
          }) => (
            <Form.Field error={Boolean(error)}>
              <MobileFriendlyFormDropdown
                label={formatMessage(m.team)}
                onChange={(e, data) => onChange(data.value)}
                onBlur={onBlur}
                value={value ?? ''}
                options={lists?.teams.dropdownItems}
                fluid
                selection
                search
                placeholder={formatMessage(m.teamPlaceholder)}
                isMobile={isMobile}
              />
            </Form.Field>
          )}
        />
      </Grid.Column>

      <Grid.Column>
        <label>{formatMessage(m.date)}</label>
        <Controller
          control={control}
          name="date"
          rules={{ validate: validations.date }}
          render={({
            field: { onBlur, value, onChange },
            fieldState: { error },
          }) => (
            <Form.Field error={Boolean(error)}>
              <DatePicker
                selected={
                  value
                    ? moment((value as string).substring(0, 10)).toDate()
                    : null
                }
                onChange={(newValue) => {
                  if (newValue) {
                    onChange(moment(newValue as Date).format('YYYY-MM-DD'));
                  }
                }}
                onBlur={onBlur}
                className="tw-w-60"
              />
            </Form.Field>
          )}
        />
      </Grid.Column>

      <Grid.Column>
        <Controller
          control={control}
          name="meetingTypeId"
          render={({ field: { value }, fieldState: { error } }) => (
            <Form.Field error={Boolean(error)}>
              <label>{formatMessage(m.meetingType)}</label>
              {value && (
                <div className="tw-mx-4 tw-my-3">
                  {lists?.meetingTypes.indexedMeetingTypes[value]?.name}
                </div>
              )}
            </Form.Field>
          )}
        />
      </Grid.Column>

      <Grid.Column>
        <Controller
          control={control}
          name="populationId"
          rules={{ validate: validations.populationId }}
          render={({
            field: { onBlur, value, onChange },
            fieldState: { error },
          }) => (
            <Form.Field error={Boolean(error)}>
              <MobileFriendlyFormDropdown
                label={formatMessage(m.population)}
                onChange={(e, data) => onChange(data.value)}
                onBlur={onBlur}
                value={value ?? ''}
                options={lists?.populations.dropdownItems}
                fluid
                selection
                search
                placeholder={formatMessage(m.populationPlaceholder)}
                isMobile={isMobile}
              />
            </Form.Field>
          )}
        />
      </Grid.Column>

      <Grid.Column>
        <Controller
          control={control}
          name="locationId"
          rules={{ validate: validations.locationId }}
          render={({
            field: { onBlur, value, onChange },
            fieldState: { error },
          }) => (
            <Form.Field error={Boolean(error)}>
              <MobileFriendlyFormDropdown
                label={formatMessage(m.location)}
                onChange={(e, data) => onChange(data.value)}
                onBlur={onBlur}
                value={value ?? ''}
                options={lists?.locations.dropdownItems}
                fluid
                selection
                search
                placeholder={formatMessage(m.locationPlaceholder)}
                isMobile={isMobile}
              />
            </Form.Field>
          )}
        />
      </Grid.Column>

      <Grid.Column>
        <Controller
          control={control}
          name="districtId"
          rules={{ validate: validations.districtId }}
          render={({
            field: { onBlur, value, onChange },
            fieldState: { error },
          }) => (
            <Form.Field error={Boolean(error)}>
              <MobileFriendlyFormDropdown
                label={formatMessage(m.district)}
                onChange={(e, data) => onChange(data.value)}
                onBlur={onBlur}
                value={value ?? ''}
                options={lists?.districts.dropdownItems}
                fluid
                selection
                search
                placeholder={formatMessage(m.districtPlaceholder)}
                isMobile={isMobile}
              />
            </Form.Field>
          )}
        />
      </Grid.Column>

      <Grid.Column>
        <Controller
          control={control}
          name="momentId"
          rules={{ validate: validations.momentId }}
          render={({
            field: { onBlur, value, onChange },
            fieldState: { error },
          }) => (
            <Form.Field error={Boolean(error)}>
              <MobileFriendlyFormDropdown
                label={formatMessage(m.moment)}
                onChange={(e, data) => onChange(data.value)}
                onBlur={onBlur}
                value={value ?? ''}
                options={lists?.moments.dropdownItems}
                fluid
                selection
                search
                placeholder={formatMessage(m.momentPlaceholder)}
                isMobile={isMobile}
              />
            </Form.Field>
          )}
        />
      </Grid.Column>

      <Grid.Column>
        <Controller
          control={control}
          name="contactStateId"
          rules={{ validate: validations.contactStateId }}
          render={({
            field: { onBlur, value, onChange },
            fieldState: { error },
          }) => (
            <Form.Field error={Boolean(error)}>
              <MobileFriendlyFormDropdown
                label={formatMessage(m.contactState)}
                onChange={(e, data) => onChange(data.value)}
                onBlur={onBlur}
                value={value ?? ''}
                options={lists?.contactStates.dropdownItems}
                fluid
                selection
                search
                placeholder={formatMessage(m.contactStatePlaceholder)}
                isMobile={isMobile}
              />
            </Form.Field>
          )}
        />
      </Grid.Column>

      <Grid.Column>
        <Controller
          control={control}
          name="initiatorId"
          rules={{ validate: validations.initiatorId }}
          render={({
            field: { onBlur, value, onChange },
            fieldState: { error },
          }) => (
            <Form.Field error={Boolean(error)}>
              <MobileFriendlyFormDropdown
                label={formatMessage(m.initiator)}
                onChange={(e, data) => onChange(data.value)}
                onBlur={onBlur}
                value={value ?? ''}
                options={lists?.initiators.dropdownItems}
                fluid
                selection
                search
                placeholder={formatMessage(m.initiatorPlaceholder)}
                isMobile={isMobile}
              />
            </Form.Field>
          )}
        />
      </Grid.Column>

      <Grid.Column>
        <Form.Field>
          <label>Référé par</label>
          <Controller
            control={control}
            name="referredBy"
            render={({ field: { value, onChange } }) => (
              <ReferredByInput
                value={value}
                onChange={(value) => onChange(value)}
              />
            )}
          />
        </Form.Field>
      </Grid.Column>
    </Grid>
  );
};
