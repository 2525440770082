import React from 'react';
import { Dropdown, FormDropdownProps } from 'semantic-ui-react';

interface Props extends FormDropdownProps {
  isMobile?: boolean;
}

export const MobileFriendlyFormDropdown: React.FC<Props> = ({
  isMobile,
  children,
  ...props
}) => {

  if (isMobile) {
    props.search = false;
  }

  return <Dropdown {...props}>{children}</Dropdown>;
};
