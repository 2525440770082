import { useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';
import { MeetingType } from '../types';
import { MeetingTypesApi } from '../api';
import { ApiError, defaultErrorHandler } from 'api';
import { useQuery } from 'react-query';

export interface MeetingTypeLists {
  meetingTypes: MeetingType[];
  dropdownItems: DropdownItemProps[];
  indexedMeetingTypes: Record<number, MeetingType>;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchMeetingTypes = (options?: {
  staleTime?: number;
}): MeetingTypeLists => {
  const {
    data: meetingTypes,
    isFetching,
    isFetched,
  } = useQuery('meetingTypes', MeetingTypesApi.getAll, {
    staleTime: 15 * 60 * 1000,
    onSettled: (_, error) =>
      error && error instanceof ApiError && defaultErrorHandler(error),
    ...options,
  });

  const indexedMeetingTypes = useMemo(
    () =>
      (meetingTypes || []).reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<number, MeetingType>),
    [meetingTypes]
  );

  const dropdownItems = useMemo(() => {
    const activeMeetingTypes = (meetingTypes || []).filter(
      (meetinType) => meetinType.isActive
    );

    const items = activeMeetingTypes.map((meetingType) => ({
      key: meetingType.id,
      value: meetingType.id,
      text: meetingType.name,
    }));

    const sortedItems = orderBy(items, (item) =>
      deburr(item.text).toLowerCase()
    );

    return sortedItems;
  }, [meetingTypes]);

  return {
    meetingTypes: meetingTypes || [],
    dropdownItems,
    indexedMeetingTypes,
    isFetched,
    isFetching,
  };
};
