import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  InterventionFormValues,
  InterventionListValueFormValues,
} from './InterventionForm';
import { Button, Header, List } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { InterventionListValue } from './InterventionListValue';

const m = defineMessages({
  title: {
    id: 'Intervention.Form.List.Title',
    defaultMessage: 'Valeurs',
  },
  category: {
    id: 'Intervention.Form.List.Category',
    defaultMessage: 'Catégorie',
  },
  description: {
    id: 'Intervention.Form.List.Description',
    defaultMessage: 'Description',
  },
  add: {
    id: 'Intervention.Form.List.Add',
    defaultMessage: 'Ajouter une valeur',
  },
});

export const InterventionListValues = () => {
  const { formatMessage } = useIntl();

  const { control } = useFormContext<InterventionFormValues>();

  const { fields, append, remove } = useFieldArray({
    control,
    keyName: 'fieldId',
    name: 'interventionListValues',
  });

  const handleNew = () => {
    const emptyValue: InterventionListValueFormValues = {
      valueCategory: '',
      valueDescription: '',
    };
    append(emptyValue);
  };

  const handleDelete = (index: number) => {
    remove(index);
  };

  return (
    <>
      <Header size="medium">{formatMessage(m.title)}</Header>
      <List relaxed>
        <List.Item>
          <div className="tw-flex tw-gap-4">
            <div className="tw-w-60">{formatMessage(m.category)}</div>
            <div className="tw-flex-1">{formatMessage(m.description)}</div>
          </div>
        </List.Item>

        {fields.map((interventionListValue, index) => (
          <InterventionListValue
            key={interventionListValue.fieldId}
            index={index}
            onDelete={handleDelete}
          />
        ))}
      </List>

      <Button type="button" inverted primary onClick={handleNew}>
        {formatMessage(m.add)}
      </Button>
    </>
  );
};
