import React from 'react';
import { Image, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export const ErrorTopMenu: React.FC = () => {
  return (
    <Menu inverted size="massive" borderless className="top-menu tw-bg-white">
      <Menu.Menu position="left">
        <Menu.Item as={Link} to="/">
          <Image src="/logo.png" size="small" />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};
