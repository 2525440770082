import React from 'react';
import { PageLayout } from 'components/Layout/PageLayout';
import { PageHeader } from 'components/Layout/PageHeader';
import { defineMessages, useIntl } from 'react-intl';
import { useFetchTeams } from '../hooks/useFetchTeams';
import { TeamsTable } from '../components/TeamsTable/TeamsTable';
import { ApplicationLoader } from 'components/ApplicationLoader';

const m = defineMessages({
  pageTitle: { id: 'Teams.Page.Title', defaultMessage: 'Équipes' },
});

export const TeamsPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const { teams, isFetched: areTeamsFetched } = useFetchTeams({staleTime: 0});

  const isLoaded = areTeamsFetched;

  return (
    <PageLayout pageTitle={formatMessage(m.pageTitle)}>
      <PageHeader
        header={formatMessage(m.pageTitle)}
        iconName="users"
      />
      {isLoaded ? <TeamsTable teams={teams} /> : <ApplicationLoader />}
    </PageLayout>
  );
};
