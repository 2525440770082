import { useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import { Gender } from '../types';
import { GendersApi } from '../api';
import { ApiError, defaultErrorHandler } from 'api';
import { useQuery } from 'react-query';

export interface GenderLists {
  genders: Gender[];
  dropdownItems: DropdownItemProps[];
  indexedGenders: Record<number, Gender>;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchGenders = (options?: {
  staleTime?: number;
}): GenderLists => {
  const {
    data: genders,
    isFetching,
    isFetched,
  } = useQuery('genders', GendersApi.getAll, {
    staleTime: 15 * 60 * 1000,
    onSettled: (_, error) =>
      error && error instanceof ApiError && defaultErrorHandler(error),
    ...options,
  });

  const indexedGenders = useMemo(
    () =>
      (genders || []).reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<number, Gender>),
    [genders]
  );

  const dropdownItems = useMemo(() => {
    const activeGenders = (genders || []).filter((gender) => gender.isActive);

    const items = activeGenders.map((gender) => ({
      key: gender.id,
      value: gender.id,
      text: gender.name,
      order: gender.order,
    }));

    const sortedItems = orderBy(items, (i) => i.order);

    return sortedItems;
  }, [genders]);

  return {
    genders: genders || [],
    dropdownItems,
    indexedGenders,
    isFetched,
    isFetching,
  };
};
