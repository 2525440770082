import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const useSecurity = () => {
  const currentUser = useSelector((state: RootState) => state.account.user);
  
  const isAdmin = currentUser && currentUser.roleNames.includes('Admin');
  
  const canAccessAdminSite = () => !!isAdmin;

  return { canAccessAdminSite, isAdmin, currentUser };
};
