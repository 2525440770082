import { defaultErrorHandler } from 'api';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { PageHeader } from 'components/Layout/PageHeader';
import { PageLayout } from 'components/Layout/PageLayout';
import moment from 'moment';
import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { MealsApi } from '../api';
import { MealForm, MealFormValues } from '../components/MealForm/MealForm';

const m = defineMessages({
  pageTitle: {
    id: 'Meal.NewPage.Title',
    defaultMessage: 'Nouveau repas',
  },
  savedSuccessfully: {
    id: 'Meals.NewPage.SavedSuccessfully',
    defaultMessage: "Le repas a été créé avec succès",
  },
});

export const NewMealPage : React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [saving, setSaving] = useState(false); 

  const emptyMeal: MealFormValues = {
    teamId: null,
    date: moment.utc().toISOString(),
    count: 1,
  };

  const returnToMealsPage = () => {
    history.push(`/meals`);
  };

  const handleSubmit = (data: MealFormValues) => {
    setSaving(true);

    MealsApi.create(data)
      .then(() => {
        toast.success(formatMessage(m.savedSuccessfully));
        returnToMealsPage();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };
  
  return (
    <>
      <PageLayout pageTitle={formatMessage(m.pageTitle)}>
        <PageHeader
          header={formatMessage(m.pageTitle)}
          iconName="food"
        />
        <ApplicationSegment>
          <MealForm
            meal={emptyMeal}
            onSubmit={handleSubmit}
          />
        </ApplicationSegment>
        {saving && <ApplicationLoader />}
      </PageLayout>
    </>
  )
}
