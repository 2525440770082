import { defaultErrorHandler } from 'api';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { PageHeader } from 'components/Layout/PageHeader';
import { PageLayout } from 'components/Layout/PageLayout';
import React, { Fragment, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { HomelessnessesApi } from '../api';
import {
  HomelessnessForm,
  HomelessnessFormValues,
} from '../components/HomelessnessForm';
import { useFetchHomelessness } from '../hooks/useFetchHomelessness';

const m = defineMessages({
  pageTitle: {
    id: 'Homelessness.EditPage.PageTittle',
    defaultMessage: 'Modifier une intervention en itinérance',
  },
  savedSuccessfully: {
    id: 'Homelessness.EditPage.SavedSuccessfully',
    defaultMessage: "L'intervention en itinérance a été sauvegardé avec succès",
  },
});

interface Params {
  homelessnessId: string;
}

export const EditHomelessnessPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const params = useParams<Params>();
  const id = parseInt(params.homelessnessId, 10);
  const { homelessness } = useFetchHomelessness(id);
  const [saving, setSaving] = useState(false);

  const returnToHomelessnessesPage = () => {
    history.push(`/admin/homelessnesses`);
  };

  const handleSubmit = (editedHomelessness: HomelessnessFormValues) => {
    setSaving(true);

    HomelessnessesApi.update(id, editedHomelessness)
      .then(() => {
        toast.success(formatMessage(m.savedSuccessfully));
        returnToHomelessnessesPage();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };

  if (homelessness === null) {
    return null;
  }

  const showLoader = !homelessness || saving;

  return (
    <Fragment>
      <PageLayout pageTitle={formatMessage(m.pageTitle)}>
        <PageHeader
          header={formatMessage(m.pageTitle)}
          iconName="shoe-prints"
        />
        {homelessness && (
          <HomelessnessForm
            homelessness={homelessness}
            onSubmit={handleSubmit}
          />
        )}
        {showLoader && <ApplicationLoader />}
      </PageLayout>
    </Fragment>
  );
};
