import { useResponsive } from 'hooks/useResponsive';
import React from 'react';
import { Segment, SegmentProps } from 'semantic-ui-react';

export const ApplicationSegment: React.FC<SegmentProps> = ({
  children,
  ...segmentProps
}) => {
  const { isMobile } = useResponsive();

  const additionalClassName = 'tw-rounded-none tw-border-none tw-shadow-none';

  const style = isMobile ? { marginTop: '0', marginBottom: '4px' } : null;

  return (
    <Segment
      {...segmentProps}
      style={style}
      className={`${segmentProps.className} ${additionalClassName}`}
    >
      {children}
    </Segment>
  );
};
