import { call, put, takeEvery } from 'redux-saga/effects';
import { AccountApi } from './api';
import * as actionsTypes from './actionTypes';
import { clearAccount, fetchCurrentUserFailure, fetchCurrentUserSuccess } from './actions';
import { CurrentUser } from '.';
import { SagaIterator } from 'redux-saga';

function* fetchCurrentUser() {
  try {
    const currentUser : CurrentUser = yield call(AccountApi.fetchCurrentUser);
    yield put(fetchCurrentUserSuccess(currentUser));    
  } catch (error) {
    yield put(fetchCurrentUserFailure());
  }
}

function* handleSignOut() {
  yield call(AccountApi.signOut);
  yield put(clearAccount());

}

export default function* AccountSagas() : SagaIterator {
  yield takeEvery(actionsTypes.FETCH_CURRENT_USER_REQUEST, fetchCurrentUser);
  yield takeEvery(actionsTypes.SIGNOUT_REQUEST, handleSignOut);
}
