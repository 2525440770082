import React from 'react';
import { Grid } from 'semantic-ui-react';
import { SubsetDetailedStats } from 'modules/dashboard/hooks/useDashboardStats';
import { PeriodChart } from './PeriodChart';
import { EntriesStats } from './EntriesStats';
import { PersonsStats } from './PersonsStats';
import { SinglePersonMeetingsStats } from './SinglePersonMeetingsStats';
import { GroupMeetingsStats } from './GroupMeetingsStats';
import { TopInterventions } from './TopInterventions';
import { TopProblematics } from './TopProblematics';
import { useResponsive } from 'hooks/useResponsive';

interface Props {
  stats: SubsetDetailedStats;
}

export const DashboardView: React.FC<Props> = ({ stats }) => {
  const { isMobile } = useResponsive();

  const {
    allEntriesStats,
    singlePersonEntries,
    groupEntries,
    periodsStats,
    topInterventions,
    topProblematics,
  } = stats;

  const desktopView = (
    <>
      <div className="tw-flex tw-flex-wrap" style={{ margin: '-1rem' }}>
        <div
          className="tw-mb-4 tw-p-4"
          style={{ flexBasis: '46rem', flexGrow: 999 }}
        >
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={4}>
                <EntriesStats entryCount={allEntriesStats.entryCount} />
              </Grid.Column>
              <Grid.Column width={4}>
                <PersonsStats personCount={allEntriesStats.personCount} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <SinglePersonMeetingsStats stats={singlePersonEntries} />
              </Grid.Column>
              <Grid.Column width={8}>
                <GroupMeetingsStats stats={groupEntries} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <PeriodChart periodsStats={periodsStats} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div className="tw-p-4" style={{ flexBasis: '25rem', flexGrow: 1 }}>
          <div className="tw-flex tw-flex-wrap" style={{ margin: '-1rem' }}>
            <div
              className="tw-mb-4 tw-p-4"
              style={{ flexBasis: '25rem', flexGrow: 1 }}
            >
              <TopInterventions topInterventions={topInterventions} />
            </div>
            <div
              className="tw-mb-4 tw-p-4"
              style={{ flexBasis: '25rem', flexGrow: 1 }}
            >
              <TopProblematics topProblematics={topProblematics} />
            </div>
          </div>          
        </div>
      </div>
    </>
  );

  const mobileView = (
    <>
      <div
        className="tw-grid"
        style={{ gridTemplateColumns: '1fr 1fr', gap: '4px' }}
      >
        <EntriesStats entryCount={allEntriesStats.entryCount} />
        <PersonsStats personCount={allEntriesStats.personCount} />
      </div>
      <SinglePersonMeetingsStats stats={singlePersonEntries} />
      <GroupMeetingsStats stats={groupEntries} />
      <PeriodChart periodsStats={periodsStats} />
      <TopInterventions topInterventions={topInterventions} />
      <TopProblematics topProblematics={topProblematics} />
    </>
  );

  return isMobile ? mobileView : desktopView;
};
