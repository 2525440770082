import { useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';
import { District } from '../types';
import { DistrictsApi } from '../api';
import { ApiError, defaultErrorHandler } from 'api';
import { useQuery } from 'react-query';

export interface DistrictLists {
  districts: District[];
  dropdownItems: DropdownItemProps[];
  indexedDistricts: Record<number, District>;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchDistricts = (options?: {
  staleTime?: number;
}): DistrictLists => {
  const {
    data: districts,
    isFetching,
    isFetched,
  } = useQuery('districts', DistrictsApi.getAll, {
    staleTime: 15 * 60 * 1000,
    onSettled: (_, error) =>
      error && error instanceof ApiError && defaultErrorHandler(error),
    ...options,
  });

  const indexedDistricts = useMemo(
    () =>
      (districts || []).reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<number, District>),
    [districts]
  );

  const dropdownItems = useMemo(() => {
    const activeDistricts = (districts || []).filter(
      (district) => district.isActive
    );

    const items = activeDistricts.map((district) => ({
      key: district.id,
      value: district.id,
      text: district.name,
    }));

    const sortedItems = orderBy(items, (item) =>
      deburr(item.text).toLowerCase()
    );

    return sortedItems;
  }, [districts]);

  return {
    districts: districts || [],
    dropdownItems,
    indexedDistricts,
    isFetched,
    isFetching,
  };
};
