import { useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';
import { Population } from '../types';
import { PopulationsApi } from '../api';
import { ApiError, defaultErrorHandler } from 'api';
import { useQuery } from 'react-query';

export interface PopulationLists {
  populations: Population[];
  dropdownItems: DropdownItemProps[];
  indexedPopulations: Record<number, Population>;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchPopulations = (options?: {
  staleTime?: number;
}): PopulationLists => {
  const {
    data: populations,
    isFetching,
    isFetched,
  } = useQuery('populations', PopulationsApi.getAll, {
    staleTime: 15 * 60 * 1000,
    onSettled: (_, error) =>
      error && error instanceof ApiError && defaultErrorHandler(error),
    ...options,
  });

  const indexedPopulations = useMemo(
    () =>
      (populations || []).reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<number, Population>),
    [populations]
  );

  const dropdownItems = useMemo(() => {
    const activePopulations = (populations || []).filter(
      (population) => population.isActive
    );

    const items = activePopulations.map((population) => ({
      key: population.id,
      value: population.id,
      text: population.name,
    }));

    const sortedItems = orderBy(items, (item) =>
      deburr(item.text).toLowerCase()
    );

    return sortedItems;
  }, [populations]);

  return {
    populations: populations || [],
    dropdownItems,
    indexedPopulations,
    isFetched,
    isFetching,
  };
};
