import { EntryLists } from 'modules/entry/hooks/useEntryLists';
import React, { useContext } from 'react';
import { Icon } from 'semantic-ui-react';
import {
  EntryDemographyFormValues,
  EntryFormContext,
} from './EntryForm';
import { defineMessages, useIntl } from 'react-intl';

const m = defineMessages({
  origin: {
    id: 'Entry.Form.Demographies.Origin',
    defaultMessage: 'Origine',
  },
  occupation: {
    id: 'Entry.Form.Demographies.Occupation',
    defaultMessage: 'Occupation/Revenu',
  },
  language: {
    id: 'Entry.Form.Demographies.InterventionLanguage',
    defaultMessage: 'Langue',
  },
  pleaseComplete: {
    id: 'Entry.Form.Demographies.PleaseComplete',
    defaultMessage: 'Veuillez compléter cette démographie',
  },
});

interface Props {
  demography: EntryDemographyFormValues;
  onEdit: () => void;
}

export const DemographyMobile: React.FC<Props> = ({ demography, onEdit }) => {
  const { formatMessage } = useIntl();

  const { lists } = useContext(EntryFormContext);

  const {
    genders : { indexedGenders : genders},
    origins : { indexedOrigins : origins},
    ageGroups : {indexedAgeGroups : ageGroups},
    occupations : {indexedOccupations : occupations},
    interventionLanguages : {indexedInterventionLanguages : interventionLanguages},
  } = lists as EntryLists;

  const {
    count,
    genderId,
    ageGroupId,
    occupationId,
    originIds,
    interventionLanguageId,
  } = demography;

  const countString = (count || 1) > 1 ? `${count} personnes` : 'Une personne';

  const gender =
    genderId === null ? null : genders[genderId]?.name;

  const ageGroup =
    ageGroupId === null
      ? null
      : ageGroups[ageGroupId]?.name;

  const genderAndAgeGroup =
    gender || ageGroup
      ? `(${[gender, ageGroup].filter((x) => x).join(', ')})`
      : '';

  const origin =
    originIds && originIds.length
      ? originIds.map((id) => origins[id]?.name).join(', ')
      : null;

  const occupation =
    occupationId === null
      ? null
      : occupations[occupationId]?.name;

  const language =
    interventionLanguageId === null
      ? null
      : interventionLanguages[interventionLanguageId]?.name;

  const handleEdit = () => onEdit();

  const isComplete =
    genderId &&
    ageGroupId &&
    occupationId &&
    originIds &&
    originIds.length &&
    interventionLanguageId;

  return (
    <div
      className="tw-flex tw-justify-between tw-items-center tw-py-3 tw-border-b tw-cursor-pointer"
      onClick={handleEdit}
    >
      <div>
        <div>
          {countString} {genderAndAgeGroup}
        </div>

        {occupation && (
          <div>
            {formatMessage(m.occupation)}: {occupation}
          </div>
        )}

        {origin && (
          <div>
            {formatMessage(m.origin)}: {origin}
          </div>
        )}

        {language && (
          <div>
            {formatMessage(m.language)}: {language}
          </div>
        )}

        {!isComplete && (
          <div className="tw-text-red tw-mt-4">
            {formatMessage(m.pleaseComplete)}
          </div>
        )}
      </div>
      <div>
        <Icon name="edit outline" color="blue" size="large" />
      </div>
    </div>
  );
};
