import merge from "lodash/merge";
import { defaultEntryQuery, EntryQuery } from "./entryQuery";

export interface QueryStringEntryQuery {
  fromdate?: string;
  todate?: string;
  createdby?: string[];
  teams?: number[];
  meetingtypes?: number[];
  populations?: number[];
  locations?: number[];
  districts?: number[];
  moments?: number[];
  contactstate?: number[];
  initiators?: number[];
  demminpersoncount?: string | null;
  demmaxpersoncount?: string | null;
  genders?: number[];
  agegroups?: number[];
  occupations?: number[];
  origins?: number[];
  originslogic?: string | null;
  hasintervention?: boolean | 'any' | null;
  interminduration?: string | null;
  intermaxduration?: string | null;
  interventions?: number[];
  interventionslogic?: string | null;
  interventionstextvalues?: string[];
  interventionsminnumvalue?: string | null;
  interventionsmaxnumvalue?: string | null;
  problematics?: number[];
  problematicslogic?: string | null;
  hashomelessness?: boolean | 'any' | null;
  homelesssituation?: number[];
  homelessinterventions?: number[];
  homelessinterventionslogic?: string | null;
  hastapajprogress?: boolean | 'any' | null;
  tapajprogresses?: number[];
  referredby?: string[];
}

export const getEntryQueryFromQueryString = (
  parsedQueryString: QueryStringEntryQuery
): EntryQuery => {
  const rawEntryQuery = {
    fromDate: parsedQueryString.fromdate,
    toDate: parsedQueryString.todate,
    createdByCaseworkers: parsedQueryString.createdby,
    teams: parsedQueryString.teams,
    meetingTypes: parsedQueryString.meetingtypes,
    populations: parsedQueryString.populations,
    locations: parsedQueryString.locations,
    districts: parsedQueryString.districts,
    moments: parsedQueryString.moments,
    contactStates: parsedQueryString.contactstate,
    initiators: parsedQueryString.initiators,
    demographies: {
      minPersonCount: parsedQueryString.demminpersoncount,
      maxPersonCount: parsedQueryString.demmaxpersoncount,
      genders: parsedQueryString.genders,
      ageGroups: parsedQueryString.agegroups,
      occupations: parsedQueryString.occupations,
      origins: parsedQueryString.origins,
      originsLogicalOperator: parsedQueryString.originslogic,
    },
    interventions: {
      hasIntervention: parsedQueryString.hasintervention,
      minDurationMinutes: parsedQueryString.interminduration,
      maxDurationMinutes: parsedQueryString.intermaxduration,
      interventions: parsedQueryString.interventions,
      interventionsLogicalOperator: parsedQueryString.interventionslogic,
      interventionsTextValues: parsedQueryString.interventionstextvalues,
      interventionsMinNumericValue: parsedQueryString.interventionsminnumvalue,
      interventionsMaxNumericValue: parsedQueryString.interventionsmaxnumvalue,
      problematics: parsedQueryString.problematics,
      problematicsLogicalOperator: parsedQueryString.problematicslogic,
    },
    homelessnesses: {
      hasHomelessness: parsedQueryString.hashomelessness,
      homelessnessSituation: parsedQueryString.homelesssituation,
      homelessnessInterventions: parsedQueryString.homelessinterventions,
      homelessnessInterventionsLogicalOperator:
        parsedQueryString.homelessinterventionslogic,
    },
    tapajProgress: {
      hasTapajProgress: parsedQueryString.hastapajprogress,
      tapajProgresses: parsedQueryString.tapajprogresses,
    },
    referredBy: parsedQueryString.referredby,
  };

  const defaults = merge({}, defaultEntryQuery);
  const entryQuery = merge(defaults, rawEntryQuery);

  return entryQuery;
};

const isEmptyArray = (object: any) =>
  Array.isArray(object) && object.length === 0;

const removeEmptyArray = (object: any) =>
  Object.fromEntries(
    Object.entries(object).filter(([_, value]) => !isEmptyArray(object))
  );

export const getFlatEntryQuery = (
  entryQuery: EntryQuery
): QueryStringEntryQuery => {
  const flatEntryQuery: QueryStringEntryQuery = {
    fromdate: entryQuery.fromDate as string,
    todate: entryQuery.toDate as string,
    createdby: entryQuery.createdByCaseworkers,
    teams: entryQuery.teams,
    meetingtypes: entryQuery.meetingTypes,
    populations: entryQuery.populations,
    locations: entryQuery.locations,
    districts: entryQuery.districts,
    moments: entryQuery.moments,
    contactstate: entryQuery.contactStates,
    initiators: entryQuery.initiators,
    demminpersoncount: entryQuery.demographies?.minPersonCount,
    demmaxpersoncount: entryQuery.demographies?.maxPersonCount,
    genders: entryQuery.demographies?.genders,
    agegroups: entryQuery.demographies?.ageGroups,
    occupations: entryQuery.demographies?.occupations,
    origins: entryQuery.demographies?.origins,
    originslogic: entryQuery.demographies?.originsLogicalOperator,
    hasintervention: entryQuery.interventions?.hasIntervention,
    interminduration: entryQuery.interventions?.minDurationMinutes,
    intermaxduration: entryQuery.interventions?.maxDurationMinutes,
    interventions: entryQuery.interventions?.interventions,
    interventionslogic: entryQuery.interventions?.interventionsLogicalOperator,
    interventionstextvalues: entryQuery.interventions?.interventionsTextValues,
    interventionsminnumvalue:
      entryQuery.interventions?.interventionsMinNumericValue,
    interventionsmaxnumvalue:
      entryQuery.interventions?.interventionsMaxNumericValue,
    problematics: entryQuery.interventions?.problematics,
    problematicslogic: entryQuery.interventions?.problematicsLogicalOperator,
    hashomelessness: entryQuery.homelessnesses?.hasHomelessness,
    homelesssituation: entryQuery.homelessnesses?.homelessnessSituation,
    homelessinterventions: entryQuery.homelessnesses?.homelessnessInterventions,
    homelessinterventionslogic:
      entryQuery.homelessnesses?.homelessnessInterventionsLogicalOperator,
    hastapajprogress: entryQuery.tapajProgress?.hasTapajProgress,
    tapajprogresses: entryQuery.tapajProgress?.tapajProgresses,
    referredby: entryQuery.referredBy,
  };

  return removeEmptyArray(flatEntryQuery);
};