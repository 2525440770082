import { requests } from 'api';
import { HomelessnessFormValues } from './components/HomelessnessForm/HomelessnessForm';
import { HomelessnessSummary, Homelessness } from './types';

const apiBaseUrl = '/api/homelessnesses';

export const HomelessnessesApi = {
  getAll: (): Promise<HomelessnessSummary[]> => requests.get(apiBaseUrl),
  getById: (id: number): Promise<Homelessness> =>
    requests.get(`${apiBaseUrl}/${id}`),
  create: (newHomelessness: HomelessnessFormValues): Promise<Homelessness> =>
    requests.post(apiBaseUrl, newHomelessness),
  update: (
    id: number,
    editedHomelessness: HomelessnessFormValues
  ): Promise<any> => requests.patch(`${apiBaseUrl}/${id}`, editedHomelessness),
};
