export interface InterventionLanguage {
  id : number;
  name : string;
  isActive: boolean;
}

export enum Language {
  Other = 1,
  French = 2,
  English = 3,
}