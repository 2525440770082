import { useEffect, useState } from 'react';
import { defaultErrorHandler } from 'api';
import { TeamsApi } from '../api';
import { Team } from '../types';

interface FetchedTeam {
  team : Team | null | undefined;
  isFetched: boolean;
  isFetching: boolean; 
}

export const useFetchTeam = (id: number | null) : FetchedTeam => {
  const [team, setTeam] = useState<Team | null | undefined>(undefined);
  const [isFetched, setIsFetched] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);

    if (id === null) {
      setTeam(null);
    } else {
      TeamsApi.getById(id)
        .then((fetchedTeam) => {
          setTeam(fetchedTeam);
          setIsFetched(true);
        })
        .catch((error) => {
          setTeam(null);
          setIsFetched(false);
          defaultErrorHandler(error);
        })
        .finally(() => setIsFetching(false));
    }
  }, [id]);

  return {team, isFetched, isFetching};
};
