import { useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';
import { Occupation } from '../types';
import { OccupationsApi } from '../api';
import { ApiError, defaultErrorHandler } from 'api';
import { useQuery } from 'react-query';

export interface OccupationLists {
  occupations: Occupation[];
  dropdownItems: DropdownItemProps[];
  indexedOccupations: Record<number, Occupation>;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchOccupations = (options?: {
  staleTime?: number;
}): OccupationLists => {
  const {
    data: occupations,
    isFetching,
    isFetched,
  } = useQuery('occupations', OccupationsApi.getAll, {
    staleTime: 15 * 60 * 1000,
    onSettled: (_, error) =>
      error && error instanceof ApiError && defaultErrorHandler(error),
    ...options,
  });

  const indexedOccupations = useMemo(
    () =>
      (occupations || []).reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<number, Occupation>),
    [occupations]
  );

  const dropdownItems = useMemo(() => {
    const activeOccupation = (occupations || []).filter(
      (occupation) => occupation.isActive
    );

    const items = activeOccupation.map((occupation) => ({
      key: occupation.id,
      value: occupation.id,
      text: occupation.name,
    }));

    const sortedItems = orderBy(items, (item) =>
      deburr(item.text).toLowerCase()
    );
    return sortedItems;
  }, [occupations]);

  return {
    occupations: occupations || [],
    dropdownItems,
    indexedOccupations,
    isFetched,
    isFetching,
  };
};
