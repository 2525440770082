import React, { useState } from 'react';
import { useResponsive } from 'hooks/useResponsive';
import { TopMenu } from './TopMenu';
import { SideMenu } from './SideMenu';
import { MainContainer } from './MainContainer';
import './PageLayout.scss';

interface Props {
  pageTitle?: string;
  contentTopRef?: React.RefObject<HTMLDivElement>;
}

export const PageLayout: React.FC<Props> = ({ pageTitle, children, contentTopRef }) => {
  const { isMobile } = useResponsive();
  const [sideMenuVisibleOnMobile, setSideMenuVisibleOnMobile] = useState(false);

  const handleToggleSideMenuVisibility = () => {
    setSideMenuVisibleOnMobile((visibilty) => !visibilty);
  };

  const backgroundColor = isMobile ? 'tw-bg-primary' : 'tw-bg-gray-200';
  const overlayHiddenClass = sideMenuVisibleOnMobile ? '' : 'hidden';
  const overlay = (
    <div
      className={`overlay ${overlayHiddenClass}`}
      onClick={handleToggleSideMenuVisibility}
    />
  );

  return (
    <div className={`pagelayout ${backgroundColor}`}>
      {isMobile && overlay}

      <TopMenu
        pageTitle={pageTitle}
        onHamburgerClick={handleToggleSideMenuVisibility}
      />

      <div className="main">
        <SideMenu
          isVisibleOnMobile={sideMenuVisibleOnMobile}
          toggleVisibilityOnMobile={handleToggleSideMenuVisibility}
        />
        <div className="tw-relative tw-h-full tw-flex-1 tw-overflow-hidden">
          <div className="content">
            <div ref={contentTopRef} />
            <MainContainer>
              {children}
            </MainContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
