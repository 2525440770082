import { useEntryValidations } from 'modules/entry/hooks/useEntryValidations';
import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Dropdown, Form, Icon, List } from 'semantic-ui-react';
import {
  EntryDemographyFormValues,
  EntryFormContext,
  EntryFormContextType,
  EntryFormValues,
} from './EntryForm';

const mainFieldName = 'demographies';

interface Props {
  index: number;
  demography: EntryDemographyFormValues;
  onDelete: () => void;
  canDelete: boolean;
}

export const DemographyFormDesktop: React.FC<Props> = ({
  index,
  demography,
  onDelete,
  canDelete,
}) => {
  const validations = useEntryValidations();

  const { lists } = useContext(EntryFormContext) as EntryFormContextType;

  const { control } = useFormContext<EntryFormValues>();

  const handleDelete = () => onDelete();

  return (
    <>
      <Controller
        control={control}
        name={`${mainFieldName}.${index}.id`}
        defaultValue={demography.id}
        render={({ field: { value } }) => <input value={value} type="hidden" />}
      />

      <List horizontal className="tw-flex tw-items-start">
        <List.Item className="tw-w-40">
          <Controller
            control={control}
            name={`${mainFieldName}.${index}.count`}
            defaultValue={demography.count}
            rules={{ validate: validations.demography.count }}
            render={({
              field: { onBlur, value, onChange },
              fieldState: { error },
            }) => (
              <Form.Field error={Boolean(error)}>
                <input
                  value={value ?? ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  type="number"
                  min="1"
                />
              </Form.Field>
            )}
          />
        </List.Item>

        <List.Item className="tw-w-40">
          <Controller
            control={control}
            name={`${mainFieldName}.${index}.genderId`}
            defaultValue={demography.genderId}
            rules={{ validate: validations.demography.genderId }}
            render={({
              field: { onBlur, value, onChange },
              fieldState: { error },
            }) => (
              <Form.Field error={Boolean(error)}>
                <Dropdown
                  fluid
                  search
                  selection
                  options={lists?.genders.dropdownItems}
                  value={value ?? ''}
                  onChange={(event, data) => onChange(data.value)}
                  onBlur={onBlur}
                />
              </Form.Field>
            )}
          />
        </List.Item>

        <List.Item className="tw-w-48">
          <Controller
            control={control}
            name={`${mainFieldName}.${index}.ageGroupId`}
            defaultValue={demography.ageGroupId}
            rules={{ validate: validations.demography.ageGroupId }}
            render={({
              field: { onBlur, value, onChange },
              fieldState: { error },
            }) => (
              <Form.Field error={Boolean(error)}>
                <Dropdown
                  fluid
                  search
                  selection
                  options={lists?.ageGroups.dropdownItems}
                  value={value ?? ''}
                  onChange={(event, data) => onChange(data.value)}
                  onBlur={onBlur}
                />
              </Form.Field>
            )}
          />
        </List.Item>

        <List.Item className="tw-w-64">
          <Controller
            control={control}
            name={`${mainFieldName}.${index}.occupationId`}
            defaultValue={demography.occupationId}
            rules={{ validate: validations.demography.occupationId }}
            render={({
              field: { onBlur, value, onChange },
              fieldState: { error },
            }) => (
              <Form.Field error={Boolean(error)}>
                <Dropdown
                  fluid
                  search
                  selection
                  options={lists?.occupations.dropdownItems}
                  value={value ?? ''}
                  onChange={(event, data) => onChange(data.value)}
                  onBlur={onBlur}
                />
              </Form.Field>
            )}
          />
        </List.Item>

        <List.Item className="tw-w-72">
          <Controller
            control={control}
            name={`${mainFieldName}.${index}.originIds`}
            defaultValue={demography.originIds}
            rules={{ validate: validations.demography.originIds }}
            render={({
              field: { onBlur, value, onChange },
              fieldState: { error },
            }) => (
              <Form.Field error={Boolean(error)}>
                <Dropdown
                  fluid
                  search
                  selection
                  multiple
                  options={lists?.origins.dropdownItems}
                  value={value}
                  onChange={(event, data) => onChange(data.value)}
                  onBlur={onBlur}
                />
              </Form.Field>
            )}
          />
        </List.Item>

        <List.Item className="tw-w-48">
          <Controller
            control={control}
            name={`${mainFieldName}.${index}.interventionLanguageId`}
            defaultValue={demography.interventionLanguageId}
            rules={{
              validate: validations.demography.interventionLanguageId,
            }}
            render={({
              field: { onBlur, value, onChange },
              fieldState: { error },
            }) => (
              <Form.Field error={Boolean(error)}>
                <Dropdown
                  fluid
                  search
                  selection
                  options={lists?.interventionLanguages.dropdownItems}
                  value={value ?? ''}
                  onChange={(event, data) => onChange(data.value)}
                  onBlur={onBlur}
                />
              </Form.Field>
            )}
          />
        </List.Item>
        <List.Item>
          {canDelete && (
            <Icon
              name="trash alternate"
              size="big"
              color="grey"
              onClick={handleDelete}
              className="tw-cursor-pointer tw-mt-1"
            />
          )}
        </List.Item>
      </List>
    </>
  );
};
