import React from 'react';
import { PageLayout } from 'components/Layout/PageLayout';
import { PageHeader } from 'components/Layout/PageHeader';
import { defineMessages, useIntl } from 'react-intl';
import { useFetchUsers } from '../hooks/useFetchUsers';
import { UsersTable } from '../components/UsersTable';
import { ApplicationLoader } from 'components/ApplicationLoader';

const m = defineMessages({
  pageTitle: { id: 'Users.Page.Title', defaultMessage: 'Utilisateurs' },
});

export const UsersPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const { users, isFetched: areUsersFetched } = useFetchUsers({ staleTime: 0 });

  const isLoaded = areUsersFetched;

  return (
    <PageLayout pageTitle={formatMessage(m.pageTitle)}>
      <PageHeader
        header={formatMessage(m.pageTitle)}
        iconName="user"
      />
      {isLoaded ? <UsersTable users={users} /> : <ApplicationLoader /> }
    </PageLayout>
  );
};
