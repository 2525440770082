import { useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';
import { InterventionSummary } from '../types';
import { InterventionsApi } from '../api';
import { ApiError, defaultErrorHandler } from 'api';
import { useQuery } from 'react-query';

export interface InterventionLists {
  interventions: InterventionSummary[];
  dropdownItems: DropdownItemProps[];
  indexedInterventions: Record<number, InterventionSummary>;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchInterventions = (options?: {
  staleTime?: number;
}): InterventionLists => {
  const {
    data: interventions,
    isFetching,
    isFetched,
  } = useQuery('interventions', InterventionsApi.getAll, {
    staleTime: 15 * 60 * 1000,
    onSettled: (_, error) =>
      error && error instanceof ApiError && defaultErrorHandler(error),
    ...options,
  });

  const indexedInterventions = useMemo(
    () =>
      (interventions || []).reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<number, InterventionSummary>),
    [interventions]
  );

  const dropdownItems = useMemo(() => {
    const activeInterventions = (interventions || []).filter(
      (intervention) => intervention.isActive
    );

    const items = activeInterventions.map((intervention) => ({
      key: intervention.id,
      value: intervention.id,
      text: intervention.name,
    }));

    const sortedItems = orderBy(items, (item) =>
      deburr(item.text).toLowerCase()
    );

    return sortedItems;
  }, [interventions]);

  return {
    interventions: interventions || [],
    dropdownItems,
    indexedInterventions,
    isFetched,
    isFetching,
  };
};
