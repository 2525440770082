import * as actionsTypes from './actionTypes';
import { AccountActionTypes, CurrentUser } from '.';

export function fetchCurrentUser(): AccountActionTypes {
  return {
    type: actionsTypes.FETCH_CURRENT_USER_REQUEST,
  };
}

export function fetchCurrentUserSuccess(user: CurrentUser): AccountActionTypes {
  return {
    type: actionsTypes.FETCH_CURRENT_USER_SUCCESS,
    payload: user,
  };
}

export function fetchCurrentUserFailure(): AccountActionTypes {
  return {
    type: actionsTypes.FETCH_CURRENT_USER_FAILURE,
  };
}

export function signOut() : AccountActionTypes {
  return {
    type: actionsTypes.SIGNOUT_REQUEST
  }
}

export function clearAccount() : AccountActionTypes {
  return {
    type: actionsTypes.CLEAR_ACCOUNT_REQUEST
  }
}