import { useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';
import { Moment } from '../types';
import { MomentsApi } from '../api';
import { ApiError, defaultErrorHandler } from 'api';
import { useQuery } from 'react-query';

export interface MomentLists {
  moments: Moment[];
  dropdownItems: DropdownItemProps[];
  indexedMoments: Record<number, Moment>;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchMoments = (options?: {
  staleTime?: number;
}): MomentLists => {
  const {
    data: moments,
    isFetching,
    isFetched,
  } = useQuery('moments', MomentsApi.getAll, {
    staleTime: 15 * 60 * 1000,
    onSettled: (_, error) =>
      error && error instanceof ApiError && defaultErrorHandler(error),
    ...options,
  });

  const indexedMoments = useMemo(
    () =>
      (moments || []).reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<number, Moment>),
    [moments]
  );

  const dropdownItems = useMemo(() => {
    const activeMoments = (moments || []).filter((moment) => moment.isActive);

    const items = activeMoments.map((moment) => ({
      key: moment.id,
      value: moment.id,
      text: moment.name,
    }));

    const sortedItems = orderBy(items, (item) =>
      deburr(item.text).toLowerCase()
    );

    return sortedItems;
  }, [moments]);

  return {
    moments: moments || [],
    dropdownItems,
    indexedMoments,
    isFetched,
    isFetching,
  };
};
