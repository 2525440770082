import React from 'react';
import { Menu, MenuItemProps } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { AppIcon } from './AppIcon';

interface Props extends MenuItemProps {
  iconName?: SemanticICONS | string;
  label: string;
  iconOnly: boolean;
}

export const SideMenuItem: React.FC<Props> = ({
  iconName,
  label,
  iconOnly,
  ...otherProps
}) => {
  const isEnabled =
    otherProps.to !== undefined || otherProps.onClick !== undefined;
  const opcatityClass = isEnabled ? '' : 'tw-opacity-60';

  return (
    <Menu.Item {...otherProps} title={label} className={opcatityClass}>
      <AppIcon name={iconName as SemanticICONS} size="large" />
      {!iconOnly && <span className="tw-ml-3">{label}</span>}
    </Menu.Item>
  );
};
