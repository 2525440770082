import React, { useContext, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { DropdownItemProps, Form, Grid, Header } from 'semantic-ui-react';
import { EntryQuery } from 'modules/entry/utils/entryQuery';
import { MobileFriendlyFormDropdown } from 'components/MobileFriendlyDropdown';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { EntryQueryContext } from 'modules/entry/components/EntryQueryContext';

const m = defineMessages({
  tapajProgressesSection: {
    id: 'EntryQueryForm.Tapaj',
    defaultMessage: 'Tapaj et Capab',
  },
  tapajProgressesFilter: {
    id: 'EntryQueryForm.TapajProgressFilter',
    defaultMessage: 'Filtre',
  },
  withTapajProgress: {
    id: 'EntryQueryForm.TapajProgressFilter.WithProgress',
    defaultMessage: 'Avec progrès',
  },
  withoutTapajProgress: {
    id: 'EntryQueryForm.TapajProgressFilter.WithoutProgress',
    defaultMessage: 'Sans progrès',
  },
  withOrWithoutTapajProgress: {
    id: 'EntryQueryForm.TapajProgressFilter.WithOrWithoutProgress',
    defaultMessage: 'Avec et sans progrès',
  },
  tapajProgresses: {
    id: 'EntryQueryForm.TapajProgresses',
    defaultMessage: 'Progrès',
  },
  alltapajProgresses: {
    id: 'EntryQueryForm.AllTapajProgresses',
    defaultMessage: 'Tous les progrès',
  },
});

const tapajCapabTeamIds = [3, 9];

interface Props {
  isMobile: boolean;
  onChange: () => void;
}

export const TapajSection: React.FC<Props> = ({
  isMobile,
  onChange,
}) => {
  const { formatMessage } = useIntl();
  const { lists } = useContext(EntryQueryContext);

  const { control } = useFormContext<EntryQuery>();

  const hasTapajProgress = useWatch({
    control,
    name: 'tapajProgress.hasTapajProgress',
    defaultValue: null,
  });

  const formTeams = useWatch({
    control,
    name: 'teams',
    defaultValue: [] as number[],
  });

  const tapajProgressInclusionFilterDropdownItems = useMemo<
    DropdownItemProps[]
  >(
    () => [
      {
        key: 1,
        value: 'any',
        text: formatMessage(m.withOrWithoutTapajProgress),
      },
      { key: 2, value: true, text: formatMessage(m.withTapajProgress) },
      {
        key: 3,
        value: false,
        text: formatMessage(m.withoutTapajProgress),
      },
    ],
    [formatMessage]
  );

  const handleChange = () => onChange();

  const hasTapajProgressField = (
    <Form.Field className="tw-flex tw-items-baseline">
      <label className="tw-text-purple">
        {formatMessage(m.tapajProgressesFilter)}
      </label>
      <Controller
        control={control}
        name="tapajProgress.hasTapajProgress"
        defaultValue="any"
        render={({ field: { onBlur, value, onChange } }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value ?? ''}
            options={tapajProgressInclusionFilterDropdownItems}
            fluid
            selection
            isMobile={isMobile}
            placeholder={formatMessage(m.withOrWithoutTapajProgress)}
            className="tw-w-72 tw-ml-4"
          />
        )}
      />
    </Form.Field>
  );

  const tapajProgressesField = (
    <Form.Field>
      <label className="tw-text-purple">
        {formatMessage(m.tapajProgresses)}
      </label>
      <Controller
        control={control}
        name="tapajProgress.tapajProgresses"
        render={({ field: { onBlur, value, onChange } }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.tapajProgresses.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.alltapajProgresses)}
            isMobile={isMobile}
          />
        )}
      />
    </Form.Field>
  );

  const showTapajSection =
    formTeams.length === 0 ||
    tapajCapabTeamIds.some((i) => formTeams.includes(i));

  const tapajSectionVisibilityClass = showTapajSection ? '' : 'tw-hidden';

  const tapajProgressesFieldsVisibilityClass =
    hasTapajProgress === false ? 'tw-hidden' : '';

  return (
    <div className={tapajSectionVisibilityClass}>
      <ApplicationSegment>
        <div className="tw-flex tw-items-baseline tw-pr-4">
          <Header className="tw-flex-1" color="purple">
            {formatMessage(m.tapajProgressesSection)}
          </Header>
          {hasTapajProgressField}
        </div>

        <div className={`tw-p-4 ${tapajProgressesFieldsVisibilityClass}`}>
          <Grid columns="2" doubling>
            <Grid.Column>{tapajProgressesField}</Grid.Column>
          </Grid>
        </div>
      </ApplicationSegment>
    </div>
  );
};
