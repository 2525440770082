import React, { Fragment } from 'react';
import { Button, Icon, Menu } from 'semantic-ui-react';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'modules/account/actions';
import { Link, NavLink } from 'react-router-dom';
import { SideMenuItem } from './SideMenuItem';
import { useResponsive } from 'hooks/useResponsive';
import { useSecurity } from 'modules/account/hooks/useSecurity';
import { RootState } from 'store';
import { setSideMenuState } from 'modules/application/actions';

const m = defineMessages({
  home: { id: 'SideMenu.Home', defaultMessage: 'Accueil' },
  entries: { id: 'SideMenu.Entries', defaultMessage: 'Entrées' },
  meals: { id: 'SideMenu.Meals', defaultMessage: 'Repas' },
  newEntry: { id: 'SideMenu.NewEntry', defaultMessage: 'Nouvelle entrée'},
  admin: { id: 'SideMenu.Admin', defaultMessage: 'Administration' },
  users: { id: 'SideMenu.Users', defaultMessage: 'Utilisateurs' },
  teams: { id: 'SideMenu.Teams', defaultMessage: 'Équipes' },
  intervention: {
    id: 'SideMenu.Intervention',
    defaultMessage: 'Interventions',
  },
  problematics: {
    id: 'SideMenu.Problematics',
    defaultMessage: 'Préoccupations et Problématiques',
  },
  homelessness: {
    id: 'SideMenu.Homelessness',
    defaultMessage: "Interventions en itinérance",
  },
  tapajProgress: {
    id: 'SideMenu.TapajProgress',
    defaultMessage: "Progrès Tapaj et Capap",
  },
  advancedSearch: {
    id: 'SideMenu.AdvancedSearch',
    defaultMessage: "Recherche avancée",
  },
  report: {
    id: 'SideMenu.Report',
    defaultMessage: "Générer les statistiques",
  },
  signOut: { id: 'SideMenu.SignOut', defaultMessage: 'Déconnexion' },
  about: { id: 'SideMenu.About', defaultMessage: 'À propos' },
});

interface Props {
  isVisibleOnMobile: boolean;
  toggleVisibilityOnMobile?: () => void;
}

export const SideMenu: React.FC<Props> = ({
  isVisibleOnMobile,
  toggleVisibilityOnMobile,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();
  const { canAccessAdminSite } = useSecurity();
  const menuState = useSelector((state : RootState) => state.application.sideMenuState);
  const isNarrow = menuState === 'narrow';

  const handleToggleVisibilityOnMobile = () => {
    toggleVisibilityOnMobile && toggleVisibilityOnMobile();
  };

  const handleToggleMenuState = () => {    
    const nextMenuState = isNarrow ? 'wide' : 'narrow';
    dispatch(setSideMenuState(nextMenuState));
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const menuWidth = isNarrow ? 'tw-w-auto' : 'tw-w-80';
  const iconOnly = isNarrow && !isMobile;

  const ClosingButton = () => {
    return (
      <div
        className="tw-absolute tw-top-0 tw-right-0 tw-p-1 tw-cursor-pointer"
        onClick={handleToggleVisibilityOnMobile}
      >
        <Icon name="close" />
      </div>
    );
  };

  const LogoAndClosingButton = () => {
    return (
      <div className="tw-relative tw-h-20 tw-py-4 tw-px-8 tw-bg-white tw-flex tw-flex-col tw-justify-center tw-items-center">
        <img src="/logo.png" className="tw-h-full" alt="logo"/>
        <ClosingButton />
      </div>
    );
  };

  const NewEntryButton = () => {
    return (
      <Menu.Item className="tw-my-2">
        <Button color="green" as={Link} to="/entries/new">
          <Icon name="plus" />
          {formatMessage(m.newEntry)}
        </Button>
      </Menu.Item>
    );
  };

  const HomeMenu = () => {
    return (
      <SideMenuItem
        as={NavLink}
        to="/"
        exact
        iconName="th large"
        label={formatMessage(m.home)}
        iconOnly={iconOnly}
      />
    );
  };

  const EntriesMenu = () => {
    return (
      <SideMenuItem
        as={NavLink}
        to="/entries"
        iconName="file alternate"
        label={formatMessage(m.entries)}
        iconOnly={iconOnly}
      />
    );
  };

  const MealsMenu = () => {
    return (
      <SideMenuItem
        as={NavLink}
        to="/meals"
        iconName="food"
        label={formatMessage(m.meals)}
        iconOnly={iconOnly}
      />
    );
  };

  const AdminMenu = () => {
    return (
      <Fragment>
        <div className={`${subMenuHeaderVisibilityClass}`}>
          <SideMenuItem
            iconName="cogs"
            label={formatMessage(m.admin)}
            iconOnly={iconOnly}
          />
        </div>

        <div className={subMenuItemLeftMarginClass}>
          <SideMenuItem
            as={NavLink}
            to="/admin/users"
            iconName="user"
            label={formatMessage(m.users)}
            iconOnly={iconOnly}
          />

          <SideMenuItem
            as={NavLink}
            to="/admin/teams"
            iconName="users"
            label={formatMessage(m.teams)}
            iconOnly={iconOnly}
          />

          <SideMenuItem
            as={NavLink}
            to="/admin/interventions"
            iconName="hand-holding-heart"
            label={formatMessage(m.intervention)}
            iconOnly={iconOnly}
          />

          <SideMenuItem
            as={NavLink}
            to="/admin/problematics"
            iconName="warning sign"
            label={formatMessage(m.problematics)}
            iconOnly={iconOnly}
          />

          <SideMenuItem
            as={NavLink}
            to="/admin/homelessnesses"
            iconName="shoe-prints"
            label={formatMessage(m.homelessness)}
            iconOnly={iconOnly}
          />

          <SideMenuItem
            as={NavLink}
            to="/admin/tapaj-progresses"
            iconName="clipboard check"
            label={formatMessage(m.tapajProgress)}
            iconOnly={iconOnly}
          />

          <SideMenuItem
            as={NavLink}
            to="/admin/advanced-search"
            iconName="search"
            label={formatMessage(m.advancedSearch)}
            iconOnly={iconOnly}
          />

          <SideMenuItem
            as={NavLink}
            to="/admin/report"
            iconName="chart pie"
            label={formatMessage(m.report)}
            iconOnly={iconOnly}
          />
        </div>
      </Fragment>
    );
  };

  const SignOutMenu = () => {
    return (
      <SideMenuItem
        as="a"
        onClick={handleSignOut}
        iconName="sign-out"
        label={formatMessage(m.signOut)}
        iconOnly={iconOnly}
      />
    );
  };

  const AboutMenu = () => {
    return (
      <SideMenuItem
        as={NavLink}
        to="/about"
        iconName="info circle"
        label={formatMessage(m.about)}
        iconOnly={iconOnly}
      />
    );
  };

  const ExpandButton = () => {
    return (
      <Icon
        inverted
        name="arrow right"
        size="large"
        className="tw-cursor-pointer"
        onClick={handleToggleMenuState}
      />
    );
  };

  const CollapseButton = () => {
    return (
      <Icon
        inverted
        name="arrow left"
        size="large"
        className="tw-cursor-pointer"
        onClick={handleToggleMenuState}
      />
    );
  };

  const CollapseAndExpandSection = () => {
    const textAlignClass = iconOnly ? 'tw-text-center' : 'tw-text-right';
    return (
      <div
        className={`${textAlignClass} tw-border-t tw-border-white tw-px-3 tw-py-4`}
      >
        {isNarrow ? <ExpandButton /> : <CollapseButton />}
      </div>
    );
  };

  const hiddenClass = isVisibleOnMobile ? '' : 'hidden';
  const mobileClass = isMobile ? 'mobile' : '';
  const subMenuHeaderVisibilityClass = iconOnly ? 'tw-invisible' : '';
  const subMenuItemLeftMarginClass = iconOnly ? '' : 'tw-ml-8';

  return (
    <div className={`side-menu ${mobileClass} ${hiddenClass} tw-flex`}>
      <Menu color="teal" inverted borderless vertical className={`tw-h-full tw-flex tw-flex-col ${menuWidth}`}>
        {isMobile && <LogoAndClosingButton />}
        <div className="tw-flex-1 tw-flex tw-flex-col tw-overflow-auto">
          <div className="tw-flex-1 tw-overflow-auto">
            {isMobile && <NewEntryButton />}
            <HomeMenu />
            <EntriesMenu />
            <MealsMenu />

            {canAccessAdminSite() && <AdminMenu />}

            <div className="tw-mt-10">
              <SignOutMenu />
              <AboutMenu />
            </div>
          </div>
          {!isMobile && <CollapseAndExpandSection />}
        </div>
      </Menu>
    </div>
  );
};
