import { useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';
import { Origin } from '../types';
import { OriginsApi } from '../api';
import { ApiError, defaultErrorHandler } from 'api';
import { useQuery } from 'react-query';

export interface OriginLists {
  origins: Origin[];
  dropdownItems: DropdownItemProps[];
  indexedOrigins: Record<number, Origin>;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchOrigins = (options?: {
  staleTime?: number;
}): OriginLists => {
  const {
    data: origins,
    isFetching,
    isFetched,
  } = useQuery('origins', OriginsApi.getAll, {
    staleTime: 15 * 60 * 1000,
    onSettled: (_, error) =>
      error && error instanceof ApiError && defaultErrorHandler(error),
    ...options,
  });

  const indexedOrigins = useMemo(
    () =>
      (origins || []).reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<number, Origin>),
    [origins]
  );

  const dropdownItems = useMemo(() => {
    const activeOrigins = (origins || []).filter((origin) => origin.isActive);

    const items = activeOrigins.map((origin) => ({
      key: origin.id,
      value: origin.id,
      text: origin.name,
    }));

    const sortedItems = orderBy(items, (item) =>
      deburr(item.text).toLowerCase()
    );

    return sortedItems;
  }, [origins]);

  return {
    origins: origins || [],
    dropdownItems,
    indexedOrigins,
    isFetched,
    isFetching,
  };
};
