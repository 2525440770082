import { useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';
import { HomelessnessSituation } from '../types';
import { HomelessnessSituationsApi } from '../api';
import { ApiError, defaultErrorHandler } from 'api';
import { useQuery } from 'react-query';

export interface HomelessnessSituationLists {
  homelessnessSituations: HomelessnessSituation[];
  dropdownItems: DropdownItemProps[];
  indexedHomelessnessSituations: Record<number, HomelessnessSituation>;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchHomelessnessSituations = (options?: {
  staleTime?: number;
}): HomelessnessSituationLists => {
  const {
    data: homelessnessSituations,
    isFetching,
    isFetched,
  } = useQuery('homelessnessSituations', HomelessnessSituationsApi.getAll, {
    staleTime: 15 * 60 * 1000,
    onSettled: (_, error) =>
      error && error instanceof ApiError && defaultErrorHandler(error),
    ...options,
  });

  const indexedHomelessnessSituations = useMemo(
    () =>
      (homelessnessSituations || []).reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<number, HomelessnessSituation>),
    [homelessnessSituations]
  );

  const dropdownItems = useMemo(() => {
    const activeHomelessnessSituations = (homelessnessSituations || []).filter(
      (hs) => hs.isActive
    );

    const items = activeHomelessnessSituations.map((homelessnessSituation) => ({
      key: homelessnessSituation.id,
      value: homelessnessSituation.id,
      text: homelessnessSituation.name,
    }));

    const sortedItems = orderBy(items, (item) =>
      deburr(item.text).toLowerCase()
    );

    return sortedItems;
  }, [homelessnessSituations]);

  return {
    homelessnessSituations: homelessnessSituations || [],
    dropdownItems,
    indexedHomelessnessSituations,
    isFetched,
    isFetching,
  };
};
