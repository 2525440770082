import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { useIntl } from 'react-intl';
import { getGlobalToast } from 'modules/application/selectors';

export const GlobalToast: React.FC = () => {
  const intl = useIntl();
  const toastNotification = useSelector(getGlobalToast);

  useEffect(() => {
    if (toastNotification) {
      switch (toastNotification.type) {
        case 'info':
          toast.info(
            intl.formatMessage({
              id: toastNotification.messageId,
              defaultMessage: toastNotification.defaultMessage,
            })
          );
          break;
        case 'warning':
          toast.warn(
            intl.formatMessage({
              id: toastNotification.messageId,
              defaultMessage: toastNotification.defaultMessage,
            })
          );
          break;
        case 'error':
          toast.error(
            intl.formatMessage({
              id: toastNotification.messageId,
              defaultMessage: toastNotification.defaultMessage,
            })
          );
          break;
        default:
          toast.success(
            intl.formatMessage({
              id: toastNotification.messageId,
              defaultMessage: toastNotification.defaultMessage,
            })
          );
      }
    }
  }, [toastNotification, intl]);

  return <ToastContainer position="top-center" />;
};
