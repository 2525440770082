import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Checkbox } from 'semantic-ui-react';

const m = defineMessages({
  showInactive: {
    id: 'ShowInactiveToggle.ShowInactive',
    defaultMessage: 'Afficher inactif',
  },
});

interface Props {
  showInactive: boolean;
  onChange: (showInactive: boolean) => void;
}

export const ShowInactiveToggle: React.FC<Props> = ({
  showInactive,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="tw-flex tw-items-center tw-mr-8">
      <Checkbox
        toggle
        checked={showInactive}
        onChange={(e, { checked }) => onChange(checked ?? false)}
      />
      <div className="tw-ml-4">{formatMessage(m.showInactive)}</div>
    </div>
  );
};
