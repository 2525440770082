import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useFormContext, useWatch } from 'react-hook-form';
import { Form, Grid } from 'semantic-ui-react';
import { EntryQuery } from 'modules/entry/utils/entryQuery';
import { ApplicationSegment } from 'components/ApplicationSegment';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const m = defineMessages({
  period: { id: 'EntryQueryForm.Period', defaultMessage: 'Période' },
});

interface Props {
  isFetchingEntries: boolean;
  onChange: () => void;  
}

export const DateSection: React.FC<Props> = ({ isFetchingEntries, onChange }) => {
  const { formatMessage } = useIntl();

  const { control, register, setValue } = useFormContext<EntryQuery>();

  register('fromDate');
  register('toDate');

  const fromDate = useWatch({
    control: control,
    name: 'fromDate',
    defaultValue: null,
  });

  const toDate = useWatch({
    control: control,
    name: 'toDate',
    defaultValue: null,
  });

  const handleDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    const [startDate, endDate] = dateRange;

    setValue(
      'fromDate',
      startDate && moment(startDate as Date).format('YYYY-MM-DD')
    );
    setValue(
      'toDate',
      endDate && moment(endDate as Date).format('YYYY-MM-DD')
    );
    
    onChange();
  };

  const dateRangeField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.period)}</label>
      <DatePicker
        selectsRange={true}
        startDate={(fromDate as string | null) ? moment(fromDate).toDate() : null}
        endDate={(toDate as string | null) ? moment(toDate).toDate() : null}
        onChange={handleDateRangeChange}
        withPortal
        className="tw-w-80"
        fixedHeight
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        disabled={isFetchingEntries}                
      />
    </Form.Field>
  );

  return (
    <ApplicationSegment>
      <div className="tw-p-4">
        <Grid columns="2">
          <Grid.Column>
            <Form.Group widths="equal">{dateRangeField}</Form.Group>
          </Grid.Column>
        </Grid>
      </div>
    </ApplicationSegment>
  );
};
