import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useResponsive } from 'hooks/useResponsive';
import { TapajSection } from './TapajSection';
import { EntryQuery } from 'modules/entry/utils/entryQuery';
import { DateSection } from './DateSection';
import { DemographiesSection } from './DemographiesSection';
import { EntrySection } from './EntrySection';
import { HomelessnessSection } from './HomelessnessSection';
import { InterventionsSection } from './InterventionsSection';
import { Form } from 'semantic-ui-react';

interface Props {
  formId?: string;
  entryQuery: EntryQuery;
  onChange: (entryQuery: EntryQuery) => void;
  onSubmit?: () => void;
  isFetchingEntries: boolean;
}

export const EntryQueryForm: React.FC<Props> = ({
  formId,
  entryQuery,
  onChange,
  onSubmit,
  isFetchingEntries,
}) => {
  const { isMobile } = useResponsive();

  const formMethods = useForm<EntryQuery>({
    defaultValues: entryQuery,
  });

  const { handleSubmit, getValues, reset: resetForm } = formMethods;

  useEffect(() => {
    resetForm(entryQuery);
  }, [entryQuery, resetForm]);

  const handleChange = () => {
    onChange(getValues() as EntryQuery);
  };

  const handleOnSubmit = () => {
    onSubmit && onSubmit();
  };

  return (
    <FormProvider {...formMethods}>
      <Form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
        <DateSection
          onChange={handleChange}
          isFetchingEntries={isFetchingEntries}
        />

        <EntrySection
          onChange={handleChange}
          isMobile={isMobile}
        />

        <DemographiesSection
          onChange={handleChange}
          isMobile={isMobile}
        />

        <InterventionsSection
          onChange={handleChange}
          isMobile={isMobile}
        />

        <HomelessnessSection
          onChange={handleChange}
          isMobile={isMobile}
        />

        <TapajSection
          onChange={handleChange}
          isMobile={isMobile}
        />
      </Form>
    </FormProvider>
  );
};
