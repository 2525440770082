import React, { useContext, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { Form, Grid, Input } from 'semantic-ui-react';
import uniq from 'lodash/uniq';
import {
  EntryFormContext,
  EntryFormContextType,
  EntryFormValues,
  EntryInterventionFormValues,
} from './EntryForm';
import { InterventionDetailForm } from './InterventionDetailForm';
import { AutoProblematicRemovalConfirmationModal } from './AutoProblematicRemovalConfirmationModal';
import { useEntryValidations } from 'modules/entry/hooks/useEntryValidations';
import { MobileFriendlyFormDropdown } from 'components/MobileFriendlyDropdown';

const m = defineMessages({
  hasIntervention: {
    id: 'Entry.Form.Interventions.HasIntervention',
    defaultMessage: 'Y a-t-il eu une intervention ? ',
  },
  interventions: {
    id: 'Entry.Form.Interventions.Interventions',
    defaultMessage: 'Interventions',
  },
  duration: {
    id: 'Entry.Form.Interventions.Duration',
    defaultMessage: 'Durée',
  },
  minutes: {
    id: 'Entry.Form.Interventions.Minutes',
    defaultMessage: 'Minutes',
  },
  yes: {
    id: 'Yes',
    defaultMessage: 'Oui',
  },
  no: {
    id: 'No',
    defaultMessage: 'Non',
  },
});

interface ProblematicsToRemove {
  problematicIds: number[];
  removedInterventionIds: number[];
}

interface Props {
  isMobile: boolean;
}

export const InterventionsForm: React.FC<Props> = ({ isMobile }) => {
  const { formatMessage } = useIntl();

  const { lists } = useContext(EntryFormContext) as EntryFormContextType;

  const [problematicsToRemove, setProblematicsToRemove] = useState<
    ProblematicsToRemove | undefined
  >();

  const { control, getValues, setValue } = useFormContext<EntryFormValues>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'interventions',
  });

  const formInterventions: EntryInterventionFormValues[] = useWatch({
    control,
    name: 'interventions',
    defaultValue: [],
  });

  const validations = useEntryValidations();

  const formInterventionIds = formInterventions.map((ci) => ci.interventionId);

  const removeAutomaticProblematics = (interventionId: number) => {
    const intervention =
      lists?.interventions.indexedInterventions[interventionId];

    const automaticProblematicIds = intervention?.problematicIds || [];

    if (automaticProblematicIds.length > 0) {
      const formProblematicIds = getValues('problematicIds');
      const problematicIdsToRemove = formProblematicIds.filter((fp) =>
        automaticProblematicIds.includes(fp)
      );
      if (problematicIdsToRemove.length > 0) {
        setProblematicsToRemove((state) => ({
          problematicIds: uniq(
            (state?.problematicIds || []).concat(problematicIdsToRemove)
          ),
          removedInterventionIds: (state?.removedInterventionIds || []).concat([
            interventionId,
          ]),
        }));
      }
    }
  };

  const handleRemoveAutomaticProblematics = () => {
    const formProblematicIds = getValues('problematicIds');
    const updatedProblematicIds = formProblematicIds.filter(
      (fp) => !(problematicsToRemove?.problematicIds || []).includes(fp)
    );
    setValue('problematicIds', updatedProblematicIds);
    setProblematicsToRemove(undefined);
  };

  const handleKeepAutomaticProblematics = () => {
    setProblematicsToRemove(undefined);
  };

  const addAutomaticProblematics = (interventionId: number) => {
    const intervention =
      lists?.interventions.indexedInterventions[interventionId];
    const automaticProblematicIds = intervention?.problematicIds || [];

    if (automaticProblematicIds.length > 0) {
      const formProblematicIds = getValues('problematicIds');
      const updatedProblematicIds = uniq(
        formProblematicIds.concat(intervention?.problematicIds as number[])
      );

      setValue('problematicIds', updatedProblematicIds);
    }
  };

  const removeDeselectedInterventions = (selectedInterventionIds: number[]) => {
    const isRemovedByUser = (interventionId: number) =>
      !selectedInterventionIds.includes(interventionId);

    formInterventionIds.forEach((fi, index) => {
      if (isRemovedByUser(fi as number)) {
        remove(index);
        removeAutomaticProblematics(fi as number);
      }
    });
  };

  const addSelectedInterventions = (selectedInterventionIds: number[]) => {
    const isAddedByUser = (interventionId: number) =>
      !formInterventionIds.includes(interventionId);

    selectedInterventionIds
      .filter((si) => isAddedByUser(si))
      .forEach((si) => {
        append({ interventionId: si, numberValue: null, textValue: null });
        addAutomaticProblematics(si);
      });
  };

  const handleInterventionChange = (selectedInterventionIds: number[] = []) => {
    removeDeselectedInterventions(selectedInterventionIds);
    addSelectedInterventions(selectedInterventionIds);
  };

  const ErrorMessage: React.FC = ({ children }) => {
    return <div className="tw-text-red tw-mb-4">{children}</div>;
  };

  return (
    <>
      <Controller
        control={control}
        name="interventionDurationInMinute"
        rules={{
          validate: () =>
            validations.interventionDurationInMinute(getValues()),
        }}
        render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => (
          <Form.Field error={Boolean(error)}>
            <label>{formatMessage(m.duration)}</label>
            <Input
              value={value}
              onChange={(event, data) => onChange(data.value)}
              onBlur={onBlur}
              type="number"
              min="0"
              label={formatMessage(m.minutes)}
              labelPosition="right"
              className="tw-w-24"
            />
            {error && (
              <ErrorMessage>
                {error.message}
              </ErrorMessage>
            )}
          </Form.Field>
        )}
      />
      <Grid columns={2} doubling>
        <Grid.Column>
          <Form.Field>
            <label>{formatMessage(m.interventions)}</label>
            <MobileFriendlyFormDropdown
              fluid
              multiple
              search
              selection
              value={formInterventionIds as number[]}
              options={lists?.interventions.dropdownItems}
              onChange={(event, data) =>
                handleInterventionChange(data.value as number[])
              }
              isMobile={isMobile}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          {fields.map((entryIntervention, index) => (
            <InterventionDetailForm
              key={entryIntervention.interventionId}
              index={index}
              entryIntervention={entryIntervention as EntryInterventionFormValues}
            />
          ))}
        </Grid.Column>
      </Grid>

      {problematicsToRemove && (
        <AutoProblematicRemovalConfirmationModal
          removedInterventionIds={problematicsToRemove.removedInterventionIds}
          problematicIdsToRemove={problematicsToRemove.problematicIds}
          onKeep={handleKeepAutomaticProblematics}
          onRemove={handleRemoveAutomaticProblematics}
        />
      )}
    </>
  );
};
