import React, { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  EntryFormContext,
  EntryFormContextType,
  EntryFormValues,
} from './EntryForm';
import { Checkbox, Form, Grid } from 'semantic-ui-react';
import { useEntryValidations } from 'modules/entry/hooks/useEntryValidations';
import { MobileFriendlyFormDropdown } from 'components/MobileFriendlyDropdown';

const m = defineMessages({
  hasHomelessness: {
    id: 'Entry.Form.HasHomelessness',
    defaultMessage: "S'agit-il d'un cas d'itinérance ?",
  },
  homelessnessInterventions: {
    id: 'Entry.Form.HomelessnessInterventions',
    defaultMessage: 'Interventions',
  },
  homelessnessSituation: {
    id: 'Entry.Form.HomelessnessSituation',
    defaultMessage: 'Situation',
  },
  yes: {
    id: 'Yes',
    defaultMessage: 'Oui',
  },
  no: {
    id: 'No',
    defaultMessage: 'Non',
  },
});

interface Props {
  isMobile: boolean;
}

export const HomelessnessesForm: React.FC<Props> = ({ isMobile }) => {
  const { formatMessage } = useIntl();

  const validations = useEntryValidations();

  const { lists } = useContext(EntryFormContext) as EntryFormContextType;

  const { control, getValues } = useFormContext<EntryFormValues>();

  const hasHomelessness = useWatch({
    control,
    name: 'hasHomelessness',
    defaultValue: false,
  });

  const interventionsVisibilityClass = hasHomelessness ? '' : 'tw-hidden';

  const ErrorMessage: React.FC = ({ children }) => {
    return <div className="tw-text-red tw-mb-4">{children}</div>;
  };

  return (
    <>
      <Controller
        control={control}
        name="hasHomelessness"
        render={({
          field: { onBlur, value, onChange },
        }) => (
          <Form.Field>
            <label>{formatMessage(m.hasHomelessness)}</label>
            <div className="tw-flex tw-items-center">
              <Checkbox
                onChange={(e, data) => onChange(data.checked)}
                onBlur={onBlur}
                checked={Boolean(value)}
                toggle
              />
              <div className="tw-ml-6">
                {value ? formatMessage(m.yes) : formatMessage(m.no)}
              </div>
            </div>
          </Form.Field>
        )}
      />

      <Grid columns={2} doubling className={interventionsVisibilityClass}>
        <Grid.Column>
          <Controller
            control={control}
            name="homelessnessSituationId"
            rules={{
              validate: () =>
                validations.homelessnessSituationId(getValues()),
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Form.Field error={Boolean(error)}>
                <label>{formatMessage(m.homelessnessSituation)}</label>
                <MobileFriendlyFormDropdown
                  fluid
                  search
                  selection
                  options={lists?.homelessnessSituations.dropdownItems}
                  value={value ?? ''}
                  onChange={(e, data) => onChange(data.value)}
                  isMobile={isMobile}
                />
                {error && (
                  <ErrorMessage>
                    {error.message}
                  </ErrorMessage>
                )}
              </Form.Field>
            )}
          />

          <Form.Field>
            <label>{formatMessage(m.homelessnessInterventions)}</label>
            <Controller
              control={control}
              name="homelessnessIds"
              render={({ field: { value, onChange } }) => (
                <MobileFriendlyFormDropdown
                  fluid
                  multiple
                  search
                  selection
                  options={lists?.homelessnesses.dropdownItems}
                  value={value}
                  onChange={(e, data) => onChange(data.value)}
                  isMobile={isMobile}
                />
              )}
            />
          </Form.Field>
        </Grid.Column>
      </Grid>
    </>
  );
};
