import React, { ImgHTMLAttributes, useState } from 'react';

export const TeamLogo: React.FC<ImgHTMLAttributes<HTMLImageElement>> = ({
  ...imgProps
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleImageSuccess = () => setIsVisible(true);

  imgProps.className += isVisible ? '' : ' tw-hidden';

  return <img {...imgProps} onLoad={handleImageSuccess} alt="team logo"/>;
};
