import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { DemographyMobile } from './DemographyMobile';
import { EntryDemographyFormValues } from './EntryForm';

const m = defineMessages({
  add: {
    id: 'Entry.Form.Demographies.Add',
    defaultMessage: 'Ajouter une démographie',
  },
});

interface Props {
  demographies: EntryDemographyFormValues[];
  onNew: () => void;
  onEdit: (index: number) => void;
}

export const DemographiesListingMobile: React.FC<Props> = ({
  demographies,
  onNew,
  onEdit,
}) => {
  const { formatMessage } = useIntl();

  const handleEdit = (index: number) => {
    onEdit(index);
  };

  const handleNew = () => onNew();

  return (
    <>
      <div className="tw-border-t tw-mb-6">
        {demographies.map((demography, index) => (
          <DemographyMobile
            key={index}
            demography={demography}
            onEdit={() => handleEdit(index)}
          />
        ))}
      </div>
      <div className="tw-py-4">
        <Button type="button" fluid inverted primary onClick={handleNew}>
          {formatMessage(m.add)}
        </Button>
      </div>
    </>
  );
};
