import { District } from 'modules/district';
import { MeetingType } from 'modules/meetingType';
import { Population } from 'modules/population';
import { TeamSummary } from 'modules/team';
import { Location } from 'modules/location';
import { useFetchTeams } from 'modules/team/hooks/useFetchTeams';
import { useFetchMeetingTypes } from 'modules/meetingType/hooks/useFetchMeetingTypes';
import { useFetchPopulations } from 'modules/population/hooks/useFetchPopulations';
import { useFetchLocations } from 'modules/location/hooks/useFetchLocations';
import { useFetchDistricts } from 'modules/district/hooks/useFetchDistricts';

export interface EntryTableLists {
  teams: Record<number, TeamSummary>;
  meetingTypes: Record<number, MeetingType>;
  populations: Record<number, Population>;
  locations: Record<number, Location>;
  districts: Record<number, District>;
  isReady: boolean;
}

export const useEntryTableLists = (): EntryTableLists => {
  const { indexedTeams, isFetched: isTeamsFetched } = useFetchTeams();
  const {
    indexedMeetingTypes,
    isFetched: isMeetingTypesFetched,
  } = useFetchMeetingTypes();
  const {
    indexedPopulations,
    isFetched: isPopulationsFetched,
  } = useFetchPopulations();
  const { indexedLocations, isFetched: isLocationsFetched } = useFetchLocations();
  const { indexedDistricts, isFetched: isDistrictsFetched } = useFetchDistricts();

  const isReady =
    isTeamsFetched &&
    isMeetingTypesFetched &&
    isPopulationsFetched &&
    isLocationsFetched &&
    isDistrictsFetched;

  return {
    teams: indexedTeams,
    meetingTypes: indexedMeetingTypes,
    populations: indexedPopulations,
    locations: indexedLocations,
    districts: indexedDistricts,
    isReady,
  };
};
