import { useCallback, useEffect, useState } from 'react';
import { useTablePaginating } from './useTablePaginating';
import { SortingDirection, useTableSorting } from './useTableSorting';
import deburr from 'lodash/deburr';
import get from 'lodash/get';

export interface Props {
  currentPage?: number | string;
  pageSize?: number | string;
  sortingColumn?: string;
  sortingDirection?: SortingDirection;
}

export const useTable = <Type>(options: Props | null) => {
  const [rows, setRowsState] = useState<Type[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [filteredRows, setFilteredRows] = useState<Type[]>([]);
  const [showInactive, setShowInactive] = useState(false);

  const {
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    pageSizeOptions,
    handlePageChange,
    handlePageSizeChange,
    setRowsToPaginate,
    currentPageRows,
    pageCount,
    hasMultiplePages,
    isPaginated,
    changePage,
    changePageSize,
  } = useTablePaginating<Type>(options);

  const {
    sortingColumn,
    setSortingColumn,
    sortingDirection,
    setSortingDirection,
    changeSortingColumn,
    setRowsToSort,
    sortedRows,
    changeSorting,
  } = useTableSorting<Type>(options);  

  const setRows = useCallback((rowsToLoad: Type[]) => {
    setIsInitialized(true);

    if (rowsToLoad) {
      setRowsState(rowsToLoad);
    } else {
      setRowsState([]);
    }
  }, []);

  useEffect(() => {
    if (isInitialized) {
      setFilteredRows(rows);
    }
  }, [rows, isInitialized]);

  useEffect(() => {
    const filterInactive = (rows: Type[]): Type[] =>
      showInactive
        ? rows
        : rows.filter((row) =>
            'isActive' in (row as object) ? get(row, 'isActive') : true
          );

    if (isInitialized) {
      const rowsToSort = filterInactive(filteredRows);
      setRowsToSort(rowsToSort);
    }
  }, [filteredRows, isInitialized, setRowsToSort, showInactive]);

  useEffect(() => {
    if (isInitialized) {
      setRowsToPaginate(sortedRows);
    }
  }, [sortedRows, isInitialized, setRowsToPaginate]);

  const searchInRows = useCallback(
    (search: string, searchableContent: (row: Type) => string) => {
      if (rows.length === 0) return;
     
      setCurrentPage(1);

      const searchResult = rows.filter(
        (entryRow) =>
          searchableContent(entryRow)
            .toLowerCase()
            .indexOf(deburr(search).toLowerCase()) > -1
      );

      setFilteredRows(searchResult);
    },
    [rows, setCurrentPage]
  );

  const areRowsReady = isInitialized && isPaginated;
  const hasRows = rows && rows.length > 0;
  const matchFound = filteredRows.length > 0;

  return {
    rows,
    setRows,
    hasRows,
    filteredRows,
    setFilteredRows,
    matchFound,
    sortedRows,
    currentPageRows,
    hasMultiplePages,
    pageCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    pageSizeOptions,
    handlePageChange,
    handlePageSizeChange,
    sortingColumn,
    setSortingColumn,
    changeSortingColumn,
    sortingDirection,
    setSortingDirection,
    searchInRows,
    areRowsReady,
    setShowInactive,
    showInactive,
    changePage,
    changePageSize,
    changeSorting,
  };
};
