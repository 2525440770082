import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Dropdown, Input, Menu, Pagination } from 'semantic-ui-react';
import deburr from 'lodash/deburr';
import { useEntryTableLists } from 'modules/entry/hooks/useEntriesTableLists';
import { EntrySummary } from 'modules/entry/types';
import { useTable } from 'hooks/useTable';
import { pageSizeOptions } from 'hooks/useTablePaginating';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { useSecurity } from 'modules/account/hooks/useSecurity';
import { useResponsive } from 'hooks/useResponsive';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { SortingDirection } from 'hooks/useTableSorting';
import { CoworkerEntriesAccess } from 'modules/account';
import { EntriesTable, EntriesTableRow } from '../EntriesTable';

const m = defineMessages({
  myEntries: {
    id: 'Entries.Table.Panes.MyEntries',
    defaultMessage: 'Mes entrées',
  },
  myTeamEntries: {
    id: 'Entries.Table.Panes.MyTeamEntries',
    defaultMessage: 'Mon équipe',
  },
  allEntries: {
    id: 'Entries.Table.Panes.AllEntries',
    defaultMessage: 'Toutes les entrées',
  },
  recordperpage: {
    id: 'Entries.Table.RecordPerPage',
    defaultMessage: 'Entrées par page',
  },
  totalCount: {
    id: 'Entries.Table.TotalCount',
    defaultMessage: 'Nombre total',
  },
  search: {
    id: 'Entries.Table.Search',
    defaultMessage: 'Rechercher',
  },
  noRow: {
    id: 'Entries.Table.NoRow',
    defaultMessage: "Il n'y a aucune entrée.",
  },
  noMatchFound: {
    id: 'Entries.Table.NoMatchFound',
    defaultMessage: 'Aucune entrée ne correspond à votre recherche.',
  },
  id: {
    id: 'Entries.Table.Id',
    defaultMessage: 'Id',
  },
  date: {
    id: 'Entries.Table.Date',
    defaultMessage: 'Date',
  },
  team: {
    id: 'Entries.Table.Team',
    defaultMessage: 'Équipe',
  },
  createdBy: {
    id: 'Entries.Table.CreatedBy',
    defaultMessage: 'Créé par',
  },
  meetingType: {
    id: 'Entries.Table.MeetingType',
    defaultMessage: 'Type de rencontre',
  },
  population: {
    id: 'Entries.Table.Population',
    defaultMessage: 'Population rejointe',
  },
  location: {
    id: 'Entries.Table.Location',
    defaultMessage: 'Lieu',
  },
  district: {
    id: 'Entries.Table.District',
    defaultMessage: 'Arrondissement',
  },
});

type Tab = 'myEntries' | 'myTeamEntries' | 'allEntries';
const defaultTab = 'myEntries';

const defaultSortingColumn = 'date';
const defaultSortingDirection: SortingDirection = 'ascending';

const alwaysVisibleColumns: (keyof EntriesTableRow)[] = [
  'id',
  'date',
  'meetingTypeName',
  'populationName',
  'locationName',
  'districtsName',
];

interface Props {
  entries: EntrySummary[];
  options: EntriesTableOptions;
  onOptionsChange: (options: EntriesTableOptions) => void;
}

export interface EntriesTableOptions {
  currentPage?: string | number;
  pageSize?: string | number;
  sortingColumn?: string;
  sortingDirection?: SortingDirection;
  search?: string;
  tab?: string;
}

export const DefaultEntriesTableLayout: React.FC<Props> = ({
  entries,
  options,
  onOptionsChange,
}) => {
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();
  const { isAdmin, currentUser } = useSecurity();

  const [currentTab, setCurrentTab] = useState<Tab>(defaultTab);

  const visibleColumns = useMemo((): (keyof EntriesTableRow)[] => {
    switch (currentTab) {
      case 'allEntries':
        return [...alwaysVisibleColumns, 'createdByUserFullName', 'teamName'];
      case 'myTeamEntries':
        return [...alwaysVisibleColumns, 'createdByUserFullName'];
      default:
        return [...alwaysVisibleColumns, 'teamName'];
    }
  }, [currentTab]);

  const {
    teams,
    meetingTypes,
    populations,
    locations,
    districts,
    isReady: areListsReady,
  } = useEntryTableLists();

  const {
    currentPageRows,
    setRows,
    hasRows,
    matchFound,
    hasMultiplePages,
    pageCount,
    sortingColumn,
    sortingDirection,
    searchInRows,
    currentPage,
    filteredRows,
    pageSize,
    areRowsReady,
    changePage,
    changePageSize,
    changeSorting,
  } = useTable<EntriesTableRow>({
    ...options,
    sortingColumn: options.sortingColumn ?? defaultSortingColumn,
    sortingDirection: options.sortingDirection ?? defaultSortingDirection,
  });

  const isLoaded = areListsReady && areRowsReady;

  useEffect(() => {
    searchInRows(options.search ?? '', (entryRow) =>
      deburr(
        Object.values([
          entryRow.id,
          entryRow.date,
          entryRow.teamName,
          entryRow.meetingTypeName,
          entryRow.populationName,
          entryRow.locationName,
          entryRow.districtsName,
          entryRow.createdByUserFullName,
          entryRow.interventionTexts,
        ]).toString()
      )
    );
  }, [options.search, searchInRows]);

  useEffect(() => {
    setCurrentTab((options.tab as Tab) ?? defaultTab);
  }, [options.tab]);

  useEffect(() => {
    changeSorting(
      options.sortingColumn ?? defaultSortingColumn,
      options.sortingDirection ?? defaultSortingDirection
    );
  }, [changeSorting, options.sortingColumn, options.sortingDirection]);

  useEffect(() => {
    changePage(options.currentPage);
  }, [changePage, options.currentPage]);

  useEffect(() => {
    changePageSize(options.pageSize);
  }, [changePageSize, options.pageSize]);

  useEffect(() => {
    if (!areListsReady) return;

    let filteredEntries: EntrySummary[];

    if (currentTab === 'allEntries' && isAdmin) {
      filteredEntries = entries;
    } else if (currentTab === 'myTeamEntries') {
      filteredEntries = entries.filter(
        (e) => e.teamId === currentUser?.team?.id
      );
    } else {
      filteredEntries = entries.filter(
        (e) => e.createdByUserId === currentUser?.id
      );
    }

    const entryRows: EntriesTableRow[] = filteredEntries.map((entry) => ({
      id: entry.id,
      date: entry.date,
      teamName: teams[entry.teamId]?.name,
      meetingTypeName: meetingTypes[entry.meetingTypeId]?.name,
      populationName: populations[entry.populationId]?.name,
      locationName: locations[entry.locationId]?.name,
      districtsName: districts[entry.districtId]?.name,
      createdByUserFullName: entry.createdByUserFullName,
      interventionTexts: (entry.interventions ?? [])
        .filter((intervention) => intervention.textValue)
        .map((intervention) => intervention.textValue as string),
    }));

    setRows(entryRows);
  }, [
    areListsReady,
    currentTab,
    currentUser?.id,
    currentUser?.team?.id,
    districts,
    entries,
    isAdmin,
    locations,
    meetingTypes,
    populations,
    setRows,
    teams,
  ]);

  const handleSelectSearch = (search: any) => {
    onOptionsChange({ ...options, currentPage: 1, search });
  };

  const handleSelectTab = (selectedTab: Tab) => {
    if (selectedTab !== currentTab) {
      onOptionsChange({ ...options, tab: selectedTab, currentPage: 1 });
    }
  };

  const handleSelectSortingColumn = (selectedColumn: string) => {
    const reverseDirection =
      sortingDirection === 'ascending' ? 'descending' : 'ascending';

    const direction =
      selectedColumn !== sortingColumn
        ? defaultSortingDirection
        : reverseDirection;

    onOptionsChange({
      ...options,
      sortingColumn: selectedColumn,
      sortingDirection: direction,
    });
  };

  const handleSelectPage = (selectedPage: any) => {
    onOptionsChange({ ...options, currentPage: selectedPage as string });
  };

  const handleSelectPageSize = (selectedPageSize: any) => {
    onOptionsChange({
      ...options,
      pageSize: selectedPageSize as string,
      currentPage: 1,
    });
  };  

  if (!isLoaded) {
    return <ApplicationLoader />;
  }

  const tabCount = isAdmin ? 3 : 2;

  const userHasAccessToTeammateEntries =
    isAdmin ||
    currentUser?.team?.coworkerEntriesAccess ===
      CoworkerEntriesAccess.OwnAndTeammateEntries;

  const userHasAccessToAllEntries = isAdmin;

  const tabs = (
    <div className={isMobile ? 'tw-mx-12' : undefined}>
      <Menu
        color={isMobile ? undefined : 'green'}
        inverted={isMobile ? true : undefined}
        widths={isMobile ? tabCount : undefined}
        pointing
        secondary
        className="tw-mb-8 tw-border-t-0 tw-border-l-0 tw-border-r-0"
      >
        <Menu.Item
          active={currentTab === 'myEntries'}
          onClick={() => handleSelectTab('myEntries')}
        >
          {formatMessage(m.myEntries)}
        </Menu.Item>

        {userHasAccessToTeammateEntries && (
          <Menu.Item
            active={currentTab === 'myTeamEntries'}
            onClick={() => handleSelectTab('myTeamEntries')}
          >
            {formatMessage(m.myTeamEntries)}
          </Menu.Item>
        )}

        {userHasAccessToAllEntries && (
          <Menu.Item
            active={currentTab === 'allEntries'}
            onClick={() => handleSelectTab('allEntries')}
          >
            {formatMessage(m.allEntries)}
          </Menu.Item>
        )}
      </Menu>
    </div>
  );

  const totalCountAndRecordPerPage = matchFound && (
    <Fragment>
      <div>
        {formatMessage(m.recordperpage)}:{' '}
        <Dropdown
          inline
          options={pageSizeOptions}
          value={pageSize}
          onChange={(event, { value }) => handleSelectPageSize(value)}
        />
      </div>
      <div>
        {formatMessage(m.totalCount)}: {filteredRows.length}
      </div>
    </Fragment>
  );

  const searchInput = (
    <Input
      icon="search"
      placeholder={formatMessage(m.search)}
      value={options.search}
      onChange={(event, { value }) => handleSelectSearch(value)}
    />
  );

  const topBar = hasRows && (
    <div className="tw-flex tw-items-center tw-justify-between tw-mb-6">
      <div className="tw-flex-1">{totalCountAndRecordPerPage}</div>
      {searchInput}
    </div>
  );

  const table = (
    <EntriesTable
      rows={currentPageRows}
      isEmpty={!hasRows}
      noMatchFound={!matchFound}
      sortingColumn={sortingColumn}
      sortingDirection={sortingDirection}
      visibleColumns={visibleColumns}
      onColumnSorting={handleSelectSortingColumn}
    />
  );  

  const pagination = hasMultiplePages && (
    <Pagination
      totalPages={pageCount}
      activePage={currentPage}
      onPageChange={(event, { activePage }) => handleSelectPage(activePage)}
    />
  );

  const bottomBarCenteringClass = isMobile ? 'tw-justify-center' : undefined;

  const bottomBar = (
    <div className={`tw-flex tw-items-baseline ${bottomBarCenteringClass}`}>
      {pagination}
    </div>
  );

  return (
    <Fragment>
      {tabs}
      <ApplicationSegment>
        {topBar}
        {table}
        {bottomBar}
      </ApplicationSegment>
    </Fragment>
  );
};
