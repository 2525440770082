import React from 'react';
import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { DemographyChartData } from './useEntryViewData';

const barSize = 16;
const barGap = 16;
const yPadding = 12.5;

interface Props {
  data: DemographyChartData[];
}

export const DemographyChart: React.FC<Props> = ({ data }) => {
  const barCount = data.length;
  const chartHeight = barCount * (barSize + barGap) - barGap + yPadding;

  return (
    <ResponsiveContainer width="99%" height={chartHeight}>
      <BarChart data={data} layout="vertical">
        <Bar dataKey="count" fill="#076991" barSize={barSize}>
          <LabelList dataKey="count" position="right" />
        </Bar>
        <XAxis type="number" hide={true} scale="auto" padding={{ right: 30 }} />
        <YAxis
          type="category"
          dataKey="name"
          axisLine={false}
          width={120}
          tickLine={false}
          interval={0}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
