import React, { Fragment, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { PageLayout } from 'components/Layout/PageLayout';
import { PageHeader } from 'components/Layout/PageHeader';
import { EntryForm, EntryFormValues } from '../components/EntryForm';
import { EntriesApi } from '../api';
import { toast } from 'react-toastify';
import { defaultErrorHandler } from 'api';
import { useHistory, useParams } from 'react-router';
import { useFetchEntry } from '../hooks/useFetchEntry';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { useQueryClient } from 'react-query';

const m = defineMessages({
  pageTitle: {
    id: 'Entry.EditPage.Title',
    defaultMessage: 'Modifier une entrée',
  },
  savedSuccessfully: {
    id: 'Entry.EditPage.SavedSuccessfully',
    defaultMessage: "L'entrée a été sauvegardé avec succès.",
  },
});

interface Params {
  entryId: string;
}

export const EditEntryPage: React.FC = () => {
  const contentTopRef = useRef<HTMLDivElement>(null);  
  const { formatMessage } = useIntl();
  const history = useHistory();
  const params = useParams<Params>();
  const id = parseInt(params.entryId, 10);
  const entry = useFetchEntry(id);
  const [saving, setSaving] = useState(false);
  const queryClient = useQueryClient()
  
  const handleSubmit = (editedEntry: EntryFormValues) => {    
    setSaving(true);
    
    EntriesApi.update(id, editedEntry)
    .then(() => {
        queryClient.invalidateQueries('entries');
        toast.success(formatMessage(m.savedSuccessfully));
        contentTopRef.current!.scrollIntoView();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };

  if (entry === null) {
    history.push('/entries');
  }

  const showLoader = !entry || saving;

  return (
    <Fragment>
      <PageLayout
        pageTitle={formatMessage(m.pageTitle)}
        contentTopRef={contentTopRef}
      >
        <PageHeader
          header={formatMessage(m.pageTitle)}
          iconName="file alternate"
        />
        {entry && <EntryForm entry={entry} onSubmit={handleSubmit} />}
        {showLoader && <ApplicationLoader />}
      </PageLayout>
    </Fragment>
  );
};
