import { requests } from 'api';
import { InterventionFormValues } from './components/InterventionForm';
import {
  InterventionSummary,
  Intervention,
  InterventionListValue,
} from './types';

const apiBaseUrl = '/api/interventions';

export const InterventionsApi = {
  getAll: (): Promise<InterventionSummary[]> => requests.get(apiBaseUrl),
  getById: (id: number): Promise<Intervention> =>
    requests.get(`${apiBaseUrl}/${id}`),
  create: (newIntervention: InterventionFormValues): Promise<Intervention> =>
    requests.post(apiBaseUrl, newIntervention),
  update: (
    id: number,
    editedIntervention: InterventionFormValues
  ): Promise<void> => requests.patch(`${apiBaseUrl}/${id}`, editedIntervention),
  getListValues: (id: number): Promise<InterventionListValue[]> =>
    requests.get(`${apiBaseUrl}/${id}/list-values`),
};
