import { useEffect, useState } from 'react';
import { defaultErrorHandler } from 'api';
import { HomelessnessesApi } from '../api';
import { Homelessness } from '../types';

interface FetchedHomelessness {
  homelessness: Homelessness | null | undefined;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchHomelessness = (id: number | null): FetchedHomelessness => {
  const [homelessness, setHomlessness] =
    useState<Homelessness | null | undefined>(undefined);
  const [isFetched, setIsFetched] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);

    if (id === null) {
      setHomlessness(null);
    } else {
      HomelessnessesApi.getById(id)
        .then((fetchedHomelessness) => {
          setHomlessness(fetchedHomelessness);
          setIsFetched(true);
        })
        .catch((error) => {
          setHomlessness(null);
          setIsFetched(false);
          defaultErrorHandler(error);
        })
        .finally(() => setIsFetching(false));
    }
  }, [id]);

  return { homelessness, isFetched, isFetching };
};
