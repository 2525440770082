import { TeamLists, useFetchTeams } from 'modules/team/hooks/useFetchTeams';
import {
  MeetingTypeLists,
  useFetchMeetingTypes,
} from 'modules/meetingType/hooks/useFetchMeetingTypes';
import {
  PopulationLists,
  useFetchPopulations,
} from 'modules/population/hooks/useFetchPopulations';
import {
  LocationLists,
  useFetchLocations,
} from 'modules/location/hooks/useFetchLocations';
import {
  DistrictLists,
  useFetchDistricts,
} from 'modules/district/hooks/useFetchDistricts';
import {
  MomentLists,
  useFetchMoments,
} from 'modules/moment/hooks/useFetchMoments';
import {
  ContactStateLists,
  useFetchContactStates,
} from 'modules/contactState/hooks/useFetchContactStates';
import {
  InitiatorLists,
  useFetchInitiators,
} from 'modules/initiator/hooks/useFetchInitiators';
import {
  GenderLists,
  useFetchGenders,
} from 'modules/gender/hooks/useFetchGenders';
import {
  AgeGroupLists,
  useFetchAgeGroups,
} from 'modules/ageGroup/hooks/useFetchAgeGroups';
import {
  OccupationLists,
  useFetchOccupations,
} from 'modules/occupation/hooks/useFetchOccupations';
import {
  OriginLists,
  useFetchOrigins,
} from 'modules/origin/hooks/useFetchOrigins';
import {
  InterventionLists,
  useFetchInterventions,
} from 'modules/intervention/hooks/useFetchInterventions';
import {
  ProblematicLists,
  useFetchProblematics,
} from 'modules/problematic/hooks/useFetchProblematics';
import {
  HomelessnessLists,
  useFetchHomelessnesses,
} from 'modules/homelessness/hooks/useFetchHomelessnesses';
import {
  InterventionLanguageLists,
  useFetchInterventionLanguages,
} from 'modules/interventionLanguage/hooks/useFetchInterventionLanguages';
import {
  TapajProgressLists,
  useFetchTapajProgresses,
} from 'modules/tapajProgress/hooks/useFetchTapajProgresses';
import {
  HomelessnessSituationLists,
  useFetchHomelessnessSituations,
} from 'modules/homelessnessSituation/hooks/useFetchHomelessnessSituations';
import { useMemo } from 'react';

export interface EntryLists {
  teams: TeamLists;
  meetingTypes: MeetingTypeLists;
  populations: PopulationLists;
  locations: LocationLists;
  districts: DistrictLists;
  moments: MomentLists;
  contactStates: ContactStateLists;
  initiators: InitiatorLists;
  genders: GenderLists;
  ageGroups: AgeGroupLists;
  occupations: OccupationLists;
  origins: OriginLists;
  interventions: InterventionLists;
  problematics: ProblematicLists;
  homelessnesses: HomelessnessLists;
  interventionLanguages: InterventionLanguageLists;
  tapajProgresses: TapajProgressLists;
  homelessnessSituations: HomelessnessSituationLists;
  isReady: boolean;
}

export const useEntryLists = (): EntryLists => {
  const teams = useFetchTeams();
  const meetingTypes = useFetchMeetingTypes();
  const populations = useFetchPopulations();
  const locations = useFetchLocations();
  const districts = useFetchDistricts();
  const moments = useFetchMoments();
  const contactStates = useFetchContactStates();
  const initiators = useFetchInitiators();
  const genders = useFetchGenders();
  const ageGroups = useFetchAgeGroups();
  const occupations = useFetchOccupations();
  const origins = useFetchOrigins();
  const interventions = useFetchInterventions();
  const problematics = useFetchProblematics();
  const homelessnesses = useFetchHomelessnesses();
  const interventionLanguages = useFetchInterventionLanguages();
  const tapajProgresses = useFetchTapajProgresses();
  const homelessnessSituations = useFetchHomelessnessSituations();

  const isReady =
    teams.isFetched &&
    meetingTypes.isFetched &&
    populations.isFetched &&
    locations.isFetched &&
    districts.isFetched &&
    moments.isFetched &&
    contactStates.isFetched &&
    initiators.isFetched &&
    genders.isFetched &&
    ageGroups.isFetched &&
    occupations.isFetched &&
    origins.isFetched &&
    interventions.isFetched &&
    problematics.isFetched &&
    homelessnesses.isFetched &&
    interventionLanguages.isFetched &&
    tapajProgresses.isFetched &&
    homelessnessSituations.isFetched;

  const lists = useMemo(
    () => ({
      teams,
      meetingTypes,
      populations,
      locations,
      districts,
      moments,
      contactStates,
      initiators,
      genders,
      ageGroups,
      occupations,
      origins,
      interventions,
      problematics,
      homelessnesses,
      interventionLanguages,
      tapajProgresses,
      homelessnessSituations,
      isReady,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReady]
  );

  return lists;
};
