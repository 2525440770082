import { defineMessages, useIntl } from 'react-intl';
import { RoleSet } from '../types';
import { DropdownItemProps } from 'semantic-ui-react';
import { useCallback, useMemo } from 'react';

const m = defineMessages({
  admin: { id: 'RoleSet.Admin', defaultMessage: 'Administrateur' },
  caseworker: { id: 'RoleSet.Caseworker', defaultMessage: 'Intervenant' },
});

const permissions = defineMessages({
  createEntries: {
    id: 'UserPermissions.Entry.Create',
    defaultMessage: 'Créer des entrées',
  },
  viewAndEditMyEntries: {
    id: 'UserPermissions.Entry.ViewAndEditMyEntries',
    defaultMessage: 'Voir/Modifier ses entrées',
  },
  viewMyTeamEntries: {
    id: 'UserPermissions.Entry.ViewMyTeamEntries',
    defaultMessage: 'Voir les entrées de son équipe',
  },
  createAndEditMeals: {
    id: 'UserPermissions.Meal.CreateAndEditMeals',
    defaultMessage: 'Ajouter/Modifier les repas',
  },
  viewAndEditAllEntries: {
    id: 'UserPermissions.Entry.ViewAndEditAllEntries',
    defaultMessage: 'Voir/Modifier toutes les entrées',
  },
  manageUsers: {
    id: 'UserPermissions.User.Manage',
    defaultMessage: 'Gérer les utilisateurs',
  },
  manageTeams: {
    id: 'UserPermissions.Team.Manage',
    defaultMessage: 'Gérer les équipes',
  },
  mangeInterventions: {
    id: 'UserPermissions.Intervention.Manage',
    defaultMessage: 'Gérer la liste des interventions',
  },
  manageProblematics: {
    id: 'UserPermissions.Problematic.Mangage',
    defaultMessage: 'Gérer la liste des préoccupations et problématiques',
  },
  manageHomelessnesses: {
    id: 'UserPermissions.Homelessness.Manage',
    defaultMessage: 'Gérer la liste des interventions en itinérance',
  },
});

export const useRoleSets = () => {
  const { formatMessage } = useIntl();

  const roleSets = useMemo<RoleSet[]>(
    () => [
      {
        name: 'Admin',
        displayName: formatMessage(m.admin),
        roleNames: ['Admin'],
        permissions: [
          formatMessage(permissions.createEntries),
          formatMessage(permissions.viewAndEditAllEntries),
          formatMessage(permissions.createAndEditMeals),
          formatMessage(permissions.manageUsers),
          formatMessage(permissions.manageTeams),
          formatMessage(permissions.mangeInterventions),
          formatMessage(permissions.manageProblematics),
          formatMessage(permissions.manageHomelessnesses),
        ],
      },
      {
        name: 'Caseworker',
        displayName: formatMessage(m.caseworker),
        roleNames: ['Caseworker'],
        permissions: [
          formatMessage(permissions.createEntries),
          formatMessage(permissions.viewAndEditMyEntries),
          formatMessage(permissions.viewMyTeamEntries),
          formatMessage(permissions.createAndEditMeals),
        ],
      },
    ],
    [formatMessage]
  );

  const dropdownItems = useMemo<DropdownItemProps[]>(
    () =>
      roleSets.map((roleSet) => ({
        key: roleSet.name,
        value: roleSet.name,
        text: roleSet.displayName,
      })),
    [roleSets]
  );

  const getMatchingRoleSet = useCallback(
    (userRoleNames: string[]): RoleSet | undefined => {
      const matchingRoleSet = roleSets.find((roleSet) =>
        roleSet.roleNames.every((r) => userRoleNames.includes(r))
      );

      return matchingRoleSet;
    },
    [roleSets]
  );

  const getRoleSetByName = useCallback(
    (roleName: string | undefined): RoleSet | undefined =>
      roleSets.find((roleSet) => roleSet.name === roleName),
    [roleSets]
  );

  return { roleSets, getMatchingRoleSet, getRoleSetByName, dropdownItems };
};
