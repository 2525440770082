export interface EntryQuery {
  fromDate: string | null;
  toDate: string | null;
  createdByCaseworkers: string[];
  teams: number[];
  meetingTypes: number[];
  populations: number[];
  locations: number[];
  districts: number[];
  moments: number[];
  contactStates: number[];
  initiators: number[];
  demographies: {
    minPersonCount: string | null;
    maxPersonCount: string | null;
    genders: number[];
    ageGroups: number[];
    occupations: number[];
    origins: number[];
    originsLogicalOperator: string | null;
  };
  interventions: {
    hasIntervention: boolean | null | 'any';
    minDurationMinutes: string | null;
    maxDurationMinutes: string | null;
    interventions: number[];
    interventionsLogicalOperator: string | null;
    interventionsTextValues: string[];
    interventionsMinNumericValue: string | null;
    interventionsMaxNumericValue: string | null;
    problematics: number[];
    problematicsLogicalOperator: string | null;
  };
  homelessnesses: {
    hasHomelessness: boolean | null | 'any';
    homelessnessSituation: number[];
    homelessnessInterventions: number[];
    homelessnessInterventionsLogicalOperator: string | null;
  };
  tapajProgress: {
    hasTapajProgress: boolean | null | 'any';
    tapajProgresses: number[];
  };
  referredBy: string[];
}

export const defaultEntryQuery: EntryQuery = {
  fromDate: '',
  toDate: '',
  createdByCaseworkers: [],
  teams: [],
  meetingTypes: [],
  populations: [],
  locations: [],
  districts: [],
  moments: [],
  contactStates: [],
  initiators: [],
  demographies: {
    minPersonCount: null,
    maxPersonCount: null,
    genders: [],
    ageGroups: [],
    occupations: [],
    origins: [],
    originsLogicalOperator: null,
  },
  interventions: {
    hasIntervention: null,
    minDurationMinutes: null,
    maxDurationMinutes: null,
    interventions: [],
    interventionsLogicalOperator: null,
    interventionsTextValues: [],
    interventionsMinNumericValue: null,
    interventionsMaxNumericValue: null,
    problematics: [],
    problematicsLogicalOperator: null,
  },
  homelessnesses: {
    hasHomelessness: null,
    homelessnessSituation: [],
    homelessnessInterventions: [],
    homelessnessInterventionsLogicalOperator: null,
  },
  tapajProgress: {
    hasTapajProgress: null,
    tapajProgresses: [],
  },
  referredBy: [],
};
