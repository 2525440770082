import React, { Fragment, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { PageLayout } from 'components/Layout/PageLayout';
import { PageHeader } from 'components/Layout/PageHeader';
import {
  EntryForm,
  EntryFormValues,
  MeetingType,
} from '../components/EntryForm';
import { EntriesApi } from '../api';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'modules/account/selectors';
import { toast } from 'react-toastify';
import { defaultErrorHandler } from 'api';
import { Language } from 'modules/interventionLanguage';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { useQueryClient } from 'react-query';
import moment from 'moment';

const m = defineMessages({
  pageTitle: { id: 'Entry.Page.Title', defaultMessage: 'Nouvelle entrée' },
  savedSuccessfully: {
    id: 'Entry.Page.SavedSuccessfully',
    defaultMessage: "L'entrée a été sauvegardé avec succès.",
  },
});

export const NewEntryPage: React.FC = () => {
  const contentTopRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();
  const currentUser = useSelector(getCurrentUser);
  const [saving, setSaving] = useState(false);
  const queryClient = useQueryClient();

  const getEmptyEntry = (): EntryFormValues => {
    const sessionDefaultEntry = JSON.parse(
      sessionStorage.getItem('defaultEntry') as string
    );

    return {
      teamId: sessionDefaultEntry?.teamId ?? currentUser?.team?.id,
      date:
      sessionDefaultEntry?.date ?? moment().format().substring(0, 10),
      meetingTypeId: MeetingType.Single,
      populationId: sessionDefaultEntry?.populationId ?? null,
      locationId: sessionDefaultEntry?.locationId ?? null,
      districtId: sessionDefaultEntry?.districtId ?? null,
      momentId: sessionDefaultEntry?.momentId ?? null,
      contactStateId: sessionDefaultEntry?.contactStateId ?? null,
      initiatorId: sessionDefaultEntry?.initiatorId ?? null,
      interventionDurationInMinute: 0,
      hasIntervention: true,
      hasHomelessness: false,
      demographies: [
        {
          count: 1,
          genderId: null,
          ageGroupId: null,
          occupationId: null,
          originIds: [],
          interventionLanguageId: Language.French,
        },
      ],
      interventions: [],
      problematicIds: [],
      homelessnessIds: [],
      homelessnessSituationId: null,
      hasTapajProgress: false,
      tapajProgressIds: [],
      demographySectionMobileMode: 'list',
    };
  };

  const [entry, setEntry] = useState<EntryFormValues>(getEmptyEntry());

  const saveDefaultEntry = (newEntry: EntryFormValues) => {
    const defaultEntry = {
      teamId: newEntry.teamId,
      date: newEntry.date,
      meetingTypeId: newEntry.meetingTypeId,
      populationId: newEntry.populationId,
      locationId: newEntry.locationId,
      districtId: newEntry.districtId,
      momentId: newEntry.momentId,
      contactStateId: newEntry.contactStateId,
      initiatorId: newEntry.initiatorId,
    };

    sessionStorage.setItem('defaultEntry', JSON.stringify(defaultEntry));
  };

  const handleSubmit = (newEntry: EntryFormValues) => {
    setSaving(true);
    saveDefaultEntry(newEntry);
    
    EntriesApi.create(newEntry)
    .then(() => {
        queryClient.invalidateQueries('entries');
        toast.success(formatMessage(m.savedSuccessfully));
        setEntry(getEmptyEntry());
        contentTopRef.current!.scrollIntoView();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };

  return (
    <Fragment>
      <PageLayout
        pageTitle={formatMessage(m.pageTitle)}
        contentTopRef={contentTopRef}
      >
        <PageHeader
          header={formatMessage(m.pageTitle)}
          iconName="file alternate"
        />
        <EntryForm entry={entry} onSubmit={handleSubmit} />
        {saving && <ApplicationLoader />}
      </PageLayout>
    </Fragment>
  );
};
