import React from 'react';
import { useResponsive } from 'hooks/useResponsive';

export const MainContainer: React.FC = ({ children }) => {
  const { isMobile } = useResponsive();

  const paddingClass = isMobile ? '' : 'tw-px-12 tw-py-12';

  return <div className={paddingClass}>{children}</div>;
};
