import { ApplicationSegment } from 'components/ApplicationSegment';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import { Field } from './Field';
import { EntryViewData } from './useEntryViewData';

const m = defineMessages({
  team: { id: 'Entry.View.Team', defaultMessage: 'Équipe' },
  meetingType: {
    id: 'Entry.View.MeetingType',
    defaultMessage: 'Type de rencontre',
  },
  location: { id: 'Entry.View.Location', defaultMessage: "Lieu d'activité" },
  moment: { id: 'Entry.View.Moment', defaultMessage: 'Moment' },
  initiator: {
    id: 'Entry.View.Initiator',
    defaultMessage: 'Initiateur du contact',
  },
  date: { id: 'Entry.View.Date', defaultMessage: 'Date' },
  population: {
    id: 'Entry.View.Population',
    defaultMessage: 'Population rejointe',
  },
  district: {
    id: 'Entry.View.District',
    defaultMessage: 'Arrondissement',
  },
  contactState: {
    id: 'Entry.View.ContactState',
    defaultMessage: 'État du contact',
  },
  referredBy: {
    id: 'Entry.View.ReferredBy',
    defaultMessage: 'Référé par',
  },
});

interface Props {
  viewData: EntryViewData;
}

export const BaseSection: React.FC<Props> = ({ viewData }) => {
  const { formatMessage, formatDate } = useIntl();
  const {
    team,
    meetingType,
    location,
    moment,
    initiator,
    date,
    population,
    district,
    contactState,
    referredBy,
  } = viewData;

  return (
    <ApplicationSegment>
      <div className="tw-p-2">
        <Grid columns={2} doubling className="tw-my-1">
          <Grid.Column className="tw-p-0">
            <div className="tw-pl-8">
              <Field label={formatMessage(m.team)} value={team} />
              <Field label={formatMessage(m.meetingType)} value={meetingType} />
              <Field label={formatMessage(m.location)} value={location} />
              <Field label={formatMessage(m.moment)} value={moment} />
              <Field label={formatMessage(m.initiator)} value={initiator} />              
            </div>
          </Grid.Column>

          <Grid.Column className="tw-p-0">
            <div className="tw-pl-8">
              <Field
                label={formatMessage(m.date)}
                value={formatDate(date, {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                  timeZone: 'utc',
                })}
              />
              <Field label={formatMessage(m.population)} value={population} />
              <Field label={formatMessage(m.district)} value={district} />
              <Field
                label={formatMessage(m.contactState)}
                value={contactState}
              />
              {referredBy && (
                <Field label={formatMessage(m.referredBy)} value={referredBy} />
              )}
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </ApplicationSegment>
  );
};
