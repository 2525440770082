import * as actionsTypes from './actionTypes';

export interface CurrentUser {
  id: string;
  fullName: string;
  userName: string;
  roleNames: string[];
  team: CurrentUserTeam | null;
}

interface CurrentUserTeam {
  id: number;
  coworkerEntriesAccess: CoworkerEntriesAccess;
}

export enum CoworkerEntriesAccess {
  OwnAndTeammateEntries = 0,
  OwnEntriesOnly = 1,
}

export interface AccountState {
  user: CurrentUser | undefined;
  isAuthenticated: boolean;
}

interface FetchCurrentUserAction {
  type: typeof actionsTypes.FETCH_CURRENT_USER_REQUEST;
}

interface FetchCurrentUserSuccessAction {
  type: typeof actionsTypes.FETCH_CURRENT_USER_SUCCESS;
  payload: CurrentUser;
}

interface FetchCurrentUserFailureAction {
  type: typeof actionsTypes.FETCH_CURRENT_USER_FAILURE;
}

interface SignOutAction {
  type: typeof actionsTypes.SIGNOUT_REQUEST;
}

interface ClearAccountAction {
  type: typeof actionsTypes.CLEAR_ACCOUNT_REQUEST;
}

export type AccountActionTypes =
  | FetchCurrentUserAction
  | FetchCurrentUserSuccessAction
  | FetchCurrentUserFailureAction
  | SignOutAction
  | ClearAccountAction;
