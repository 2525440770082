import { ApplicationSegment } from 'components/ApplicationSegment';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Checkbox, Form, Grid } from 'semantic-ui-react';
import { useTeamValidations } from 'modules/team/hooks/useTeamValidation';
import { ImageInput } from 'components/ImageInput';

const m = defineMessages({
  name: { id: 'Team.Form.Name', defaultMessage: 'Nom' },
  logo: { id: 'Team.Form.Logo', defaultMessage: 'Logo' },
  save: { id: 'Team.Form.Save', defaultMessage: 'Sauvegarder' },
  active: { id: 'ActivityToggleSwitch.Active', defaultMessage: 'Actif' },
  inactive: { id: 'ActivityToggleSwitch.Inactive', defaultMessage: 'Inactif' },
});

export interface TeamFormValues {
  name: string;
  isActive: boolean;
  logo?: string;
  newLogoFile?: File;
}

interface Props {
  team: TeamFormValues;
  onSubmit: (data: TeamFormValues) => void;
}

export const TeamForm: React.FC<Props> = ({ team, onSubmit }) => {
  const { formatMessage } = useIntl();

  const validations = useTeamValidations();

  const { handleSubmit, control, reset, formState: { errors } } = useForm<TeamFormValues>({
    defaultValues: team,
  });

  useEffect(() => {
    reset(team);
  }, [team, reset]);

  const handleOnSubmit = (data: TeamFormValues) => {
    onSubmit(data);
  };

  const ErrorMessage: React.FC = ({ children }) => {
    return <div className="tw-text-red tw-mb-4">{children}</div>;
  };

  return (
    <ApplicationSegment>
      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid columns={2} doubling>
          <Grid.Column>
            <Form.Field error={Boolean(errors.name)} className="tw-max-w-lg">
              <label className="tw-text-purple">{formatMessage(m.name)}</label>
              <Controller
                control={control}
                name="name"
                rules={{ validate: validations.name }}
                render={({ field: { onBlur, value, onChange} }) => (
                  <input
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    type="text"
                  />
                )}
              />
              {errors.name && (
                <ErrorMessage>{errors.name.message}</ErrorMessage>
              )}
            </Form.Field>

            <Form.Field>
              <label className="tw-text-purple">{formatMessage(m.logo)}</label>
              <Controller
                control={control}
                name="newLogoFile"
                render={({ field: { onChange} }) => (
                  <ImageInput
                    image={team.logo}
                    onChange={onChange}
                    maxImageSizePx={200}
                  />
                )}
              />
            </Form.Field>
          </Grid.Column>
        </Grid>

        <div className="tw-flex tw-justify-between tw-pt-8 tw-mt-8 tw-border-t tw-border-gray-300">
          <Controller
            control={control}
            name="isActive"
            render={({ field: { onBlur, value, onChange} }) => (
              <div className="tw-flex tw-items-center">
                <Checkbox
                  onChange={(e, data) => onChange(data.checked)}
                  onBlur={onBlur}
                  checked={value}
                  toggle
                />
                <div className="tw-ml-6">
                  {value ? formatMessage(m.active) : formatMessage(m.inactive)}
                </div>
              </div>
            )}
          />

          <Button primary type="submit">
            {formatMessage(m.save)}
          </Button>
        </div>
      </Form>
    </ApplicationSegment>
  );
};
