import React, { Fragment } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Header, Table } from 'semantic-ui-react';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import { AppIcon } from 'components/Layout/AppIcon';

const m = defineMessages({
  title: {
    id: 'Meals.Page.Stats.Title',
    defaultMessage: 'Total par équipe',
  },
  teamName: {
    id: 'Meals.Page.Stats.TeamName',
    defaultMessage: 'Nom',
  },
  mealCount: {
    id: 'Meals.Page.Stats.MealCount',
    defaultMessage: 'Quantité',
  },
  total: {
    id: 'Meals.Page.Stats.Total',
    defaultMessage: 'Total',
  },
  noMeal: {
    id: 'Meals.Page.Stats.NoMeal',
    defaultMessage: 'Aucun repas',
  },
});

export interface MealCountByTeamStat {
  teamName: string;
  mealCount: number;
}

interface Props {
  mealCountByTeam?: MealCountByTeamStat[];
}

export const MealCountByTeam: React.FC<Props> = ({ mealCountByTeam }) => {
  const { formatMessage } = useIntl();

  const hasMeal = mealCountByTeam && mealCountByTeam.length > 0;

  const sortedMealCountByTeam = sortBy(
    mealCountByTeam,
    (stat) => stat.teamName
  );

  const totalMealCount = sumBy(mealCountByTeam, (stat) => stat.mealCount);

  const header = (
    <Header size="tiny" color="purple" className="tw-m-0 tw-mb-5">
      <AppIcon name="users" size="tiny" />
      <Header.Content className="tw-align-middle tw-pl-3">
        {formatMessage(m.title)}
      </Header.Content>
    </Header>
  );

  const table = (
    <Table
      sortable
      celled
      striped
      selectable
      size="small"
      className="text-sm"
      unstackable
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{formatMessage(m.teamName)}</Table.HeaderCell>
          <Table.HeaderCell>{formatMessage(m.mealCount)}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {sortedMealCountByTeam.map((row) => (
          <Table.Row key={row.teamName}>
            <Table.Cell>{row.teamName}</Table.Cell>
            <Table.Cell collapsing textAlign="right">
              {row.mealCount}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );

  const total = (
    <div className="tw-text-right">
      <span className="tw-text-purple tw-mr-2">{formatMessage(m.total)}</span>
      <span className="tw-text-green tw-text-3xl">{totalMealCount}</span>
    </div>
  );

  return (
    <Fragment>
      {header}
      {hasMeal && (
        <>
          {table}
          {total}
        </>
      )}
      {!hasMeal && formatMessage(m.noMeal)}
    </Fragment>
  );
};
