import React, { Fragment, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { defaultErrorHandler } from 'api';
import { Segment } from 'semantic-ui-react';
import {
  TapajProgressForm,
  TapajProgressFormValues,
} from '../components/TapajProgressForm';
import { TapajProgressesApi } from '../api';
import { PageLayout } from 'components/Layout/PageLayout';
import { PageHeader } from 'components/Layout/PageHeader';
import { ApplicationLoader } from 'components/ApplicationLoader';

const m = defineMessages({
  pageTitle: {
    id: 'TapajProgress.NewPage.Title',
    defaultMessage: 'Nouveau progrès Tapaj ou Capab',
  },
  savedSuccessfully: {
    id: 'TapajProgress.NewPage.SavedSuccessfully',
    defaultMessage: "Le progrès Tapaj ou Capab a été sauvegardé avec succès",
  },
});

export const NewTapajProgressPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [saving, setSaving] = useState(false);

  const emtpyTapajProgress: TapajProgressFormValues = {
    name: '',
    isActive: true,
  };

  const returnToTapajProgressesPage = () => {
    history.push('/admin/tapaj-progresses');
  };

  const handleSubmit = (data: TapajProgressFormValues) => {
    setSaving(true);

    TapajProgressesApi.create(data)
      .then(() => {
        toast.success(formatMessage(m.savedSuccessfully));
        returnToTapajProgressesPage();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };

  return (
    <Fragment>
      <PageLayout pageTitle={formatMessage(m.pageTitle)}>
        <PageHeader
          header={formatMessage(m.pageTitle)}
          iconName="clipboard check"
        />
        <Segment>
          <TapajProgressForm
            tapajProgress={emtpyTapajProgress}
            onSubmit={handleSubmit}
          />
        </Segment>
        { saving && <ApplicationLoader />}
      </PageLayout>
    </Fragment>
  );
};
