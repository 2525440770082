import { Reducer } from 'react';
import {
  CLEAR_ACCOUNT_REQUEST,
  FETCH_CURRENT_USER_FAILURE,
  FETCH_CURRENT_USER_SUCCESS,
} from './actionTypes';
import { AccountActionTypes, AccountState } from './types';

const initialState: AccountState = {
  user: undefined,
  isAuthenticated: false,
};

export const accountReducer: Reducer<AccountState, AccountActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case FETCH_CURRENT_USER_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        user: undefined,
      };
    case CLEAR_ACCOUNT_REQUEST:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
