import { requests } from 'api';
import { User, UserSummary } from './types';

export interface UserUpdate {
  roleNames: string[];
  teamId: number;
  isActive: boolean;
}

const apiBaseUrl = '/api/users';

export const UsersApi = {
  getAll: (): Promise<UserSummary[]> => requests.get(apiBaseUrl),
  getById: (id: string): Promise<User> => requests.get(`${apiBaseUrl}/${id}`),
  update: (id: string, user: UserUpdate): Promise<User> =>
    requests.patch(`${apiBaseUrl}/${id}`, user),
};
