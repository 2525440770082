import React from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Header, List } from 'semantic-ui-react';
import { DemographyFormDesktop } from './DemographyFormDesktop';
import { EntryDemographyFormValues, EntryFormValues } from './EntryForm';

const m = defineMessages({
  title: {
    id: 'Entry.Form.Demographies.Title',
    defaultMessage: 'Démographies',
  },
  count: {
    id: 'Entry.Form.Demographies.Count',
    defaultMessage: 'Nombre de personne',
  },
  gender: { id: 'Entry.Form.Demographies.Gender', defaultMessage: 'Sexe' },
  ageGroup: {
    id: 'Entry.Form.Demographies.AgeGroup',
    defaultMessage: "Groupe d'âge",
  },
  occupation: {
    id: 'Entry.Form.Demographies.Occupation',
    defaultMessage: 'Occupation/Revenu',
  },
  origin: { id: 'Entry.Form.Demographies.Origin', defaultMessage: 'Origine' },
  language: {
    id: 'Entry.Form.Demographies.InterventionLanguage',
    defaultMessage: 'Langue',
  },
  add: {
    id: 'Entry.Form.Demographies.Add',
    defaultMessage: 'Ajouter une démographie',
  },
});

interface Props {
  demographies: FieldArrayWithId<EntryFormValues, "demographies", "fieldId">[];
  onDelete: (index: number) => void;
  onNew: () => void;
}

export const DemographiesSectionDesktop: React.FC<Props> = ({
  demographies,
  onDelete,
  onNew,
}) => {
  const { formatMessage } = useIntl();

  const hasDemographies = demographies && demographies.length > 0;

  const handleDelete = (index: number) => onDelete(index);

  const handleNew = () => onNew();

  return (
    <>
      <Header size="small">{formatMessage(m.title)}</Header>

      <List relaxed>
        {hasDemographies && (
          <List.Item>
            <List horizontal className="tw-flex tw-items-center">
              <List.Item className="tw-w-40">
                {formatMessage(m.count)}
              </List.Item>
              <List.Item className="tw-w-40">
                {formatMessage(m.gender)}
              </List.Item>
              <List.Item className="tw-w-48">
                {formatMessage(m.ageGroup)}
              </List.Item>
              <List.Item className="tw-w-64">
                {formatMessage(m.occupation)}
              </List.Item>
              <List.Item className="tw-w-72">
                {formatMessage(m.origin)}
              </List.Item>
              <List.Item className="tw-w-48">
              {formatMessage(m.language)}
              </List.Item>
            </List>
          </List.Item>
        )}

        {demographies.map((demography, index) => (
          <List.Item key={demography.fieldId}>
            <DemographyFormDesktop
              index={index}
              demography={demography as EntryDemographyFormValues}
              onDelete={() => handleDelete(index)}
              canDelete={demographies.length > 1}
            />
          </List.Item>
        ))}
      </List>

      <Button type="button" inverted primary onClick={handleNew}>
        {formatMessage(m.add)}
      </Button>
    </>
  );
};
