import { useEffect, useState } from 'react';
import { defaultErrorHandler } from 'api';
import { TapajProgressesApi } from '../api';
import { TapajProgress } from '../types';

interface FetchedTapajProgress {
  tapajProgress: TapajProgress | null | undefined;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchTapajProgress = (id: number | null): FetchedTapajProgress => {
  const [tapajProgress, setTapajProgress] =
    useState<TapajProgress | null | undefined>(undefined);
  const [isFetched, setIsFetched] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);

    if (id === null) {
      setTapajProgress(null);
    } else {
      TapajProgressesApi.getById(id)
        .then((fetchedTapajProgress) => {
          setTapajProgress(fetchedTapajProgress);
          setIsFetched(true);
        })
        .catch((error) => {
          setTapajProgress(null);
          setIsFetched(false);
          defaultErrorHandler(error);
        })
        .finally(() => setIsFetching(false));
    }
  }, [id]);

  return { tapajProgress, isFetched, isFetching };
};
