import React, { Fragment, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { defaultErrorHandler } from 'api';
import { Segment } from 'semantic-ui-react';
import {
  InterventionForm,
  InterventionFormValues,
} from '../components/InterventionForm';
import { InterventionsApi } from '../api';
import { PageLayout } from 'components/Layout/PageLayout';
import { PageHeader } from 'components/Layout/PageHeader';
import { InterventionType } from '../types';
import { ApplicationLoader } from 'components/ApplicationLoader';

const m = defineMessages({
  pageTitle: {
    id: 'Interventions.NewPage.Title',
    defaultMessage: 'Nouvelle intervention',
  },
  savedSuccessfully: {
    id: 'Interventions.NewPage.SavedSuccessfully',
    defaultMessage: "L'intervention a été sauvegardé avec succès",
  },
});

export const NewInterventionPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [saving, setSaving] = useState(false);

  const emptyIntervention: InterventionFormValues = {
    name: '',
    interventionType: InterventionType.Boolean,
    isActive: true,
    problematicIds: [],
    interventionListValues: [],
  };

  const returnToInterventionsPage = () => {
    history.push(`/admin/interventions`);
  };

  const handleSubmit = (data: InterventionFormValues) => {
    setSaving(true);

    InterventionsApi.create(data)
      .then(() => {
        toast.success(formatMessage(m.savedSuccessfully));
        returnToInterventionsPage();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };

  return (
    <Fragment>
      <PageLayout pageTitle={formatMessage(m.pageTitle)}>
        <PageHeader
          header={formatMessage(m.pageTitle)}
          iconName="hand-holding-heart"
        />
        <Segment>
          <InterventionForm
            intervention={emptyIntervention}
            onSubmit={handleSubmit}
          />
        </Segment>
        {saving && <ApplicationLoader />}
      </PageLayout>
    </Fragment>
  );
};
