import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import reportWebVitals from './reportWebVitals';
import { configureStore } from 'store/store';
import { App } from './App';
import { ConnectedIntlProvider } from 'modules/i18n/ConnectedIntlProvider';
import 'semantic-ui-less/semantic.less';
import './index.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { InternalServerError } from 'modules/errors/view/InternalServerError';

export const history = createBrowserHistory();

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history },
    },
  },
});
appInsights.loadAppInsights();

const store = configureStore(history);
const queryClient = new QueryClient();

ReactDOM.render(
  React.createElement(
    withAITracking(reactPlugin, () => (
      <AppInsightsContext.Provider value={reactPlugin}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <ConnectedRouter history={history}>
              <ConnectedIntlProvider>
                <AppInsightsErrorBoundary
                  appInsights={reactPlugin}
                  onError={() => <InternalServerError />}
                >
                  <App />
                </AppInsightsErrorBoundary>
              </ConnectedIntlProvider>
            </ConnectedRouter>
          </QueryClientProvider>
        </Provider>
      </AppInsightsContext.Provider>
    ))
  ),
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
