import { defineMessages, useIntl } from 'react-intl';

const m = defineMessages({
  nameIsRequired: {
    id: 'Team.Validation.Name.IsRequired',
    defaultMessage: 'Le nom est requis',
  },
});

export const useTeamValidations = () => {
  const { formatMessage } = useIntl();

  const validations = {
    name: (value: string) => Boolean(value) || formatMessage(m.nameIsRequired),
  };

  return validations;
};
