import { useEffect, useState } from 'react';
import { EntrySummary } from '../types';
import { EntriesApi } from '../api';
import { defaultErrorHandler } from 'api';

interface Output {
  entries: EntrySummary[];
  isLoading: boolean;
}

export const useFetchEntries = (): Output => {
  const [entries, setEntries] = useState<EntrySummary[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getEntries();
  }, []);

  const getEntries = () => {
    EntriesApi.getAll()
      .then((fetchedEntries) => {
        setEntries(fetchedEntries);
      })
      .catch((error) => {
        setEntries([]);
        defaultErrorHandler(error);
      })
      .finally(() => setIsLoading(false));
  };

  return { entries, isLoading };
};
