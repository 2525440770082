import React from 'react'
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Button, ButtonProps } from 'semantic-ui-react';

const m = defineMessages({  
  home: {
    id: 'Errors.ErrorPage.Home',
    defaultMessage: 'Accueuil',
  },
});


export const HomeButton : React.FC<ButtonProps> = (props) => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const handleButtonClicked = () => history.push('/');

  return (
    <Button {...props} onClick={handleButtonClicked}>
      {formatMessage(m.home)}
    </Button>
  )
}
