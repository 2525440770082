import { useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import { AgeGroup } from '../types';
import { AgeGroupsApi } from '../api';
import { ApiError, defaultErrorHandler } from 'api';
import { useQuery } from 'react-query';

export interface AgeGroupLists {
  ageGroups: AgeGroup[];
  dropdownItems: DropdownItemProps[];
  indexedAgeGroups: Record<number, AgeGroup>;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchAgeGroups = (options?: {
  staleTime?: number;
}): AgeGroupLists => {
  const {
    data: ageGroups,
    isFetching,
    isFetched,
  } = useQuery('ageGroups', AgeGroupsApi.getAll, {
    staleTime: 15 * 60 * 1000,
    onSettled: (_, error) =>
      error && error instanceof ApiError && defaultErrorHandler(error),
    ...options,
  });

  const indexedAgeGroups = useMemo(
    () =>
      (ageGroups || []).reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<number, AgeGroup>),
    [ageGroups]
  );

  const dropdownItems = useMemo(() => {
    const activeAgeGroups = (ageGroups || []).filter(
      (ageGroup) => ageGroup.isActive
    );

    const sortedAgeGroups = orderBy(activeAgeGroups, (ageGroup) => ageGroup.id);

    const items = sortedAgeGroups.map((ageGroup) => ({
      key: ageGroup.id,
      value: ageGroup.id,
      text: ageGroup.name,
    }));

    return items;
  }, [ageGroups]);

  return {
    ageGroups: ageGroups || [],
    dropdownItems,
    indexedAgeGroups,
    isFetched,
    isFetching,
  };
};
