import { requests } from 'api';
import { TapajProgressFormValues } from './components/TapajProgressForm';
import { TapajProgress } from './types';

const apiBaseUrl = '/api/tapaj-progresses';

export const TapajProgressesApi = {
  getAll: (): Promise<TapajProgress[]> => requests.get(apiBaseUrl),
  getById: (id: number): Promise<TapajProgress> =>
    requests.get(`${apiBaseUrl}/${id}`),
  create: (newTapajProgress: TapajProgressFormValues): Promise<TapajProgress> =>
    requests.post(apiBaseUrl, newTapajProgress),
  update: (
    id: number,
    editedTapajProgress: TapajProgressFormValues
  ): Promise<any> => requests.patch(`${apiBaseUrl}/${id}`, editedTapajProgress),
};
