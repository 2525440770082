import React from 'react'
import { defineMessages, useIntl } from 'react-intl';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { SingleStatCard } from './SingleStatCard';

const m = defineMessages({
  entry: {
    id: 'Dashboard.View.Entry',
    defaultMessage: 'Entrée',
  },
  entries: {
    id: 'Dashboard.View.Entries',
    defaultMessage: 'Entrées',
  },
});

interface Props {
  entryCount: number;
}

export const EntriesStats : React.FC<Props> = ({entryCount}) => {
  const { formatMessage } = useIntl();

  return (
    <ApplicationSegment>
      <SingleStatCard
        value={entryCount}
        label={
          entryCount > 1
            ? formatMessage(m.entries)
            : formatMessage(m.entry)
        }
        icon="file alternate"
      />
    </ApplicationSegment>
  )
}
