import React, { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { Form, Grid, Header } from 'semantic-ui-react';
import { EntryQuery } from 'modules/entry/utils/entryQuery';
import { MobileFriendlyFormDropdown } from 'components/MobileFriendlyDropdown';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { EntryQueryContext } from 'modules/entry/components/EntryQueryContext';
import { LogicalOperatorDropdown } from './LogicalOperatorDropdown';

const m = defineMessages({
  demographies: {
    id: 'EntryQueryForm.Demographies',
    defaultMessage: 'Démographies',
  },
  genders: {
    id: 'EntryQueryForm.Genders',
    defaultMessage: 'Sexes',
  },
  personCount: {
    id: 'EntryQueryForm.PersonCount',
    defaultMessage: 'Nombre de personnes',
  },
  personCountTo: {
    id: 'EntryQueryForm.PersonCountTo',
    defaultMessage: 'à',
  },
  allGenders: {
    id: 'EntryQueryForm.AllGenders',
    defaultMessage: 'Tous les sexes',
  },
  ageGroups: {
    id: 'EntryQueryForm.AgeGroups',
    defaultMessage: "Groupes d'âge",
  },
  allAgeGroups: {
    id: 'EntryQueryForm.AllAgeGroups',
    defaultMessage: "Tous les groupes d'âge",
  },
  occupations: {
    id: 'EntryQueryForm.Occupations',
    defaultMessage: 'Occupations/Revenus',
  },
  allOccupations: {
    id: 'EntryQueryForm.AllOccupations',
    defaultMessage: 'Tous les occupations/revenus',
  },
  origins: {
    id: 'EntryQueryForm.Origins',
    defaultMessage: 'Origines',
  },
  allOrigins: {
    id: 'EntryQueryForm.AllOrigins',
    defaultMessage: 'Toutes les origines',
  },
});

interface Props {
  isMobile: boolean;
  onChange: () => void;
}

export const DemographiesSection: React.FC<Props> = ({
  isMobile,
  onChange,
}) => {
  const { formatMessage } = useIntl();
  const { lists } = useContext(EntryQueryContext);

  const { control } = useFormContext<EntryQuery>();

  const handleChange = () => onChange();

  const persontCountField = (
    <Form.Field>
      <p className="tw-text-purple tw-font-bold">
        <Controller
          control={control}
          name="demographies.minPersonCount"
          render={({ field: { onBlur, value, onChange } }) => (
            <>
              <label>{formatMessage(m.personCount)}</label>
              <input
                className="tw-inline-block tw-w-24 tw-align-baseline tw-mx-4"
                type="number"
                placeholder="Min"
                min="0"
                value={value ?? ''}
                onChange={(event) => {
                  onChange(event.target.value);
                  handleChange();
                }}
                onBlur={onBlur}
              />
            </>
          )}
        />
        {formatMessage(m.personCountTo)}
        <Controller
          control={control}
          name="demographies.maxPersonCount"
          render={({ field: { onBlur, value, onChange } }) => (
            <input
              className="tw-inline-block tw-w-24 tw-align-baseline tw-mx-4"
              type="number"
              placeholder="Max"
              min="0"
              value={value ?? ''}
              onChange={(event) => {
                onChange(event.target.value);
                handleChange();
              }}
              onBlur={onBlur}
            />
          )}
        />
      </p>
    </Form.Field>
  );

  const gendersField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.genders)}</label>
      <Controller
        control={control}
        name="demographies.genders"
        render={({ field: { onBlur, value, onChange } }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.genders.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allGenders)}
            isMobile={isMobile}
          />
        )}
      />
    </Form.Field>
  );

  const ageGroupsField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.ageGroups)}</label>
      <Controller
        control={control}
        name="demographies.ageGroups"
        render={({ field: { onBlur, value, onChange } }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.ageGroups.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allAgeGroups)}
            isMobile={isMobile}
          />
        )}
      />
    </Form.Field>
  );

  const occupationsField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.occupations)}</label>
      <Controller
        control={control}
        name="demographies.occupations"
        render={({ field: { onBlur, value, onChange } }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.occupations.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allOccupations)}
            isMobile={isMobile}
          />
        )}
      />
    </Form.Field>
  );

  const originsField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.origins)}</label>
      <Controller
        control={control}
        name="demographies.origins"
        render={({ field: { onBlur, value, onChange } }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.origins.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allOrigins)}
            isMobile={isMobile}
          />
        )}
      />
      <Controller
        control={control}
        name="demographies.originsLogicalOperator"
        render={({ field: { value, onChange } }) => (
          <LogicalOperatorDropdown
            value={value ?? ''}
            onChange={(newValue) => {
              onChange(newValue);
              handleChange();
            }}
          />
        )}
      />
    </Form.Field>
  );

  return (
    <ApplicationSegment>
      <Header className="tw-flex-1" color="purple">
        {formatMessage(m.demographies)}
      </Header>
      <div className="tw-p-4">
        {persontCountField}
        <Grid columns="2" doubling>
          <Grid.Column>{gendersField}</Grid.Column>
          <Grid.Column>{ageGroupsField}</Grid.Column>
          <Grid.Column>{occupationsField}</Grid.Column>
          <Grid.Column>{originsField}</Grid.Column>
        </Grid>
      </div>
    </ApplicationSegment>
  );
};
