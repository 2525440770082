import { useEffect, useState } from 'react';
import { defaultErrorHandler } from 'api';
import { MealsApi } from '../api';
import { Meal } from '../types';

interface FetchedMeal {
  meal: Meal | null | undefined;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchMeal = (id: number | null): FetchedMeal => {
  const [meal, setMeal] =
    useState<Meal | null | undefined>(undefined);
  const [isFetched, setIsFetched] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);

    if (id === null) {
      setMeal(null);
    } else {
      MealsApi.getById(id)
        .then((fetchedMeal) => {
          setMeal(fetchedMeal);
          setIsFetched(true);
        })
        .catch((error) => {
          setMeal(null);
          setIsFetched(false);
          defaultErrorHandler(error);
        })
        .finally(() => setIsFetching(false));
    }
  }, [id]);

  return { meal, isFetched, isFetching };
};
