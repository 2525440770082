import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { Button, Form, Grid, Input } from 'semantic-ui-react';
import { useFetchTeams } from 'modules/team/hooks/useFetchTeams';
import { MobileFriendlyFormDropdown } from 'components/MobileFriendlyDropdown';
import { useResponsive } from 'hooks/useResponsive';
import moment from 'moment';
import { useMealValidations } from 'modules/meal/hooks/useMealValidations';
import { ApplicationLoader } from 'components/ApplicationLoader';
import DatePicker from 'react-datepicker';

const m = defineMessages({
  team: { id: 'Meal.Form.Team', defaultMessage: 'Équipe' },
  teamPlaceholder: {
    id: 'Meal.Form.Team.Placeholder',
    defaultMessage: 'Veuillez choisir une équipe',
  },
  date: {
    id: 'Meal.Form.Date',
    defaultMessage: 'Date',
  },
  count: {
    id: 'Meal.Form.Count',
    defaultMessage: 'Quantité',
  },
  meals: {
    id: 'Meal.Form.Meals',
    defaultMessage: 'Repas',
  },
  create: { id: 'Meal.Form.Create', defaultMessage: 'Créer' },
  save: { id: 'Meal.Form.Save', defaultMessage: 'Sauvegarder' },
  delete: { id: 'Meal.Form.Delete', defaultMessage: 'Supprimer' },
});

export interface MealFormValues {
  id?: number;
  teamId: number | null;
  date: string;
  count: number;
}

interface Props {
  meal: MealFormValues;
  onSubmit: (data: MealFormValues) => void;
  onDelete?: () => void;
}

export const MealForm: React.FC<Props> = ({ meal, onSubmit, onDelete }) => {
  const { formatMessage } = useIntl();

  const { isMobile } = useResponsive();
  const { dropdownItems: teamsDropdownItems, isFetched: areTeamsFetched } =
    useFetchTeams();

  const { handleSubmit, control, reset, formState: { errors } } = useForm<MealFormValues>({
    defaultValues: meal,
  });

  const validations = useMealValidations();

  useEffect(() => {
    reset(meal);
  }, [meal, reset]);

  const isNew = !meal.id;

  const handleOnSubmit = (data: MealFormValues) => {
    onSubmit(data);
  };

  const handleDelete = () => {
    onDelete && onDelete();
  };

  const ErrorMessage: React.FC = ({ children }) => {
    return <div className="tw-text-red tw-mb-4">{children}</div>;
  };

  const isLoaded = areTeamsFetched;

  if (!isLoaded) {
    return <ApplicationLoader />;
  }

  return (
    <ApplicationSegment>
      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid columns={2} doubling>
          <Grid.Column>
            <Form.Field error={Boolean(errors.teamId)}>
              <label className="tw-text-purple">{formatMessage(m.team)}</label>
              <Controller
                control={control}
                name="teamId"
                rules={{ validate: validations.teamId }}
                render={({ field: { onBlur, value, onChange} }) => (
                  <MobileFriendlyFormDropdown
                    onChange={(e, data) => onChange(data.value)}
                    onBlur={onBlur}
                    value={value ?? ''}
                    selection
                    fluid
                    search
                    options={teamsDropdownItems}
                    placeholder={formatMessage(m.teamPlaceholder)}
                    isMobile={isMobile}
                  />
                )}
              />
              {errors.teamId && (
                <ErrorMessage>{errors.teamId.message}</ErrorMessage>
              )}
            </Form.Field>
          </Grid.Column>

          <Grid.Column>
            <Form.Field>
              <label>{formatMessage(m.date)}</label>
              <Controller
                control={control}
                name="date"
                rules={{ validate: validations.date }}
                render={({ field: { onBlur, value, onChange} }) => (
                  <DatePicker
                    selected={
                      value ? moment((value as string).substring(0, 10)).toDate() : null
                    }
                    onChange={(newValue) => {
                      if (newValue) {
                        onChange(moment(newValue as Date).format('YYYY-MM-DD'));
                      }
                    }}
                    onBlur={onBlur}
                    className="tw-w-60"
                  />
                )}
              />
              {errors.date && (
                <ErrorMessage>{errors.date.message}</ErrorMessage>
              )}
            </Form.Field>
          </Grid.Column>

          <Grid.Column>
            <Form.Field>
              <label>{formatMessage(m.count)}</label>
              <Controller
                control={control}
                name="count"
                rules={{ validate: validations.count }}
                render={({ field: { onBlur, value, onChange} }) => (
                  <Input
                    value={value}
                    onChange={(event, data) => onChange(data.value)}
                    onBlur={onBlur}
                    type="number"
                    min="1"
                    label={formatMessage(m.meals)}
                    labelPosition="right"
                    className="tw-w-24"
                  />
                )}
              />
              {errors.count && (
                <ErrorMessage>{errors.count.message}</ErrorMessage>
              )}
            </Form.Field>
          </Grid.Column>
        </Grid>

        <div className="tw-flex tw-pt-8 tw-mt-8 tw-border-t tw-border-gray-300">
          <div className="tw-mr-auto">
            {onDelete && (
              <Button negative type="button" onClick={handleDelete}>
                {formatMessage(m.delete)}
              </Button>
            )}
          </div>
          <div className="tw-ml-auto">
            <Button primary type="submit">
              {isNew ? formatMessage(m.create) : formatMessage(m.save)}
            </Button>
          </div>
        </div>
      </Form>
    </ApplicationSegment>
  );
};
