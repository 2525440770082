import { history } from 'index';

type DebugDetails = {
  stackTrace: string;
};

export class ApiError extends Error {
  public readonly statusCode: number;
  public readonly debugMessage?: string;
  public readonly errorCode?: string;  
  constructor(
    statusCode: number,
    message?: string | undefined,
    debugMessage?: string,
    debugDetails?: DebugDetails,
    errorCode?: string,
  ) {
    super(message);
    this.statusCode = statusCode;
    this.debugMessage = debugMessage;
    this.stack = debugDetails?.stackTrace;
    this.errorCode = errorCode;
  }
}

export const defaultErrorHandler = (
  error: unknown
): void => {
  if (error && error instanceof ApiError) {
    if (error.errorCode === 'ERR_CANCELED') return;
  
    switch (error.statusCode) {
      case 401:
        history.push("/error/401");
        break;
      case 403:
        history.push("/error/403");
        break;
      case 404:
        history.push("/error/404");
        break;
      default:
        history.push("/error/500", error);
    }
  }
};