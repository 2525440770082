import { requests } from 'api';
import { ProblematicFormValues } from './components/ProblematicForm';
import { ProblematicSummary, Problematic } from './types';

const apiBaseUrl = '/api/problematics';

export const ProblematicsApi = {
  getAll: (): Promise<ProblematicSummary[]> => requests.get(apiBaseUrl),
  getById: (id: number): Promise<Problematic> =>
    requests.get(`${apiBaseUrl}/${id}`),
  create: (newProblematic: ProblematicFormValues): Promise<Problematic> =>
    requests.post(apiBaseUrl, newProblematic),
  update: (
    id: number,
    editedProblematic: ProblematicFormValues
  ): Promise<any> => requests.patch(`${apiBaseUrl}/${id}`, editedProblematic),
};
