import { ApplicationSegment } from 'components/ApplicationSegment';
import React, { Fragment } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Header } from 'semantic-ui-react';
import { Field } from './Field';
import { EntryViewData } from './useEntryViewData';

const m = defineMessages({
  interventions: {
    id: 'Entry.View.Interventions',
    defaultMessage: 'Interventions',
  },
  interventionDuration: {
    id: 'Entry.View.Interventions.Duration',
    defaultMessage: 'Durée (minutes)',
  },
  problematics: {
    id: 'Entry.View.Problematics',
    defaultMessage: 'Préoccupations et problématiques',
  },
  homelessness: { id: 'Entry.View.Homelessness', defaultMessage: 'Itinérance' },
  homelessnessSituation: {
    id: 'Entry.View.Homelessness.Situation',
    defaultMessage: 'Situation',
  },
  homelessnessInterventions: {
    id: 'Entry.View.Homelessness.Interventions',
    defaultMessage: 'Interventions',
  },
  tapaj: { id: 'Entry.View.TAPAJ', defaultMessage: 'TAPAJ' },
});

interface Props {
  viewData: EntryViewData;
}

export const InterventionsSection: React.FC<Props> = ({ viewData }) => {
  const { formatMessage } = useIntl();
  const {
    hasIntervention,
    interventions,
    problematics,
    homelessnesses,
    interventionDurationInMinute,
    homelessnessSituation,
    hasHomelessness,
    hasTapajProgress,
    tapajProgresses,
  } = viewData;

  if (!hasIntervention) {
    return null;
  }

  return (
    <ApplicationSegment>
      <div className="tw-p-2">
        <Header color="purple" className="tw-mb-4">
          {formatMessage(m.interventions)}
        </Header>
        <div className="tw-pl-8">
          <Field
            label={formatMessage(m.interventionDuration)}
            value={interventionDurationInMinute}
          />
          {(interventions ?? []).map((intervention) => (
            <Field
              key={intervention.name}
              label={intervention.name}
              value={intervention.value}
            />
          ))}
        </div>

        {problematics && (
          <Fragment>
            <Header color="purple" className="tw-mb-4">
              {formatMessage(m.problematics)}
            </Header>
            <div className="tw-pl-8">{problematics}</div>
          </Fragment>
        )}

        {hasHomelessness && (
          <Fragment>
            <Header color="purple" className="tw-mb-4">
              {formatMessage(m.homelessness)}
            </Header>
            <div className="tw-pl-8">
              <Field
                label={formatMessage(m.homelessnessSituation)}
                value={homelessnessSituation}
              />
              <div className="tw-flex tw-flex-wrap tw-items-start tw-my-2">
                <div className="tw-w-44 tw-font-bold tw-flex-shrink-0 tw-text-purple">
                  {formatMessage(m.homelessnessInterventions)}:
                </div>
                <div>
                  {(homelessnesses ?? []).map((homelessness, key) => (
                    <div key={key} className="tw-mb-2">
                      {homelessness}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Fragment>
        )}

        {hasTapajProgress && (
          <Fragment>
            <Header color="purple" className="tw-mb-4">
              {formatMessage(m.tapaj)}
            </Header>
            <div className="tw-pl-8">
              {(tapajProgresses ?? []).map((progress, key) => (
                <div key={key} className="tw-my-1">
                  {progress}
                </div>
              ))}
            </div>
          </Fragment>
        )}
      </div>
    </ApplicationSegment>
  );
};
