import React from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';

interface Props {
  value: any;
  label: string;
  icon: SemanticICONS;
}

export const SingleStatCard: React.FC<Props> = ({value, label, icon}) => {
  return (
    <div className="tw-flex tw-text-purple">
      <div className="tw-w-1/2 tw-flex tw-justify-center tw-items-center tw-text-center">
        <div>
          <div className="tw-text-4xl tw-text-green">{value}</div>
          <div>{label}</div>
        </div>
      </div>
      <div className="tw-w-1/2 tw-flex tw-justify-center tw-items-center">
        <Icon name={icon} size="big" />
      </div>
    </div>
  );
};
