import { useEffect, useState } from 'react';
import { defaultErrorHandler } from 'api';
import { ProblematicsApi } from '../api';
import { Problematic } from '../types';

interface FetchedProblematic {
  problematic: Problematic | null | undefined;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchProblematic = (id: number | null): FetchedProblematic => {
  const [problematic, setProblematic] =
    useState<Problematic | null | undefined>(undefined);
  const [isFetched, setIsFetched] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);

    if (id === null) {
      setProblematic(null);
    } else {
      ProblematicsApi.getById(id)
        .then((fetchedProblematic) => {
          setProblematic(fetchedProblematic);
          setIsFetched(true);
        })
        .catch((error) => {
          setProblematic(null);
          setIsFetched(false);
          defaultErrorHandler(error);
        })
        .finally(() => setIsFetching(false));
    }
  }, [id]);

  return { problematic, isFetched, isFetching };
};
