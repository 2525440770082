import { EntryLists } from 'modules/entry/hooks/useEntryLists';
import { InterventionType } from 'modules/intervention/types';
import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import {
  EntryFormContext,
  EntryFormContextType,
  EntryFormValues,
  EntryInterventionFormValues,
} from './EntryForm';
import { InterventionTextDropdown } from '../InterventionTextDropdown';
import { InterventionListValueDropdown } from './InterventionListValueDropdown';

const mainFieldName = 'interventions';

interface Props {
  index: number;
  entryIntervention: EntryInterventionFormValues;
}

export const InterventionDetailForm: React.FC<Props> = ({
  index,
  entryIntervention: entryIntervention,
}) => {
  const { lists } = useContext(EntryFormContext) as EntryFormContextType;
  const {
    interventions: { indexedInterventions: interventions },
  } = lists as EntryLists;

  const { control } = useFormContext<EntryFormValues>();

  const intervention =
    interventions[entryIntervention.interventionId as number];

  if (!intervention) {
    return null;
  }

  const { name, interventionType } = intervention;

  if (interventionType === InterventionType.Boolean) {
    return null;
  }

  return (
    <Form.Field key={entryIntervention.interventionId}>
      <label>{name}</label>

      <Controller
        control={control}
        name={`${mainFieldName}.${index}.interventionId`}
        defaultValue={entryIntervention.interventionId}
        render={({ field: { value } }) => (
          <input value={value ?? ''} type="hidden" />
        )}
      />

      {interventionType === InterventionType.Number && (
        <Controller
          control={control}
          name={`${mainFieldName}.${index}.numberValue`}
          defaultValue={entryIntervention.numberValue}
          render={({ field: { onBlur, value, onChange } }) => (
            <input
              value={value ?? ''}
              onChange={onChange}
              onBlur={onBlur}
              type="number"
              style={{ maxWidth: '7em' }}
            />
          )}
        />
      )}

      {interventionType === InterventionType.Text && (
        <Controller
          control={control}
          name={`${mainFieldName}.${index}.textValue`}
          defaultValue={entryIntervention.textValue}
          render={({ field: { value, onChange } }) => (
            <InterventionTextDropdown
              interventionId={entryIntervention.interventionId as number}
              onChange={onChange}
              value={value}
            />
          )}
        />
      )}

      {interventionType === InterventionType.List &&
        entryIntervention.interventionId && (
          <Controller
            control={control}
            name={`${mainFieldName}.${index}.textValue`}
            render={({ field: { value, onChange } }) => (
              <InterventionListValueDropdown
                interventionId={entryIntervention.interventionId as number}
                values={value as string[]}
                onChange={onChange}
              />
            )}
          />
        )}
    </Form.Field>
  );
};
