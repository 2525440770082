import React from 'react'
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Button, ButtonProps } from 'semantic-ui-react';

const m = defineMessages({  
  back: {
    id: 'Errors.ErrorPage.Back',
    defaultMessage: 'Retour',
  },
});


export const BackButton : React.FC<ButtonProps> = (props) => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const handleButtonClicked = () => history.goBack();

  return (
    <Button {...props} onClick={handleButtonClicked}>
      {formatMessage(m.back)}
    </Button>
  )
}
