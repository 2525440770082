import { useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';
import { TeamSummary } from '../types';
import { TeamsApi } from '../api';
import { ApiError, defaultErrorHandler } from 'api';
import { useQuery } from 'react-query';

export interface TeamLists {
  teams: TeamSummary[];
  dropdownItems: DropdownItemProps[];
  indexedTeams: Record<number, TeamSummary>;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchTeams = (options?: { staleTime?: number }): TeamLists => {
  const {
    data: teams,
    isFetching,
    isFetched,
  } = useQuery('teams', TeamsApi.getAll, {
    staleTime: 15 * 60 * 1000,
    onSettled: (_, error) =>
      error && error instanceof ApiError && defaultErrorHandler(error),
    ...options,
  });

  const indexedTeams = useMemo(
    () =>
      (teams || []).reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<number, TeamSummary>),
    [teams]
  );

  const dropdownItems = useMemo(() => {
    const activeTeams = (teams || []).filter((team) => team.isActive);

    const items = activeTeams.map((team) => ({
      key: team.id,
      value: team.id,
      text: team.name,
    }));

    const sortedItems = orderBy(items, (item) =>
      deburr(item.text).toLowerCase()
    );

    return sortedItems;
  }, [teams]);

  return {
    teams: teams || [],
    dropdownItems,
    indexedTeams,
    isFetched,
    isFetching,
  };
};
