import React from 'react';
import { Icon, IconProps } from 'semantic-ui-react';
import { ReactComponent as HandHoldingHeart } from './icons/hand-holding-heart-solid.svg';
import { ReactComponent as ShoePrints } from './icons/shoe-prints-solid.svg';

const extendedIcons: Record<string, React.FC> = {
  'hand-holding-heart': HandHoldingHeart,
  'shoe-prints': ShoePrints,
};

export const AppIcon: React.FC<IconProps> = ({ ...iconProps }) => {
  const { name, size } = iconProps;

  const isExtendedIcon = (name: string) =>
    Object.keys(extendedIcons).includes(name);

  if (name && isExtendedIcon(name)) {
    const ExtendedIcon = extendedIcons[name];
    return <i className={`icon ${size}`}><ExtendedIcon /></i>;
  } else {
    return <Icon {...iconProps} />;
  }
};
