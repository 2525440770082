import { requests } from 'api';
import { Entry } from 'modules/entry/types';

const apiBaseUrl = '/api/dashboard';

export interface DashboardSearchParams {
  fromDate: string;
  toDate: string;
}

export const DashboardApi = {
  get: (searchParams: DashboardSearchParams): Promise<Entry[]> =>
    requests.getWithParams(`${apiBaseUrl}`, searchParams),
};
