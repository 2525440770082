import React, { Fragment } from 'react';
import { useHistory, useParams } from 'react-router';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { PageHeader } from 'components/Layout/PageHeader';
import { PageLayout } from 'components/Layout/PageLayout';
import { useResponsive } from 'hooks/useResponsive';
import { EntryView } from '../components/EntryView/EntryView';
import { useFetchEntry } from '../hooks/useFetchEntry';
import { ApplicationLoader } from 'components/ApplicationLoader';

const m = defineMessages({
  pageTitle: { id: 'Entry.Page.Title', defaultMessage: 'Entrée' },
});

interface Params {
  entryId: string;
}

export const EntryPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const params = useParams<Params>();
  const entry = useFetchEntry(parseInt(params.entryId, 10));
  const { isMobile } = useResponsive();

  if (entry === null) {
    history.push('/entries');
  }

  return (
    <Fragment>
      <PageLayout pageTitle={formatMessage(m.pageTitle)}>
        {entry ? (
          <>
            <div className="tw-flex tw-items-center tw-justify-between">
              <PageHeader
                header={formatMessage(m.pageTitle)}
                iconName="file alternate"
              />
              <div className="tw-flex-1" />

              <div className="tw-mb-6">
                <Button
                  color="green"
                  inverted={!isMobile}
                  as={Link}
                  to={`/entries/${entry.id}/edit`}
                >
                  Modifier
                </Button>
              </div>
            </div>
            <div className="tw-mb-6">
              <EntryView entry={entry} />
            </div>
          </>
        ) : (
          <ApplicationLoader />
        )}
      </PageLayout>
    </Fragment>
  );
};
