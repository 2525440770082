import React from 'react'
import { AppIcon } from 'components/Layout/AppIcon'
import { Header, SemanticICONS } from 'semantic-ui-react'

interface Props {
  header: string;
  iconName: SemanticICONS;
}

export const SegmentHeader : React.FC<Props> = ({header, iconName}) => {
  return (
    <Header size="tiny" color="purple" className="tw-m-0 tw-mb-5">
      <AppIcon name={iconName as SemanticICONS} size="tiny" />
      <Header.Content className="tw-align-middle tw-pl-3">
        {header}
      </Header.Content>
    </Header>
  )
}
