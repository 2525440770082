import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Checkbox, Form } from 'semantic-ui-react';
import { EntryFormValues } from './EntryForm';
import { InterventionsForm } from './InterventionsForm';
import { ProblematicsForm } from './ProblematicsForm';
import { HomelessnessesForm } from './HomelessnessesForm';
import { ApplicationSegment } from 'components/ApplicationSegment';

const m = defineMessages({
  hasIntervention: {
    id: 'Entry.Form.Interventions.HasIntervention',
    defaultMessage: 'Y a-t-il eu une intervention ? ',
  },
  interventions: {
    id: 'Entry.Form.Interventions.Interventions',
    defaultMessage: 'Interventions',
  },
  yes: {
    id: 'Yes',
    defaultMessage: 'Oui',
  },
  no: {
    id: 'No',
    defaultMessage: 'Non',
  },
});

interface Props {
  isMobile: boolean;
}

export const InterventionsSection: React.FC<Props> = ({ isMobile }) => {
  const { formatMessage } = useIntl();

  const { control } = useFormContext<EntryFormValues>();

  const hasIntervention = useWatch({
    control,
    name: 'hasIntervention',
    defaultValue: true,
  });

  const interventionsVisibilityClass = hasIntervention ? '' : 'tw-hidden';
  const problematicsVisibilityClass = hasIntervention ? '' : 'tw-hidden';
  const homelessnessVisibilityClass = hasIntervention ? '' : 'tw-hidden';

  return (
    <>
      <ApplicationSegment>
        <Form.Field>
          <label>{formatMessage(m.hasIntervention)}</label>
          <Controller
            control={control}
            name="hasIntervention"
            render={({ field: { onBlur, value, onChange} }) => (
              <div className="tw-flex tw-items-center">
                <Checkbox
                  onChange={(e, data) => onChange(data.checked)}
                  onBlur={onBlur}
                  checked={Boolean(value)}
                  toggle
                />
                <div className="tw-ml-6">
                  {value ? formatMessage(m.yes) : formatMessage(m.no)}
                </div>
              </div>
            )}
          />
        </Form.Field>

        <div className={interventionsVisibilityClass}>
          <InterventionsForm isMobile={isMobile} />
        </div>
      </ApplicationSegment>

      <ApplicationSegment className={problematicsVisibilityClass}>
        <ProblematicsForm isMobile={isMobile} />
      </ApplicationSegment>

      <ApplicationSegment className={homelessnessVisibilityClass}>
        <HomelessnessesForm isMobile={isMobile} />
      </ApplicationSegment>
    </>
  );
};
