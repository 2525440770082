import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const m = defineMessages({
  dateRange: { id: 'Meals.Page.DateRange', defaultMessage: 'Période' },
});

interface Props {
  fromDate: moment.Moment | null;
  toDate: moment.Moment | null;
  isFetchingMeals: boolean;
  onChange: (
    fromDate: moment.Moment | null,
    toDate: moment.Moment | null
  ) => void;
}

export const DateRangeSelector: React.FC<Props> = ({
  fromDate,
  toDate,
  isFetchingMeals,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  const handleDateRangeChange = (dateRange: any) => {
    const [startDate, endDate] = dateRange as [Date | null, Date | null];

    onChange(
      startDate && moment.utc(moment(startDate).format('YYYY-MM-DD')),
      endDate && moment.utc(moment(endDate).format('YYYY-MM-DD'))
    );
  };

  const dateRangeField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.dateRange)}</label>
      <DatePicker
        selectsRange={true}
        startDate={
          (fromDate as moment.Moment | null) &&
          moment(fromDate!.format('YYYY-MM-DD')).toDate()
        }
        endDate={
          (toDate as moment.Moment | null) &&
          moment(toDate!.format('YYYY-MM-DD')).toDate()
        }
        onChange={handleDateRangeChange}
        withPortal
        className="tw-w-80"
        fixedHeight
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        disabled={isFetchingMeals}
      />
    </Form.Field>
  );

  return (
    <Form>
      <Form.Group>{dateRangeField}</Form.Group>
    </Form>
  );
};
