import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ApplicationSegment } from 'components/ApplicationSegment';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import { PageHeader } from 'components/Layout/PageHeader';
import { PageLayout } from 'components/Layout/PageLayout';
import { DateRangeSelector } from 'modules/meal/components/DateRangeSelector';
import { Meal } from '../types';
import { MealsApi } from '../api';
import { defaultErrorHandler } from 'api';
import { MealsTable } from 'modules/meal/components/MealsTable';
import { useFetchTeams } from 'modules/team/hooks/useFetchTeams';
import {
  MealCountByTeamStat,
  MealCountByTeam,
} from '../components/MealCountByTeam';
import { useResponsive } from 'hooks/useResponsive';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { TeamSummary } from 'modules/team';
import { Dimmer, Loader } from 'semantic-ui-react';

const startOfMonth = moment.utc().startOf('month');
const endOfMonth = moment.utc().endOf('month');

const m = defineMessages({
  pageTitle: {
    id: 'Meals.Page.Title',
    defaultMessage: 'Repas',
  },
});

export const MealsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();
  const { indexedTeams: teams, isFetched: areTeamsFetched } = useFetchTeams();

  const [fromDate, setFromDate] = useState<moment.Moment | null>(startOfMonth);
  const [toDate, setToDate] = useState<moment.Moment | null>(endOfMonth);
  const [meals, setMeals] = useState<Meal[]>([]);
  const [mealCountByTeam, setMealCountByTeam] =
    useState<MealCountByTeamStat[]>();
  const [areMealFetched, setAreMealFetched] = useState(false);
  const [isFetchingMeals, setIsFetchingMeals] = useState(false);

  const handleDateChange = (fromDate: moment.Moment | null, toDate: moment.Moment | null) => {
    setFromDate(fromDate);
    setToDate(toDate);
  };

  const fetchMeals = (
    fromDate: moment.Moment,
    toDate: moment.Moment,
    teams: Record<number, TeamSummary>
  ) => {
    setIsFetchingMeals(true);
    MealsApi.getByDateRange(fromDate.toISOString(), toDate.toISOString())
      .then((fetchedMeals) => {
        setMeals(fetchedMeals);

        const getMealCountByTeam = (): MealCountByTeamStat[] => {
          const mealsByTeamName = groupBy(
            fetchedMeals,
            (meal) => teams[meal.teamId].name
          );

          return Object.entries(mealsByTeamName).map(([teamName, meals]) => ({
            teamName: teamName,
            mealCount: sumBy(meals, (meal) => meal.count),
          }));
        };

        setMealCountByTeam(getMealCountByTeam());
      })
      .catch((error) => {
        defaultErrorHandler(error);
      })
      .finally(() => {
        setIsFetchingMeals(false);
        setAreMealFetched(true)
      });
  };

  useEffect(() => {
    if (!areTeamsFetched) return;
    if  (fromDate && toDate) {
      fetchMeals(fromDate, toDate, teams);
    } else {
      setMeals([]);
      setMealCountByTeam([]);
    }
  }, [fromDate, toDate, areTeamsFetched, teams]);

  const isLoaded = areTeamsFetched && areMealFetched;
  const statsVisibilityClass = isFetchingMeals ? 'tw-invisible' : 'tw-visible';

  const dates = (
    <ApplicationSegment>
      <DateRangeSelector
        fromDate={fromDate}
        toDate={toDate}
        isFetchingMeals={isFetchingMeals}
        onChange={handleDateChange}
      />
    </ApplicationSegment>
  );

  const mealsTable = (
    <ApplicationSegment>
      <MealsTable meals={meals} teams={teams} />
    </ApplicationSegment>
  );

  const mealsStat = (
    <ApplicationSegment>
      <Dimmer active={isFetchingMeals} inverted>
        <Loader />
      </Dimmer>
      <div className={statsVisibilityClass}>
        <MealCountByTeam mealCountByTeam={mealCountByTeam} />
      </div>
    </ApplicationSegment>
  );

  const desktopView = (
    <>
      {dates}
      <div className="tw-flex tw-flex-wrap" style={{ margin: '-0.5rem' }}>
        <div
          className="tw-mb-4 tw-p-2"
          style={{ flexBasis: '30rem', flexGrow: 999 }}
        >
          {mealsTable}
        </div>
        <div className="tw-p-2" style={{ flexBasis: '25rem', flexGrow: 1 }}>          
          {mealsStat}
        </div>
      </div>
    </>
  );

  const mobileView = (
    <>
      {dates}
      {mealsTable}
      {mealsStat}
    </>
  );

  const mealsView = isMobile ? mobileView : desktopView;

  return (
    <PageLayout pageTitle={formatMessage(m.pageTitle)}>
      <PageHeader header={formatMessage(m.pageTitle)} iconName="food" />
      {isLoaded ? mealsView : <ApplicationLoader />}
    </PageLayout>
  );
};
