import React from 'react';
import { PageLayout } from 'components/Layout/PageLayout';
import { PageHeader } from 'components/Layout/PageHeader';
import { defineMessages, useIntl } from 'react-intl';
import { useFetchTapajProgresses } from '../hooks/useFetchTapajProgresses';
import { TapajProgressesTable } from '../components/TapajProgressesTable';
import { ApplicationLoader } from 'components/ApplicationLoader';

const m = defineMessages({
  pageTitle: {
    id: 'TapajProgresses.Page.Title',
    defaultMessage: 'Progrès Tapaj et Capab',
  },
});

export const TapajProgressesPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const { tapajProgresses, isFetched: areTapajProgressesFetched } =
    useFetchTapajProgresses({ staleTime: 0 });

  const isLoaded = areTapajProgressesFetched;

  return (
    <PageLayout pageTitle={formatMessage(m.pageTitle)}>
      <PageHeader
        header={formatMessage(m.pageTitle)}
        iconName="clipboard check"
      />

      {isLoaded ? (
        <TapajProgressesTable tapajProgresses={tapajProgresses} />
      ) : (
        <ApplicationLoader />
      )}
    </PageLayout>
  );
};
