import { defineMessages, useIntl } from 'react-intl';

const m = defineMessages({
  roleIsRequired: {
    id: 'User.Validation.Role.IsRequired',
    defaultMessage: 'Le rôle est requis',
  },
  teamIsRequired: {
    id: 'User.Validation.Team.IsRequired',
    defaultMessage: "L'équipe est requise",
  },
});

export const useUserValidations = () => {
  const { formatMessage } = useIntl();

  const validations = {
    roleSetName: (value: string) => Boolean(value) || formatMessage(m.roleIsRequired),
    teamId: (value: number | null) => Boolean(value) || formatMessage(m.teamIsRequired),
  };

  return validations;
};
