import React from 'react';
import { FormattedDate } from 'react-intl';
import { useHistory } from 'react-router';
import { Table } from 'semantic-ui-react';
import { EntriesTableRow } from './EntriesTable';

interface Props {
  row: EntriesTableRow;
  visibleColumns?: (keyof EntriesTableRow)[];
}

export const Row: React.FC<Props> = ({ row, visibleColumns }) => {
  const history = useHistory();

  const handleRowClick = (id: number) => {
    history.push(`/entries/${id}`);
  };

  return (
    <Table.Row
      key={row.id}
      onClick={() => handleRowClick(row.id)}
      className="tw-cursor-pointer"
    >
      {(!visibleColumns || visibleColumns.includes('id')) && (
        <Table.Cell>{row.id}</Table.Cell>
      )}

      {(!visibleColumns || visibleColumns.includes('date')) && (
        <Table.Cell>
          <FormattedDate
            value={row.date}
            day="numeric"
            month="long"
            year="numeric"
            timeZone="utc"
          />
        </Table.Cell>
      )}

      {(!visibleColumns ||
        visibleColumns.includes('createdByUserFullName')) && (
        <Table.Cell>{row.createdByUserFullName}</Table.Cell>
      )}

      {(!visibleColumns || visibleColumns.includes('teamName')) && (
        <Table.Cell>{row.teamName}</Table.Cell>
      )}

      {(!visibleColumns || visibleColumns.includes('meetingTypeName')) && (
        <Table.Cell>{row.meetingTypeName}</Table.Cell>
      )}

      {(!visibleColumns || visibleColumns.includes('populationName')) && (
        <Table.Cell>{row.populationName}</Table.Cell>
      )}

      {(!visibleColumns || visibleColumns.includes('locationName')) && (
        <Table.Cell>{row.locationName}</Table.Cell>
      )}

      {(!visibleColumns || visibleColumns.includes('districtsName')) && (
        <Table.Cell>{row.districtsName}</Table.Cell>
      )}
    </Table.Row>
  );
};
