import { useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';
import { TapajProgress } from '../types';
import { TapajProgressesApi } from '../api';
import { ApiError, defaultErrorHandler } from 'api';
import { useQuery } from 'react-query';

export interface TapajProgressLists {
  tapajProgresses: TapajProgress[];
  dropdownItems: DropdownItemProps[];
  indexedTapajProgresses: Record<number, TapajProgress>;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchTapajProgresses = (options?: {
  staleTime?: number;
}): TapajProgressLists => {
  const {
    data: tapajProgresses,
    isFetching,
    isFetched,
  } = useQuery('tapajProgresses', TapajProgressesApi.getAll, {
    staleTime: 15 * 60 * 1000,
    onSettled: (_, error) =>
      error && error instanceof ApiError && defaultErrorHandler(error),
    ...options,
  });

  const indexedTapajProgresses = useMemo(
    () =>
      (tapajProgresses || []).reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<number, TapajProgress>),
    [tapajProgresses]
  );

  const dropdownItems = useMemo(() => {
    const activeTapajProgresses = (tapajProgresses || []).filter(
      (tapajProgresses) => tapajProgresses.isActive
    );

    const items = activeTapajProgresses.map((tapajProgress) => ({
      key: tapajProgress.id,
      value: tapajProgress.id,
      text: tapajProgress.name,
    }));

    const sortedItems = orderBy(items, (item) =>
      deburr(item.text).toLowerCase()
    );

    return sortedItems;
  }, [tapajProgresses]);

  return {
    tapajProgresses: tapajProgresses || [],
    dropdownItems,
    indexedTapajProgresses,
    isFetched,
    isFetching,
  };
};
