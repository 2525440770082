import React from 'react';

interface Props {
  label: string | null;
  value: string | number | null;
}

export const Field: React.FC<Props> = ({ label, value }) => {
  return (
    <div className="tw-flex tw-flex-wrap tw-items-center tw-my-2">
      <div className="tw-w-44 tw-font-bold tw-flex-shrink-0 tw-text-purple">{label}:</div>
      <div>{value}</div>
    </div>
  );
};
