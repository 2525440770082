import { requests } from 'api';
import { Entry, EntrySummary } from './types';
import { EntryFormValues } from './components/EntryForm';

const apiBaseUrl = '/api/entries';

interface SearchParams {
  fromDate: string;
  toDate: string;
}

export const EntriesApi = {
  create: (newEntry: EntryFormValues): Promise<Entry> =>
    requests.post(apiBaseUrl, newEntry),
  getById: (id: number): Promise<Entry> => requests.get(`${apiBaseUrl}/${id}`),
  getAll: (): Promise<EntrySummary[]> => requests.get(apiBaseUrl),
  update: (id: number, editedEntry: EntryFormValues): Promise<any> =>
    requests.patch(`${apiBaseUrl}/${id}`, editedEntry),
  getInterventionTextValues: (
    interventionId: number | null | undefined,
    searchQuery: string
  ): Promise<string[]> =>
    requests.getWithParams(`${apiBaseUrl}/intervention-text-values`, {
      interventionId: interventionId,
      searchQuery: searchQuery,
    }),
  search: (searchParams: SearchParams): Promise<Entry[]> =>
    requests.getWithParams(`${apiBaseUrl}/search`, searchParams),
  getReferredBySuggestion: (searchQuery: string): Promise<string[]> =>
    requests.getWithParams(`${apiBaseUrl}/referred-by-values`, {
      searchQuery: searchQuery,
    }),
};
