import React, { Fragment, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { defaultErrorHandler } from 'api';
import { Segment } from 'semantic-ui-react';
import {
  ProblematicForm,
  ProblematicFormValues,
} from '../components/ProblematicForm';
import { ProblematicsApi } from '../api';
import { PageLayout } from 'components/Layout/PageLayout';
import { PageHeader } from 'components/Layout/PageHeader';
import { ApplicationLoader } from 'components/ApplicationLoader';

const m = defineMessages({
  pageTitle: {
    id: 'Problematic.NewPage.Title',
    defaultMessage: 'Nouvelle préoccupation ou problématique',
  },
  savedSuccessfully: {
    id: 'Problematic.NewPage.SavedSuccessfully',
    defaultMessage:
      'La préoccupation ou problématique a été sauvegardé avec succès',
  },
});

export const NewProblematicPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [saving, setSaving] = useState(false);

  const emtpyProblematic: ProblematicFormValues = {
    name: '',
    isActive: true,
  };

  const returnToProblematicsPage = () => {
    history.push(`/admin/problematics`);
  };

  const handleSubmit = (data: ProblematicFormValues) => {
    setSaving(true);

    ProblematicsApi.create(data)
      .then(() => {
        toast.success(formatMessage(m.savedSuccessfully));
        returnToProblematicsPage();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };

  return (
    <Fragment>
      <PageLayout pageTitle={formatMessage(m.pageTitle)}>
        <PageHeader
          header={formatMessage(m.pageTitle)}
          iconName="warning sign"
        />
        <Segment>
          <ProblematicForm
            problematic={emtpyProblematic}
            onSubmit={handleSubmit}
          />
        </Segment>
        {saving && <ApplicationLoader />}
      </PageLayout>
    </Fragment>
  );
};
