import React from 'react';
import { LoaderImage } from 'components/LoaderImage';

interface Props {
  message?: string;
}

export const SplashScreen: React.FC<Props> = ({ message }) => {
  return (
    <div
      style={{ zIndex: 1000 }}
      className="tw-fixed tw-inset-0 tw-bg-white tw-opacity-80 tw-flex tw-justify-center tw-items-center tw-text-center tw-p-12"
    >
      <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
        <img src="/logo.png" className="tw-m-12" alt="logo"/>
        <LoaderImage />
        {message && <div>{message}</div>}
      </div>
    </div>
  );
};
