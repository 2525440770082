import { requests } from 'api';
import { MealFormValues } from './components/MealForm/MealForm';
import { Meal } from './types';

const apiBaseUrl = '/api/meals';

export const MealsApi = {
  getById: (id: number): Promise<Meal> => requests.get(`${apiBaseUrl}/${id}`),
  getByDateRange: (fromDate: string, toDate: string): Promise<Meal[]> =>
    requests.getWithParams(apiBaseUrl, { fromDate, toDate }),
  create: (newMeal: MealFormValues): Promise<Meal> =>
    requests.post(apiBaseUrl, newMeal),
  update: (id: number, editedMeal: MealFormValues): Promise<void> =>
    requests.patch(`${apiBaseUrl}/${id}`, editedMeal),
  delete: (id: number): Promise<void> => requests.del(`${apiBaseUrl}/${id}`),
};
