import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Grid, SemanticICONS } from 'semantic-ui-react';
import { SegmentHeader } from './SegmentHeader';

const m = defineMessages({
  entry: {
    id: 'Dashboard.View.Entry',
    defaultMessage: 'Entrée',
  },
  entries: {
    id: 'Dashboard.View.Entries',
    defaultMessage: 'Entrées',
  },
  person: {
    id: 'Dashboard.View.Person',
    defaultMessage: 'Personne',
  },
  persons: {
    id: 'Dashboard.View.Persons',
    defaultMessage: 'Personnes',
  },
});

interface FilteredEntriesStat {
  groupName: string;
  entryCount: number;
  personCount: number;
}

interface Props {
  header: string;
  iconName: SemanticICONS;
  firstColumnStat: FilteredEntriesStat;
  secondColumnStat: FilteredEntriesStat;
}

export const MultipleStatCard: React.FC<Props> = ({
  header,
  iconName,
  firstColumnStat,
  secondColumnStat,
}) => {
  const { formatMessage } = useIntl();

  const StatColumn: React.FC<{ stats: FilteredEntriesStat }> = ({ stats }) => {
    return (
      <>
        <div
          className="tw-grid tw-items-center"
          style={{ gridTemplateColumns: 'auto auto' }}
        >
          <StatRow
            value={stats.entryCount}
            label={
              stats.entryCount > 1
                ? formatMessage(m.entries)
                : formatMessage(m.entry)
            }
          />
          <StatRow
            value={stats.personCount}
            label={
              stats.personCount > 1
                ? formatMessage(m.persons)
                : formatMessage(m.person)
            }
          />
        </div>
        <div className="tw-text-center tw-text-sm tw-mt-2">
          {stats.groupName}
        </div>
      </>
    );
  };

  const StatRow: React.FC<{ value: any; label: string }> = ({
    value,
    label,
  }) => {
    return (
      <>
        <div className="tw-text-right tw-text-green tw-text-3xl tw-align-middle tw-mx-2">
          {value}
        </div>
        <div className="tw-align-middle tw-mx-2">{label}</div>
      </>
    );
  };

  return (
    <div>
      <SegmentHeader header={header} iconName={iconName} />
      <Grid columns={2} divided className="tw-my-1">
        <Grid.Column className="tw-p-1">
          <StatColumn stats={firstColumnStat} />
        </Grid.Column>
        <Grid.Column className="tw-p-1">
          <StatColumn stats={secondColumnStat} />
        </Grid.Column>
      </Grid>
    </div>
  );
};
