import { defaultErrorHandler } from 'api';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { PageHeader } from 'components/Layout/PageHeader';
import { PageLayout } from 'components/Layout/PageLayout';
import React, { Fragment, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { TapajProgressesApi } from '../api';
import {
  TapajProgressForm,
  TapajProgressFormValues,
} from '../components/TapajProgressForm';
import { useFetchTapajProgress } from '../hooks/useFetchTapajProgress';

const m = defineMessages({
  pageTitle: {
    id: 'TapajProgress.EditPage.PageTittle',
    defaultMessage: 'Modifier un progrès Tapaj ou Capab',
  },
  savedSuccessfully: {
    id: 'TapajProgress.EditPage.SavedSuccessfully',
    defaultMessage: 'Le progrès Tapaj ou Capab a été sauvegardé avec succès',
  },
});

interface Params {
  tapajProgressId: string;
}

export const EditTapajProgressPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const params = useParams<Params>();
  const id = parseInt(params.tapajProgressId, 10);
  const { tapajProgress } = useFetchTapajProgress(id);
  const [saving, setSaving] = useState(false);

  const returnToTapajProgressPage = () => {
    history.push('/admin/tapaj-progresses');
  };

  const handleSubmit = (editTapajProgress: TapajProgressFormValues) => {
    setSaving(true);

    TapajProgressesApi.update(id, editTapajProgress)
      .then(() => {
        toast.success(formatMessage(m.savedSuccessfully));
        returnToTapajProgressPage();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };

  if (tapajProgress === null) {
    return null;
  }

  const showLoader = !tapajProgress || saving;

  return (
    <Fragment>
      <PageLayout pageTitle={formatMessage(m.pageTitle)}>
        <PageHeader
          header={formatMessage(m.pageTitle)}
          iconName="clipboard check"
        />
        {tapajProgress && (
          <TapajProgressForm
            tapajProgress={tapajProgress}
            onSubmit={handleSubmit}
          />
        )}
        {showLoader && <ApplicationLoader />}
      </PageLayout>
    </Fragment>
  );
};
