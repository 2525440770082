import React, { useState } from 'react';
import { PageLayout } from 'components/Layout/PageLayout';
import { defineMessages, useIntl } from 'react-intl';
import { PageHeader } from 'components/Layout/PageHeader';
import { useEntryLists } from '../hooks/useEntryLists';
import { useFetchUsers } from 'modules/user/hooks/useFetchUsers';
import { useEntryQuery } from 'modules/entry/hooks/useEntryQuery';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { Grid } from 'semantic-ui-react';
import { EntryQueryForm } from 'modules/entry/components/EntryQueryForm';
import { StatPreview } from 'modules/report/components/StatPreview';
import { EntryQueryContextProvider } from '../components/EntryQueryContext';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import {
  AdvancedSearchResultsTableLayout,
  AdvancedSearchResultsTableOptions,
} from '../components/AdvancedSearchResultsTableLayout';
import {
  getEntryQueryFromQueryString,
  getFlatEntryQuery,
  QueryStringEntryQuery,
} from '../utils/queryStringEntryQuery';
import { EntryQuery } from '../utils/entryQuery';

const m = defineMessages({
  pageTitle: {
    id: 'AdvancedSearch.Page.Title',
    defaultMessage: 'Recherche avancée',
  },
});

export const AdvancedSearchPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const history = useHistory();

  const lists = useEntryLists();
  const users = useFetchUsers();

  const parsedQueryString = queryString.parse(location.search, {
    arrayFormat: 'bracket',
    parseNumbers: true,
    parseBooleans: true,
  }) as QueryStringEntryQuery & AdvancedSearchResultsTableOptions;

  const initialEntryQuery: EntryQuery =
    getEntryQueryFromQueryString(parsedQueryString);

  const [entryQuery, setEntryQuery] = useState<EntryQuery>(initialEntryQuery);

  const { entryQueryResult, isFetching: isFetchingEntries } = useEntryQuery({
    entryQuery,
  });

  const convertToQueryString = (object: Record<string, any>) => {
    return queryString.stringify(object, {
      skipNull: true,
      skipEmptyString: true,
      arrayFormat: 'bracket',
    });
  };

  const handleEntryQueryChange = (newEntryQuery: EntryQuery) => {
    setEntryQuery(newEntryQuery);

    const flatEntryQuery = getFlatEntryQuery(newEntryQuery);

    history.replace({
      pathname: location.pathname,
      search: convertToQueryString({
        ...parsedQueryString,
        ...flatEntryQuery,
      }),
    });
  };

  const handleOptionsChange = (options: AdvancedSearchResultsTableOptions) => {
    history.replace({
      pathname: location.pathname,
      search: convertToQueryString(options),
    });
  };

  const isLoaded = lists.isReady && users.isFetched;

  return (
    <EntryQueryContextProvider value={{ lists, users }}>
      <PageLayout pageTitle={formatMessage(m.pageTitle)}>
        <PageHeader header={formatMessage(m.pageTitle)} iconName="search" />
        {isLoaded && (
          <>
            <Grid columns={2} doubling>
              <Grid.Column width="13">
                <EntryQueryForm
                  entryQuery={entryQuery}
                  onChange={handleEntryQueryChange}
                  isFetchingEntries={isFetchingEntries}
                />
                {entryQueryResult && (
                  <AdvancedSearchResultsTableLayout
                    entries={entryQueryResult?.filteredEntries || []}
                    options={parsedQueryString}
                    onOptionsChange={handleOptionsChange}
                  />
                )}
              </Grid.Column>
              <Grid.Column width="3">
                <div className="tw-sticky" style={{ top: '2rem' }}>
                  <StatPreview
                    entryCount={entryQueryResult?.entryCount ?? 0}
                    personCount={entryQueryResult?.personCount ?? 0}
                    isFetchingEntries={isFetchingEntries}
                  />
                </div>
              </Grid.Column>
            </Grid>
          </>
        )}
        {!isLoaded && <ApplicationLoader />}
      </PageLayout>
    </EntryQueryContextProvider>
  );
};
