import { useCallback } from 'react';
import {
  EntryFormValues,
  EntryInterventionFormValues,
} from '../components/EntryForm';

export const useEntryForm = () => {
  const compactInterventions = useCallback((entry: EntryFormValues) => {
    entry.interventions = entry.interventions.reduce((result, intervention) => {
      const treatedIntervention = result.find(
        (x) => x.interventionId === intervention.interventionId
      );

      if (treatedIntervention) {
        (treatedIntervention.textValue as string[]).push(
          intervention.textValue as string
        );
      } else {
        result.push({
          interventionId: intervention.interventionId,
          textValue: intervention.textValue
            ? [intervention.textValue as string]
            : [],
          numberValue: intervention.numberValue,
        });
      }

      return result;
    }, [] as EntryInterventionFormValues[]);
  }, []);

  const flattenInterventions = useCallback((entry: EntryFormValues) => {
    entry.interventions = entry.interventions.reduce((result, intervention) => {
      if (
        Array.isArray(intervention.textValue) &&
        intervention.textValue.length
      ) {
        result = result.concat(
          intervention.textValue.map((textValue) => ({
            interventionId: intervention.interventionId,
            textValue: textValue || null,
            numberValue: null,
          }))
        );
      } else {
        result.push({
          interventionId: intervention.interventionId,
          textValue: null,
          numberValue: intervention.numberValue || null,
        });
      }
      return result;
    }, [] as EntryInterventionFormValues[]);
  }, []);

  const cleanForm = useCallback((entry: EntryFormValues) => {
    if (entry.hasIntervention === false) {
      entry.interventions = [];
      entry.problematicIds = [];
      entry.hasHomelessness = false;
      entry.interventionDurationInMinute = 0;
    }

    if (entry.hasHomelessness === false) {
      entry.homelessnessSituationId = null;
      entry.homelessnessIds = [];
    }

    if (entry.hasTapajProgress === false) {
      entry.tapajProgressIds = [];
    }
  }, []);

  const prepareFormForSubmission = useCallback(
    (entry: EntryFormValues) => {
      cleanForm(entry);
      flattenInterventions(entry);
    },
    [cleanForm, flattenInterventions]
  );

  const prepareEntryForForm = useCallback(
    (entry: EntryFormValues) => {
      compactInterventions(entry);
    },
    [compactInterventions]
  );

  return { prepareEntryForForm, prepareFormForSubmission };
};
