import React from 'react';
import { defaultErrorHandler } from 'api';
import { InterventionsApi } from 'modules/intervention/api';
import { Form } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { orderBy } from 'lodash';

interface InterventionListValueDropdownProps {
  interventionId: number;
  values?: string[] | null;
  onChange: (values: string[]) => void;
}

export const InterventionListValueDropdown = ({
  interventionId,
  values,
  onChange,
}: InterventionListValueDropdownProps) => {
  const { data: interventionListValues, isFetching } = useQuery(
    'interventionListValues',
    () => InterventionsApi.getListValues(interventionId),
    {
      onSettled: (_, error) => defaultErrorHandler(error),
    }
  );

  const sortedinterventionListValues = orderBy(interventionListValues ?? [], [
    (value) => value.valueCategory,
    (value) => value.valueDescription,
  ]);

  const options = sortedinterventionListValues.map((value) => {
    const fullDescription = [value.valueCategory, value.valueDescription]
      .filter(Boolean)
      .join(' - ');

    return {
      key: fullDescription,
      text: fullDescription,
      value: fullDescription,
      content: value.valueCategory ? (
        <>
          <strong>{value.valueCategory}</strong> - {value.valueDescription}
        </>
      ) : (
        <>{value.valueDescription}</>
      ),
    };
  });

  return (
    <Form.Dropdown
      selection
      onChange={(e, { value }) => onChange((value as string[]) ?? [])}
      multiple
      search
      options={options}
      value={values ?? ''}
      loading={isFetching}
      disabled={isFetching}
    />
  );
};
