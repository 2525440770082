import React from 'react';
import { ErrorTopMenu } from './ErrorTopMenu';
import './PageLayout.scss';

export const ErrorPageLayout: React.FC = ({ children }) => {
  return (
    <div className={`pagelayout tw-bg-gray-200`}>
      <ErrorTopMenu />

      <div className="main">
        <div className="tw-relative tw-h-full tw-flex-1 tw-overflow-hidden">
          <div className="content">{children}</div>
        </div>
      </div>
    </div>
  );
};
