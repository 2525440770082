import { EntriesApi } from 'modules/entry/api';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { DropdownItemProps, Form } from 'semantic-ui-react';

const m = defineMessages({
  add: {
    id: 'InterventionTextDropdown.Add',
    defaultMessage: 'Ajouter ',
  },
  noResult: {
    id: 'InterventionTextDropdown.NoResult',
    defaultMessage: 'Aucune suggestion trouvée',
  },
});

interface Props {
  interventionId?: number;
  onChange: (value: any[]) => void;
  value: any;
}

export const InterventionTextDropdown: React.FC<Props> = ({
  interventionId,
  onChange,
  value,
}) => {
  const { formatMessage } = useIntl();

  const [options, setOptions] = useState<DropdownItemProps[]>([]);

  useEffect(() => {
    const currentValueOptions = value
      ? (value as string[]).map((v) => ({ key: v, text: v, value: v }))
      : [];
    setOptions(currentValueOptions);
  }, [value]);

  const handleAddItem = (newTextItem: any) => {
    setOptions((state) => [
      { key: newTextItem, text: newTextItem, value: newTextItem },
      ...state,
    ]);
  };

  const handleSearch = (searchQuery: string) => {
    if (searchQuery.length <= 1) return;

    EntriesApi.getInterventionTextValues(interventionId, searchQuery).then(
      (fetchedValues) => {
        const fetchedValueOptions = (fetchedValues as string[]).map((v) => ({
          key: v,
          text: v,
          value: v,
        }));

        const currentValueOptions = value
          ? (value as string[]).map((v) => ({ key: v, text: v, value: v }))
          : [];

        setOptions([...fetchedValueOptions, ...currentValueOptions]);
      }
    );
  };

  return (
    <Form.Dropdown
      selection
      onChange={(e, { value }) => onChange((value as any[]) ?? [])}
      multiple
      search
      allowAdditions
      onAddItem={(e, { value }) => handleAddItem(value)}
      options={options}
      onSearchChange={(e, { searchQuery }) => handleSearch(searchQuery)}
      value={value}
      noResultsMessage={formatMessage(m.noResult)}
      additionLabel={formatMessage(m.add)}
    />
  );
};
