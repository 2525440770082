import React from 'react';
import {
  defineMessages,
  FormattedDate,
  FormattedTime,
  useIntl,
} from 'react-intl';
import { EntryViewData } from './useEntryViewData';

const m = defineMessages({
  createBy: {
    id: 'Entry.View.TimeStamps.CreatedBy',
    defaultMessage: 'Créé par',
  },
  createdOn: { id: 'Entry.View.TimeStamps.CreatedOn', defaultMessage: 'le' },
  createdAt: { id: 'Entry.View.TimeStamps.CreatedOn', defaultMessage: 'à' },
  updatedBy: {
    id: 'Entry.View.TimeStamps.UpdatedBy',
    defaultMessage: 'Modifié par',
  },
  updatedOn: { id: 'Entry.View.TimeStamps.UpdatedOn', defaultMessage: 'le' },
  updatedAt: { id: 'Entry.View.TimeStamps.UpdatedOn', defaultMessage: 'à' },
});

interface Props {
  viewData: EntryViewData;
}

export const TimeStampInfos: React.FC<Props> = ({ viewData }) => {
  const { formatMessage } = useIntl();

  const { createdAt, createdByUserFullName, updatedAt, updatedByUserFullName } =
    viewData;

  return (
    <div className="tw-my-4 tw-flex tw-flex-col tw-items-end ">
      {createdByUserFullName && (
        <div>
          <span>{formatMessage(m.createBy)} : </span>
          <span className="tw-font-bold">{createdByUserFullName}</span>{' '}
          {createdAt && (
            <>
              {formatMessage(m.createdOn)}{' '}
              <span className="tw-font-bold">
                <FormattedDate
                  value={createdAt}
                  day="numeric"
                  month="long"
                  year="numeric"
                />{' '}
              </span>
              {formatMessage(m.createdAt)}{' '}
              <span className="tw-font-bold">
                <FormattedTime value={createdAt} />
              </span>
            </>
          )}
        </div>
      )}

      {updatedByUserFullName && (
        <div>
          <span>{formatMessage(m.updatedBy)} : </span>
          <span className="tw-font-bold">{updatedByUserFullName}</span>{' '}
          {updatedAt && (
            <>
              {formatMessage(m.updatedOn)}{' '}
              <span className="tw-font-bold">
                <FormattedDate
                  value={updatedAt}
                  day="numeric"
                  month="long"
                  year="numeric"
                />{' '}
              </span>
              {formatMessage(m.updatedAt)}{' '}
              <span className="tw-font-bold">
                <FormattedTime value={updatedAt} />
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
};
