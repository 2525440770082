import { ApplicationSegment } from 'components/ApplicationSegment';
import { PeriodStats } from 'modules/dashboard/hooks/useDashboardStats';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { SegmentHeader } from './SegmentHeader';

const m = defineMessages({
  tooltipLabelName: {
    id: 'Dashboard.PeriodChart.TooltipLabelName',
    defaultMessage: "Entrée(s)",
  },
});

interface Props {
  periodsStats : PeriodStats[];
}

export const PeriodChart: React.FC<Props> = ({periodsStats}) => {
  const { formatMessage } = useIntl(); 

  const tooltipFormatter = (value: string | number | Array<string | number>) => {
    return [value, formatMessage(m.tooltipLabelName)];
};

  return (
    <ApplicationSegment>
      <SegmentHeader header="Entrées" iconName="file alternate" />
      <ResponsiveContainer height={250} width="99%">
        <LineChart
          width={1000}
          height={250}
          data={periodsStats}
          margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="label" />
          <YAxis width={30} />
          <Tooltip formatter={tooltipFormatter}/>
          <Line type="linear" dataKey="entryCount" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </ApplicationSegment>
  );
};
