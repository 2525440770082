import React from 'react';
import { PageLayout } from 'components/Layout/PageLayout';
import { PageHeader } from 'components/Layout/PageHeader';
import { defineMessages, useIntl } from 'react-intl';
import { useFetchHomelessnesses } from '../hooks/useFetchHomelessnesses';
import { HomelessnessesTable } from '../components/HomelessnessesTable';
import { ApplicationLoader } from 'components/ApplicationLoader';

const m = defineMessages({
  pageTitle: {
    id: 'Homelessnesses.Page.Title',
    defaultMessage: 'Interventions en itinérances',
  },
});

export const HomelessnessesPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const { homelessnesses, isFetched: areHomelessnessesFetched } =
    useFetchHomelessnesses({ staleTime: 0 });

  const isLoaded = areHomelessnessesFetched;

  return (
    <PageLayout pageTitle={formatMessage(m.pageTitle)}>
      <PageHeader header={formatMessage(m.pageTitle)} iconName="shoe-prints" />

      {isLoaded ? (
        <HomelessnessesTable homelessnesses={homelessnesses} />
      ) : (
        <ApplicationLoader />
      )}
    </PageLayout>
  );
};
