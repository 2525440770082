import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { DropdownItemProps } from 'semantic-ui-react';

const interventionTypes = defineMessages({
  Boolean: { id: 'InterventionType.Boolean', defaultMessage: 'Booléenne' },
  Text: { id: 'InterventionType.Text', defaultMessage: 'Texte' },
  Number: { id: 'InterventionType.Number', defaultMessage: 'Numérique' },
  List: { id: 'InterventionType.List', defaultMessage: 'Liste' },
});

export const useInterventionTypes = () => {
  const { formatMessage } = useIntl();

  const interventionTypeNamesByValue = useMemo<Record<string, string>>(
    () => ({
      Boolean: formatMessage(interventionTypes.Boolean),
      Text: formatMessage(interventionTypes.Text),
      Number: formatMessage(interventionTypes.Number),
      List: formatMessage(interventionTypes.List),
    }),
    [formatMessage]
  );

  const dropdownItems = useMemo<DropdownItemProps[]>(
    () =>
      Object.keys(interventionTypeNamesByValue).map((key) => {
        return {
          key: key,
          value: key,
          text: interventionTypeNamesByValue[key as string],
        };
      }),
    [interventionTypeNamesByValue]
  );

  return { interventionTypeNamesByValue, dropdownItems };
};
