import { PrivateRoute } from 'modules/account/components/PrivateRoute';
import { ExternalLoginPage } from 'modules/account/views/ExternalLoginPage';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { RootState } from 'store';
import { startApplication } from 'modules/application/actions';
import { SplashScreen } from 'modules/application/views/SplashScreen';
import { NewEntryPage } from 'modules/entry/views/NewEntryPage';
import { GlobalToast } from 'modules/application/components/GlobalToast';
import 'react-toastify/dist/ReactToastify.min.css';
import { defineMessages, useIntl } from 'react-intl';
import { EntriesPage } from 'modules/entry/views/EntriesPage';
import { EntryPage } from 'modules/entry/views/EntryPage';
import { UsersPage } from 'modules/user/views/UsersPage';
import { useSecurity } from 'modules/account/hooks/useSecurity';
import { EditUserPage } from 'modules/user/views/EditUserPage';
import { EditEntryPage } from 'modules/entry/views/EditEntryPage';
import { TeamsPage } from 'modules/team/views/TeamsPage';
import { NewTeamPage } from 'modules/team/views/NewTeamPage';
import { EditTeamPage } from 'modules/team/views/EditTeamPage';
import { InterventionsPage } from 'modules/intervention/views/InterventionsPage';
import { NewInterventionPage } from 'modules/intervention/views/NewInterventionPage';
import { EditInterventionPage } from 'modules/intervention/views/EditInterventionPage';
import { ProblematicsPage } from 'modules/problematic/views/ProblematicsPage';
import { NewProblematicPage } from 'modules/problematic/views/NewProblematicPage';
import { EditProblematicPage } from 'modules/problematic/views/EditProblematicPage';
import { HomelessnessesPage } from 'modules/homelessness/views/HomelessnessesPage';
import { NewHomelessnessPage } from 'modules/homelessness/views/NewHomelessnessPage';
import { EditHomelessnessPage } from 'modules/homelessness/views/EditHomelessnessPage';
import { TapajProgressesPage } from 'modules/tapajProgress/views/TapajProgressesPage';
import { NewTapajProgressPage } from 'modules/tapajProgress/views/NewTapajProgressPage';
import { EditTapajProgressPage } from 'modules/tapajProgress/views/EditTapajProgressPage';
import { ReportPage } from 'modules/report/views/ReportPage';
import { ForbiddenError } from 'modules/errors/view/ForbiddenError';
import { InternalServerError } from 'modules/errors/view/InternalServerError';
import { NotFoundError } from 'modules/errors/view/NoFoundError';
import { UnauthorizedError } from 'modules/errors/view/UnauthorizedError';
import { DashboardPage } from 'modules/dashboard/views/DashboardPage';
import { MealsPage } from 'modules/meal/views/MealsPage';
import { NewMealPage } from 'modules/meal/views/NewMealPage';
import { EditMealPage } from 'modules/meal/views/EditMealPage';
import { AboutPage } from 'modules/about/views/AboutPage';
import { AdvancedSearchPage } from 'modules/entry/views/AdvancedSearchPage';

const m = defineMessages({
  loading: { id: 'Application.Loading', defaultMessage: 'En Chargement' },
});

export const App: React.FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const isLoaded = useSelector(
    (state: RootState) => state.application.isLoaded
  );

  const { isAdmin } = useSecurity();

  useEffect(() => {
    dispatch(startApplication());
  }, [dispatch]);

  if (!isLoaded) {
    return <SplashScreen message={formatMessage(m.loading)} />;
  }

  return (
    <div>
      <GlobalToast />
      <Switch>
        <PrivateRoute exact path="/" component={DashboardPage} />
        <PrivateRoute exact path="/entries/new" component={NewEntryPage} />
        <PrivateRoute
          exact
          path="/entries/:entryId/edit"
          component={EditEntryPage}
        />
        <PrivateRoute exact path="/entries/:entryId" component={EntryPage} />
        <PrivateRoute exact path="/entries" component={EntriesPage} />
        <PrivateRoute exact path="/meals" component={MealsPage} />
        <PrivateRoute exact path="/meals/new" component={NewMealPage} />
        <PrivateRoute exact path="/meals/:mealId" component={EditMealPage} />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/users"
          component={UsersPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/users/:userId"
          component={EditUserPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/teams"
          component={TeamsPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/teams/new"
          component={NewTeamPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/teams/:teamId"
          component={EditTeamPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/interventions"
          component={InterventionsPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/interventions/new"
          component={NewInterventionPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/interventions/:interventionId"
          component={EditInterventionPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/problematics"
          component={ProblematicsPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/problematics/new"
          component={NewProblematicPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/problematics/:problematicId"
          component={EditProblematicPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/homelessnesses"
          component={HomelessnessesPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/homelessnesses/new"
          component={NewHomelessnessPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/homelessnesses/:homelessnessId"
          component={EditHomelessnessPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/tapaj-progresses"
          component={TapajProgressesPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/tapaj-progresses/new"
          component={NewTapajProgressPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/tapaj-progresses/:tapajProgressId"
          component={EditTapajProgressPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/advanced-search"
          component={AdvancedSearchPage}
        />
        <PrivateRoute
          isAuthorized={isAdmin}
          exact
          path="/admin/report"
          component={ReportPage}
        />

        <PrivateRoute exact path="/about" component={AboutPage} />

        <Route exact path="/error/401" component={UnauthorizedError} />
        <Route exact path="/error/403" component={ForbiddenError} />
        <Route exact path="/error/404" component={NotFoundError} />
        <Route exact path="/error/500" component={InternalServerError} />

        <Route exact path="/login" component={ExternalLoginPage} />

        <Route component={NotFoundError} />
      </Switch>
    </div>
  );
};
