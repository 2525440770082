import React, { Fragment } from 'react';
import { Entry } from 'modules/entry/types';
import { useEntryViewData } from './useEntryViewData';
import { BaseSection } from './BaseSection';
import { DemographySection } from './DemographySection';
import { InterventionsSection } from './InterventionsSection';
import { TimeStampInfos } from './TimeStampInfos';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { useSecurity } from 'modules/account/hooks/useSecurity';

interface Props {
  entry: Entry;
}

export const EntryView: React.FC<Props> = ({ entry }) => {
  const { viewData, isReady: isViewDataReady } = useEntryViewData(entry);
  const { isAdmin } = useSecurity();

  const isLoaded = isViewDataReady;

  if (!isLoaded) {
    return <ApplicationLoader />;
  }

  if (!viewData) {
    return null;
  }

  return (
    <Fragment>
      <BaseSection viewData={viewData} />
      <DemographySection viewData={viewData} />
      <InterventionsSection viewData={viewData} />
      {isAdmin && <TimeStampInfos viewData={viewData} />}
    </Fragment>
  );
};
