import React, { Fragment, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useResponsive } from 'hooks/useResponsive';
import { Link } from 'react-router-dom';
import {
  Button,
  Dropdown,
  Input,
  InputOnChangeData,
  Pagination,
  Table,
} from 'semantic-ui-react';
import deburr from 'lodash/deburr';
import { Meal } from 'modules/meal/types';
import { useTable } from 'hooks/useTable';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { pageSizeOptions } from 'hooks/useTablePaginating';
import { EmptyStateMessage } from 'components/EmptyStateMessage';
import { TeamSummary } from 'modules/team';

const m = defineMessages({
  recordperpage: {
    id: 'Meals.Table.RecordPerPage',
    defaultMessage: 'Repas par page',
  },
  totalCount: {
    id: 'Meals.Table.TotalCount',
    defaultMessage: 'Nombre total',
  },
  search: {
    id: 'Meals.Table.Search',
    defaultMessage: 'Rechercher',
  },
  noRow: {
    id: 'Meals.Table.NoRow',
    defaultMessage: "Il n'y a aucun repas.",
  },
  noMatchFound: {
    id: 'Meals.Table.NoMatchFound',
    defaultMessage: 'Aucun repas ne correspond à votre recherche.',
  },
  date: {
    id: 'Meals.Table.Date',
    defaultMessage: 'Date',
  },
  team: {
    id: 'Meals.Table.Team',
    defaultMessage: 'Équipe',
  },
  count: {
    id: 'Meals.Table.MealCount',
    defaultMessage: 'Quantité',
  },
  new: {
    id: 'Meals.Table.New',
    defaultMessage: 'Ajouter un repas',
  },
});

interface MealRow {
  id: number;
  date: string;
  formattedDate: string;
  teamName: string;
  count: number;
}

interface Props {
  meals: Meal[];
  teams: Record<number, TeamSummary>;
}

export const MealsTable: React.FC<Props> = ({ meals, teams }) => {
  const { formatMessage, formatDate } = useIntl();
  const history = useHistory();
  const { isMobile } = useResponsive();

  const {
    currentPageRows,
    setRows,
    hasRows,
    matchFound,
    hasMultiplePages,
    pageCount,
    sortingColumn,
    sortingDirection,
    searchInRows,
    changeSortingColumn,
    currentPage,
    handlePageChange,
    filteredRows,
    pageSize,
    handlePageSizeChange,
    areRowsReady,
  } = useTable<MealRow>({
    sortingColumn: 'date',
    sortingDirection: 'descending',
  });

  const isLoaded = areRowsReady;

  useEffect(() => {
    const rows: MealRow[] = meals.map((meal) => ({
      id: meal.id,
      date: meal.date,
      formattedDate: formatDate(meal.date, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone: 'utc',
      }),
      teamName: teams[meal.teamId]?.name,
      count: meal.count,
    }));
    setRows(rows);
  }, [meals, setRows, formatDate, teams]);

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    searchInRows(data.value, (entryRow) =>
      deburr(
        Object.values([
          entryRow.id,
          entryRow.teamName,
          entryRow.formattedDate,
          entryRow.count,
        ]).toString()
      )
    );
  };

  const handleRowClick = (id: number) => {
    history.push(`/meals/${id}`);
  };

  if (!isLoaded) {
    return <ApplicationLoader />;
  }

  const totalCountAndRecordPerPage = matchFound && (
    <Fragment>
      <div>
        {formatMessage(m.recordperpage)}:{' '}
        <Dropdown
          inline
          options={pageSizeOptions}
          value={pageSize}
          onChange={handlePageSizeChange}
        />
      </div>
      <div>
        {formatMessage(m.totalCount)}: {filteredRows.length}
      </div>
    </Fragment>
  );

  const searchInput = (
    <Input
      icon="search"
      placeholder={formatMessage(m.search)}
      onChange={handleSearchChange}
    />
  );

  const topBar = hasRows && (
    <div className="tw-flex tw-items-center tw-justify-between tw-mb-6">
      <div className="tw-flex-1">{totalCountAndRecordPerPage}</div>
      {searchInput}
    </div>
  );

  const table = (
    <Fragment>
      <Table
        sortable
        celled
        striped
        selectable
        size="small"
        className="text-sm"
        unstackable
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortingColumn === 'date' ? sortingDirection : undefined}
              onClick={changeSortingColumn('date')}
            >
              {formatMessage(m.date)}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                sortingColumn === 'teamName' ? sortingDirection : undefined
              }
              onClick={changeSortingColumn('teamName')}
            >
              {formatMessage(m.team)}
            </Table.HeaderCell>

            <Table.HeaderCell
              sorted={sortingColumn === 'count' ? sortingDirection : undefined}
              onClick={changeSortingColumn('count')}
              textAlign="right"
            >
              {formatMessage(m.count)}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {currentPageRows.map((row) => (
            <Table.Row
              key={row.id}
              onClick={() => handleRowClick(row.id)}
              className="tw-cursor-pointer"
            >
              <Table.Cell collapsing>{row.formattedDate}</Table.Cell>
              <Table.Cell>{row.teamName}</Table.Cell>
              <Table.Cell collapsing textAlign="right">
                {row.count}
              </Table.Cell>
            </Table.Row>
          ))}
          {!hasRows && (
            <Table.Row>
              <Table.Cell colSpan="3">
                <EmptyStateMessage message={formatMessage(m.noRow)} />
              </Table.Cell>
            </Table.Row>
          )}

          {hasRows && !matchFound && (
            <Table.Row>
              <Table.Cell colSpan="3">
                <EmptyStateMessage message={formatMessage(m.noMatchFound)} />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Fragment>
  );

  const pagination = hasMultiplePages && (
    <Pagination
      totalPages={pageCount}
      activePage={currentPage}
      onPageChange={handlePageChange}
    />
  );

  const bottomBarCenteringClass = isMobile ? 'tw-justify-center' : undefined;

  const bottomBar = (
    <div className={`tw-flex tw-items-baseline ${bottomBarCenteringClass}`}>
      {pagination}
      <div className="tw-flex-1" />
      <Button as={Link} to="/meals/new" primary>
        {formatMessage(m.new)}
      </Button>
    </div>
  );

  return (
    <Fragment>
      {topBar}
      {table}
      {bottomBar}
    </Fragment>
  );
};
