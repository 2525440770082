import { defineMessages, useIntl } from 'react-intl';
import {
  DemographySectionMobileMode,
  EntryFormValues,
} from '../components/EntryForm';

const m = defineMessages({
  teamIsRequired: {
    id: 'Entry.Validation.Team.IsRequired',
    defaultMessage: "L'équipe est requise",
  },
  dateIsRequired: {
    id: 'Entry.Validation.Date.IsRequired',
    defaultMessage: 'La date est requise',
  },
  populationIsRequired: {
    id: 'Entry.Validation.Population.IsRequired',
    defaultMessage: 'La population rejointe est requise',
  },
  locationIsRequired: {
    id: 'Entry.Validation.Location.IsRequired',
    defaultMessage: 'Le lieu est requis',
  },
  districtIsRequired: {
    id: 'Entry.Validation.District.IsRequired',
    defaultMessage: "L'arrondissement est requis",
  },
  momentIsRequired: {
    id: 'Entry.Validation.Moment.IsRequired',
    defaultMessage: 'Le moment est requis',
  },
  contactStateIsRequired: {
    id: 'Entry.Validation.ContactState.IsRequired',
    defaultMessage: "L'état du contact est requis",
  },
  initiatorIsRequired: {
    id: 'Entry.Validation.Initiator.IsRequired',
    defaultMessage: "L'initiateur du contact est requis",
  },
  demographyCountIsRequired: {
    id: 'Entry.Validation.Demograpy.Count.IsRequired',
    defaultMessage: 'Le nombre de personnne est requis',
  },
  demographyGenderIsRequired: {
    id: 'Entry.Validation.Demograpy.Gender.IsRequired',
    defaultMessage: 'Le sexe est requis',
  },
  demographyAgeGroupIsRequired: {
    id: 'Entry.Validation.Demograpy.AgeGroup.IsRequired',
    defaultMessage: "Le groupe d'âge est requis",
  },
  demographyOccupationIsRequired: {
    id: 'Entry.Validation.Demograpy.Occupation.IsRequired',
    defaultMessage: "L'occupation est requis",
  },
  demographyOriginIsRequired: {
    id: 'Entry.Validation.Demograpy.Origin.IsRequired',
    defaultMessage: "L'origine est requises",
  },
  demographyInterventionLanguageIsRequired: {
    id: 'Entry.Validation.Demograpy.InterventionLanguage.IsRequired',
    defaultMessage: "La langue de l'intervention est requise",
  },
  homelessnessSituationIsRequired: {
    id: 'Entry.Validation.Homelessness.Situation.IsRequired',
    defaultMessage: 'La situation est requise',
  },
  interventionDurationInMinuteIsRequired: {
    id: 'Entry.Validation.Intervention.DurationInMinute.IsRequired',
    defaultMessage: "La durée de l'intervention est requise",
  },
  noDemographyShouldBeInEditMode: {
    id: 'Entry.Validation.NoDemographyShouldBeInEditMode',
    defaultMessage:
      "Veuillez terminer l'édition de la démographie avant de sauvegarder l'entrée",
  },
});

const isPresent = (value: string | number | null | undefined): boolean =>
  value !== '' && value !== null;

export const useEntryValidations = () => {
  const { formatMessage } = useIntl();

  const validations = {
    teamId: (value: number | null) =>
      isPresent(value) || formatMessage(m.teamIsRequired),
    date: (value: string | null) =>
      isPresent(value) || formatMessage(m.dateIsRequired),
    populationId: (value: number | null) =>
      isPresent(value) || formatMessage(m.populationIsRequired),
    locationId: (value: number | null) =>
      isPresent(value) || formatMessage(m.locationIsRequired),
    districtId: (value: number | null) =>
      isPresent(value) || formatMessage(m.districtIsRequired),
    momentId: (value: number | null) =>
      isPresent(value) || formatMessage(m.momentIsRequired),
    contactStateId: (value: number | null) =>
      isPresent(value) || formatMessage(m.contactStateIsRequired),
    initiatorId: (value: number | null) =>
      isPresent(value) || formatMessage(m.initiatorIsRequired),
    demography: {
      count: (value: number | null) =>
        (value && value >= 1) || formatMessage(m.demographyCountIsRequired),
      genderId: (value: number | null) =>
        isPresent(value) || formatMessage(m.demographyGenderIsRequired),
      ageGroupId: (value: number | null) =>
        isPresent(value) || formatMessage(m.demographyAgeGroupIsRequired),
      occupationId: (value: number | null) =>
        isPresent(value) || formatMessage(m.demographyOccupationIsRequired),
      originIds: (value: number[] | null) =>
        (value !== null && value.length > 0) ||
        formatMessage(m.demographyOriginIsRequired),
      interventionLanguageId: (value: number | null) =>
        isPresent(value) ||
        formatMessage(m.demographyInterventionLanguageIsRequired),
    },
    homelessnessSituationId: (values: EntryFormValues) => {
      if (values.hasHomelessness) {
        return (
          values.homelessnessSituationId != null ||
          formatMessage(m.homelessnessSituationIsRequired)
        );
      } else {
        return true;
      }
    },
    interventionDurationInMinute: (values: EntryFormValues) => {
      if (values.hasIntervention) {
        return (
          Number(values.interventionDurationInMinute) >= 1 ||
          formatMessage(m.interventionDurationInMinuteIsRequired)
        );
      } else {
        return true;
      }
    },
    demographySectionMobileMode: (mode?: DemographySectionMobileMode) =>
      !mode || mode === 'list' || formatMessage(m.noDemographyShouldBeInEditMode),
  };

  return validations;
};
