import React from 'react';
import { Image, Button, Dropdown, Icon, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { RootState } from 'store';
import { CurrentUser } from 'modules/account';
import { signOut } from 'modules/account/actions';
import { useResponsive } from 'hooks/useResponsive';

const m = defineMessages({
  newEntry: { id: 'SideMenu.Home', defaultMessage: 'Nouvelle entrée' },
  signOut: { id: 'SideMenu.SignOut', defaultMessage: 'Déconnexion' },
});

interface Props {
  pageTitle?: string;
  onHamburgerClick: () => void;
}

export const TopMenu: React.FC<Props> = ({ pageTitle, onHamburgerClick }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();

  const user = useSelector<RootState, CurrentUser | undefined>(
    (state) => state.account.user
  );

  const handleHamburgerClick = () => onHamburgerClick();

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const userDropdownMenu = (
    <Dropdown.Menu>
      <Dropdown.Item onClick={handleSignOut}>
        {formatMessage(m.signOut)}
      </Dropdown.Item>
    </Dropdown.Menu>
  );

  const MobileMenu = (
    <Menu
      inverted
      size="massive"
      borderless
      className="top-menu tw-bg-transparent"
    >
      <Menu.Menu position="left">
        <Menu.Item onClick={handleHamburgerClick}>
          <Icon name="bars" />
        </Menu.Item>
      </Menu.Menu>

      {pageTitle && (
        <Menu.Menu position="right">
          <Menu.Item className="tw-font-bold tw-text-2xl">
            {pageTitle}
          </Menu.Item>
        </Menu.Menu>
      )}

      <Menu.Menu position="right">
        {user && (
          <Dropdown item icon="user">
            {userDropdownMenu}
          </Dropdown>
        )}
      </Menu.Menu>
    </Menu>
  );

  const DesktopMenu = (
    <Menu size="massive" borderless className="top-menu tw-bg-white">
      <Menu.Menu position="left">
        <Menu.Item as={Link} to="/">
          <Image src="/logo.png" size="small" />
        </Menu.Item>
      </Menu.Menu>

      <Menu.Menu position="right">
        <Menu.Item>
          <Button secondary as={Link} to="/entries/new">
            <Icon name="plus" />
            {formatMessage(m.newEntry)}
          </Button>
        </Menu.Item>
        {user && (
          <Dropdown item trigger={<span>{user.fullName}</span>}>
            {userDropdownMenu}
          </Dropdown>
        )}
      </Menu.Menu>
    </Menu>
  );

  return <>{isMobile ? MobileMenu : DesktopMenu}</>;
};
