import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form, Icon, List } from 'semantic-ui-react';
import { InterventionFormValues } from './InterventionForm';

interface InterventionListValueProps {
  index: number;
  onDelete: (index: number) => void;
}

export const InterventionListValue = ({
  index,
  onDelete,
}: InterventionListValueProps) => {
  const { control } = useFormContext<InterventionFormValues>();

  const categoryField = (
    <Form.Field className="tw-w-60 tw-mb-0">
      <Controller
        control={control}
        name={`interventionListValues.${index}.valueCategory`}
        defaultValue=""
        render={({ field: { onBlur, value, onChange } }) => (
          <input
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            type="text"
          />
        )}
      />
    </Form.Field>
  );

  const descriptionField = (
    <Controller
      control={control}
      name={`interventionListValues.${index}.valueDescription`}
      defaultValue=""
      rules={{ required: true }}
      render={({
        field: { onBlur, value, onChange },
        fieldState: { error },
      }) => (
        <Form.Field error={Boolean(error)} className="tw-flex-1 tw-mb-0">
          <input
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            type="text"
            className="tw-w-full"
          />
        </Form.Field>
      )}
    />
  );

  const deleteIcon = (
    <Icon
      name="trash alternate"
      size="big"
      color="grey"
      onClick={() => onDelete(index)}
      className="tw-cursor-pointer"
    />
  );

  return (
    <List.Item>
      <div className="tw-flex tw-items-center tw-gap-4">
        {categoryField}
        {descriptionField}
        {deleteIcon}
      </div>
    </List.Item>
  );
};
