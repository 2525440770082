import { useCallback, useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import deburr from 'lodash/deburr';

export type SortingDirection = 'ascending' | 'descending' | undefined;

interface Props {
  sortingColumn?: string;
  sortingDirection?: SortingDirection;
}

export const useTableSorting = <Type>(options: Props | null) => {
  const [rowsToSort, setRowsToSortState] = useState<Type[]>([]);
  const [sortedRows, setSortedRows] = useState<Type[]>([]);

  const [isInitialized, setIsInitialized] = useState(false);
  const [isSorted, setIsSorted] = useState(false); 

  const [sortingColumn, setSortingColumn] = useState(
    options?.sortingColumn ?? ''
  );
  
  const [sortingDirection, setSortingDirection] = useState<SortingDirection>(
    options?.sortingDirection ?? 'ascending'
  );

  const setRowsToSort = useCallback((rowsToSort: Type[]) => {
    setRowsToSortState(rowsToSort);
    setIsInitialized(true);
  }, []);

  const changeSortingColumn = useCallback(
    (clickedColumn: string) => () => {
      if (sortingColumn !== clickedColumn) {
        setSortingColumn(clickedColumn);
        setSortingDirection('ascending');
        return;
      }
      setSortingDirection(
        sortingDirection === 'ascending' ? 'descending' : 'ascending'
      );
    },
    [sortingDirection, sortingColumn]
  );

  const changeSorting = useCallback(
    (newSortingColumn: string, newSortingDirection: string) => {      
      setSortingColumn(newSortingColumn);
      setSortingDirection(newSortingDirection as SortingDirection);
    },
    []
  );

  const getSortedRows = useCallback(
    (rows: Type[]) =>
      orderBy(
        rows,
        [
          (row) => {
            const columnValue = get(row, sortingColumn);
            return typeof columnValue === 'string'
              ? deburr(columnValue)
              : columnValue;
          },
        ],
        sortingDirection === 'ascending' ? 'asc' : 'desc'
      ),
    [sortingDirection, sortingColumn]
  );

  useEffect(() => {
    if (isInitialized) {
      setSortedRows(getSortedRows(rowsToSort));
      setIsSorted(true);
    }
  }, [rowsToSort, isInitialized, sortingColumn, sortingDirection, getSortedRows]);

  return {
    sortingColumn,
    setSortingColumn,
    changeSortingColumn,
    sortingDirection,
    setSortingDirection,
    getSortedRows,
    rowsToSort,
    setRowsToSort,
    sortedRows,
    isSorted,
    changeSorting,
  };
};
