import React, { useState, useRef } from 'react';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import { EntriesApi } from 'modules/entry/api';
import { Search, SearchProps, SearchResultProps } from 'semantic-ui-react';

interface Props {
  onChange: (value: string) => void;
  value: any;
}

export const ReferredByInput: React.FC<Props> = ({ onChange, value }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResultProps[]>();

  const delayedGetSuggestions = useRef(
    debounce((searchQuery) => getSuggestions(searchQuery), 500)
  ).current;

  const getSuggestions = (searchQuery: string) => {
    setIsLoading(true);

    EntriesApi.getReferredBySuggestion(searchQuery as string)
      .then((suggestions) => {
        setSearchResults(
          sortBy(suggestions, (s) => s).map((suggestion) => ({
            title: suggestion,
          }))
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearchChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: SearchProps
  ) => {
    onChange(data.value ?? '');
    const searchQuery = data.value && data.value.trim();
    const isSearchQueryValid = searchQuery && searchQuery.length > 1;

    if (isSearchQueryValid) {
      delayedGetSuggestions(searchQuery);
    } else {
      delayedGetSuggestions.cancel();
      setSearchResults([]);
    }
  };

  const handleResultSelect = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: SearchProps
  ) => {
    const selectedValue = data.result.title;
    onChange(selectedValue);
  };

  return (
    <Search
      onSearchChange={handleSearchChange}
      results={searchResults}
      onResultSelect={handleResultSelect}
      loading={isLoading}
      value={value}
      showNoResults={false}
      icon=""
    />
  );
};
