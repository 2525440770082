import { ApplicationSegment } from 'components/ApplicationSegment';
import { Language } from 'modules/interventionLanguage';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DemographiesSectionDesktop } from './DemographiesSectionDesktop';
import { DemographiesSectionMobile } from './DemographiesSectionMobile';
import { EntryDemographyFormValues, EntryFormValues } from './EntryForm';

interface Props {
  isMobile: boolean;
}

export const DemographiesSection: React.FC<Props> = ({ isMobile }) => {
  const { control, setValue } = useFormContext<EntryFormValues>();

  const { fields, append, remove } = useFieldArray({
    control,
    keyName: 'fieldId',
    name: 'demographies',
  });

  const handleNew = () => {
    const emptyDemography: EntryDemographyFormValues = {
      count: 1,
      genderId: null,
      ageGroupId: null,
      occupationId: null,
      originIds: [],
      interventionLanguageId: Language.French,
    };

    append(emptyDemography);
  };

  const handleCreate = (formValues: EntryDemographyFormValues) => {
    append(formValues);
  };

  const handleDelete = (index: number) => {
    remove(index);
  };

  const handleUpdate = (
    index: number,
    formValues: EntryDemographyFormValues
  ) => {
    setValue(`demographies.${index}`, formValues);
    setValue(`demographies.${index}.originIds`, formValues.originIds);
  };

  const mobileSectionVisibilityClass = isMobile ? '' : 'tw-hidden';
  const desktopSectionVisibilityClass = isMobile ? 'tw-hidden' : '';

  return (
    <>
      <ApplicationSegment className={mobileSectionVisibilityClass}>
        <DemographiesSectionMobile
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      </ApplicationSegment>

      <ApplicationSegment className={desktopSectionVisibilityClass}>
        <DemographiesSectionDesktop
          demographies={fields}
          onDelete={handleDelete}
          onNew={handleNew}
        />
      </ApplicationSegment>
    </>
  );
};
