import { useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';
import { ProblematicSummary } from '../types';
import { ProblematicsApi } from '../api';
import { ApiError, defaultErrorHandler } from 'api';
import { useQuery } from 'react-query';

export interface ProblematicLists {
  problematics: ProblematicSummary[];
  dropdownItems: DropdownItemProps[];
  indexedProblematics: Record<number, ProblematicSummary>;
  isFetched: boolean;
  isFetching: boolean;
}

export const useFetchProblematics = (options?: {
  staleTime?: number;
}): ProblematicLists => {
  const {
    data: problematics,
    isFetching,
    isFetched,
  } = useQuery('problematics', ProblematicsApi.getAll, {
    staleTime: 15 * 60 * 1000,
    onSettled: (_, error) =>
      error && error instanceof ApiError && defaultErrorHandler(error),
    ...options,
  });

  const indexedProblematics = useMemo(
    () =>
      (problematics || []).reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<number, ProblematicSummary>),
    [problematics]
  );

  const dropdownItems = useMemo(() => {
    const activeProblematics = (problematics || []).filter(
      (problematic) => problematic.isActive
    );

    const items = activeProblematics.map((problematic) => ({
      key: problematic.id,
      value: problematic.id,
      text: problematic.name,
    }));

    const sortedItems = orderBy(items, (item) =>
      deburr(item.text).toLowerCase()
    );

    return sortedItems;
  }, [problematics]);

  return {
    problematics: problematics || [],
    dropdownItems,
    indexedProblematics,
    isFetched,
    isFetching,
  };
};
