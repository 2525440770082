import React, { Fragment, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  Button,
  Dropdown,
  Input,
  InputOnChangeData,
  Pagination,
  Table,
} from 'semantic-ui-react';
import deburr from 'lodash/deburr';
import { useTable } from 'hooks/useTable';
import { pageSizeOptions } from 'hooks/useTablePaginating';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { Link, useHistory } from 'react-router-dom';
import { EmptyStateMessage } from 'components/EmptyStateMessage';
import { useResponsive } from 'hooks/useResponsive';
import { ActiveIndicator } from 'components/ActiveIndicator';
import { TapajProgress } from 'modules/tapajProgress';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { ShowInactiveToggle } from 'components/ShowInactiveToggle';

const m = defineMessages({
  recordperpage: {
    id: 'TapajProgresses.Table.RecordPerPage',
    defaultMessage: 'Progrès Tapaj ou Capab par page',
  },
  totalCount: {
    id: 'TapajProgresses.Table.TotalCount',
    defaultMessage: 'Nombre total',
  },
  search: {
    id: 'TapajProgresses.Table.Search',
    defaultMessage: 'Rechercher',
  },
  noRow: {
    id: 'TapajProgresses.Table.NoRow',
    defaultMessage: "Il n'y a aucun progrès Tapaj ou Capab.",
  },
  noMatchFound: {
    id: 'TapajProgresses.Table.NoMatchFound',
    defaultMessage: 'Aucun progrès Tapaj ou Capab ne correspond à votre recherche.',
  },
  name: {
    id: 'TapajProgresses.Table.Name',
    defaultMessage: 'Nom',
  },
  active: {
    id: 'TapajProgresses.Table.Active',
    defaultMessage: 'Actif',
  },
  new: {
    id: 'TapajProgresses.Table.New',
    defaultMessage: 'Créer un progrès Tapaj ou Capab',
  },
});

interface TapajProgressRow {
  id: number;
  name: string;  
  isActive: boolean;
}

interface Props {
  tapajProgresses: TapajProgress[];
}

export const TapajProgressesTable: React.FC<Props> = ({ tapajProgresses }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { isMobile } = useResponsive();
  
  const {
    currentPageRows,
    setRows,
    hasRows,
    matchFound,
    hasMultiplePages,
    pageCount,
    sortingColumn,
    sortingDirection,
    searchInRows,
    changeSortingColumn,
    currentPage,
    handlePageChange,
    filteredRows,
    pageSize,
    handlePageSizeChange,
    areRowsReady,
    showInactive,
    setShowInactive,
  } = useTable<TapajProgressRow>({
    sortingColumn: 'name',
    sortingDirection: 'ascending',
  });

  const isLoaded = areRowsReady;

  useEffect(() => {
    const rows: TapajProgressRow[] = tapajProgresses.map((u) => ({
      id: u.id,
      name: u.name,      
      isActive: u.isActive,
    }));
    setRows(rows);
  }, [tapajProgresses, setRows]);

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    searchInRows(data.value, (entryRow) =>
      deburr(
        Object.values([
          entryRow.id,
          entryRow.name,          
        ]).toString()
      )
    );
  };

  const handleRowClick = (id: number) => {
    history.push(`/admin/tapaj-progresses/${id}`);
  };

  if (!isLoaded) {
    return <ApplicationLoader />;
  }

  const totalCountAndRecordPerPage = matchFound && (
    <Fragment>
      <div>
        {formatMessage(m.recordperpage)}:{' '}
        <Dropdown
          inline
          options={pageSizeOptions}
          value={pageSize}
          onChange={handlePageSizeChange}
        />
      </div>
      <div>
        {formatMessage(m.totalCount)}: {filteredRows.length}
      </div>
    </Fragment>
  );

  const searchInput = (
    <Input
      icon="search"
      placeholder={formatMessage(m.search)}
      onChange={handleSearchChange}
    />
  );

  const topBar = hasRows && (
    <div className="tw-flex tw-items-center tw-justify-between tw-mb-6">
      <div className="tw-flex-1">{totalCountAndRecordPerPage}</div>
      <div className="tw-flex tw-items-center">
        <ShowInactiveToggle
          showInactive={showInactive}
          onChange={setShowInactive}
        />
        {searchInput}
      </div>
    </div>
  );

  const table = (
    <Fragment>
      <Table
        sortable
        celled
        striped
        selectable
        size="small"
        className="text-sm"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortingColumn === 'name' ? sortingDirection : undefined}
              onClick={changeSortingColumn('name')}
            >
              {formatMessage(m.name)}
            </Table.HeaderCell>   

            {showInactive && (
              <Table.HeaderCell
                sorted={
                  sortingColumn === 'isActive' ? sortingDirection : undefined
                }
                onClick={changeSortingColumn('isActive')}
                collapsing
              >
                {formatMessage(m.active)}
              </Table.HeaderCell>
            )}         
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {currentPageRows.map((row) => (
            <Table.Row
              key={row.id}
              onClick={() => handleRowClick(row.id)}
              className="tw-cursor-pointer"
            >
              <Table.Cell>{row.name}</Table.Cell>              
              
              {showInactive && (
                <Table.Cell collapsing>
                  <div className="tw-flex tw-justify-center">
                    <ActiveIndicator active={row.isActive} />
                  </div>
                </Table.Cell>
              )}
            </Table.Row>
          ))}
          {!hasRows && (
            <Table.Row>
              <Table.Cell colSpan="2">
                <EmptyStateMessage message={formatMessage(m.noRow)} />
              </Table.Cell>
            </Table.Row>
          )}

          {hasRows && !matchFound && (
            <Table.Row>
              <Table.Cell colSpan="2">
                <EmptyStateMessage message={formatMessage(m.noMatchFound)} />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Fragment>
  );

  const pagination = hasMultiplePages && (
    <Pagination
      totalPages={pageCount}
      activePage={currentPage}
      onPageChange={handlePageChange}
    />
  );

  const bottomBarCenteringClass = isMobile ? 'tw-justify-center' : undefined;

  const bottomBar = (
    <div className={`tw-flex tw-items-baseline ${bottomBarCenteringClass}`}>
      {pagination}
      <div className="tw-flex-1" />
      <Button as={Link} to="/admin/tapaj-progresses/new" primary>
        {formatMessage(m.new)}
      </Button>
    </div>
  );

  return (
    <Fragment>
      <ApplicationSegment>
        {topBar}
        {table}
        {bottomBar}
      </ApplicationSegment>
    </Fragment>
  );
};
