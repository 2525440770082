import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Table } from 'semantic-ui-react';
import { EmptyStateMessage } from 'components/EmptyStateMessage';
import { SortingDirection } from 'hooks/useTableSorting';
import { Headers } from './Headers';
import { Row } from './Row';

const m = defineMessages({
  recordperpage: {
    id: 'Entries.Table.RecordPerPage',
    defaultMessage: 'Entrées par page',
  },
  totalCount: {
    id: 'Entries.Table.TotalCount',
    defaultMessage: 'Nombre total',
  },
  noRow: {
    id: 'Entries.Table.NoRow',
    defaultMessage: "Il n'y a aucune entrée.",
  },
  noMatchFound: {
    id: 'Entries.Table.NoMatchFound',
    defaultMessage: 'Aucune entrée ne correspond à votre recherche.',
  },
});

export interface EntriesTableRow {
  id: number;
  date: string;
  teamName?: string;
  meetingTypeName?: string;
  populationName?: string;
  locationName?: string;
  districtsName?: string;
  createdByUserFullName: string;
  interventionTexts: string[];
}

interface Props {
  rows: EntriesTableRow[];
  isEmpty?: boolean;
  noMatchFound?: boolean;
  sortingColumn: string;
  sortingDirection: SortingDirection;
  visibleColumns?: (keyof EntriesTableRow)[];
  onColumnSorting: (columnName: string) => void;
}

export const EntriesTable: React.FC<Props> = ({
  rows,
  isEmpty,
  noMatchFound,
  sortingColumn,
  sortingDirection,
  visibleColumns,
  onColumnSorting,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Table sortable celled striped selectable size="small" className="text-sm">
      <Headers
        sortingColumn={sortingColumn}
        sortingDirection={sortingDirection}
        visibleColumns={visibleColumns}
        onColumnSorting={onColumnSorting}
      />

      <Table.Body>
        {rows.map((row) => (
          <Row key={row.id} row={row} visibleColumns={visibleColumns} />
        ))}
        {isEmpty && (
          <Table.Row>
            <Table.Cell colSpan="8">
              <EmptyStateMessage message={formatMessage(m.noRow)} />
            </Table.Cell>
          </Table.Row>
        )}
        {!isEmpty && noMatchFound && (
          <Table.Row>
            <Table.Cell colSpan="8">
              <EmptyStateMessage message={formatMessage(m.noMatchFound)} />
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};
