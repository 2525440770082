import { ApplicationSegment } from 'components/ApplicationSegment';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Grid, Header } from 'semantic-ui-react';
import { DemographyStat } from './DemographyStat';
import { EntryViewData } from './useEntryViewData';

const m = defineMessages({
  demography: { id: 'Entry.View.Demography', defaultMessage: 'Démographie' },
  onePerson: {
    id: 'Entry.View.Demography.OnePerson',
    defaultMessage: 'Une personne',
  },
  persons: { id: 'Entry.View.Demography.Persons', defaultMessage: 'personnes' },
  gender: { id: 'Entry.View.Demography.Gender', defaultMessage: 'Sexe' },
  origin: { id: 'Entry.View.Demography.Origin', defaultMessage: 'Origine' },
  ageGroup: {
    id: 'Entry.View.Demography.AgeGroup',
    defaultMessage: "Groupe d'âge",
  },
  occupation: {
    id: 'Entry.View.Demography.Occupation',
    defaultMessage: 'Occupation/revenu',
  },
  language: {
    id: 'Entry.View.Demography.Lanugage',
    defaultMessage: "Langue d'intervention",
  },
});

interface Props {
  viewData: EntryViewData;
}

export const DemographySection: React.FC<Props> = ({ viewData }) => {
  const { formatMessage } = useIntl();
  const {
    personCount,
    genderData,
    occupationData,
    originData,
    ageGroupData,
    languageData,
  } = viewData.demographies;

  return (
    <ApplicationSegment>
      <div className="tw-p-2">
        <div className="tw-flex">
          <div className="tw-mr-4">
            <Header color="purple" className="tw-mb-10">
              {formatMessage(m.demography)}
            </Header>
          </div>
          <div>
            {personCount <= 1
              ? formatMessage(m.onePerson)
              : `${personCount} ${formatMessage(m.persons)}`}
          </div>
        </div>
        <Grid columns={2} doubling>
          <Grid.Column className="tw-p-0">
            <div className="tw-max-w-xl tw-pl-8">
              <DemographyStat label={formatMessage(m.gender)} data={genderData ?? []} />
              <DemographyStat
                label={formatMessage(m.occupation)}
                data={occupationData ?? []}
              />
              <DemographyStat label={formatMessage(m.origin)} data={originData ?? []} />
            </div>
          </Grid.Column>
          <Grid.Column className="tw-p-0">
            <div className="tw-max-w-xl tw-pl-8">
              <DemographyStat label={formatMessage(m.ageGroup)} data={ageGroupData ?? []} />
              <DemographyStat
                label={formatMessage(m.language)}
                data={languageData ?? []}
              />
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </ApplicationSegment>
  );
};
