import { useEffect, useState } from 'react';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import { Entry } from 'modules/entry/types';
import { useEntryLists } from 'modules/entry/hooks/useEntryLists';

export interface DemographyChartData {
  name: string;
  count: number;
}

export interface InterventionData {
  name: string | null;
  value: string | number | null;
}

export interface EntryViewData {
  date: string;
  team: string;
  meetingType: string;
  population: string;
  location: string;
  district: string;
  moment: string;
  contactState: string;
  initiator: string;
  demographies: {
    genderData: DemographyChartData[];
    occupationData: DemographyChartData[];
    ageGroupData: DemographyChartData[];
    languageData: DemographyChartData[];
    originData: DemographyChartData[];
    personCount: number;
  };
  hasIntervention: boolean;
  interventions: InterventionData[] | null;
  interventionDurationInMinute: number | null;
  problematics: string | null;
  hasHomelessness: boolean;
  homelessnessSituation: string;
  homelessnesses: string[] | null;
  hasTapajProgress: boolean;
  tapajProgresses: string[] | null;
  createdAt: string;
  createdByUserFullName: string;
  updatedAt: string;
  updatedByUserFullName: string;
  referredBy?: string;
}

interface Output {
  viewData: EntryViewData | undefined;
  isReady: boolean;
}

export const useEntryViewData = (entry: Entry): Output => {
  const lists = useEntryLists();
  const [viewData, setViewData] = useState<EntryViewData>();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!entry || !lists.isReady) {
      setViewData(undefined);
      setIsReady(false);
      return;
    }

    const genderData = map(
      groupBy(entry.demographies ?? [], (demography) => demography.genderId),
      (grouped, genderId) => {
        return {
          name: lists.genders.indexedGenders[parseInt(genderId, 10)]?.name,
          count: sumBy(grouped, (x) => x.count),
        };
      }
    );

    const occupationData = map(
      groupBy(
        entry.demographies ?? [],
        (demography) => demography.occupationId
      ),
      (grouped, occupationId) => {
        return {
          name: lists.occupations.indexedOccupations[parseInt(occupationId, 10)]
            ?.name,
          count: sumBy(grouped, (x) => x.count),
        };
      }
    );

    const ageGroupData = map(
      groupBy(entry.demographies ?? [], (demography) => demography.ageGroupId),
      (grouped, ageGroupId) => {
        return {
          name: lists.ageGroups.indexedAgeGroups[parseInt(ageGroupId, 10)]
            ?.name,
          count: sumBy(grouped, (x) => x.count),
        };
      }
    );
    const languageData = map(
      groupBy(
        entry.demographies ?? [],
        (demography) => demography.interventionLanguageId
      ),
      (grouped, interventionLanguageId) => {
        return {
          name: lists.interventionLanguages.indexedInterventionLanguages[
            parseInt(interventionLanguageId, 10)
          ]?.name,
          count: sumBy(grouped, (x) => x.count),
        };
      }
    );

    const originData = map(
      groupBy(
        (entry.demographies ?? []).map((d) => ({
          origins: d.originIds
            .map((originId) => lists.origins.indexedOrigins[originId]?.name)
            .join(', '),
          count: d.count,
        })),
        (demography) => demography.origins
      ),
      (grouped, origins) => {
        return {
          name: origins,
          count: sumBy(grouped, (x) => x.count),
        };
      }
    );

    const interventions = (entry.interventions ?? []).map((i) => ({
      name: lists.interventions.indexedInterventions[i.interventionId]?.name,
      value: [i.textValue, i.numberValue, 'Oui'].filter((v) => v)[0],
    }));

    const problematics = (entry.problematicIds ?? [])
      .map((p) => lists.problematics.indexedProblematics[p]?.name)
      .join(', ');

    const homelessnesses = (entry.homelessnessIds ?? []).map(
      (id) => lists.homelessnesses.indexedHomelessnesses[id]?.name
    );

    const homelessnessSituation = entry.homelessnessSituationId
      ? lists.homelessnessSituations.indexedHomelessnessSituations[
          entry.homelessnessSituationId
        ]?.name
      : '';

    const tapajProgresses = (entry.tapajProgressIds ?? []).map(
      (id) => lists.tapajProgresses.indexedTapajProgresses[id]?.name
    );

    const personCount = (entry.demographies ?? []).reduce(
      (total, demography) => (total += demography.count),
      0
    );

    const data: EntryViewData = {
      date: entry.date,
      team: lists.teams.indexedTeams[entry.teamId]?.name,
      meetingType:
        lists.meetingTypes.indexedMeetingTypes[entry.meetingTypeId]?.name,
      population:
        lists.populations.indexedPopulations[entry.populationId]?.name,
      location: lists.locations.indexedLocations[entry.locationId]?.name,
      district: lists.districts.indexedDistricts[entry.districtId]?.name,
      moment: lists.moments.indexedMoments[entry.momentId]?.name,
      contactState:
        lists.contactStates.indexedContactStates[entry.contactStateId]?.name,
      initiator: lists.initiators.indexedInitiators[entry.initiatorId]?.name,
      demographies: {
        genderData: genderData,
        occupationData: occupationData,
        ageGroupData: ageGroupData,
        languageData: languageData,
        originData: originData,
        personCount: personCount,
      },
      hasIntervention: entry.hasIntervention,
      interventions: interventions,
      interventionDurationInMinute: entry.interventionDurationInMinute,
      problematics: problematics,
      hasHomelessness: entry.hasHomelessness,
      homelessnessSituation: homelessnessSituation,
      homelessnesses: homelessnesses,
      hasTapajProgress: entry.hasTapajProgress,
      tapajProgresses: tapajProgresses,
      createdAt: entry.createdAt,
      createdByUserFullName: entry.createdByUserFullName,
      updatedAt: entry.updatedAt,
      updatedByUserFullName: entry.updatedByUserFullName,
      referredBy: entry.referredBy,
    };

    setViewData(data);
    setIsReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry, lists.isReady]);

  return { viewData: viewData, isReady: isReady };
};
