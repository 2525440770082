import React, { createContext, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Button, Form } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { BaseForm } from './BaseForm';
import { DemographiesSection } from './DemographiesSection';
import { InterventionsSection } from './InterventionsSection';
import { EntryLists, useEntryLists } from 'modules/entry/hooks/useEntryLists';
import { useResponsive } from 'hooks/useResponsive';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { TapajSection } from './TapajSection';
import { useEntryForm } from 'modules/entry/hooks/useEntryForm';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { useEntryValidations } from 'modules/entry/hooks/useEntryValidations';

const tapajCapabTeamIds = [3, 9];

export enum MeetingType {
  Single = 1,
  Group = 2,
}

const m = defineMessages({
  save: {
    id: 'Entry.Form.Save',
    defaultMessage: 'Sauvegarder',
  },
});

export interface EntryDemographyFormValues {
  id?: number;
  count: number | null;
  genderId: number | null;
  ageGroupId: number | null;
  occupationId: number | null;
  originIds: number[];
  interventionLanguageId: number | null;
}

export interface EntryInterventionFormValues {
  interventionId: number | null;
  textValue?: string | string[] | null;
  numberValue?: number | null;
}

export type DemographySectionMobileMode = 'list' | 'form';

export interface EntryFormValues {
  teamId: number | null;
  date: string | null;
  meetingTypeId: number | null;
  populationId: number | null;
  locationId: number | null;
  districtId: number | null;
  momentId: number | null;
  contactStateId: number | null;
  initiatorId: number | null;
  hasIntervention: boolean | null;
  hasHomelessness: boolean | null;
  interventionDurationInMinute: number;
  demographies: EntryDemographyFormValues[];
  interventions: EntryInterventionFormValues[];
  problematicIds: number[];
  homelessnessIds: number[];
  homelessnessSituationId?: number | null;
  hasTapajProgress: boolean | null;
  tapajProgressIds: number[];
  referredBy?: string;
  demographySectionMobileMode?: DemographySectionMobileMode;
}

export interface EntryFormContextType {
  lists: EntryLists | null;
}

export const EntryFormContext = createContext<EntryFormContextType>({
  lists: null,
});

interface Props {
  entry: EntryFormValues;
  onSubmit: (entry: EntryFormValues) => void;
}

export const EntryForm: React.FC<Props> = ({ entry, onSubmit }) => {
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();

  const { prepareEntryForForm, prepareFormForSubmission } = useEntryForm();
  const validations = useEntryValidations();
  
  const formMethods = useForm<EntryFormValues>();
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    register,
    formState: { errors },
  } = formMethods;

  register('demographySectionMobileMode', {
    validate: validations.demographySectionMobileMode,
  });

  const lists = useEntryLists();

  const hasIntervention = useWatch({
    control,
    name: 'hasIntervention',
    defaultValue: true,
  });

  const formDemographies = useWatch({
    control,
    name: 'demographies',
    defaultValue: [],
  });

  const formTeamId = useWatch({
    control,
    name: 'teamId',
    defaultValue: 0,
  });

  useEffect(() => {
    if (hasIntervention === false) {
      setValue('hasHomelessness', false);
    }
  }, [hasIntervention, setValue]);

  useEffect(() => {
    const personCount = formDemographies.reduce(
      (total, demography) =>
        total + ((demography as EntryDemographyFormValues).count ?? 0),
      0
    );
    const meetingTypeId =
      personCount > 1 ? MeetingType.Group : MeetingType.Single;

    setValue('meetingTypeId', meetingTypeId);
  }, [formDemographies, setValue]);

  useEffect(() => {
    prepareEntryForForm(entry);
    reset(entry);
  }, [entry, reset, prepareEntryForForm]);

  const handleOnSubmit = (newEntry: EntryFormValues) => {
    prepareFormForSubmission(newEntry);
    onSubmit(newEntry);
  };

  const ErrorMessage: React.FC = ({ children }) => {
    return <div className="tw-text-red tw-mb-4">{children}</div>;
  };

  const isLoaded = lists.isReady;

  if (!isLoaded) {
    return <ApplicationLoader />;
  }

  return (
    <>
      <EntryFormContext.Provider value={{ lists }}>
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(handleOnSubmit)}>
            <ApplicationSegment>
              <BaseForm isMobile={isMobile} />
            </ApplicationSegment>

            <DemographiesSection isMobile={isMobile} />
            <InterventionsSection isMobile={isMobile} />

            {formTeamId && tapajCapabTeamIds.includes(formTeamId) && (
              <ApplicationSegment>
                <TapajSection isMobile={isMobile} />
              </ApplicationSegment>
            )}

            {errors.demographySectionMobileMode && (
              <ApplicationSegment>
                <ErrorMessage>
                  {errors.demographySectionMobileMode.message}
                </ErrorMessage>
              </ApplicationSegment>
            )}

            {isMobile && (
              <div
                className="tw-p-6 tw-text-white tw-text-xl tw-font-bold tw-text-center tw-cursor-pointer"
                onClick={handleSubmit(handleOnSubmit)}
              >
                {formatMessage(m.save)}
              </div>
            )}

            {!isMobile && (
              <div className="tw-flex tw-justify-end tw-mb-6">
                <Button type="submit" primary>
                  {formatMessage(m.save)}
                </Button>
              </div>
            )}
          </Form>
        </FormProvider>
      </EntryFormContext.Provider>
    </>
  );
};
