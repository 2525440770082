import React from 'react';
import { DemographyChart } from './DemographyChart';
import { DemographyChartData } from './useEntryViewData';

interface Props {
  label: string;
  data: DemographyChartData[];
}

export const DemographyStat: React.FC<Props> = ({ label, data }) => {
  return (
    <div className="tw-mb-4">
      <div className="tw-text-purple tw-font-bold">{label}</div>
      <div className="tw-ml-6">
        <DemographyChart data={data} />
      </div>
    </div>
  );
};
