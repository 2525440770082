import React from 'react';
import { Form, Header, Segment, Input } from 'semantic-ui-react';
import { MSButton } from 'components/MSButton';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { RootState } from 'store';

const m = defineMessages({
  subtitle: {
    id: 'LoginPage.Subtitle',
    defaultMessage: 'Connexion',
  },
  message: {
    id: 'LoginPage.Message',
    defaultMessage: "Veuillez-vous identifier pour accéder a l'application",
  },
});

export const ExternalLoginPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const referer =
    useSelector((state: RootState) => state.router.location?.pathname) || '/';
  const match = useRouteMatch(referer);

  return (
    <div className="tw-flex tw-items-center tw-h-screen">
      <div className="tw-max-w-lg tw-mx-auto tw-text-center">
        <div className="tw-flex tw-flex-col tw-items-center tw-p-4">
          <img src="/logo.png" className="tw-w-4/5 tw-mb-8" alt="logo" />
          <Form size="large" action="/api/auth" method="post">
            <Segment>
              <Header as="h3">{formatMessage(m.subtitle)}</Header>
              <p>{formatMessage(m.message)}</p>
              <Input
                type="hidden"
                name="returnUrl"
                value={match ? '/' : referer}
              />
              <div className="tw-flex tw-justify-center tw-mt-2">
                <MSButton />
              </div>
            </Segment>
          </Form>
        </div>
      </div>
    </div>
  );
};
