import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface Props extends RouteProps {
  isAuthorized?: boolean;
}

export const PrivateRoute: React.FC<Props> = ({ isAuthorized, ...props }) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.account.isAuthenticated
  );  

  isAuthorized = isAuthorized ?? true;

  if (!isAuthorized) {
    return <Redirect to="/error/403" />;
  }

  return (
    <Route
      render={({ location }) =>
        isAuthenticated ? (
          <Route {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
