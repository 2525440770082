import React from 'react';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import { defaultApi } from 'api';
import 'moment/locale/fr';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';

registerLocale('fr', fr);

const translations = {
  'en-CA': require('translations/en-CA.json'),
};

interface Props {
  children?: React.ReactNode;
}

export const ConnectedIntlProvider: React.FC<Props> = ({ children }) => {
  const language = 'fr-CA';
  const defaultLocale = 'fr-CA';
  const messages =
    language === defaultLocale ? undefined : translations[language];

  defaultApi.defaults.headers.common['accept-language'] = language;
  moment.locale(language);
  setDefaultLocale('fr');

  return (
    <IntlProvider
      locale={language}
      messages={messages}
      defaultLocale={defaultLocale}
    >
      {children}
    </IntlProvider>
  );
};
