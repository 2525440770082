import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ErrorPageLayout } from 'components/Layout/ErrorPageLayout';
import { ErrorMessage } from '../components/ErrorMessage';
import { LoginButton } from '../components/LoginButton';
import { BackButton } from '../components/BackButton';

import { ReactComponent as ForbiddenImage } from '../img/forbidden.svg';

const m = defineMessages({
  header: {
    id: 'Errors.ErrorPage.Forbidden.Header',
    defaultMessage: "Oups... on dirait que tu n'a pas accès",
  },
  subHeader: {
    id: 'Errors.ErrorPage.Forbidden.SubHeader',
    defaultMessage: 'Réessayez avec un autre compte.',
  },
});

export const ForbiddenError: React.FC = () => {
  const { formatMessage } = useIntl();

  const buttons = (
    <>
      <BackButton basic primary />
      <LoginButton primary />
    </>
  );  

  return (
    <ErrorPageLayout>
      <ErrorMessage
        headerMessage={formatMessage(m.header)}
        subHeaderMessage={formatMessage(m.subHeader)}
        errorImage={ForbiddenImage}
        buttons={buttons}
      />
    </ErrorPageLayout>
  );
};
