import React, { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { Form, Grid } from 'semantic-ui-react';
import { EntryQuery } from 'modules/entry/utils/entryQuery';
import { MobileFriendlyFormDropdown } from 'components/MobileFriendlyDropdown';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { ReferredByDropdown } from './ReferredByDropdown';
import { EntryQueryContext } from 'modules/entry/components/EntryQueryContext';

const m = defineMessages({
  caseworkers: {
    id: 'EntryQueryForm.Caseworkers',
    defaultMessage: 'Intervenants',
  },
  allCaseworkers: {
    id: 'EntryQueryForm.AllCaseworkers',
    defaultMessage: 'Tous les intervenants',
  },
  teams: { id: 'EntryQueryForm.Teams', defaultMessage: 'Équipes' },
  allTeams: {
    id: 'EntryQueryForm.AllTeams',
    defaultMessage: 'Toutes les équipes',
  },
  meetingTypes: {
    id: 'EntryQueryForm.MeetingTypes',
    defaultMessage: 'Types de rencontre',
  },
  allMeetingTypes: {
    id: 'EntryQueryForm.AllMeetingTypes',
    defaultMessage: 'Tous les types de rencontre',
  },
  populations: {
    id: 'EntryQueryForm.Population',
    defaultMessage: 'Populations rejointes',
  },
  allPopulations: {
    id: 'EntryQueryForm.AllPopulations',
    defaultMessage: 'Toutes les populations rejointes',
  },
  locations: {
    id: 'EntryQueryForm.Locations',
    defaultMessage: "Lieux d'activité",
  },
  allLocations: {
    id: 'EntryQueryForm.AllLocations',
    defaultMessage: "Tous les lieux d'activité",
  },
  districts: { id: 'EntryQueryForm.Districts', defaultMessage: 'Arrondissements' },
  allDistricts: {
    id: 'EntryQueryForm.AllDistricts',
    defaultMessage: 'Tous les arrondissements',
  },
  moments: { id: 'EntryQueryForm.Moments', defaultMessage: 'Moments' },
  allMoments: {
    id: 'EntryQueryForm.AllMoments',
    defaultMessage: 'Tous les moments',
  },
  contactStates: {
    id: 'EntryQueryForm.ContactStates',
    defaultMessage: 'États du contact',
  },
  allContactStates: {
    id: 'EntryQueryForm.AllContactStates',
    defaultMessage: 'Tous les états du contact',
  },
  initiators: {
    id: 'EntryQueryForm.Initiators',
    defaultMessage: 'Initiateurs du contact',
  },
  allInitiators: {
    id: 'EntryQueryForm.AllInitiators',
    defaultMessage: 'Tous les initiateurs du contact',
  },
  referredBy: {
    id: 'EntryQueryForm.ReferredBy',
    defaultMessage: 'Référé par',
  },
});

interface Props {
  isMobile: boolean;
  onChange: () => void;
}

export const EntrySection: React.FC<Props> = ({
  isMobile,
  onChange,
}) => {
  const { formatMessage } = useIntl();
  const { lists, users } = useContext(EntryQueryContext);

  const { control } = useFormContext<EntryQuery>();

  const handleChange = () => onChange();

  const createdByCaseworkersField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.caseworkers)}</label>
      <Controller
        control={control}
        name="createdByCaseworkers"
        render={({ field: { onBlur, value, onChange} }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={users?.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allCaseworkers)}
            isMobile={isMobile}
          />
        )}
      />
    </Form.Field>
  );

  const teamsField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.teams)}</label>
      <Controller
        control={control}
        name="teams"
        render={({ field: { onBlur, value, onChange} }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.teams.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allTeams)}
            isMobile={isMobile}
          />
        )}
      />
    </Form.Field>
  );

  const meetingTypesField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.meetingTypes)}</label>
      <Controller
        control={control}
        name="meetingTypes"
        render={({ field: { onBlur, value, onChange} }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.meetingTypes.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allMeetingTypes)}
            isMobile={isMobile}
          />
        )}
      />
    </Form.Field>
  );

  const populationsField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.populations)}</label>
      <Controller
        control={control}
        name="populations"
        render={({ field: { onBlur, value, onChange} }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.populations.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allPopulations)}
            isMobile={isMobile}
          />
        )}
      />
    </Form.Field>
  );

  const locationsField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.locations)}</label>
      <Controller
        control={control}
        name="locations"
        render={({ field: { onBlur, value, onChange} }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.locations.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allLocations)}
            isMobile={isMobile}
          />
        )}
      />
    </Form.Field>
  );

  const districtsField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.districts)}</label>
      <Controller
        control={control}
        name="districts"
        render={({ field: { onBlur, value, onChange} }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.districts.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allDistricts)}
            isMobile={isMobile}
          />
        )}
      />
    </Form.Field>
  );

  const momentsField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.moments)}</label>
      <Controller
        control={control}
        name="moments"
        render={({ field: { onBlur, value, onChange} }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.moments.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allMoments)}
            isMobile={isMobile}
          />
        )}
      />
    </Form.Field>
  );

  const contactStatesField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.contactStates)}</label>
      <Controller
        control={control}
        name="contactStates"
        render={({ field: { onBlur, value, onChange} }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.contactStates.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allContactStates)}
            isMobile={isMobile}
          />
        )}
      />
    </Form.Field>
  );

  const initiatorsField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.initiators)}</label>
      <Controller
        control={control}
        name="initiators"
        render={({ field: { onBlur, value, onChange} }) => (
          <MobileFriendlyFormDropdown
            onChange={(e, { value }) => {
              onChange(value);
              handleChange();
            }}
            onBlur={onBlur}
            value={value}
            options={lists?.initiators.dropdownItems}
            fluid
            selection
            multiple
            search
            placeholder={formatMessage(m.allInitiators)}
            isMobile={isMobile}
          />
        )}
      />
    </Form.Field>
  );

  const referredByField = (
    <Form.Field>
      <label className="tw-text-purple">{formatMessage(m.referredBy)}</label>
      <Controller
        control={control}
        name="referredBy"
        render={({ field: { value, onChange} }) => (
          <ReferredByDropdown
            onChange={(value) => {
              onChange(value);
              handleChange();
            }}
            value={value}
          />
        )}
      />
    </Form.Field>
  );

  return (
    <ApplicationSegment>
      <div className="tw-p-4">
        <Grid columns="2" doubling>
          <Grid.Column>{createdByCaseworkersField}</Grid.Column>
          <Grid.Column>{teamsField}</Grid.Column>
          <Grid.Column>{meetingTypesField}</Grid.Column>
          <Grid.Column>{populationsField}</Grid.Column>
          <Grid.Column>{locationsField}</Grid.Column>
          <Grid.Column>{districtsField}</Grid.Column>
          <Grid.Column>{momentsField}</Grid.Column>
          <Grid.Column>{contactStatesField}</Grid.Column>
          <Grid.Column>{initiatorsField}</Grid.Column>
          <Grid.Column>{referredByField}</Grid.Column>
        </Grid>
      </div>
    </ApplicationSegment>
  );
};
