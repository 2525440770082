import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import moment from 'moment';
import { PageLayout } from 'components/Layout/PageLayout';
import { PageHeader } from 'components/Layout/PageHeader';
import { DashboardApi } from '../api';
import { Entry } from 'modules/entry/types';
import {
  DashboardStats,
  SubsetDetailedStats,
  useDashboardStats,
} from '../hooks/useDashboardStats';
import { defaultErrorHandler } from 'api';
import { DashboardView } from '../components/DashboardView/DashboardView';
import { DashboardTab, Tabs } from '../components/DashboardView/Tabs';
import { EmptyStateMessage } from 'components/EmptyStateMessage';
import { ApplicationSegment } from 'components/ApplicationSegment';

const m = defineMessages({
  pageTitle: {
    id: 'Dashboard.Page.Title',
    defaultMessage: 'Tableau de bord',
  },
  noEntry: {
    id: 'Dashboard.Page.NoEntryMessage',
    defaultMessage: 'Aucune entrée',
  },
});

export const DashboardPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const [stats, setStats] = useState<DashboardStats>();
  const [filteredStats, setFileteredStats] = useState<SubsetDetailedStats>();
  const [entries, setEntries] = useState<Entry[]>([]);
  const [periodRange, setPeriodRange] = useState<moment.Moment[]>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentTab, setCurrentTab] = useState<DashboardTab>(
    DashboardTab.MyEntries
  );

  const { getDashboardStats, isReady: useDashboardStatsReady } =
    useDashboardStats();

  useEffect(() => {
    if (!stats) {
      setFileteredStats(undefined);
      return;
    }    

    switch (currentTab) {
      case DashboardTab.AllEntries:
        setFileteredStats(stats.allEntries);
        break;
      case DashboardTab.MyTeamEntries:
        setFileteredStats(stats.myTeamEntriesStats);
        break;
      default:
        setFileteredStats(stats.myEntriesStats);
    }
  }, [stats, currentTab]);

  useEffect(() => {
    if (!periodRange) {
      return;
    }
    
    DashboardApi.get({
      fromDate: periodRange[0].toISOString(),
      toDate: periodRange[1].toISOString(),
    })
      .then((fetchedEntries) => {
        setEntries(fetchedEntries);
      })
      .catch((error) => {
        defaultErrorHandler(error);
      })
      .finally(() => setIsLoaded(true));
  }, [periodRange]);

  useEffect(() => {
    if (!entries.length || !useDashboardStatsReady) {
      setStats(undefined);
      return;
    }

    setStats(getDashboardStats(entries));
  }, [entries, getDashboardStats, useDashboardStatsReady]);

  
  return (
    <PageLayout pageTitle={formatMessage(m.pageTitle)}>
      <PageHeader header={formatMessage(m.pageTitle)} iconName="th large" />
      
      <Tabs
        currentTab={currentTab}
        onTabChange={setCurrentTab}
        onPeriodRangeChange={setPeriodRange}
      />

      {filteredStats && <DashboardView stats={filteredStats} />}

      {isLoaded && !filteredStats && (
        <ApplicationSegment>
          <EmptyStateMessage message={formatMessage(m.noEntry)} />
        </ApplicationSegment>
      )}
    </PageLayout>
  );
};
