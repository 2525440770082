import { Reducer } from 'react';
import { ApplicationActionTypes, ApplicationState } from '.';
import * as actionTypes from './actionTypes';

const initialState: ApplicationState = {
  isLoaded: false,
  globalToast: null,
  sideMenuState: 'wide',
};

export const applicationReducer: Reducer<
  ApplicationState,
  ApplicationActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_APPLICATION_COMPLETED:
      return {
        ...state,
        isLoaded: true,
      };
    case actionTypes.SHOW_GLOBAL_TOAST_NOTIFICATION:
      return {
        ...state,
        globalToast: action.payload,
      };
    case actionTypes.SET_SIDE_MENU_STATE:
      return {
        ...state,
        sideMenuState: action.payload,
      };
    default:
      return state;
  }
};
