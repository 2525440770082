import React, { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  EntryFormContext,
  EntryFormContextType,
  EntryFormValues,
} from './EntryForm';
import { Checkbox, Form, Grid, Header } from 'semantic-ui-react';
import { MobileFriendlyFormDropdown } from 'components/MobileFriendlyDropdown';

const m = defineMessages({
  title: {
    id: 'Entry.Form.TapajProgresss.Title',
    defaultMessage: "CAPAB & TAPAJ",
  },
  hasTapajProgress: {
    id: 'Entry.Form.TapajProgresss.HasTapajProgress',
    defaultMessage: "Y a-t-il eu du progrès ?",
  },
  progress: {
    id: 'Entry.Form.TapajProgresss.Progresses',
    defaultMessage: 'Progrès',
  },
  yes: {
    id: 'Yes',
    defaultMessage: 'Oui',
  },
  no: {
    id: 'No',
    defaultMessage: 'Non',
  },
});

interface Props {
  isMobile: boolean;
}

export const TapajSection: React.FC<Props> = ({ isMobile }) => {
  const { formatMessage } = useIntl();

  const { lists } = useContext(EntryFormContext) as EntryFormContextType;  

  const { control } = useFormContext<EntryFormValues>();

  const hasTapajProgress = useWatch({
    control,
    name: 'hasTapajProgress',
    defaultValue: false,
  });

  const tapajVisibilityClass = hasTapajProgress ? '' : 'tw-hidden';

  return (
    <>
      <Header size="small">{formatMessage(m.title)}</Header>
      
      <Form.Field>
        <label>{formatMessage(m.hasTapajProgress)}</label>
        <Controller
          control={control}
          name="hasTapajProgress"
          render={({ field: { onBlur, value, onChange} }) => (
            <div className="tw-flex tw-items-center">
              <Checkbox
                onChange={(e, data) => onChange(data.checked)}
                onBlur={onBlur}
                checked={Boolean(value)}
                toggle
              />
              <div className="tw-ml-6">
                {value ? formatMessage(m.yes) : formatMessage(m.no)}
              </div>
            </div>
          )}
        />
      </Form.Field>

      <Grid columns={2} doubling className={tapajVisibilityClass}>
        <Grid.Column>
          <Form.Field>
            <label>{formatMessage(m.progress)}</label>
            <Controller
              control={control}
              name="tapajProgressIds"
              render={({ field: { value, onChange} }) => (
                <MobileFriendlyFormDropdown
                  fluid
                  multiple
                  search
                  selection
                  options={lists?.tapajProgresses.dropdownItems}
                  value={value}
                  onChange={(e, data) => onChange(data.value)}
                  isMobile={isMobile}
                />
              )}
            />
          </Form.Field>
        </Grid.Column>
      </Grid>
    </>
  );
};
