import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { defineMessages, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { defaultErrorHandler } from 'api';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { PageHeader } from 'components/Layout/PageHeader';
import { PageLayout } from 'components/Layout/PageLayout';
import { MealsApi } from '../api';
import { MealForm, MealFormValues } from '../components/MealForm/MealForm';
import { useFetchMeal } from '../hooks/useFetchMeal';

const m = defineMessages({
  pageTitle: {
    id: 'Meal.EditPage.Title',
    defaultMessage: 'Modifier un repas',
  },
  savedSuccessfully: {
    id: 'Meals.EditPage.SavedSuccessfully',
    defaultMessage: "Le repas a été sauvegardé avec succès",
  },
  deletedSuccessfully: {
    id: 'Meals.EditPage.DeletedSuccessfully',
    defaultMessage: "Le repas a été supprimé avec succès",
  },
});

interface Params {
  mealId: string;
}

export const EditMealPage : React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const params = useParams<Params>();
  const id = parseInt(params.mealId, 10);
  const { meal } = useFetchMeal(id);
  const [saving, setSaving] = useState(false);
  
  const returnToMealsPage = () => {
    history.push(`/meals`);
  };

  const handleSubmit = (editedMeal: MealFormValues) => {
    setSaving(true);

    MealsApi.update(id, editedMeal)
      .then(() => {
        toast.success(formatMessage(m.savedSuccessfully));
        returnToMealsPage();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };

  const handleDelete = () => {
    setSaving(true);

    MealsApi.delete(id)
      .then(() => {
        toast.success(formatMessage(m.savedSuccessfully));
        returnToMealsPage();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };  

  const showLoader = !meal || saving;

  return (
    <>
      <PageLayout pageTitle={formatMessage(m.pageTitle)}>
        <PageHeader
          header={formatMessage(m.pageTitle)}
          iconName="food"
        />

        {meal && (
          <ApplicationSegment>
            <MealForm
              meal={meal}
              onSubmit={handleSubmit}
              onDelete={handleDelete}
            />
          </ApplicationSegment>
        )}

        {showLoader && <ApplicationLoader />}
      </PageLayout>
    </>
  )
}
