import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Dropdown, Header, Pagination } from 'semantic-ui-react';
import { SortingDirection } from 'hooks/useTableSorting';
import { useEntryTableLists } from 'modules/entry/hooks/useEntriesTableLists';
import { Entry } from 'modules/entry/types';
import { useTable } from 'hooks/useTable';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { pageSizeOptions } from 'hooks/useTablePaginating';
import { ApplicationSegment } from 'components/ApplicationSegment';
import { useResponsive } from 'hooks/useResponsive';
import { EntriesTable, EntriesTableRow } from '../EntriesTable/EntriesTable';

const m = defineMessages({
  title: {
    id: 'AdvancedSearch.Entries.Table.Title',
    defaultMessage: 'Résultat de la recherche',
  },
  recordperpage: {
    id: 'Entries.Table.RecordPerPage',
    defaultMessage: 'Entrées par page',
  },
  totalCount: {
    id: 'Entries.Table.TotalCount',
    defaultMessage: 'Nombre total',
  },
  noRow: {
    id: 'Entries.Table.NoRow',
    defaultMessage: "Il n'y a aucune entrée.",
  },
});

const defaultSortingColumn = 'date';
const defaultSortingDirection: SortingDirection = 'ascending';

export interface AdvancedSearchResultsTableOptions {
  currentPage?: string | number;
  pageSize?: string | number;
  sortingColumn?: string;
  sortingDirection?: SortingDirection;
}

interface Props {
  entries: Entry[];
  options: AdvancedSearchResultsTableOptions;
  onOptionsChange: (options: AdvancedSearchResultsTableOptions) => void;
}

export const AdvancedSearchResultsTableLayout: React.FC<Props> = ({
  entries,
  options,
  onOptionsChange,
}) => {
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();

  const {
    teams,
    meetingTypes,
    populations,
    locations,
    districts,
    isReady: areListsReady,
  } = useEntryTableLists();

  const {
    currentPageRows,
    setRows,
    hasRows,
    matchFound,
    hasMultiplePages,
    pageCount,
    sortingColumn,
    sortingDirection,
    currentPage,
    filteredRows,
    pageSize,
    areRowsReady,
    changePage,
    changePageSize,
    changeSorting,
  } = useTable<EntriesTableRow>({
    ...options,
    sortingColumn: options.sortingColumn ?? defaultSortingColumn,
    sortingDirection: options.sortingDirection ?? defaultSortingDirection,
  });

  const isLoaded = areListsReady && areRowsReady;

  useEffect(() => {
    changeSorting(
      options.sortingColumn ?? defaultSortingColumn,
      options.sortingDirection ?? defaultSortingDirection
    );
  }, [changeSorting, options.sortingColumn, options.sortingDirection]);

  useEffect(() => {
    changePage(options.currentPage);
  }, [changePage, options.currentPage]);

  useEffect(() => {
    changePageSize(options.pageSize);
  }, [changePageSize, options.pageSize]);

  useEffect(() => {
    if (!areListsReady) return;

    const entryRows: EntriesTableRow[] = entries.map((entry) => ({
      id: entry.id,
      date: entry.date,
      teamName: teams[entry.teamId]?.name,
      meetingTypeName: meetingTypes[entry.meetingTypeId]?.name,
      populationName: populations[entry.populationId]?.name,
      locationName: locations[entry.locationId]?.name,
      districtsName: districts[entry.districtId]?.name,
      createdByUserFullName: entry.createdByUserFullName,
      interventionTexts: (entry.interventions ?? [])
        .filter((intervention) => intervention.textValue)
        .map((intervention) => intervention.textValue as string),
    }));

    setRows(entryRows);
  }, [
    areListsReady,
    districts,
    entries,
    locations,
    meetingTypes,
    populations,
    setRows,
    teams,
  ]);

  const handleSelectSortingColumn = (selectedColumn: string) => {
    const reverseDirection =
      sortingDirection === 'ascending' ? 'descending' : 'ascending';

    const direction =
      selectedColumn !== sortingColumn
        ? defaultSortingDirection
        : reverseDirection;

    onOptionsChange({
      ...options,
      sortingColumn: selectedColumn,
      sortingDirection: direction,
    });
  };

  const handleSelectPage = (selectedPage: any) => {
    onOptionsChange({ ...options, currentPage: selectedPage as string });
  };

  const handleSelectPageSize = (selectedPageSize: any) => {
    onOptionsChange({
      ...options,
      pageSize: selectedPageSize as string,
      currentPage: 1,
    });
  };

  if (!isLoaded) {
    return <ApplicationLoader />;
  }

  const title = (
    <div className="tw-pr-4">
      <Header color="purple">{formatMessage(m.title)}</Header>
    </div>
  );

  const totalCountAndRecordPerPage = matchFound && (
    <div>
      <div>
        {formatMessage(m.recordperpage)}:{' '}
        <Dropdown
          inline
          options={pageSizeOptions}
          value={pageSize}
          onChange={(event, { value }) => handleSelectPageSize(value)}
        />
      </div>
      <div>
        {formatMessage(m.totalCount)}: {filteredRows.length}
      </div>
    </div>
  );

  const topBar = hasRows && (
    <div className="tw-flex tw-items-center tw-justify-start tw-mb-6">
      {totalCountAndRecordPerPage}
    </div>
  );

  const table = (
    <EntriesTable
      rows={currentPageRows}
      noMatchFound={!hasRows}
      sortingColumn={sortingColumn}
      sortingDirection={sortingDirection}
      onColumnSorting={handleSelectSortingColumn}
    />
  );

  const pagination = hasMultiplePages && (
    <Pagination
      totalPages={pageCount}
      activePage={currentPage}
      onPageChange={(event, { activePage }) => handleSelectPage(activePage)}
    />
  );

  const bottomBarCenteringClass = isMobile ? 'tw-justify-center' : undefined;

  const bottomBar = (
    <div className={`tw-flex tw-items-baseline ${bottomBarCenteringClass}`}>
      {pagination}
    </div>
  );

  return (
    <>
      <ApplicationSegment>
        {title}
        {topBar}
        {table}
        {bottomBar}
      </ApplicationSegment>
    </>
  );
};
