import { defineMessages, useIntl } from 'react-intl';

const m = defineMessages({
  teamIsRequired: {
    id: 'Meal.Validation.Team.IsRequired',
    defaultMessage: "L'équipe est requise",
  },
  dateIsRequired: {
    id: 'Meal.Validation.Date.IsRequired',
    defaultMessage: 'La date est requise',
  },
  mealCountIsRequired: {
    id: 'Meal.Validation.MealCount.IsRequired',
    defaultMessage: 'Le nombre de repas est requis',
  },
});

export const useMealValidations = () => {
  const { formatMessage } = useIntl();

  const validations = {
    teamId: (value: number | null) =>
      Boolean(value) || formatMessage(m.teamIsRequired),
    date: (value: string) => Boolean(value) || formatMessage(m.dateIsRequired),
    count: (value: number) =>
      Boolean(value) || formatMessage(m.mealCountIsRequired),
  };

  return validations;
};
