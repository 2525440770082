import { ApplicationSegment } from 'components/ApplicationSegment';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Dimmer, Divider, Icon, Loader } from 'semantic-ui-react';

const m = defineMessages({
  entries: {
    id: 'Report.StatPreview.Entries',
    defaultMessage: 'Entrées',
  },
  persons: {
    id: 'Report.StatPreview.Persons',
    defaultMessage: 'Personnes',
  },
});

interface Props {
  entryCount: number;
  personCount: number;
  isFetchingEntries: boolean;
}

export const StatPreview: React.FC<Props> = ({ entryCount, personCount, isFetchingEntries }) => {
  const { formatMessage } = useIntl();
  
  const statsVisibilityClass = isFetchingEntries ? 'tw-invisible' : 'tw-visible';
  return (
    <ApplicationSegment>
      <Dimmer active={isFetchingEntries} inverted>
        <Loader />
      </Dimmer>
      <div className={statsVisibilityClass}>
        <div className="tw-py-3">
          <div className="tw-flex tw-text-purple">
            <div className="tw-w-1/2 tw-flex tw-justify-center tw-items-center tw-text-center">
              <div>
                <div className="tw-text-4xl tw-text-green">{entryCount}</div>
                <div>{formatMessage(m.entries)}</div>
              </div>
            </div>
            <div className="tw-w-1/2 tw-flex tw-justify-center tw-items-center">
              <Icon name="file alternate" size="big" />
            </div>
          </div>

          <Divider />

          <div className="tw-flex tw-text-purple">
            <div className="tw-w-1/2 tw-flex tw-justify-center tw-items-center tw-text-center">
              <div>
                <div className="tw-text-4xl tw-text-green">{personCount}</div>
                <div>{formatMessage(m.persons)}</div>
              </div>
            </div>
            <div className="tw-w-1/2 tw-flex tw-justify-center tw-items-center">
              <Icon name="user" size="big" />
            </div>
          </div>
        </div>
      </div>
    </ApplicationSegment>
  );
};
