import React, { Fragment, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { defaultErrorHandler } from 'api';
import { Segment } from 'semantic-ui-react';
import { TeamForm, TeamFormValues } from '../components/TeamForm';
import { TeamsApi } from '../api';
import { PageLayout } from 'components/Layout/PageLayout';
import { PageHeader } from 'components/Layout/PageHeader';
import { ApplicationLoader } from 'components/ApplicationLoader';

const m = defineMessages({
  pageTitle: { id: 'Team.NewPage.Title', defaultMessage: 'Nouvelle équipe' },
  savedSuccessfully: {
    id: 'Team.NewPage.SavedSuccessfully',
    defaultMessage: "L'équipe a été sauvegardé avec succès",
  },
});

export const NewTeamPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [saving, setSaving] = useState(false);

  const emtpyTeam: TeamFormValues = {
    name: '',
    isActive: true,
  };

  const returnToTeamsPage = () => {
    history.push(`/admin/teams`);
  };

  const handleSubmit = (data: TeamFormValues) => {
    setSaving(true);

    TeamsApi.create(data)
      .then(() => {
        toast.success(formatMessage(m.savedSuccessfully));
        returnToTeamsPage();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };

  return (
    <Fragment>
      <PageLayout pageTitle={formatMessage(m.pageTitle)}>
        <PageHeader header={formatMessage(m.pageTitle)} iconName="users" />
        <Segment>
          <TeamForm team={emtpyTeam} onSubmit={handleSubmit} />
        </Segment>
        {saving && <ApplicationLoader />}
      </PageLayout>
    </Fragment>
  );
};
