import countBy from 'lodash/countBy';
import groupBy from 'lodash/groupBy';
import has from 'lodash/has';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import uniq from 'lodash/uniq';
import { MeetingType } from 'modules/entry/components/EntryForm';
import { EntryLists } from 'modules/entry/hooks/useEntryLists';
import { Entry, EntryDemography } from 'modules/entry/types';
import { EntryQueryResult } from 'modules/entry/utils/entryQueryResult';
import { UserList } from 'modules/user/hooks/useFetchUsers';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  CountItemStat,
  DefaultGroupedDemographiesStat,
  DefaultGroupedEntriesStat,
  DefaultItemStat,
  HomelessnessStat,
  InterventionDurationStat,
  InterventionStat,
  InterventionsWithNumberValueStat,
  InterventionWithTextStat,
  ProblematicStat,
  ReportStats,
} from './reportTypes';

const m = defineMessages({
  from: {
    id: 'Report.InterventionDuration.From',
    defaultMessage: 'De',
  },
  to: {
    id: 'Report.InterventionDuration.To',
    defaultMessage: 'à',
  },
  minutes: {
    id: 'Report.InterventionDuration.Minutes',
    defaultMessage: 'minutes',
  },
});

const interventionDurationGroupRangeInMinutes = 15;

const getDemographiesPersonCount = (demographies: EntryDemography[]): number =>
  sumBy(demographies, (demography) => demography.count);

const getEntriesDefaultStat = (
  entries: Entry[],
  demographiesByEntryId: Record<number, EntryDemography[]>,
  totalPersonCount: number
) => {
  const personCount = sumBy(entries, (entry) =>
    getDemographiesPersonCount(demographiesByEntryId[entry.id])
  );

  return {
    entryCount: entries.length,
    personCount,
    personPercent:
      totalPersonCount > 0 ? (personCount / totalPersonCount) * 100 : 0,
  };
};

const getGroupedEntriesDefaultStat = (
  groupedEntries: Record<string, Entry[]>,
  demographiesByEntryId: Record<number, EntryDemography[]>,
  totalEntryCount: number,
  totalPersonCount: number
): DefaultGroupedEntriesStat => {
  const itemsStat = Object.entries(groupedEntries).map(
    ([groupName, entries]) => {
      const demographies = entries.flatMap(
        (entry) => demographiesByEntryId[entry.id]
      );

      const personCount = getDemographiesPersonCount(demographies);

      return {
        name: groupName,
        entryCount: entries?.length ?? 0,
        personCount: personCount,
        personPercent:
          totalPersonCount > 0 ? (personCount / totalPersonCount) * 100 : 0,
      };
    }
  );

  return {
    entryCount: totalEntryCount,
    personCount: totalPersonCount,
    itemsStat: itemsStat,
  };
};

const getGroupedDemographiesDefaultStat = (
  groupedDemographies: Record<string, EntryDemography[]>,
  totalPersonCount: number
): DefaultGroupedDemographiesStat => {
  const itemsStat = Object.entries(groupedDemographies).map(
    ([groupName, demographies]) => {
      const personCount = getDemographiesPersonCount(demographies);

      return {
        name: groupName,
        entryCount: uniq(demographies.map((demography) => demography.entryId))
          .length,
        personCount: personCount,
        personPercent:
          totalPersonCount > 0 ? (personCount / totalPersonCount) * 100 : 0,
      };
    }
  );

  return {
    personCount: totalPersonCount,
    itemsStat: itemsStat,
  };
};

const getAgeGroupsStat = (
  groupedDemographies: Record<string, EntryDemography[]>,
  totalPersonCount: number
): DefaultGroupedDemographiesStat => {
  const itemsStat = Object.entries(groupedDemographies).map(
    ([groupName, demographies]) => {
      const personCount = getDemographiesPersonCount(demographies);

      return {
        name: groupName,
        entryCount: uniq(demographies.map((demography) => demography.entryId))
          .length,
        personCount: personCount,
        personPercent:
          totalPersonCount > 0 ? (personCount / totalPersonCount) * 100 : 0,
        orderKey: Number(groupName.match(/\d+/)?.shift()) || 0,
      };
    }
  );

  return {
    personCount: totalPersonCount,
    itemsStat: itemsStat,
  };
};

interface Props {
  lists: EntryLists;
  users: UserList;
  entryQueryResult?: EntryQueryResult;
}

export const useReportStat = ({lists, users, entryQueryResult} : Props) => {
  const { formatMessage } = useIntl();

  const getInterventionStats = useCallback(
    (
      entries: Entry[],
      demographiesByEntryId: Record<number, EntryDemography[]>
    ): InterventionStat[] => {
      const totalPersonCount = sumBy(entries, (entry) =>
        getDemographiesPersonCount(demographiesByEntryId[entry.id])
      );

      const personCountsByInterventionName = entries.reduce((result, entry) => {
        const entryInterventionNames = uniq(
          entry.interventions.map(
            (i) =>
              lists.interventions.indexedInterventions[i.interventionId].name
          )
        );

        const entryPersonCount = sumBy(
          demographiesByEntryId[entry.id],
          (demography) => demography.count
        );

        entryInterventionNames.forEach((name) => {
          if (!has(result, name)) {
            result[name] = [];
          }

          result[name].push(entryPersonCount);
        });

        return result;
      }, {} as Record<string, number[]>);

      const stats = Object.keys(personCountsByInterventionName).map((name) => {
        const personCount = sum(personCountsByInterventionName[name]);

        return {
          name: name,
          count: personCountsByInterventionName[name].length,
          personCount,
          personPercent:
            totalPersonCount > 0 ? (personCount / totalPersonCount) * 100 : 0,
        };
      });

      return stats;
    },
    [lists.interventions.indexedInterventions]
  );

  const getInterventionsWithNumberValueStats = useCallback(
    (entries: Entry[]): InterventionsWithNumberValueStat => {
      const allInterventionsWithNumberValue = entries
        .flatMap((entry) => entry.interventions)
        .filter((intervention) => (intervention.numberValue ?? 0) > 0);

      const interventionsByInterventionId = groupBy(
        allInterventionsWithNumberValue,
        (intervention) => intervention.interventionId
      );

      const total = sumBy(
        allInterventionsWithNumberValue,
        (intervention) => intervention.numberValue as number
      );

      const itemsStat: CountItemStat[] = Object.entries(
        interventionsByInterventionId
      ).map(([interventionId, interventions]) => {
        const count = sumBy(
          interventions,
          (intervention) => intervention.numberValue as number
        );

        return {
          name: lists.interventions.indexedInterventions[
            parseInt(interventionId)
          ].name,
          count,
          countPercent: total > 0 ? (count / total) * 100 : 0,
        };
      });

      return {
        total,
        itemsStat,
      };
    },
    [lists.interventions.indexedInterventions]
  );

  const getProblematicStats = useCallback(
    (
      entries: Entry[],
      demographiesByEntryId: Record<number, EntryDemography[]>
    ): ProblematicStat => {
      const totalPersonCount = sumBy(entries, (entry) =>
        getDemographiesPersonCount(demographiesByEntryId[entry.id])
      );

      const personCountsByProblematicId = entries.reduce((result, entry) => {
        const entryPersonCount = sumBy(
          demographiesByEntryId[entry.id],
          (demography) => demography.count
        );

        entry.problematicIds.forEach((problematicId) => {
          if (!has(result, problematicId)) {
            result[problematicId] = [];
          }

          result[problematicId].push(entryPersonCount);
        });

        return result;
      }, {} as Record<number, number[]>);

      const itemsStat = Object.entries(personCountsByProblematicId).map(
        ([problematicId, personCounts]) => {
          const personCountsSum = sum(personCounts);

          return {
            name: (lists.problematics.indexedProblematics as any)[problematicId]
              .name,
            count: personCounts.length,
            personCount: personCountsSum,
            personPercent:
              totalPersonCount > 0
                ? (personCountsSum / totalPersonCount) * 100
                : 0,
          };
        }
      );

      return { personCount: totalPersonCount, itemsStat };
    },
    [lists.problematics.indexedProblematics]
  );

  const getInterventionDurationStats = useCallback(
    (
      entries: Entry[],
      demographiesByEntryId: Record<number, EntryDemography[]>
    ): InterventionDurationStat => {
      const interventionCount = entries.length;
      const totalDurationInMinutes = sumBy(
        entries,
        (entry) => entry.interventionDurationInMinute ?? 0
      );
      const totalPersonCount = sumBy(entries, (entry) =>
        getDemographiesPersonCount(demographiesByEntryId[entry.id])
      );

      const entriesByDurationRangeMultiple = groupBy(entries, (entry) =>
        Math.ceil(
          entry.interventionDurationInMinute /
            interventionDurationGroupRangeInMinutes
        )
      );

      const itemsStat: DefaultItemStat[] = Object.entries(
        entriesByDurationRangeMultiple
      ).map(([rangeMultiple, entriesInRange]) => {
        const personCount = sumBy(entriesInRange, (entry) =>
          getDemographiesPersonCount(demographiesByEntryId[entry.id])
        );

        const rangeTo =
          parseInt(rangeMultiple) * interventionDurationGroupRangeInMinutes;
          
        const rangeFrom = rangeTo - interventionDurationGroupRangeInMinutes + 1;

        return {
          name: `${formatMessage(m.from)} ${rangeFrom} ${formatMessage(
            m.to
          )} ${rangeTo} ${formatMessage(m.minutes)}`,
          orderKey: rangeFrom,
          entryCount: entriesInRange.length,
          personCount,
          personPercent:
            totalPersonCount > 0 ? (personCount / totalPersonCount) * 100 : 0,
        };
      });

      return {
        averageDurationInMinutes:
          interventionCount > 0
            ? totalDurationInMinutes / interventionCount
            : 0,
        personCount: totalPersonCount,
        itemsStat,
      };
    },
    [formatMessage]
  );

  const getInterventionsWithTextValueStats = useCallback(
    (entries: Entry[]): InterventionWithTextStat[] => {
      const interventionsWithText = entries
        .flatMap((entry) => entry.interventions)
        .filter((intervention) => intervention.textValue);

      const interventionsById = groupBy(
        interventionsWithText,
        (intervention) => intervention.interventionId
      );

      const stats = Object.entries(interventionsById).map(
        ([interventionId, interventions]) => {
          const textValues = interventions.map(
            (intervention) => intervention.textValue
          );
          const textValuesCount = countBy(textValues);
          const itemsStat = Object.entries(textValuesCount).map(
            ([textValue, count]) => ({ textValue, count })
          );

          return {
            name: (lists.interventions.indexedInterventions as any)[
              interventionId
            ].name,
            itemsStat: itemsStat,
            total: textValues.length,
          };
        }
      );

      return stats;
    },
    [lists.interventions.indexedInterventions]
  );

  const getHomelessnessStats = useCallback(
    (
      entries: Entry[],
      demographiesByEntryId: Record<number, EntryDemography[]>
    ): HomelessnessStat => {
      const totalPersonCount = sumBy(entries, (entry) =>
        getDemographiesPersonCount(demographiesByEntryId[entry.id])
      );

      const personCountsByHomelessnessName = entries.reduce((result, entry) => {
        const entryHomelessnessNames = uniq(
          entry.homelessnessIds.map(
            (i) => lists.homelessnesses.indexedHomelessnesses[i].name
          )
        );

        const entryPersonCount = sumBy(
          demographiesByEntryId[entry.id],
          (demography) => demography.count
        );

        entryHomelessnessNames.forEach((name) => {
          if (!has(result, name)) {
            result[name] = [];
          }

          result[name].push(entryPersonCount);
        });

        return result;
      }, {} as Record<string, number[]>);

      const itemsStat = Object.keys(personCountsByHomelessnessName).map(
        (name) => {
          const personCount = sum(personCountsByHomelessnessName[name]);

          return {
            name: name,
            count: personCountsByHomelessnessName[name].length,
            personCount,
            personPercent:
              totalPersonCount > 0 ? (personCount / totalPersonCount) * 100 : 0,
          };
        }
      );

      return {
        entryCount: entries.length,
        personCount: totalPersonCount,
        itemsStat: itemsStat,
      };
    },
    [lists.homelessnesses.indexedHomelessnesses]
  );

  const getTapajProgressStats = useCallback(
    (
      entries: Entry[],
      demographiesByEntryId: Record<number, EntryDemography[]>
    ): HomelessnessStat => {
      const totalPersonCount = sumBy(entries, (entry) =>
        getDemographiesPersonCount(demographiesByEntryId[entry.id])
      );

      const personCountsByTapajProgressName = entries.reduce(
        (result, entry) => {
          const entryTapajProgressNames = uniq(
            entry.tapajProgressIds.map(
              (i) => lists.tapajProgresses.indexedTapajProgresses[i].name
            )
          );

          const entryPersonCount = sumBy(
            demographiesByEntryId[entry.id],
            (demography) => demography.count
          );

          entryTapajProgressNames.forEach((name) => {
            if (!has(result, name)) {
              result[name] = [];
            }

            result[name].push(entryPersonCount);
          });

          return result;
        },
        {} as Record<string, number[]>
      );

      const itemsStat = Object.keys(personCountsByTapajProgressName).map(
        (name) => {
          const personCount = sum(personCountsByTapajProgressName[name]);

          return {
            name: name,
            count: personCountsByTapajProgressName[name].length,
            personCount,
            personPercent:
              totalPersonCount > 0 ? (personCount / totalPersonCount) * 100 : 0,
          };
        }
      );

      return {
        entryCount: entries.length,
        personCount: totalPersonCount,
        itemsStat: itemsStat,
      };
    },
    [lists.tapajProgresses.indexedTapajProgresses]
  );

  const getReportStats = useCallback(() => {
    if (!entryQueryResult) {
      return undefined;
    }

    const {
      filteredEntries: entries,
      filteredDemographies: allDemographies,
      entryQuery: reportQuery,
    } = entryQueryResult;

    const demographiesByEntryId = groupBy(allDemographies, (d) => d.entryId);

    const entryCount = entries.length;
    const personCount = getDemographiesPersonCount(allDemographies);

    const entriesWithIntervention = entries.filter(
      (entry) => entry.hasIntervention
    );

    const entriesWithoutIntervention = entries.filter(
      (entry) => entry.hasIntervention === false
    );

    const entriesWithInterventionAndGroup = entriesWithIntervention.filter(
      (entry) => entry.meetingTypeId === MeetingType.Group
    );

    const entriesWithInterventionAndSinglePerson =
      entriesWithIntervention.filter(
        (entry) => entry.meetingTypeId === MeetingType.Single
      );

    const entriesWithoutInterventionAndGroup =
      entriesWithoutIntervention.filter(
        (entry) => entry.meetingTypeId === MeetingType.Group
      );

    const entriesWithoutInterventionAndSinglePerson =
      entriesWithoutIntervention.filter(
        (entry) => entry.meetingTypeId === MeetingType.Single
      );

    const entriesWithProblematics = entriesWithIntervention.filter(
      (entry) => (entry.problematicIds ?? []).length
    );

    const entriesWithHomelessness = entries.filter(
      (entry) => entry.hasHomelessness
    );

    const personCountWithHomelessness = sumBy(
      entriesWithHomelessness,
      (entry) => getDemographiesPersonCount(demographiesByEntryId[entry.id])
    );

    const entriesWithHomelessnessSituation = entries.filter(
      (entry) => entry.homelessnessSituationId
    );

    const entriesWithTapajProgress = entries.filter(
      (entry) => entry.hasTapajProgress
    );

    const entrieWithReferredBy = entries.filter((entry) => entry.referredBy);

    const personCountWithReferredBy = sumBy(entrieWithReferredBy, (entry) =>
      getDemographiesPersonCount(demographiesByEntryId[entry.id])
    );

    const stats: ReportStats = {
      allEntriesStat: getEntriesDefaultStat(
        entries,
        demographiesByEntryId,
        personCount
      ),
      entriesWithInterventionStat: getEntriesDefaultStat(
        entriesWithIntervention,
        demographiesByEntryId,
        personCount
      ),
      entriesWithInterventionAndSinglePersonStat: getEntriesDefaultStat(
        entriesWithInterventionAndSinglePerson,
        demographiesByEntryId,
        personCount
      ),
      entriesWithInterventionAndGroupStat: getEntriesDefaultStat(
        entriesWithInterventionAndGroup,
        demographiesByEntryId,
        personCount
      ),
      entriesWithoutInterventionAndSinglePersonStat: getEntriesDefaultStat(
        entriesWithoutInterventionAndSinglePerson,
        demographiesByEntryId,
        personCount
      ),
      entriesWithoutInterventionAndGroupStat: getEntriesDefaultStat(
        entriesWithoutInterventionAndGroup,
        demographiesByEntryId,
        personCount
      ),
      entriesWithProblematicsStat: getEntriesDefaultStat(
        entriesWithProblematics,
        demographiesByEntryId,
        personCount
      ),
      entriesWithHomelessnessStat: getEntriesDefaultStat(
        entriesWithHomelessness,
        demographiesByEntryId,
        personCount
      ),
      entriesWithTapajProgressStat: getEntriesDefaultStat(
        entriesWithTapajProgress,
        demographiesByEntryId,
        personCount
      ),
      caseworkersStat: getGroupedEntriesDefaultStat(
        groupBy(entries, (entry) => entry.createdByUserFullName),
        demographiesByEntryId,
        entryCount,
        personCount
      ),
      teamsStat: getGroupedEntriesDefaultStat(
        groupBy(
          entries,
          (entry) => lists.teams.indexedTeams[entry.teamId].name
        ),
        demographiesByEntryId,
        entryCount,
        personCount
      ),
      meetingTypesStat: getGroupedEntriesDefaultStat(
        groupBy(
          entries,
          (entry) =>
            lists.meetingTypes.indexedMeetingTypes[entry.meetingTypeId].name
        ),
        demographiesByEntryId,
        entryCount,
        personCount
      ),
      populationsStat: getGroupedEntriesDefaultStat(
        groupBy(
          entries,
          (entry) =>
            lists.populations.indexedPopulations[entry.populationId].name
        ),
        demographiesByEntryId,
        entryCount,
        personCount
      ),
      locationsStat: getGroupedEntriesDefaultStat(
        groupBy(
          entries,
          (entry) => lists.locations.indexedLocations[entry.locationId].name
        ),
        demographiesByEntryId,
        entryCount,
        personCount
      ),
      districtsStat: getGroupedEntriesDefaultStat(
        groupBy(
          entries,
          (entry) => lists.districts.indexedDistricts[entry.districtId].name
        ),
        demographiesByEntryId,
        entryCount,
        personCount
      ),
      momentsStat: getGroupedEntriesDefaultStat(
        groupBy(
          entries,
          (entry) => lists.moments.indexedMoments[entry.momentId].name
        ),
        demographiesByEntryId,
        entryCount,
        personCount
      ),
      contactStatesStat: getGroupedEntriesDefaultStat(
        groupBy(
          entries,
          (entry) =>
            lists.contactStates.indexedContactStates[entry.contactStateId].name
        ),
        demographiesByEntryId,
        entryCount,
        personCount
      ),
      initiatorsStat: getGroupedEntriesDefaultStat(
        groupBy(
          entries,
          (entry) => lists.initiators.indexedInitiators[entry.initiatorId].name
        ),
        demographiesByEntryId,
        entryCount,
        personCount
      ),
      demographiesCountStat: getGroupedEntriesDefaultStat(
        groupBy(entries, (entry) =>
          getDemographiesPersonCount(entry.demographies)
        ),
        demographiesByEntryId,
        entryCount,
        personCount
      ),
      gendersStat: getGroupedDemographiesDefaultStat(
        groupBy(
          allDemographies,
          (demography) => lists.genders.indexedGenders[demography.genderId].name
        ),
        personCount
      ),
      ageGroupsStat: getAgeGroupsStat(
        groupBy(
          allDemographies,
          (demography) =>
            lists.ageGroups.indexedAgeGroups[demography.ageGroupId].name
        ),
        personCount
      ),
      occupationsStat: getGroupedDemographiesDefaultStat(
        groupBy(
          allDemographies,
          (demography) =>
            lists.occupations.indexedOccupations[demography.occupationId].name
        ),
        personCount
      ),
      languagesStat: getGroupedDemographiesDefaultStat(
        groupBy(
          allDemographies,
          (demography) =>
            lists.interventionLanguages.indexedInterventionLanguages[
              demography.interventionLanguageId
            ].name
        ),
        personCount
      ),
      originsStat: getGroupedDemographiesDefaultStat(
        allDemographies.reduce((grouped, demography) => {
          for (const originId of demography.originIds) {
            const groupName = lists.origins.indexedOrigins[originId].name;

            if (!has(grouped, groupName)) {
              grouped[groupName] = [];
            }

            grouped[groupName].push(demography);
          }
          return grouped;
        }, {} as Record<number | string, EntryDemography[]>),
        personCount
      ),
      interventionsStat: getInterventionStats(
        entriesWithIntervention,
        demographiesByEntryId
      ),
      interventionDurationStat: getInterventionDurationStats(
        entriesWithIntervention,
        demographiesByEntryId
      ),
      interventionsWithNumberValueStat: getInterventionsWithNumberValueStats(
        entriesWithIntervention
      ),
      problematicsStat: getProblematicStats(
        entriesWithProblematics,
        demographiesByEntryId
      ),
      interventionsWithTextValueStat: getInterventionsWithTextValueStats(
        entriesWithIntervention
      ),
      homelessnessSituationsStat: getGroupedEntriesDefaultStat(
        groupBy(
          entriesWithHomelessnessSituation,
          (entry) =>
            lists.homelessnessSituations.indexedHomelessnessSituations[
              entry.homelessnessSituationId as number
            ].name
        ),
        demographiesByEntryId,
        entriesWithHomelessness.length,
        personCountWithHomelessness
      ),
      homelessnessesStat: getHomelessnessStats(
        entriesWithHomelessness,
        demographiesByEntryId
      ),
      tapajProgressesStat: getTapajProgressStats(
        entriesWithTapajProgress,
        demographiesByEntryId
      ),
      referredByStat: getGroupedEntriesDefaultStat(
        groupBy(entrieWithReferredBy, (entry) => entry.referredBy),
        demographiesByEntryId,
        entrieWithReferredBy.length,
        personCountWithReferredBy
      ),
      reportQuery,
      lists,
      users,
    };

    return stats;
  }, [
    getHomelessnessStats,
    getInterventionDurationStats,
    getInterventionStats,
    getInterventionsWithNumberValueStats,
    getInterventionsWithTextValueStats,
    getProblematicStats,
    getTapajProgressStats,
    lists,
    entryQueryResult,
    users,
  ]);

  const getReportStatsAsync = useCallback(() => {
    return new Promise<ReportStats | undefined>((success, failure) => {
      const stats = getReportStats();
      success(stats);
    });
  }, [getReportStats]);

  return {
    getReportStatsAsync,
  };
};
