import { defaultErrorHandler } from 'api';
import { ApplicationLoader } from 'components/ApplicationLoader';
import { PageHeader } from 'components/Layout/PageHeader';
import { PageLayout } from 'components/Layout/PageLayout';
import React, { Fragment, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { TeamsApi } from '../api';
import { TeamForm, TeamFormValues } from '../components/TeamForm';
import { useFetchTeam } from '../hooks/useFetchTeam';

const m = defineMessages({
  pageTitle: {
    id: 'Team.EditPage.PageTittle',
    defaultMessage: 'Modifier une équipe',
  },
  savedSuccessfully: {
    id: 'Team.EditPage.SavedSuccessfully',
    defaultMessage: "L'équipe a été sauvegardé avec succès.",
  },
});

interface Params {
  teamId: string;
}

export const EditTeamPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const params = useParams<Params>();
  const id = parseInt(params.teamId, 10);
  const { team } = useFetchTeam(id);
  const [saving, setSaving] = useState(false);
  const [teamFormValues, setTeamFormValues] = useState<TeamFormValues>();

  const returnToTeamsPage = () => {
    history.push(`/admin/teams`);
  };

  const handleSubmit = (editedTeam: TeamFormValues) => {
    setSaving(true);

    TeamsApi.update(id, editedTeam)
      .then(() => {
        toast.success(formatMessage(m.savedSuccessfully));
        returnToTeamsPage();
      })
      .catch((error) => defaultErrorHandler(error))
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    if (team) {
      setTeamFormValues({
        name: team.name,
        isActive: team.isActive,
        logo: `/api/teams/${team.id}/logo`,
      });
    } else {
      setTeamFormValues(undefined);
    }
  }, [team]);

  if (team === null) {
    return null;
  }

  const showLoader = !teamFormValues || saving;

  return (
    <Fragment>
      <PageLayout pageTitle={formatMessage(m.pageTitle)}>
        <PageHeader header={formatMessage(m.pageTitle)} iconName="users" />
        {teamFormValues && (
          <TeamForm team={teamFormValues} onSubmit={handleSubmit} />
        )}
        {showLoader && <ApplicationLoader />}
      </PageLayout>
    </Fragment>
  );
};
