import React from 'react';
import { PageLayout } from 'components/Layout/PageLayout';
import { PageHeader } from 'components/Layout/PageHeader';
import { defineMessages, useIntl } from 'react-intl';
import { useFetchProblematics } from '../hooks/useFetchProblematics';
import { ProblematicsTable } from '../components/ProblematicsTable';
import { ApplicationLoader } from 'components/ApplicationLoader';

const m = defineMessages({
  pageTitle: {
    id: 'Problematics.Page.Title',
    defaultMessage: 'Préoccupations et Problématiques',
  },
});

export const ProblematicsPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const { problematics, isFetched: areProblematicsFetched } =
    useFetchProblematics({ staleTime: 0 });

  const isLoaded = areProblematicsFetched;

  return (
    <PageLayout pageTitle={formatMessage(m.pageTitle)}>
      <PageHeader header={formatMessage(m.pageTitle)} iconName="warning sign" />

      {isLoaded ? (
        <ProblematicsTable problematics={problematics} />
      ) : (
        <ApplicationLoader />
      )}
    </PageLayout>
  );
};
