import React from 'react';
import { Header } from 'semantic-ui-react';

interface Props {
  headerMessage: string;
  subHeaderMessage: string;
  errorImage: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  buttons: React.ReactElement;
}

export const ErrorMessage: React.FC<Props> = ({
  headerMessage,
  subHeaderMessage,
  errorImage: ErrorImage,
  buttons,
}) => {
  
  const header = (
    <Header as="h1" className="tw-py-4">
      {headerMessage}
    </Header>
  );

  const subHeader = <p className="tw-text-lg">{subHeaderMessage}</p>;

  return (
    <div className="tw-h-full tw-flex tw-justify-center tw-items-center tw-p-10">
      <div
        className="tw-flex tw-flex-col tw-justify-center tw-items-stretch tw-text-center tw-max-"
        style={{ maxWidth: '50rem' }}
      >
        <ErrorImage />
        {header}
        {subHeader}
        <div className="tw-flex tw-justify-around tw-my-6">{buttons}</div>        
      </div>
    </div>
  );
};
