import { requests } from 'api';
import { TeamFormValues } from './components/TeamForm';
import { TeamSummary, Team } from './types';

const apiBaseUrl = '/api/teams';

const update = async (
  id: number,
  editedTeam: TeamFormValues
): Promise<void> => {
  return requests
    .patch(`${apiBaseUrl}/${id}`, {
      name: editedTeam.name,
      isActive: editedTeam.isActive,
    })
    .then(() => {
      if (editedTeam.newLogoFile) {
        return updateLogo(id, editedTeam.newLogoFile);
      } else if (editedTeam.newLogoFile == null) {
        return requests.del(`${apiBaseUrl}/${id}/logo`);
      }
    });
};

const create = (newTeam: TeamFormValues): Promise<void> => {
  return requests
    .post(apiBaseUrl, {
      name: newTeam.name,
      isActive: newTeam.isActive,
    })
    .then((createdTeam) => {
      if (newTeam.newLogoFile) {
        return updateLogo(createdTeam.id, newTeam.newLogoFile);
      }
    });
};

const updateLogo = (id: number, logo: File): Promise<void> => {
  const formData = new FormData();
  formData.append('logoFile', logo as File);
  return requests.patch(`${apiBaseUrl}/${id}/logo`, formData);
};

export const TeamsApi = {
  getAll: (): Promise<TeamSummary[]> => requests.get(apiBaseUrl),
  getById: (id: number): Promise<Team> => requests.get(`${apiBaseUrl}/${id}`),
  create: create,
  update: update,
};
