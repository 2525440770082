import React from 'react';
import { PageLayout } from 'components/Layout/PageLayout';
import { PageHeader } from 'components/Layout/PageHeader';
import { defineMessages, useIntl } from 'react-intl';
import { useFetchInterventions } from '../hooks/useFetchInterventions';
import { InterventionsTable } from '../components/InterventionsTable';
import { ApplicationLoader } from 'components/ApplicationLoader';

const m = defineMessages({
  pageTitle: {
    id: 'Interventions.Page.Title',
    defaultMessage: 'Interventions',
  },
});

export const InterventionsPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const { interventions, isFetched: areInterventionsFetched } =
    useFetchInterventions({ staleTime: 0 });

  const isLoaded = areInterventionsFetched;

  return (
    <PageLayout pageTitle={formatMessage(m.pageTitle)}>
      <PageHeader
        header={formatMessage(m.pageTitle)}
        iconName="hand-holding-heart"
      />

      {isLoaded ? (
        <InterventionsTable interventions={interventions} />
      ) : (
        <ApplicationLoader />
      )}
    </PageLayout>
  );
};
